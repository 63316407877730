import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Accordion, AccordionDetails, AccordionSummary, Box, colors, LinearProgress, Typography } from '@mui/material'
import Header from '../components/Header'
import Tabs from '../components/Tabs'
import { format } from 'date-fns'
import { useStore } from '../store/StoredData'
import { Link, useHistory } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RemoveRedEyeSharp, TableViewSharp } from '@mui/icons-material'

const Dashboard_NEW = () => {

    const { masterCategories, setMasterCategories, masterSubCategories, setMasterSubCategories, } = useStore();
    console.log('STORE MASTER CATEGORIES DATA ==== ', masterCategories);
    console.log('STORE MASTER SUB-CATEGORIES DATA ==== ', masterSubCategories);

    const [categoryData, setCategoryData] = useState([])
    const [subCategoryData, setSubCategoryData] = useState([])
    const [showTooltip, setShowTooltip] = useState(false)
    const history = useHistory()
    const [currentClickedCat, setCurrentClickedCat] = useState("")
    const [accordionOpen, setAccordionOpen] = useState(false)

    const getCategoryData = async () => {
        await axios.get('/api/article/category-dashboard')
            .then((resp) => {
                setCategoryData(resp.data.message)
                console.log("Category Data List ::", resp.data.message);
            }).catch((err) => console.error("Error ::", err))
    }

    const gotoCategoryTable = (categoryName) => {
        console.log('gotoCategoryTable() recieved cat_name === ', categoryName);
        const clickedCategoryID = masterCategories.filter((msc) => msc.name === categoryName)?.map(x => x.id);
        console.log("Clicked Category ID :::", categoryName, clickedCategoryID)
        history.push(`/dashboard/${clickedCategoryID}/0`)
    }

    const getSubcatData = (categoryName) => {
        console.log('getSubcatData() recieved cat_name === ', categoryName);
        const clickedCategoryID = masterCategories.filter((msc) => msc.name === categoryName)?.map(row => row.id)
        setCurrentClickedCat(categoryName)
        console.log("Clicked Category ID :::", categoryName, !accordionOpen && clickedCategoryID)
        setSubCategoryData([])
        if (!accordionOpen) {
            axios.get('/api/article/category-subcategory-dashboard/' + clickedCategoryID)
                .then((resp) => {
                    setSubCategoryData(resp.data.message)
                    console.log("Category Data List ::", resp.data.message);
                }).catch((err) => console.error("Error ::", err))
        }
    }

    useEffect(() => {
        getCategoryData()
    }, [])

    return (
        <>
            <Header />
            <Tabs />
            {/* <Box height='80vh' m={1} display='flex' flexWrap='wrap' justifyContent='center' style={{ overflowY: 'scroll' }}>
                {categoryData.map((cat) => (
                    <div title='Click To View Associated News Items' key={cat.category} style={{ width: '23%', cursor: 'pointer', padding: '24px', margin: '8px', border: '1px solid #0d47a1', borderRadius: "10px" }} onClick={() => gotoCategoryTable(cat.category)} >
                        <Box color={colors.blue[900]} fontSize={20} fontWeight={600}>{cat.category}</Box>
                        <Box py={1}><b>Total Count  : </b>{cat.count}</Box>
                        <Box ><b>Latest News : </b> {format(new Date(cat.last_logtime), "MMM dd, yyyy hh:mm:ss a")}</Box>
                    </div>
                ))}
            </Box> */}
            <Box mx={1} display='flex' flexDirection='row' flexWrap='wrap' justifyContent='flex-start' alignItems='flex-start' >
                {categoryData?.length ? categoryData.map((cat) => (
                    <Accordion style={{ width: "48%", margin: "5px" }} expanded={currentClickedCat === cat.category_name && accordionOpen}>
                        <AccordionSummary
                            // expandIcon={<ExpandMoreIcon />}
                            // aria-controls="panel1a-content"
                            // id="panel1a-header"
                            sx={{ pointerEvents: "auto" }}
                            expandIcon={<ExpandMoreIcon sx={{ pointerEvents: "auto" }} />}
                            onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault()
                                setAccordionOpen(!accordionOpen)
                                getSubcatData(cat.category_name)
                            }}
                        >
                            <Box display='flex' width='100%' justifyContent='flex-start' alignItems='center'>
                                <Box
                                    onClick={() => { gotoCategoryTable(cat.category_name) }}
                                    width='5%'
                                    style={{ cursor: 'pointer', textDecoration: 'none' }}
                                    role="button"
                                    title='View Articles List (Read Only)'>
                                    <TableViewSharp color='secondary' fontSize="small" />
                                </Box>
                                <Box width='30%' color={colors.indigo[900]} fontSize={15} fontWeight={'bold'} sx={{ pointerEvents: "auto" }}>{cat.category_name}</Box>
                                <Box width='25%' textAlign='left' fontSize={12} ><b>Total Count  : </b>{cat.cat_count}</Box>
                                <Box fontSize={12} ><b>Latest News: </b> {cat.latest_news ? format(new Date(cat.latest_news), "MMM dd, yyyy hh:mm:ss a") : null}</Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails >
                            {subCategoryData?.length ? subCategoryData.map(sub =>
                                <Box display='flex' justifyContent='space-between' alignItems='center' mt={1} textAlign='left' >
                                    <Box width='35%' fontSize={12} fontWeight={600} >{sub.subcategory_name}</Box>
                                    <Box width='25%' fontSize={12} mx={1}><b>Total Count: </b>{sub.subcat_count}</Box>
                                    <Box width='45%' fontSize={12} ><b>Latest News: </b> {sub.latest_news ? format(new Date(sub.latest_news), "MMM dd, yyyy hh:mm:ss a") : null}</Box>
                                </Box>
                            ) : <LinearProgress />}
                        </AccordionDetails>
                    </Accordion>
                )) : <Box width='100%'><LinearProgress /></Box>}
            </Box>
        </>
    )
}

export default Dashboard_NEW