import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, colors, TextField, MenuItem, Modal, Fade, Typography, CircularProgress, Autocomplete, Input, Button } from '@mui/material';
import { EditSharp, CheckBoxOutlined, CheckBoxSharp, CheckBoxRounded, ThumbsUpDownSharp, ThumbUpSharp, GppGoodSharp, GppBadSharp, SearchSharp } from '@mui/icons-material';
import axios from 'axios';
import ReactDatePicker from 'react-datepicker';
import { format } from 'date-fns';
import Header from '../components/Header';
import Tabs from '../components/Tabs';
import 'react-datepicker/dist/react-datepicker.css';

import '../App.css'

const CompanyVariance = () => {

    // const [companyDescription, setCompanyDescription] = useState([]);
    // const [industry, setIndustry] = useState([]);
    // const [city, setCity] = useState([]);
    // const [state, setState] = useState([]);
    // const [companyWebsite, setcompanyWebsite] = useState([])
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [companyVarianceList, setCompanyVarianceList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [financialUnitsList] = useState([
        { text: "Actual Numbers", value: "W" },
        { text: "Thousands", value: "K" },
        { text: "Millions", value: "M" },
        { text: "Billions", value: "B" },
        { text: "Trillions", value: "T" },
    ]);
    const [genderList] = useState([
        { text: "Male", value: 'M' },
        { text: "Female", value: 'F' },
        // { text: "Unknown", value: '' },
    ]);
    const [selectedGender, setSelectedGender] = useState();
    const [sicGroups, setSicGoups] = useState([]);
    const [selectedSicGroup, setSelectedSicGoup] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState();
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [searchTicker, setSearchTicker] = useState('');
    const [companies, setCompanies] = useState([]);
    const [companyVarianceReference, setCompanyVarianceReference] = useState({
        company_friendly_name: "",
        company_alias: "",
        ticker: "",
        exchange_traded: "",
        type_of_filer: "",
        company_priority: "",
        cik: "",
        company_name: "",
        company_overiew: "",
        bs_units: "",
        is_units: "",
        cf_units: "",
        latest_revenue: "",
        latest_net_income: "",
        latest_eps: "",
        employees_count: "",
        website: "",
        ceo: "",
        gender: "",
        sic_id: "",
        sic_grp_code: "",
        sic_grp_name: "",
        city: "",
        state: "",
        country: "",
        headquarters: "",
        currency: "",
        phone_number: "",
        date_filed: "",
        form_group: "",
        omp: "",
        sk_mst_company_overview: "",
        sk_txn_filing_overview: "",
        updated_by: "",
        updated_timestamp: "",
    });
    const [companyVarianceEditable, setCompanyVarianceEditable] = useState({
        company_friendly_name: "",
        company_alias: "",
        ticker: "",
        exchange_traded: "",
        type_of_filer: "",
        company_priority: "",
        cik: "",
        company_overiew: "",
        bs_units: "",
        is_units: "",
        cf_units: "",
        latest_revenue: "",
        latest_net_income: "",
        latest_eps: "",
        employees_count: "",
        website: "",
        ceo: "",
        gender: "",
        sic_id: "",
        sic_grp_code: "",
        sic_grp_name: "",
        city: "",
        state: "",
        country: "",
        headquarters: "",
        currency: "",
        phone_number: "",
        date_filed: "",
        form_group: "",
        omp: "",
        sk_mst_company_overview: "",
        sk_txn_filing_overview: "",
        updated_by: currentUser.name,
        updated_timestamp: new Date()
    });
    const [loading, setLoading] = useState(false);
    const [cardLoading, setCardLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const [showDropdown, setShowDropdown] = useState(true);
    const [companySearchText, setCompanySearchText] = useState('');
    const inputRef = useRef();

    const handleDateRange = (currDt) => {
        setDateRange((prvDt) => {
            if (prvDt === currDt) {
                return currDt;
            } else {
                return currDt;
            }
        });
    };

    const openModal = async (item) => {
        if (item) {
            setCardLoading(true)
            setIsModalOpen(true)
            setShowDropdown(false)
            let cik = item.cik;
            let date_filed = format(new Date(item.date_filed), 'yyyy-MM-dd');
            await axios.get('/api/company-card/v2/variance/details/' + cik + '/' + date_filed).then(resp => {
                console.log('company-card/v2/variance/details API RESP === ', resp.data.message.length, resp.data.message);
                setCompanyVarianceEditable(prev => ({ ...prev, ...resp.data.message[0] }));
                setSelectedSicGoup({
                    sic_id: resp.data.message[0].sic_id || '',
                    sic_name: resp.data.message[0].sic_name || '',
                    sic_grp_code: resp.data.message[0].sic_group || '',
                    sic_grp_name: resp.data.message[0].sic_group_desc || '',
                })
                if (resp.data.message[1])
                    setCompanyVarianceReference(prev => ({ ...prev, ...resp.data.message[1] }));
                setCardLoading(false);
            }).catch(error => {
                console.error(`Error From variance get details API - `, error);
                setCardLoading(false);
            });
        }
    };

    const openModalTicker = async (item) => {
        if (item) {
            setCardLoading(true);
            setIsModalOpen(true);
            let cik = item.cik;
            let date_filed = format(new Date(dateRange[1]), 'yyyy-MM-dd');
            await axios.get('/api/company-card/v2/variance/details/' + cik + '/' + date_filed)
                .then(resp => {
                    console.log('company-card/v2/variance/details API RESP === ', resp.data.message.length, resp.data.message);
                    setCompanyVarianceEditable(resp.data.message[0]);
                    setSelectedSicGoup({
                        sic_id: resp.data.message[0].sic_id || '',
                        sic_name: resp.data.message[0].sic_name || '',
                        sic_grp_code: resp.data.message[0].sic_group || '',
                        sic_grp_name: resp.data.message[0].sic_group_desc || '',
                    })
                    if (resp.data.message[1])
                        setCompanyVarianceReference(resp.data.message[1]);
                    setCardLoading(false);
                }).catch(error => {
                    console.error(`Error From variance get details API - `, error);
                    setCardLoading(false);
                })
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const fetchVarianceDataByDaterange = async () => {
        setLoading(true);
        setCompanyVarianceList([])
        try {
            const response = await axios.get(`api/company-card/v2/variance/${format(new Date(dateRange[0]), "yyyy-MM-dd")}/${format(new Date(dateRange[1]), "yyyy-MM-dd")}`);
            if (response.data.message.length > 0) {
                console.log('CARD Variance API RESP === ', response.data.message);
                setCompanyVarianceList(response.data.message);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching API data:', error);
            setLoading(false);
        }
    };

    // const fetchVarianceDataByTicker = async () => {
    //     setLoading(true);
    //     setCompanyVarianceList([])
    //     try {
    //         const response = await axios.get(`api/company-card/v2/variance/${searchTicker}`);
    //         if (response.data.message.length > 0)
    //             setCompanyVarianceList(response.data.message);
    //         setLoading(false);
    //     } catch (error) {
    //         console.error('Error fetching API data:', error);
    //         setLoading(false);
    //     }
    // };

    useEffect(() => {
        axios.get('api/company-card/countries')
            .then(resp => {
                let c = resp.data.message.map(x => ({ country_name: x.country_name, country_code: x.country_code }))
                setCountries(c)
            }).catch(error => console.error('Error From Countries API - ', error));
    }, []);

    useEffect(() => {
        axios.get('api/company-card/currencies')
            .then(resp => {
                let c = resp.data.message.map(x => x.currency_code)
                setCurrencies(c)
            }).catch(error => console.error('Error From Currencies API - ', error));
    }, []);

    useEffect(() => {
        axios.get('api/company-card/master-sic-groups')
            .then(resp => setSicGoups(resp.data.message))
            .catch(error => console.error('Error From Sic Groups API - ', error));
    }, []);

    useEffect(() => {
        if (dateRange[1]) {
            fetchVarianceDataByDaterange();
        }
        setCurrentUser(JSON.parse(localStorage.user).name);
    }, [dateRange[1], localStorage.user]);

    const handleClose = () => {
        setIsModalOpen(false);
        fetchVarianceDataByDaterange();
    };

    const handleSicGroupChange = (e, value) => {
        console.log('Selected SIC GROUP OPTION = ', value);
        setSelectedSicGoup(value);
    };

    const handleCountryChange = (e, value) => {
        console.log('Selected Country OPTION = ', value);
        setSelectedCountry(value);
        setCompanyVarianceEditable(x => ({ ...x, country: value.country_name }));
    };

    const handleCurrencyChange = (e, value) => {
        console.log('Selected Currency OPTION = ', value);
        setSelectedCurrency(value);
        setCompanyVarianceEditable(x => ({ ...x, currency: value }));
    };

    const handleSubmission = (isCardApproved) => {
        console.log('PREPARED UPDATE API CALL DATA :::::::::: ', { companyVarianceReference, companyVarianceEditable });
        setLoading(true);
        axios.put(`/api/company-card/v2/vairance`, {
            cik: companyVarianceEditable.cik,
            company_friendly_name: companyVarianceEditable.company_friendly_name,
            company_alias: companyVarianceEditable.company_alias,
            ticker: companyVarianceEditable.ticker,
            type_of_filer: companyVarianceEditable.type_of_filer,
            company_overview: companyVarianceEditable.company_overiew,
            sic: selectedSicGroup.sic_id,
            sic_desc: selectedSicGroup.sic_name,
            sic_group: selectedSicGroup.sic_grp_code,
            sic_group_desc: selectedSicGroup.sic_grp_name,
            industry: selectedSicGroup.sic_grp_name,
            city: companyVarianceEditable.city,
            state: companyVarianceEditable.state,
            country: companyVarianceEditable.country,
            currency: companyVarianceEditable.currency,
            website: companyVarianceEditable.website,
            ceo: companyVarianceEditable.ceo,
            gender: companyVarianceEditable.gender,
            phone: companyVarianceEditable.phone_number,
            bs_units: companyVarianceEditable.bs_units,
            cf_units: companyVarianceEditable.cf_units,
            is_units: companyVarianceEditable.is_units,
            username: currentUser,
            updated_by: companyVarianceEditable.updated_by,
            is_card_approved: isCardApproved,
            exchange_traded: companyVarianceEditable.exchange_traded,
            company_priority: companyVarianceEditable.company_priority,
            employees_count: companyVarianceEditable.employees_count,
            latest_revenue: companyVarianceEditable.latest_revenue,
            latest_net_income: companyVarianceEditable.latest_net_income,
            latest_eps: companyVarianceEditable.latest_eps,
            headquarters: companyVarianceEditable.headquarters
        }).then((response) => {
            alert(`Successfuly Updated CARD!`, response.data.success);
            // document.location.reload();
            handleClose();
            fetchVarianceDataByDaterange();
        }).catch(err => {
            console.error(`Error From UPDATE API - `, err);
            alert(`Error While Saving the Data:\n${err.response.data.message}`);
            // handleClose();
        });
    };

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 300);
        };
    };

    // Implemented Race Condition
    let fetchController;
    const handleChange = async (event) => {
        if (isFocused == true) setIsFocused(false);
        setCompanies([]);
        setCompanySearchText(event.target.value);

        // Cancel the previous fetch if it's still ongoing
        if (fetchController) {
            fetchController.abort();
        }

        // Create a new AbortController for the new fetch
        fetchController = new AbortController();
        const { signal } = fetchController;

        let searchKey = event.target.value.trim();
        if (searchKey.length >= 1) {
            console.log(`Calling Company Search API === /api/company-card/companies?s=${searchKey}`)
            try {
                const response = await fetch(`/api/company-card/companies?s=${searchKey}`, {
                    signal
                });
                if (response.ok) {
                    const data = await response.json();
                    if (!signal.aborted) {
                        setCompanies(data.message);
                    }
                }
            } catch (error) {
                if (error.name !== 'AbortError') {
                    console.error('Error While Fetching Company Search API - ', searchKey);
                    console.error('Fetch failed:', error);
                }
            }
        }
    };

    const optimizedFn = useCallback(debounce(handleChange), []);

    // const handleClickDropdown = (val) => {
    //     inputRef.current.value = val.company_name;
    //     setCompanySearchText(inputRef.current.value);
    //     router.push("/company/" + val.ticker);
    //     setCompanies([]);
    // };

    return (
        <div>
            <Header />
            <Tabs />
            <Box p={1} boxShadow={1}>
                <Box m={0.5}>
                    <Box m={0.5} display="flex" flexDirection="row" justifyContent="flex-start">
                        <Box item mr={3} fontWeight={500} color="orchid">Date Range:</Box>
                        <Box item mr={10}>
                            <ReactDatePicker id="ReactDatePicker"
                                className='ReactDatePicker'
                                dateFormat="MMM dd, yyyy"
                                selectsRange={true}
                                startDate={dateRange[0]}
                                endDate={dateRange[1]}
                                maxDate={new Date()}
                                onChange={date_range => handleDateRange(date_range)
                                }
                            />
                        </Box>
                        {/* <label htmlFor="ticker">Search By Ticker:&nbsp;</label>
                        <Input style={{ margin: '0 10px' }} name='ticker' label='Search By Ticker' value={searchTicker} onChange={e => setSearchTicker(e.target.value)} />
                        <Button type='button' variant='contained' size='small' endIcon={<SearchSharp />} color='secondary' onClick={fetchVarianceDataByTicker} className='btn btn-secondary' >Search</Button> */}
                        <div className="input-field" style={{ position: 'relative' }}>
                            <input
                                type="text"
                                autoComplete='off'
                                className="form-control"
                                name="search-companies"
                                ref={inputRef}
                                placeholder="Search By Ticker / Company Name"
                                defaultValue={companySearchText}
                                style={{ width: 250, border: 'none', border: '1px solid #CCC', borderRadius: 3, padding: '5px 10px' }}
                                onChange={optimizedFn}
                                // onKeyDown={(e) => onPressEnter(e)}
                                onFocus={() => setShowDropdown(true)}
                            // onBlur={() => setShowDropdown(false)}
                            // onBlurCapture={optimizedFn}
                            />
                            {showDropdown && companies.length > 0
                                ? <ul style={{ position: 'absolute', listStyle: 'none', top: 10, left: 0, width: "max-content", display: "flex", flexDirection: 'column', justifyContent: "flex-start", alignItems: "start", background: '#fff', border: "1px solid #ccc", zIndex: 9, padding: "2px 10px", boxShadow: "5px 5px 10px #00000025" }}>
                                    {companies?.map(value =>
                                        <li id="c-dropdown" key={value.ticker} style={{ cursor: 'pointer', padding: "10px", fontSize: '0.85rem' }} onClick={() => openModalTicker(value)} className="nav-link">{value.company_name}&nbsp;<b>[{value.ticker}]</b></li>
                                    )}
                                </ul>
                                : null}
                        </div>
                    </Box>
                    {loading
                        ? <div style={{ width: '100%', textAlign: 'center', color: "GrayText", fontWeight: 600, padding: "25px 0" }}>Loading...</div>
                        : <Box style={{ marginTop: '10px', width: '100%', height: '72vh', overflow: 'auto' }}>
                            <table style={{ borderCollapse: 'collapse', fontSize: '0.75rem' }}>
                                <caption style={{ textAlign: 'left' }}>Total Records:&ensp;{companyVarianceList.length}</caption>
                                <thead className='table-dark'>
                                    <tr style={{ border: '1px solid #ccc', backgroundColor: 'rgb(40, 53, 147)', color: '#FAFAFA' }}>
                                        <th style={{ border: '1px solid #ccc', padding: '0.25rem' }}>Action</th>
                                        <th style={{ border: '1px solid #ccc', padding: '0.25rem' }}>Status</th>
                                        <th style={{ border: '1px solid #ccc', padding: '0.25rem' }}>CIK</th>
                                        <th style={{ border: '1px solid #ccc', padding: '0.25rem' }}>Ticker</th>
                                        <th style={{ width: 150, border: '1px solid #ccc', padding: '0.25rem' }}>Company Name</th>
                                        <th style={{ border: '1px solid #ccc', padding: '0.25rem' }}>Form group</th>
                                        <th style={{ width: 70, border: '1px solid #ccc', padding: '0.25rem' }}>Date Filed</th>
                                        <th style={{ border: '1px solid #ccc', padding: '0.25rem', width: 150 }}>Company Overview</th>
                                        <th style={{ border: '1px solid #ccc', padding: '0.25rem' }} >Industry</th>
                                        <th style={{ border: '1px solid #ccc', padding: '0.25rem' }}>State</th>
                                        <th style={{ border: '1px solid #ccc', padding: '0.25rem' }}>City</th>
                                        <th style={{ border: '1px solid #ccc', padding: '0.25rem' }}>BS Units</th>
                                        <th style={{ border: '1px solid #ccc', padding: '0.25rem' }}>IS Units</th>
                                        <th style={{ border: '1px solid #ccc', padding: '0.25rem' }}>CF Units</th>
                                        <th style={{ width: 120, border: '1px solid #ccc', padding: '0.25rem' }}>Ceo </th>
                                        <th style={{ border: '1px solid #ccc', padding: '0.25rem' }}>Company Website</th>
                                        <th style={{ border: '1px solid #ccc', padding: '0.25rem' }}>Company Details ID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {companyVarianceList.length > 0
                                        ? companyVarianceList.map((item, idx) => <tr>
                                            <td style={{ border: '1px solid #ccc', padding: '0.25rem' }}>
                                                <Box
                                                    px={1}
                                                    style={{ cursor: 'pointer', textDecoration: 'none' }}
                                                    role="button"
                                                    title='Edit Company Varience'>
                                                    <EditSharp color='secondary' fontSize="small" onClick={() => openModal(item)} />
                                                </Box>
                                            </td>
                                            <td style={{ border: '1px solid #ccc', padding: '0.25rem', textAlign: 'center' }}>{item?.is_card_approved ? <GppGoodSharp titleAccess='CARD Is Approved' sx={{ fontSize: 24, color: 'green' }} /> : <GppBadSharp titleAccess='CARD Is Unpproved' sx={{ fontSize: 24, color: 'orangered' }} />}</td>
                                            <td style={{ border: '1px solid #ccc', padding: '0.25rem' }}>{item?.cik || ''}</td>
                                            <td style={{ border: '1px solid #ccc', padding: '0.25rem' }}>{item?.ticker || ''}</td>
                                            <td style={{ border: '1px solid #ccc', padding: '0.25rem' }}>{item?.company_name || ''}</td>
                                            <td style={{ border: '1px solid #ccc', padding: '0.25rem' }}>{item?.form_group || ''}</td>
                                            <td style={{ border: '1px solid #ccc', padding: '0.25rem' }}>{format(new Date(item?.date_filed), 'MMM dd, yy')}</td>
                                            <td className='overview-box' style={{ border: '1px solid #CCC' }}>{item.company_overview ? item?.company_overview?.split(/\s/)?.slice(0, 14)?.join(' ') + '...' : ''}</td>
                                            <td style={{ border: '1px solid #ccc', padding: '0.25rem' }}>{item?.sic_group_desc || ''}</td>
                                            <td style={{ border: '1px solid #ccc', padding: '0.25rem' }}>{item?.city || ''}</td>
                                            <td style={{ border: '1px solid #ccc', padding: '0.25rem' }}>{item?.state || ''}</td>
                                            <td style={{ border: '1px solid #ccc', padding: '0.25rem', textAlign: 'center' }}>{item?.bs_units || ''}</td>
                                            <td style={{ border: '1px solid #ccc', padding: '0.25rem', textAlign: 'center' }}>{item?.is_units || ''}</td>
                                            <td style={{ border: '1px solid #ccc', padding: '0.25rem', textAlign: 'center' }}>{item?.cf_units || ''}</td>
                                            <td style={{ border: '1px solid #ccc', padding: '0.25rem' }}>{item?.ceo || ''}</td>
                                            <td style={{ border: '1px solid #ccc', padding: '0.25rem' }}>{item?.company_website || ''}</td>
                                            <td style={{ border: '1px solid #ccc', padding: '0.25rem', textAlign: 'right' }}>{item?.sk_mst_company_overview || ''}</td>
                                        </tr>)
                                        : <tr><td colSpan={17}><div style={{ textAlign: 'center', color: "orangered", fontWeight: 500, fontSize: 18, padding: "25px 0" }}>No Variance Data for Selected Date Range</div></td></tr>}
                                </tbody>
                            </table>
                        </Box>}
                </Box>
            </Box>

            {/* EDIT VARIANCE CARD MODAL */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={isModalOpen}
                // onClose={handleClose}
                closeAfterTransition
                sx={{ height: '65vh', marginTop: '10vh' }}
            >
                <Fade in={isModalOpen}>
                    {cardLoading
                        ? <div style={{ backgroundColor: '#FFF', border: '2px solid transparent', boxShadow: "5px 5px 5px solid #CCC", padding: 10, textAlign: 'center' }}>
                            <CircularProgress color='secondary' />
                        </div>
                        : <div style={{ backgroundColor: '#FFF', border: '2px solid transparent', boxShadow: "5px 5px 5px solid #CCC", padding: 10 }}>
                            <Box display="flex" flexDirection="row" justifyContent="space-between" px={3} py={1} fontWeight={500} bgcolor={colors.teal[50]} color={colors.blueGrey[700]} fontSize={18} id="CompanyVarience">
                                <Typography variant='h6' fontWeight='bold' color={colors.blueGrey[700]} mr={10}>Edit Company CARD:&ensp;</Typography>
                                <Typography variant='subtitle1' color={colors.purple[500]} mr={10}>
                                    <strong>SEC Conformed Name (as is from sec.gov):</strong>&emsp;{companyVarianceReference.company_confirmed_name}
                                </Typography>
                                <Typography variant='subtitle1' color={colors.purple[500]}>
                                    <strong>CIK:</strong>&emsp;{companyVarianceReference.cik}
                                </Typography>
                            </Box>
                            {companyVarianceEditable
                                ? <>
                                    <Box display='flex' flexDirection='row' width='100%' justifyContent='center'>
                                        <Box border={1} borderColor={colors.blueGrey[300]} p={1} width='100%'>
                                            <Box display="flex" flexDirection="flex-start" justifyContent="flex-start">
                                                <Typography variant='subtitle1' color={colors.purple[500]} mr={10}>
                                                    <strong>Current Filing:</strong>&emsp;
                                                    <a target='_blank' href={`http://curatortool.com/omp/${companyVarianceEditable.omp}`}>{companyVarianceEditable.form_group} As On {new Date(companyVarianceEditable.date_filed).toDateString()}</a>
                                                </Typography>
                                                <Typography variant='subtitle1' color={colors.purple[500]}>
                                                    <strong>Previous Filing:</strong>&emsp;
                                                    <a target='_blank' href={`http://curatortool.com/omp/${companyVarianceReference.irk_fs_platter_omp}`}>{companyVarianceReference.form_group} As On {new Date(companyVarianceReference.date_filed).toDateString()}</a>
                                                </Typography>
                                            </Box>
                                            <hr />
                                            <Box display="flex" flexDirection="row" justifyContent="flex-start" flexWrap='wrap'>
                                                <TextField
                                                    required
                                                    autoComplete='off'
                                                    name="company_friendly_name"
                                                    id="CompanyFriendlyName"
                                                    label="Company Friendly Name"
                                                    variant="outlined"
                                                    value={companyVarianceEditable.company_friendly_name}
                                                    // variant="standard"
                                                    size='small'
                                                    sx={{ width: 350, m: 2 }}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, company_friendly_name: e.target.value })}
                                                />
                                                <TextField
                                                    autoComplete='off'
                                                    name="company_alias"
                                                    id="CompanyAlias"
                                                    label="Company Alias"
                                                    variant="outlined"
                                                    value={companyVarianceEditable.company_alias}
                                                    // variant="standard"
                                                    size='small'
                                                    sx={{ width: 180, m: 2 }}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, company_alias: e.target.value })}
                                                />
                                                <TextField
                                                    required
                                                    autoComplete='off'
                                                    name="ticker"
                                                    id="Ticker"
                                                    label="Ticker"
                                                    variant="outlined"
                                                    value={companyVarianceEditable.ticker}
                                                    // variant="standard"
                                                    size='small'
                                                    sx={{ width: 150, m: 2 }}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, ticker: e.target.value })}
                                                />
                                                <TextField
                                                    autoComplete='off'
                                                    name="exchange_traded"
                                                    id="ExchangeTraded"
                                                    label="Exchange Traded"
                                                    variant="outlined"
                                                    value={companyVarianceEditable.exchange_traded}
                                                    // variant="standard"
                                                    size='small'
                                                    sx={{ width: 150, m: 2 }}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, exchange_traded: e.target.value })}
                                                />
                                                <TextField
                                                    autoComplete='off'
                                                    name="company_priority"
                                                    id="CompanyPriority"
                                                    label="Company Priority"
                                                    variant="outlined"
                                                    value={companyVarianceEditable.company_priority}
                                                    // variant="standard"
                                                    size='small'
                                                    sx={{ width: 150, m: 2 }}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, company_priority: e.target.value })}
                                                />
                                                <TextField
                                                    autoComplete='off'
                                                    name="employees_count"
                                                    id="EmployeesCount"
                                                    label="No. Of Employees"
                                                    variant="outlined"
                                                    value={companyVarianceEditable.employees_count}
                                                    // variant="standard"
                                                    size='small'
                                                    sx={{ width: 150, m: 2 }}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, employees_count: e.target.value })}
                                                />
                                                <TextField
                                                    required
                                                    autoComplete='off'
                                                    name="company_overiew"
                                                    id="CompanyOverview"
                                                    label="Company Overview"
                                                    multiline
                                                    rows={3}
                                                    variant="outlined"
                                                    value={companyVarianceEditable.company_overiew}
                                                    // fullWidth
                                                    style={{ width: '100%' }}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, company_overiew: e.target.value })}
                                                />
                                                <Autocomplete
                                                    disablePortal
                                                    id="industry"
                                                    options={sicGroups}
                                                    groupBy={opt => opt.sic_grp_name}
                                                    getOptionLabel={opt => `${opt.sic_grp_name} [${opt.sic_grp_code}] - ${opt.sic_name} [${opt.sic_id}]`}
                                                    // getOptionLabel={opt => `${opt.sic_grp_name} [${opt.sic_grp_code}]`}
                                                    sx={{ width: 700, m: 2 }}
                                                    onChange={handleSicGroupChange}
                                                    defaultValue={selectedSicGroup}
                                                    value={selectedSicGroup}
                                                    size='small'
                                                    renderInput={(params) => <TextField {...params} label="Industry *" />}
                                                />
                                                <TextField
                                                    autoComplete='off'
                                                    name="latest_revenue"
                                                    id="LatestRevenue"
                                                    label="Latest Revenue"
                                                    variant="outlined"
                                                    value={companyVarianceEditable.latest_revenue}
                                                    // variant="standard"
                                                    size='small'
                                                    sx={{ width: 150, m: 2 }}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, latest_revenue: e.target.value })}
                                                />
                                                <TextField
                                                    autoComplete='off'
                                                    name="latest_net_income"
                                                    id="LatestProfit"
                                                    label="Latest Profit"
                                                    variant="outlined"
                                                    value={companyVarianceEditable.latest_net_income}
                                                    // variant="standard"
                                                    size='small'
                                                    sx={{ width: 150, m: 2 }}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, latest_net_income: e.target.value })}
                                                />
                                                <TextField
                                                    autoComplete='off'
                                                    name="latest_eps"
                                                    id="LatestEPS"
                                                    label="Latest EPS"
                                                    variant="outlined"
                                                    value={companyVarianceEditable.latest_eps}
                                                    // variant="standard"
                                                    size='small'
                                                    sx={{ width: 150, m: 2 }}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, latest_eps: e.target.value })}
                                                />
                                                <TextField
                                                    required
                                                    select
                                                    name="bs_units"
                                                    id="BSFinancialUnits"
                                                    label="BS Financial Units"
                                                    // value={companyVarience ? companyVarience.company_website : ''}
                                                    value={companyVarianceEditable.bs_units}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, bs_units: e.target.value })}
                                                    // variant="standard"
                                                    size='small'
                                                    sx={{ width: 180, m: 2 }}
                                                    InputLabelProps={{ shrink: true }}
                                                >
                                                    {financialUnitsList.map((fin) => (
                                                        <MenuItem value={fin.value} selected={fin.value}>{fin.text}</MenuItem>
                                                    ))}
                                                </TextField>
                                                <TextField
                                                    required
                                                    select
                                                    name="is_units"
                                                    id="ISFinancialUnits"
                                                    label="IS Financial Units"
                                                    // value={companyVarience ? companyVarience.company_website : ''}
                                                    value={companyVarianceEditable.is_units}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, is_units: e.target.value })}
                                                    // variant="standard"
                                                    size='small'
                                                    sx={{ width: 180, m: 2 }}
                                                    InputLabelProps={{ shrink: true }}
                                                >
                                                    {financialUnitsList.map((fin) => (
                                                        <MenuItem value={fin.value} selected={fin.value}>{fin.text}</MenuItem>
                                                    ))}
                                                </TextField>
                                                <TextField
                                                    required
                                                    select
                                                    name="cf_units"
                                                    id="CFFinancialUnits"
                                                    label="CF Financial Units"
                                                    // value={companyVarience ? companyVarience.company_website : ''}
                                                    value={companyVarianceEditable.cf_units}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, cf_units: e.target.value })}
                                                    // variant="standard"
                                                    size='small'
                                                    sx={{ width: 180, m: 2 }}
                                                >
                                                    {financialUnitsList.map((fin) => (
                                                        <MenuItem value={fin.value} selected={fin.value}>{fin.text}</MenuItem>
                                                    ))}
                                                </TextField>
                                                <Autocomplete
                                                    disablePortal
                                                    id="Country"
                                                    options={countries}
                                                    getOptionLabel={opt => opt.country_name}
                                                    sx={{ width: 255, m: 2 }}
                                                    onChange={handleCountryChange}
                                                    defaultValue={selectedCountry}
                                                    value={selectedCountry}
                                                    size='small'
                                                    renderInput={(params) => <TextField {...params} label="Country *" />}
                                                />
                                                {/* <TextField
                                                    required
                                                    name="country"
                                                    id="Country"
                                                    label="Country"
                                                    value={companyVarianceEditable.country}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, country: e.target.value })}
                                                    // variant="standard"
                                                    size='small'
                                                    sx={{ width: 180, m: 2 }}
                                                    InputLabelProps={{ shrink: true }}
                                                /> */}
                                                <TextField
                                                    required
                                                    name="State"
                                                    id="State"
                                                    label="State"
                                                    value={companyVarianceEditable.state}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, state: e.target.value })}
                                                    // variant="standard"
                                                    size='small'
                                                    sx={{ width: 180, m: 2 }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                                <TextField
                                                    required
                                                    name="city"
                                                    id="City"
                                                    label="City"
                                                    value={companyVarianceEditable.city}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, city: e.target.value })}
                                                    // variant="standard"
                                                    size='small'
                                                    sx={{ width: 180, m: 2 }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                                <TextField
                                                    required
                                                    name="headquarters"
                                                    id="Headquarters"
                                                    label="Headquarters"
                                                    value={companyVarianceEditable.headquarters}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, headquarters: e.target.value })}
                                                    // variant="standard"
                                                    size='small'
                                                    sx={{ width: 130, m: 2 }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                                <Autocomplete
                                                    disablePortal
                                                    id="Currency"
                                                    options={currencies}
                                                    getOptionLabel={opt => opt}
                                                    sx={{ width: 120, m: 2 }}
                                                    onChange={handleCurrencyChange}
                                                    defaultValue={selectedCurrency}
                                                    value={selectedCurrency}
                                                    size='small'
                                                    renderInput={(params) => <TextField {...params} label="Currency *" />}
                                                />
                                                {/* <TextField
                                                    required
                                                    name="currency"
                                                    id="currency"
                                                    label="Currency Used"
                                                    value={companyVarianceEditable.currency}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, currency: e.target.value })}
                                                    // variant="standard"
                                                    size='small'
                                                    sx={{ width: 130, m: 2 }}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText='(ISO Code)'
                                                /> */}
                                                <TextField
                                                    required
                                                    name="website"
                                                    id="Website"
                                                    label="Website"
                                                    value={companyVarianceEditable.website}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, website: e.target.value })}
                                                    // variant="standard"
                                                    size='small'
                                                    sx={{ width: 250, m: 2 }}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText='Do not include http(s)://'
                                                />
                                                <TextField
                                                    required
                                                    name="phone_number"
                                                    id="Phone"
                                                    label="Phone Number"
                                                    // value={companyVarience ? companyVarience.company_website : ''}
                                                    value={companyVarianceEditable.phone_number}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, phone_number: e.target.value })}
                                                    // variant="standard"
                                                    size='small'
                                                    sx={{ width: 180, m: 2 }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                                <TextField
                                                    required
                                                    name="ceo"
                                                    id="Ceo"
                                                    label="Company CEO"
                                                    value={companyVarianceEditable.ceo}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, ceo: e.target.value })}
                                                    // variant="standard"
                                                    size='small'
                                                    sx={{ width: 180, m: 2 }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                                <TextField
                                                    required
                                                    select
                                                    name="gender"
                                                    id="Gender"
                                                    label="CEO Gender"
                                                    value={companyVarianceEditable.gender}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, gender: e.target.value })}
                                                    // variant="standard"
                                                    size='small'
                                                    sx={{ width: 100, m: 2 }}
                                                    InputLabelProps={{ shrink: true }}
                                                >
                                                    {genderList.map((fin) => (
                                                        <MenuItem value={fin.value} selected={fin.value}>{fin.text}</MenuItem>
                                                    ))}
                                                </TextField>
                                                <TextField
                                                    required
                                                    select
                                                    name="type_of_filer"
                                                    id="FilerType"
                                                    label="Type Of Filer"
                                                    value={companyVarianceEditable.type_of_filer}
                                                    onChange={(e) => setCompanyVarianceEditable({ ...companyVarianceEditable, type_of_filer: e.target.value })}
                                                    // variant="standard"
                                                    size='small'
                                                    sx={{ width: 150, m: 2 }}
                                                    InputLabelProps={{ shrink: true }}
                                                >
                                                    <MenuItem value={'accelerated'} selected={companyVarianceEditable.type_of_filer}>accelerated</MenuItem>
                                                    <MenuItem value={'smaller'} selected={companyVarianceEditable.type_of_filer}>smaller</MenuItem>
                                                    <MenuItem value={'emerging'} selected={companyVarianceEditable.type_of_filer}>emerging</MenuItem>
                                                </TextField>
                                            </Box>
                                            <Typography variant='subtitle1' color={colors.purple[500]}>
                                                <strong>Last Updated By:</strong>&emsp;{companyVarianceEditable?.updated_by}&ensp;
                                                <strong>Last Updated On:</strong>&emsp;{companyVarianceEditable?.updated_timestamp}
                                            </Typography>
                                        </Box>
                                        {/* <Box border={1} borderColor={colors.blueGrey[300]} p={1} width='50%'>
                                            <Typography variant='subtitle1' color={colors.purple[500]} marginBottom={2}>
                                                Previous Filing:&emsp;
                                                {new Date(companyVarianceReference.date_filed).toDateString()}&emsp;
                                                <a target='_blank' href={`http://curatortool.com/omp/${companyVarianceReference.irk_fs_platter_omp}`}>{companyVarianceReference.form_group}</a>
                                            </Typography>
                                            <TextField
                                                disabled
                                                autoComplete='off'
                                                name="company_overview"
                                                id="CompanyOverview"
                                                label="Company Overview"
                                                multiline
                                                maxRows={4}
                                                variant="outlined"
                                                value={companyVarianceReference?.company_description}
                                                // fullWidth
                                                size='small'
                                                style={{ width: '100%' }}
                                                onChange={(e) => setCompanyVarianceReference({ ...companyVarianceReference, company_overview: e.target.value })}
                                            />
                                            <TextField
                                                disabled
                                                name="Industry"
                                                id="Industry"
                                                label="Industry"
                                                value={companyVarianceReference ? companyVarianceReference?.industry : ''}
                                                onChange={(e) => setCompanyVarianceReference({ ...companyVarianceReference, industry: e.target.value })}
                                                // variant="standard"
                                                sx={{ width: 180, m: 2 }}
                                            />
                                            <TextField
                                                disabled
                                                name="city"
                                                id="City"
                                                label="City"
                                                value={companyVarianceReference?.city}
                                                onChange={(e) => setCompanyVarianceReference({ ...companyVarianceReference, city: e.target.value })}
                                                // variant="standard"
                                                sx={{ width: 180, m: 2 }}
                                            />
                                            <TextField
                                                disabled
                                                name="state"
                                                id="State"
                                                label="State"
                                                value={companyVarianceReference?.state}
                                                onChange={(e) => setCompanyVarianceReference({ ...companyVarianceReference, state: e.target.value })}
                                                // variant="standard"
                                                sx={{ width: 180, m: 2 }}
                                            />
                                            <TextField
                                                disabled
                                                name="company_website"
                                                id="CompanyWebsite"
                                                label="Company Website"
                                                // value={companyVarience ? companyVarience.company_website : ''}
                                                value={companyVarianceReference?.company_website}
                                                onChange={(e) => setCompanyVarianceReference({ ...companyVarianceReference, company_website: e.target.value })}
                                                // variant="standard"
                                                sx={{ width: 180, m: 2 }}
                                            />
                                            <TextField
                                                disabled
                                                name="ceo"
                                                id="Ceo"
                                                label="Ceo"
                                                // value={companyVarience ? companyVarience.company_website : ''}
                                                value={companyVarianceReference?.ceo}
                                                onChange={(e) => setCompanyVarianceReference({ ...companyVarianceReference, ceo: e.target.value })}
                                                // variant="standard"
                                                sx={{ width: 180, m: 2 }}
                                            />
                                            <TextField
                                                disabled
                                                name="phone_number"
                                                id="Phone"
                                                label="Phone"
                                                // value={companyVarience ? companyVarience.company_website : ''}
                                                value={companyVarianceReference?.phone}
                                                onChange={(e) => setCompanyVarianceReference({ ...companyVarianceReference, phone_number: e.target.value })}
                                                // variant="standard"
                                                sx={{ width: 180, m: 2 }}
                                            />
                                            <TextField
                                                disabled
                                                select
                                                name="Financial Units"
                                                id="FinancialUnits"
                                                label="Financial Units"
                                                // value={companyVarience ? companyVarience.company_website : ''}
                                                value={companyVarianceReference?.financial_units}
                                                onChange={(e) => setCompanyVarianceReference({ ...companyVarianceReference, financial_units: e.target.value })}
                                                // variant="standard"
                                                sx={{ width: 180, m: 2 }}
                                            >
                                                {financialUnitsList.map((fin) => (
                                                    <MenuItem value={fin.value} selected={fin.value}>{fin.text}</MenuItem>
                                                ))}
                                            </TextField>
                                            <TextField
                                                disabled
                                                name="username"
                                                id="UserName"
                                                label="Last Updated By"
                                                value={companyVarianceReference.updated_by}
                                                // variant="standard"
                                                sx={{ width: 180, m: 2 }}
                                            />
                                        </Box> */}
                                    </Box>
                                    <Box display='flex' justifyContent='flex-end' mt={1} mx={1}>
                                        <input
                                            type="submit"
                                            onClick={(e) => handleSubmission(true)}
                                            className="btn btn-success"
                                            value="Approve & Save"
                                            style={{ backgroundColor: colors.green[100], color: colors.green[900], fontWeight: 600, letterSpacing: 0.5, marginRight: '20px', border: `1px solid ${colors.green[900]}`, width: '9rem', height: '2rem', cursor: "pointer" }}
                                        />
                                        <input
                                            type="submit"
                                            onClick={(e) => handleSubmission(false)}
                                            className="btn btn-success"
                                            value="Save As Draft"
                                            style={{ backgroundColor: colors.lightBlue[100], color: colors.lightBlue[900], fontWeight: 600, letterSpacing: 0.5, marginRight: '20px', border: `1px solid ${colors.lightBlue[900]}`, width: '9rem', height: '2rem', cursor: "pointer" }}
                                        />
                                        <input
                                            type="submit"
                                            onClick={(e) => handleClose()}
                                            className="btn btn-secondary"
                                            value="Cancel"
                                            style={{ backgroundColor: colors.blueGrey[100], color: colors.blueGrey[900], fontWeight: 600, letterSpacing: 0.5, border: `1px solid ${colors.blueGrey[900]}`, width: '9rem', height: '2rem', cursor: "pointer" }}
                                        />
                                    </Box>
                                </>
                                : <Box display='flex' justifyContent='space-between' mt={1} mx={1}>
                                    <h3 className='error'>No Variance Data Found For The Selected Company</h3>
                                    <input
                                        type="submit"
                                        onClick={(e) => handleClose()}
                                        className="btn btn-sm btn-secondary"
                                        value="Close"
                                        style={{ backgroundColor: colors.blueGrey[100], color: colors.blueGrey[900], fontWeight: 600, letterSpacing: 0.5, border: `1px solid ${colors.blueGrey[900]}`, width: '9rem', height: '2rem', cursor: "pointer" }}
                                    />
                                </Box>}
                        </div>}
                </Fade>
            </Modal>
        </div >
    );
};

export default CompanyVariance;
