import React from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { Box, colors } from '@mui/material';

const Tabs = () => {
    const history = useHistory()
    const location = useLocation()

    return (
        <>
            <Box p={1} bgcolor="#fff">
                <Box display='flex' justifyContent='space-evenly' alignItems='center' textAlign='center'>
                    <Box
                        border={1}
                        borderColor={colors.indigo[800]}
                        p={.5}
                        px={1}
                        color={colors.indigo[800]}
                        fontSize={14}
                        fontWeight={500}
                        style={location.pathname === '/home' ? { background: colors.indigo[800], color: '#fff', cursor: 'pointer' } : { background: "#FFF", cursor: 'pointer' }}
                        onClick={() => history.push('/home')}
                    >Search Article</Box>
                    <Box
                        border={1}
                        borderColor={colors.indigo[800]}
                        color={colors.indigo[800]}
                        p={.5}
                        px={1}
                        mx={0.5}
                        fontSize={14}
                        fontWeight={500}
                        style={location.pathname === '/realtime-people' ? { background: colors.indigo[800], color: '#fff', cursor: 'pointer' } : { background: "#FFF", cursor: 'pointer' }}
                        onClick={() => history.push('/realtime-people')}
                    >Realtime People</Box>
                    <Box
                        border={1}
                        borderColor={colors.indigo[800]}
                        color={colors.indigo[800]}
                        p={.5}
                        px={1}
                        mx={0.5}
                        fontSize={14}
                        fontWeight={500}
                        style={location.pathname === '/news-summary' ? { background: colors.indigo[800], color: '#fff', cursor: 'pointer' } : { background: "#FFF", cursor: 'pointer' }}
                        onClick={() => history.push('/news-summary')}
                    >News Summary</Box>
                    <Box
                        border={1}
                        borderColor={colors.indigo[800]}
                        color={colors.indigo[800]}
                        p={.5}
                        px={1}
                        mx={0.5}
                        fontSize={14}
                        fontWeight={500}
                        style={location.pathname.startsWith('/ownership-news') ? { background: colors.indigo[800], color: '#fff', cursor: 'pointer' } : { background: "#FFF", cursor: 'pointer' }}
                        onClick={() => history.push('/ownership-news')}
                    >Ownership News</Box>
                    <Box
                        border={1}
                        borderColor={colors.indigo[800]}
                        color={colors.indigo[800]}
                        p={.5}
                        px={1}
                        mx={0.5}
                        fontSize={14}
                        fontWeight={500}
                        style={location.pathname.startsWith('/earnings-news') ? { background: colors.indigo[800], color: '#fff', cursor: 'pointer' } : { background: "#FFF", cursor: 'pointer' }}
                        onClick={() => history.push('/earnings-news')}
                    >Earnings News</Box>
                    <Box
                        border={1}
                        borderColor={colors.indigo[800]}
                        color={colors.indigo[800]}
                        p={.5}
                        px={1}
                        mx={0.5}
                        fontSize={14}
                        fontWeight={500}
                        style={location.pathname.startsWith('/dashboard') ? { background: colors.indigo[800], color: '#fff', cursor: 'pointer' } : { background: "#FFF", cursor: 'pointer' }}
                        onClick={() => history.push('/dashboard')}
                    >Dashboard</Box>
                    <Box
                        border={1}
                        borderColor={colors.indigo[800]}
                        color={colors.indigo[800]}
                        p={.5}
                        px={1}
                        mx={0.5}
                        fontSize={14}
                        fontWeight={500}
                        style={location.pathname.startsWith('/card-variance-report') ? { background: colors.indigo[800], color: '#fff', cursor: 'pointer' } : { background: "#FFF", cursor: 'pointer' }}
                        onClick={() => history.push('/card-variance-report')}
                    >CARD Variance</Box>
                    <Box
                        border={1}
                        borderColor={colors.indigo[800]}
                        color={colors.indigo[800]}
                        p={.5}
                        px={1}
                        mx={0.5}
                        fontSize={14}
                        fontWeight={500}
                        style={location.pathname.startsWith('/guardrails-for-stage') ? { background: colors.indigo[800], color: '#fff', cursor: 'pointer' } : { background: "#FFF", cursor: 'pointer' }}
                        onClick={() => history.push('/guardrails-for-stage')}
                    >GuardRails for Stage</Box>
                    <Box
                        border={1}
                        borderColor={colors.indigo[800]}
                        color={colors.indigo[800]}
                        p={.5}
                        px={1}
                        mx={0.5}
                        fontSize={14}
                        fontWeight={500}
                        style={location.pathname.startsWith('/manage-stage-datasets') ? { background: colors.indigo[800], color: '#fff', cursor: 'pointer' } : { background: "#FFF", cursor: 'pointer' }}
                        onClick={() => history.push('/manage-stage-datasets')}
                    >Manage STAGE Datasets</Box>
                    <Box
                        border={1}
                        borderColor={colors.indigo[800]}
                        color={colors.indigo[800]}
                        p={.5}
                        px={1}
                        mx={0.5}
                        fontSize={14}
                        fontWeight={500}
                        style={location.pathname.startsWith('/chatbot-prompt') ? { background: colors.indigo[800], color: '#fff', cursor: 'pointer' } : { background: "#FFF", cursor: 'pointer' }}
                        onClick={() => history.push('/chatbot-prompt')}
                    >Chatbot Prompt</Box>
                </Box>
            </Box>
        </>
    )
}

export default Tabs