import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { backdropClasses, Box, Button, colors, IconButton, LinearProgress, MenuItem, Select, TextField, Typography } from '@mui/material'
import { AddSharp, DeleteForeverSharp, PlusOneSharp, SaveSharp } from '@mui/icons-material'
import axios from 'axios'

import Header from '../components/Header'
import Tabs from '../components/Tabs'
import AddBoxIcon from '@mui/icons-material/AddBox';

import useQuery from '../components/useQuery'

const OwnershipNews = (props) => {
    const history = useHistory();

    const [personName, setPersonName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [cik, setCik] = useState("");
    const [ticker, setTicker] = useState("");
    const [accessionNumber, setAccessionNumber] = useState();
    const [formType, setFormType] = useState("");
    const [dateFiled, setDateFiled] = useState("");
    const [sicCode, setSicCode] = useState("");
    const [sicDesc, setSicDesc] = useState("");
    const [stateOfIncorp, setStateOfIncorp] = useState("");
    const [stateName, setStateName] = useState("");
    const [boughtSold, setBoughtSold] = useState('bought');
    const [increasedDecreased, setIncreasedDecreased] = useState("increased");
    const [sharesBoughtSold, setSharesBoughtSold] = useState();
    const [costPerShare, setCostPerShare] = useState();
    const [totalShares, setTotalShares] = useState("");

    const [headlinePersonName, setHeadlinePersonName] = useState("")
    const [headlineShares, setHeadlineShares] = useState("")
    const [headlineCompanyName, setHeadlineCompanyName] = useState("")

    const [para1PersonName, setPara1PersonName] = useState("")
    const [para1BoughtSold, setPara1BoughtSold] = useState('bought')
    const [para1Shares, setPara1Shares] = useState("")
    const [para1CompanyName, setPara1CompanyName] = useState("")
    const [para1CostPerShare, setPara1CostPerShare] = useState("")

    const [para2PersonName, setPara2PersonName] = useState("")
    const [para2SEC, setPara2SEC] = useState("")
    const [para2Date, setPara2Date] = useState("")
    const [para2CompanyName, setPara2CompanyName] = useState("")
    const [para2ShareCost, setPara2ShareCost] = useState("")
    const [para2IncreasedDescreased, setPara2IncreasedDescreased] = useState('increased')

    const [para3CompanyName, setPara3CompanyName] = useState("")
    const [para3IndustryName, setPara3IndustryName] = useState("")
    const [para3Location, setPara3Location] = useState("");

    const [references, setReferences] = useState([]);

    const [fillingId, setFillingID] = useState("")
    const [username, setUsername] = useState("")
    const [userId, setUserId] = useState("")

    const [filingList, setFilingList] = useState([])
    const [filingURL, setFilingURL] = useState()
    const [relatedNews, setRelatedNews] = useState([]);

    const [loading, setLoading] = useState(false);

    const params = useParams()
    console.log("params ::", params)

    let query = useQuery()
    console.log('OWNERSHIP NEWS PROPS ::::: ', props)
    console.log("Query Params :::", query);
    console.log("Username ::", query.get("u"));
    console.log("User ID ::", query.get("uid"));

    useEffect(() => {
        setFillingID(params.id)
        setUsername(query.get('u'))
        setUserId(query.get('id'))

        const loadFilings = () => {
            setLoading(true);
            axios.get(`/api/filings/by-id/${params.id}`)
                .then((resp) => {
                    console.log("Filings Resp :::", resp.data.message)
                    setFilingList(resp.data.message);
                    setFilingURL(resp.data.message[1].exhibit_file_path);
                    setCik(resp.data.message[0].cik);
                    setCompanyName(resp.data.message[0].company_name);
                    setTicker(resp.data.message[0].ticker);
                    setAccessionNumber(resp.data.message[0].accession_number);
                    setFormType(resp.data.message[0].form_type);
                    setDateFiled(resp.data.message[0].date_filed);
                    setStateOfIncorp(resp.data.message[0].state_of_incorp);
                    setStateName(resp.data.message[0].state_name);
                    setSicCode(resp.data.message[0].sic)
                    setSicDesc(resp.data.message[0].sic_desc);
                    setLoading(false);
                }).catch(err => console.log("Error :::", err))
        }

        if (params.id)
            loadFilings()

    }, [params.id]);

    useEffect(() => {
        const getRelatedNews = async () => {
            axios.get(`/api/filings/related-news/${ticker.toUpperCase()}`)
                .then(resp => {
                    console.log('OWNERSHIP RELATED NEWS RESP === ', resp.data.message);
                    let items = resp.data.message.map(obj => ({ title: obj.title, url: `https://candorium.com/news/${obj.id}/${obj.title.replace(/\s/gmi, '-')}` }))
                    setRelatedNews(items);
                    setReferences(items)
                }).catch(error => {
                    console.error(`Error from related-news API - `, error);
                });
        }

        if (ticker)
            getRelatedNews();

    }, [ticker])

    console.log("Filling ID", fillingId);
    console.log("User", username || JSON.parse(localStorage.user).name);
    console.log("User ID", userId || JSON.parse(localStorage.user).id);

    const handleReferences = async (idx, event) => {
        console.log('handleReferences OBJ === ', idx, event.target.name, event.target.value);
        setReferences(prev => {
            if (prev.length) {
                const newState = prev.map((obj, i) => {
                    if (i === idx)
                        return { ...obj, [event.target.name]: event.target.value }
                    return obj;
                })
                return newState
            } else {
                console.log(idx, event.target.name, event.target.value)
            }
        });
    };

    const handleAddReference = () => {
        setRelatedNews(references.flatMap(x => x))
    }

    console.log('ADD REFERENCE ::: ', references);
    console.log('RELATED  NEWS ::: ', relatedNews)

    const handleDeleteReference = (idx) => {
        let nRef = references.filter((a, i) => i !== idx);
        console.log('AFTER DELETED REFERENCES === ', nRef);
        setReferences(nRef);
    }

    const handleSaveOwnershipNews = () => {
        axios.post('/api/filings/ownership-news', {
            irk_txn_fs_platter: fillingId,
            person_name: personName,
            bought_or_sold: boughtSold,
            number_of_shares: sharesBoughtSold,
            cik: cik,
            company_name: companyName,
            ticker: ticker,
            accession_number: accessionNumber,
            date_filed: new Date(dateFiled),
            cost_per_share: costPerShare,
            form_type: formType,
            shares_increased_or_descreased: increasedDecreased,
            total_shares_count: totalShares,
            company_sic_code: sicCode,
            company_sic_desc: sicDesc,
            company_state_of_incorp: stateOfIncorp,
            state_name: stateName,
            related_news: JSON.stringify(relatedNews),
            username: username || JSON.parse(localStorage.user).name,
            userid: userId || JSON.parse(localStorage.user).id
        }).then(resp => {
            if (resp.status === 201)
                alert('Ownership News Item Saved Successfully!');
        }).catch(resp => {
            console.log('SAVE ERROR FROM API - ', resp);
            alert(resp.response.data.message);
        })
    };

    // if (loading)
    //     return <LinearProgress color='secondary'></LinearProgress>

    return (
        <>
            <Header />
            <Tabs />
            <hr />
            {loading ? <Box mt={2}><LinearProgress color='secondary'></LinearProgress></Box> : <>
                <Box mx={1} display="flex" justifyContent="flex-start" alignContent="center" alignItems="center" bgcolor={colors.blueGrey[50]} p={0.5}>
                    {filingList.length ? filingList.map((fl) => (
                        <Button onClick={() => setFilingURL(fl.exhibit_file_path)} variant='contained' size='small' color='primary' sx={{ mx: 2, fontSize: 12, textTransform: 'capitalize' }}>{fl.exhibit_file_desc}</Button>
                    )) : null}
                </Box>
                <Box width='100%' display='flex' justifyContent='space-between' mt={0.5}>
                    {/* ***** ***** ***** LEFT-SIDE FILINGS SECTION ***** ***** ***** */}
                    <Box width='49.5%' border={1} borderColor={colors.teal[600]}>
                        {filingURL
                            ? <iframe src={filingURL} height='100%' width='100%' style={{ border: 'none' }} />
                            : <Box textAlign="center" my={2}>Enter a valid 8-K Filling ID in the url....</Box>
                        }
                    </Box>

                    {/* ***** ***** ***** RIGHT-SIDE FORM SECTION ***** ***** ***** */}
                    <Box width='49.5%' display='flex' flexDirection='column'>
                        <Box display='flex' flexDirection='column' height={300} overflow='auto' p={1} mb={0.5} border={1} borderColor={colors.teal[600]} borderRadius={1} boxShadow={1}>
                            <Typography variant='h6' fontWeight='bold' color='blueviolet'>Generate News:</Typography>

                            {/* ***** HEADLINE SECTION ***** */}
                            <Typography sx={{ fontSize: 16, fontWeight: 600, color: 'blueviolet', mt: 3 }}>Headline</Typography>
                            <Box display='flex' flexWrap='wrap' alignItems='start'>
                                <Box m={0} p={0}>
                                    <TextField
                                        sx={{ fontSize: 14, margin: 0, padding: 0 }}
                                        inputProps={{ style: { fontSize: 15, color: 'orangered' } }}
                                        size="small"
                                        title='Person Name'
                                        variant='standard'
                                        placeholder='Enter Person Name'
                                        // helperText={`Use & to combine words; | to Option words`}
                                        value={personName}
                                        onChange={(event) => setPersonName(event.target.value)}
                                    />&nbsp;
                                </Box>
                                <Box m={0} p={0} >
                                    <Select variant='standard' size="small" defaultChecked={boughtSold} value={boughtSold}
                                        onChange={(e) => setBoughtSold(e.target.value)} sx={{ fontSize: 14, color: 'orangered' }}>
                                        <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='buys'>buys</MenuItem>
                                        <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='sells'>sells</MenuItem>
                                    </Select>&nbsp;
                                </Box>
                                <Box display='flex' alignItems='start' m={0} p={0} >
                                    <TextField
                                        sx={{ fontSize: 14, margin: 0, padding: 0 }}
                                        inputProps={{ style: { fontSize: 15, color: 'orangered' } }}
                                        size="small"
                                        variant='standard'
                                        title='Total Shares Count'
                                        placeholder='Shares Count'
                                        value={sharesBoughtSold}
                                        onChange={(event) => setSharesBoughtSold(event.target.value)}
                                    />
                                    <Typography sx={{ fontSize: 15 }}>&nbsp;shares of&nbsp;</Typography>
                                </Box>
                                <Box m={0} p={0} >
                                    <TextField
                                        sx={{ fontSize: 14, margin: 0, padding: 0 }}
                                        inputProps={{ style: { fontSize: 15, color: 'orangered' } }}
                                        size="small"
                                        variant='standard'
                                        title='Company Name'
                                        placeholder='Company Name'
                                        value={companyName}
                                        onChange={(event) => setCompanyName(event.target.value)}
                                    />&nbsp;
                                </Box>
                                <Box m={0} p={0} >
                                    {ticker ? <Typography>[{ticker}]</Typography> : null}
                                </Box>
                            </Box>

                            {/* Paragraph 1 */}
                            <Typography sx={{ fontSize: 16, fontWeight: 600, color: 'blueviolet', mt: 3 }}>Paragraph 1:</Typography>
                            <Box display='flex' flexWrap='wrap' alignItems='start' heigh>
                                <Box m={0} p={0} >
                                    <TextField
                                        sx={{ fontSize: 14, margin: 0, padding: 0 }}
                                        inputProps={{ style: { fontSize: 15, color: 'orangered' } }}
                                        size="small"
                                        title='Person Name'
                                        variant='standard'
                                        placeholder='Person Name'
                                        value={personName}
                                        onChange={(event) => setPersonName(event.target.value)}
                                    />&nbsp;
                                </Box >
                                <Box m={0} p={0} >
                                    <Select size="small" variant='standard' defaultChecked={boughtSold} value={boughtSold}
                                        onChange={(e) => setBoughtSold(e.target.value)} sx={{ fontSize: 14, color: 'orangered' }}>
                                        <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='buys'>buys</MenuItem>
                                        <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='sells'>sells</MenuItem>
                                    </Select>&nbsp;
                                </Box>
                                <Box display='flex' alignItems='start' m={0} p={0} >
                                    <TextField
                                        sx={{ fontSize: 14, margin: 0, padding: 0 }}
                                        inputProps={{ style: { fontSize: 15, color: 'orangered' } }}
                                        size="small"
                                        title='Total Shares Count'
                                        variant='standard'
                                        placeholder='Shares Count'
                                        value={sharesBoughtSold}
                                        onChange={(event) => setSharesBoughtSold(event.target.value)}
                                    />
                                    <Typography sx={{ fontSize: 15 }}>&nbsp;shares of&nbsp;</Typography>
                                </Box>
                                <Box display='flex' alignItems='start' m={0} p={0} >
                                    <TextField
                                        sx={{ fontSize: 14, margin: 0, padding: 0 }}
                                        inputProps={{ style: { fontSize: 15, color: 'orangered' } }}
                                        size="small"
                                        title='Company Name'
                                        variant='standard'
                                        placeholder='Company Name'
                                        value={companyName}
                                        my={1} ml={1} onChange={(event) => setCompanyName(event.target.value)}
                                    />
                                    <Typography sx={{ fontSize: 15 }}>&nbsp;at a&nbsp;</Typography>
                                </Box>
                                <Box display='flex' alignItems='start' m={0} p={0} >
                                    <TextField
                                        sx={{ fontSize: 14, margin: 0, padding: 0 }}
                                        inputProps={{ style: { fontSize: 15, color: 'orangered' } }}
                                        size="small"
                                        variant='standard'
                                        title='Cost Per Share'
                                        placeholder='Cost Per Share'
                                        value={costPerShare}
                                        my={1} ml={1} onChange={(event) => setCostPerShare(event.target.value)}
                                    /> <Typography>&nbsp;a share.</Typography>
                                </Box>
                            </Box>

                            {/* Paragaraph 2 */}
                            <Typography sx={{ fontSize: 16, fontWeight: 600, color: 'blueviolet', mt: 3 }}>Paragraph 2:</Typography>
                            <Box display='flex' flexWrap='wrap' justifyContent='flex-start' alignItems='flex-end' alignContent='flex-end'>
                                <Box display='flex' alignItems='start' m={0} p={0} >
                                    <TextField
                                        sx={{ fontSize: 14, margin: 0, padding: 0 }}
                                        inputProps={{ style: { fontSize: 15, color: 'orangered' } }}
                                        title='Company Name'
                                        variant='standard'
                                        placeholder='Enter Company Name'
                                        value={companyName}
                                        my={1} ml={1} onChange={(event) => setCompanyName(event.target.value)}
                                    />
                                    <Typography sx={{ fontSize: 15 }}>&nbsp;disclosed in an SEC form&nbsp;</Typography>
                                </Box>
                                <Box display='flex' alignItems='start' m={0} p={0} >
                                    <TextField
                                        sx={{ fontSize: 14, margin: 0, padding: 0 }}
                                        inputProps={{ style: { fontSize: 15, color: 'orangered' } }}
                                        size='small'
                                        variant='standard'
                                        title='SEC Form Type'
                                        placeholder='Form Type'
                                        value={formType}
                                        my={1} ml={1} onChange={(event) => setFormType(event.target.value)}
                                    />
                                    <Typography sx={{ fontSize: 15 }}>&nbsp;on&nbsp;</Typography>
                                </Box>
                                <Box display='flex' alignItems='start' m={0} p={0} >
                                    <TextField
                                        sx={{ fontSize: 14, margin: 0, padding: 0 }}
                                        inputProps={{ style: { fontSize: 15, color: 'orangered' } }}
                                        size='small'
                                        variant='standard'
                                        type='date'
                                        title='Date Filed'
                                        placeholder='Date (yyyy-mm-dd)'
                                        value={dateFiled}
                                        my={1} ml={1} onChange={(event) => setDateFiled(event.target.value)}
                                    />
                                    <Typography sx={{ fontSize: 15 }}>&nbsp;that the deal&nbsp;</Typography>
                                </Box>
                                <Box m={0} p={0} >
                                    <Select variant='standard' defaultChecked={increasedDecreased} value={increasedDecreased}
                                        onChange={(e) => setIncreasedDecreased(e.target.value)} sx={{ fontSize: 14, color: 'orangered' }}>
                                        <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='increased'>Increased</MenuItem>
                                        <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='decreased'>Decreased</MenuItem>
                                    </Select>&nbsp;
                                </Box>
                                <Box display='flex' alignItems='start' m={0} p={0} >
                                    <TextField
                                        sx={{ fontSize: 14, margin: 0, padding: 0 }}
                                        inputProps={{ style: { fontSize: 15, color: 'orangered' } }}
                                        title='Person Name'
                                        variant='standard'
                                        placeholder='Enter Person Name'
                                        value={personName}
                                        onChange={(event) => setPersonName(event.target.value)}
                                    />
                                    <Typography sx={{ fontSize: 15 }}>'s company holdings to&nbsp;</Typography>
                                </Box>
                                <Box m={0} p={0} >
                                    <TextField
                                        sx={{ fontSize: 14, margin: 0, padding: 0 }}
                                        inputProps={{ style: { fontSize: 15, color: 'orangered' } }}
                                        title='Total Share Holdings'
                                        variant='standard'
                                        placeholder='Total Share Holdings'
                                        value={totalShares}
                                        onChange={(event) => setTotalShares(event.target.value)}
                                    />
                                </Box>
                            </Box>

                            {/* Paragaraph 3 */}
                            <Typography sx={{ fontSize: 16, fontWeight: 600, color: 'blueviolet', mt: 3 }}>Paragraph 3:</Typography>
                            <Box display='flex' flexWrap='wrap' alignItems='start'>
                                <Box display='flex' alignItems='start' m={0} p={0} >
                                    <TextField
                                        sx={{ fontSize: 14, margin: 0, padding: 0 }}
                                        inputProps={{ style: { fontSize: 15, color: 'orangered' } }}
                                        size='small'
                                        variant='standard'
                                        title='Company Name'
                                        placeholder='Enter Company Name'
                                        value={companyName}
                                        onChange={(event) => setCompanyName(event.target.value)}
                                    />
                                    <Typography sx={{ fontSize: 15 }}>&nbsp;is a&nbsp;</Typography>

                                </Box>
                                <Box display='flex' alignItems='start' m={0} p={0} >
                                    <TextField
                                        sx={{ fontSize: 14, margin: 0, padding: 0 }}
                                        inputProps={{ style: { fontSize: 15, color: 'orangered' } }}
                                        size='small'
                                        variant='standard'
                                        title='SIC DescriptionName'
                                        placeholder='Enter Indusctry'
                                        value={sicDesc}
                                        onChange={(event) => setSicDesc(event.target.value)}
                                    />
                                </Box>
                                <Typography sx={{ fontSize: 15 }}>&nbsp;company based in&nbsp;</Typography>
                                <Box m={0} p={0} >
                                    <TextField
                                        sx={{ fontSize: 14, margin: 0, padding: 0 }}
                                        inputProps={{ style: { fontSize: 15, color: 'orangered' } }}
                                        size='small'
                                        variant='standard'
                                        title='State Of Incorporation'
                                        placeholder='Enter State Of Incorp'
                                        value={stateName}
                                        onChange={(event) => setStateName(event.target.value)}
                                    />
                                </Box>
                            </Box>

                            {/* Also Read */}
                            <Typography sx={{ fontSize: 16, fontWeight: 600, color: 'blueviolet', mt: 3 }}>Also Read:</Typography>
                            {references.length > 0
                                ? <Box display="flex" flexDirection="column" justifyContent="flex-start" p={1} marginTop={1} marginBottom={5}>
                                    {references.map((ref, idx) =>
                                        <Box key={idx} display="flex" justifyContent="flex-start" alignContent="center" alignItems="center">
                                            <Box width="5%" m={1}><Typography variantt="body1">{idx + 1}:</Typography></Box>
                                            <Box width="50%" m={1}><TextField id="title-1" variant="standard" name="title" label="Title" InputLabelProps={{ shrink: true }} fullWidth value={ref.title} onChange={e => handleReferences(idx, e)} /></Box>
                                            <Box width="40%" m={1}><TextField id="url-1" variant="standard" name="url" label="URL" InputLabelProps={{ shrink: true }} fullWidth value={ref.url} onChange={e => handleReferences(idx, e)} /></Box>
                                            <Box width="5%" m={1}>
                                                <IconButton title='Add Reference' onClick={() => handleAddReference()} size="medium">
                                                    <SaveSharp color="success" fontSize='medium' />
                                                </IconButton>
                                            </Box>
                                            <Box width="5%" m={1}>
                                                <IconButton title='Delete Reference' onClick={() => handleDeleteReference(idx)} size="medium">
                                                    <DeleteForeverSharp color="error" fontSize='medium' />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                                : null}

                            <Box textAlign="center">
                                <IconButton color='info' size='small' onClick={() => setReferences(prev => [...prev, { title: "", url: "" },])}><AddBoxIcon color='info' fontSize='small' />Add References</IconButton>
                            </Box>

                            <Box display='flex' justifyContent='flex-end' m={1}>
                                <Button size='small' variant='contained' color='inherit' sx={{ mx: 2 }} onClick={() => history.push('/ownership-news')}>Cancel</Button>
                                <Button size='small' variant='contained' color='success' onClick={handleSaveOwnershipNews}>Save</Button>
                            </Box>
                        </Box>
                        <Box overflow='auto' mt={0.5} p={1} border={1} borderColor={colors.teal[300]} borderRadius={1} boxShadow={2}>
                            <Typography variant='h6' fontWeight='bold' color='blueviolet'>Preview News:</Typography>
                            {/* TITLE SECTION */}
                            <Typography fontSize={14} my={1}>
                                <span style={{ color: colors.pink[800] }}>
                                    <h5 style={{ margin: 1, padding: 1, color: colors.blue[800] }}>News Title (email Subject):</h5>
                                    <span style={{ textTransform: 'capitalize' }}>{personName.toLowerCase() || "xxxxxxxxxx"}</span>
                                    &nbsp;{boughtSold || "xxxxxxxxxx"}&nbsp;{Intl.NumberFormat('en-US').format(sharesBoughtSold || null) || "xxxxxxxxxx"}
                                </span>&nbsp;shares of&nbsp;
                                <span style={{ color: colors.pink[800] }}>{companyName || "xxxxxxxxxx"}</span>
                            </Typography>
                            {/* SUMMARY SECTION */}
                            {/* Transaction <reduces/increases> the holding of <shareholderName> in the company to <numberOfShares> */}
                            <Typography fontSize={14} my={1}>
                                <h5 style={{ margin: 1, padding: 1, color: colors.blue[800] }}>News Summary:</h5>
                                <span style={{ color: colors.pink[800] }}>
                                    Transaction {increasedDecreased === "increased" ? "increases" : "reduces"} the holding of&nbsp;
                                    <span style={{ textTransform: 'capitalize' }}>{personName.toLowerCase() || "xxxxxxxxxx"}</span>
                                    &nbsp;in the company to {Intl.NumberFormat('en-US').format(sharesBoughtSold || null) || "xxxxxxxxxx"}
                                </span>
                            </Typography>
                            {/* DESCRIPTION SECTION */}
                            {/* <shareHolderName> has <bought/sold> <shareeNumber> shares of <SYNOVUS FINANCIAL CORP> at <perSharePrice> a share. */}
                            <Typography variant='body2' my={1}>
                                <h5 style={{ margin: 1, padding: 1, color: colors.blue[800] }}>News Description:</h5>
                                <span style={{ color: colors.pink[800], textTransform: 'capitalize' }}>{personName || "xxxxxxxxxx"}</span>&nbsp;has&nbsp;
                                <span style={{ color: colors.pink[800] }}>{boughtSold === "sells" ? "sold" : "bought"}</span>&nbsp;
                                <span style={{ color: colors.pink[800] }}>{Intl.NumberFormat('en-US').format(sharesBoughtSold || null) || "xxxxxxxxxx"}</span>&nbsp;shares of&nbsp;
                                <span style={{ color: colors.pink[800] }}>{companyName || "xxxxxxxxxx"}</span>&nbsp;at&nbsp;{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(costPerShare || null) || "xxxxx"}&nbsp;a share.
                            </Typography>
                            <Typography variant='body2' my={1}>
                                <span style={{ color: colors.pink[800] }}>{companyName || "xxxxxxxxxx"}</span>&nbsp;disclosed in an SEC Form&nbsp;
                                <span style={{ color: colors.pink[800] }}>{formType || "xxxxxxxxxx"}</span>&nbsp;submission&nbsp;
                                <span style={{ color: colors.pink[800] }}>{dateFiled ? Intl.DateTimeFormat('en-US', {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                }).format(new Date(dateFiled)) : "xxxxxxxxxx"}</span>&nbsp;that the deal&nbsp;
                                <span style={{ color: colors.pink[800] }}>{increasedDecreased || "xxxxxxxxxx"} <span style={{ textTransform: 'capitalize' }}>{personName.toLowerCase() || "xxxxxxxxxx"}</span></span>'s holding in the company to&nbsp;
                                <span style={{ color: colors.pink[800] }}>{totalShares ? Intl.NumberFormat('en-US').format(totalShares) : "xxxxxxxxxx"}</span>.
                            </Typography>
                            <Typography variant='body2' my={1}>
                                <span style={{ color: colors.pink[800] }}>{companyName || "xxxxxxxxxx"}</span>&nbsp;is a&nbsp;
                                <span style={{ color: colors.pink[800] }}>{sicDesc || "xxxxxxxxxx"}</span>&nbsp;company based in&nbsp;
                                <span style={{ color: colors.pink[800] }}>{stateName || "xxxxxxxxxx"}</span>
                            </Typography>

                            <h4 style={{ margin: 1, padding: 1, color: colors.pink[800] }}>Also Read:</h4>
                            {relatedNews.length > 0 && relatedNews.map(news =>
                                <ul><li><a href={news.url} target='_blank'>{news.title}</a></li></ul>
                            )}
                            {/* DISCLAIMER SECTION */}
                            <div style={{ width: '30%', borderBottom: '1px dashed #333' }}>&nbsp;</div>
                            <Typography variant='caption' MT={5} mb={2}>
                                <strong>NewsInMinutes</strong> is an offering of 10kInfo, Inc., bringing you news on the latest regulatory filings. You may <a href="/subscriptions">subscribe</a> to news alerts here.
                                <br />Although all care is taken to ensure the accuracy of the data, the article does not intend to replace user diligence while taking decisions of financial or legal implications.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </>}
        </>
    )
}

export default OwnershipNews