import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { Box, Button, Chip, colors, LinearProgress, Typography } from '@mui/material';
import { ReportSharp } from '@mui/icons-material';
import Header from '../components/Header';

const ViewPerson = () => {

    const params = useParams();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [personProfile, setPersonProfile] = useState(null);

    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }

    useEffect(() => {
        setLoading(true);
        const fetchPerson = async () => {
            console.log('Calling Person By ID API... ', params)
            await axios.get(`/api/people/search/${params.id}`).then(resp => {
                console.log('API RECVD PERSON ', resp.data.message)
                setPersonProfile(resp.data.message[0]);
            }).catch(error => console.error(`Error While Fetching Person By CIK, `, error))
        };
        fetchPerson().finally(() => setLoading(false));

        return () => clearImmediate();
    }, [params.id]);

    return (
        <>
            <Header />
            {loading ? <Box width="100%" display="flex" my="15%"><LinearProgress color='secondary' /></Box> : null}
            {personProfile ?
                <Box key={0}>
                    <Box display="flex" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={2}>
                        <Box display="flex" width={'100%'} justifyContent="flex-start" alignContent="center" alignItems="baseline" m={0} p={0}>
                            <Typography variant='h1' fontSize={26} color={colors.blueGrey[800]} letterSpacing={1} textAlign="left" mr={1}>{personProfile.insider_name}</Typography>
                            {personProfile.insider_career
                                ? <>
                                    <Typography variant='h2' fontSize={15} color={colors.blueGrey[900]} letterSpacing={1} textAlign="left" fontWeight={500}>{personProfile?.insider_career[0]?.company}&nbsp;-</Typography>
                                    <Typography variant='h2' fontSize={15} color={colors.blueGrey[900]} letterSpacing={1} textAlign="left" fontWeight={500} mr={40}>&nbsp;{personProfile?.insider_career[0]?.current_title}</Typography>
                                </>
                                : null}
                            {/* {currentUser.team.id === 2017112 && <Button onClick={() => history.push(`/people/edit/${personProfile.insider_cik}`)} color='secondary'>edit</Button>} */}
                            <Box display="flex" justifyContent="flex-end">
                                <Button variant="text" size="small" color='warning' startIcon={<ReportSharp color='warning' />} onClick={() => history.push(`/people/edit/${personProfile.sk_txn_sec_people_profile}`)}>edit profile</Button>
                            </Box>
                        </Box>
                    </Box>
                    <hr />
                    <Box width='100%' display='flex' >
                        <Box width='50%' borderColor="GrayText" display='flex' justifyContent='flex-start' alignItems='center' flexWrap='wrap' >
                            <Box px={2} fontWeight={500}>Also Known As:</Box>
                        </Box>
                        <Box width='50%' borderColor="GrayText" display='flex' justifyContent='flex-start' alignItems='center' flexWrap='wrap' >
                            <Box px={2} fontWeight={500}>Relevant To:</Box>
                        </Box>
                    </Box>
                    <Box width='100%' display='flex' >
                        <Box width='50%' px={1} mx={0} borderColor="GrayText" display='flex' justifyContent='flex-start' alignItems='center' flexWrap='wrap' >
                            {personProfile.insider_aliaz?.map((a) => <Chip key={a} size='small' variant='filled' color='default' label={a} style={{ margin: '3px', padding: 1 }} />)}
                        </Box>
                        <Box width='50%' px={1} mx={0} borderColor="GrayText" display='flex' justifyContent='flex-start' alignItems='center' flexWrap='wrap' >
                            {personProfile.insider_relevancy?.map((r) => <Chip key={r} size='small' variant='filled' color='default' label={r} style={{ margin: '3px', padding: 1 }} />)}
                        </Box>
                    </Box>
                    <hr />
                    <Box width="100%" px={2} flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4} >
                        <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>Overview</Typography>
                    </Box>
                    {personProfile.insider_overview && personProfile.insider_profile_picture_path
                        ? <Box display="flex" width="100%" justifyContent="space-around">
                            <Box justifyContent={"left"} px={1} className="imgp">
                                {/* <Typography textAlign="left">{personProfile.insider_overview}</Typography> */}
                                <img src={personProfile.insider_profile_picture_path} alt="profile" width={"100%"} />
                                <Box className="imgp" dangerouslySetInnerHTML={createMarkup(personProfile.insider_overview)} textAlign='justify' lineHeight={1.6}></Box>
                            </Box>
                        </Box>
                        : <div style={{ width: '100%', textAlign: 'left', marginLeft: 35, color: 'orangered' }}>No Data To Display</div>
                    }
                    <hr />
                    < Box width="100%" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4} >
                        <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>Early Life</Typography>
                    </Box>
                    {personProfile.insider_early_life ?
                        <Box display="flex" width="100%" justifyContent="space-around">
                            <Box justifyContent={"left"} px={1} className="imgp">
                                <Box className="imgp" dangerouslySetInnerHTML={createMarkup(personProfile.insider_early_life)} textAlign='justify' lineHeight={1.6}></Box>
                            </Box>
                        </Box>
                        : <div style={{ width: '100%', textAlign: 'left', marginLeft: 35, color: 'orangered' }}>No Data To Display</div>
                    }
                    <hr />
                    <Box width="100%" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4}>
                        <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>Education</Typography>
                    </Box>
                    {personProfile.insider_education
                        ? <Box display="flex" width="100%" justifyContent="space-around">
                            <Box justifyContent={"left"} width="100%">
                                {personProfile.insider_education.map((education, idx) =>
                                    <ul key={idx} style={{ margin: 0, padding: 0, marginLeft: 30 }}>
                                        {education.year && education.degree && education.school &&
                                            <li key={idx} style={{ margin: 0, padding: 0, marginLeft: 10, textAlign: "left" }}><span style={{ fontWeight: 500 }}>{education.degree}</span>, From <span style={{ fontWeight: 500 }}>{education.school}</span>, in <span style={{ fontWeight: 500 }}>{education.year}</span></li>
                                        }
                                    </ul>
                                )}
                            </Box>
                        </Box>
                        : <div style={{ width: '100%', textAlign: 'left', marginLeft: 35, color: 'orangered' }}>No Data To Display</div>
                    }
                    <hr />
                    <Box width="100%" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4}>
                        <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>Career</Typography>
                    </Box>
                    {personProfile.insider_career
                        ? <Box display="flex" width="100%" justifyContent="space-around">
                            <Box justifyContent={"left"} width="60%">
                                {personProfile.insider_career.length && personProfile.insider_career.map((career, idx) =>
                                    <ul key={idx} style={{ margin: 0, padding: 0, marginLeft: 10 }}>
                                        {career.current_title && career.company &&
                                            <li key={idx} style={{ margin: 0, padding: 0, marginLeft: 10, textAlign: "left" }}>{career.current_title},&nbsp;&nbsp; <span style={{ fontWeight: 700 }}>{career.company}</span></li>
                                        }
                                    </ul>
                                )}
                            </Box>
                            <Box width="30%">&nbsp;</Box>
                        </Box>
                        : <div style={{ width: '100%', textAlign: 'left', marginLeft: 35, color: 'orangered' }}>No Data To Display</div>
                    }
                    <hr />
                    <Box width="100%" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4}>
                        <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>Other Activities</Typography>
                    </Box>
                    {personProfile.insider_other_activities
                        ? <Box display="flex" width="100%" justifyContent="space-around">
                            <Box justifyContent={"left"} px={1} className="imgp">
                                <Box className="imgp" dangerouslySetInnerHTML={createMarkup(personProfile.insider_other_activities)} textAlign='justify' letterSpacing={.5} lineHeight={1.6}></Box>
                            </Box>
                        </Box>
                        : <div style={{ width: '100%', textAlign: 'left', marginLeft: 35, color: 'orangered' }}>No Data To Display</div>
                    }
                    <hr />
                    <Box width="100%" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4}>
                        <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>Recognitions</Typography>
                    </Box>
                    {personProfile.insider_recognitions
                        ? <Box display="flex" width="100%" justifyContent="space-around">
                            <Box justifyContent={"left"} px={1} className="imgp">
                                <Box className="imgp" dangerouslySetInnerHTML={createMarkup(personProfile.insider_recognitions)} textAlign='justify' letterSpacing={.5} lineHeight={1.6}></Box>
                            </Box>
                        </Box>
                        : <div style={{ width: '100%', textAlign: 'left', marginLeft: 35, color: 'orangered' }}>No Data To Display</div>
                    }
                    <hr />
                    <Box width="100%" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4}>
                        <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>References (See Also)</Typography>
                    </Box>
                    {personProfile.insider_references
                        ? <Box display="flex" width="100%" justifyContent="flex-start" marginLeft={5}>
                            <Box justifyContent={"left"}>
                                {personProfile.insider_references.map((reference, idx) =>
                                    <ul key={idx} style={{ margin: 0, padding: 0 }}>
                                        {reference.title && <li key={idx} style={{ textAlign: "left" }}>
                                            <Typography variant='caption' fontWeight={500}>
                                                <a href={reference.url ? reference.url : null} style={{ textDecoration: 'none', }} target='_blank' rel="noreferrer">
                                                    {reference.title}
                                                </a>
                                            </Typography>
                                        </li>}
                                    </ul>
                                )}
                            </Box>
                        </Box>
                        : <div style={{ width: '100%', textAlign: 'left', marginLeft: 35, color: 'orangered' }}>No Data To Display</div>
                    }
                    <hr />
                    <Box marginBottom={5}>&nbsp;</Box>
                </Box>
                : <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center" my="5%">
                    <Box fontSize={21} fontWeight={500} color={colors.deepOrange[400]}>Oops! Something Went Wrong. Could Not Fetch Person Details for Supplied Person ID - {params.id}!</Box>
                    <a href='/realtime-people'>Click Here To Go Back</a>
                </Box>
            }
        </>
    )
}

export default ViewPerson;