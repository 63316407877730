import React, { useEffect, useState, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { PhotoCamera } from '@mui/icons-material';
import { Box, Button, Checkbox, colors, FormControl, FormControlLabel, IconButton, InputLabel, LinearProgress, ListItemText, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import DOMPurify from 'dompurify';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { HourglassTopRounded, DeleteForeverSharp } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import Chip from '@mui/material/Chip';

import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useStore } from '../store/StoredData';
import Header from '../components/Header';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 3;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



const EditPerson = (props) => {

    const { currentUser } = useStore();
    const params = useParams();
    const history = useHistory();

    const [screenLoading, setScreenLoading] = useState(false)
    const [personProfile, setPersonProfile] = useState([])
    const [personName, setPersonName] = useState()
    const [profilePicturePath, setProfilePicturePath] = useState("");
    const [profilePictureFile, setProfilePictureFile] = useState("");
    const [profilePhotoPreview, setProfilePhotoPreview] = useState()
    const [personOverviewState, setPersonOverviewState] = useState();
    const [personEarlyLifeState, setPersonEarlyLifeState] = useState();
    const [personRecognitionState, setPersonRecognitionState] = useState();
    const [personActivitiesState, setPersonActivitiesState] = useState();

    const [overview, setOverview] = useState("");
    const [earlyLife, setEarlyLife] = useState("");
    const [education, setEducation] = useState([]);
    const [career, setCareer] = useState([]);
    const [compensation, setCompensation] = useState([]);
    const [otherActivities, setOtherActivities] = useState([]);
    const [recognition, SetRecognition] = useState("");
    const [references, setReferences] = useState([]);

    const [aka, setAka] = useState([]);
    const [akaList, setAkaList] = useState([]);
    const [relevancy, setRelevancy] = useState([
        "Business",
        "Sports",
        "Movies",
        "Music",
        "Art and Culture",
        "Academic",
        "Politician",
        "Government Official",
        "Philanthropist",
        "Newsmaker"]);

    const [relevancyList, setRelevancyList] = useState([]);
    const [personCIK, setPersonCIK] = useState([]);
    const [parentCompany, setParentCompany] = useState("");
    const [parentCompanyList, setParentCompanyList] = useState([]);
    const [knownFor, setKnownFor] = useState("");
    const [knownForList, setKnownForList] = useState([]);
    const [companySuggestion, setCompanySuggestions] = useState([]);
    const [suggDropDownState, SetSuggDropDownState] = useState(false);
    const [suggLoading, setSuggLoading] = useState(false);
    const [isCurated, setIsCurated] = useState(false);
    const [lastUpdatedAt, setLastUpdatedAt] = useState();
    const [lastUpdatedBy, setLastUpdatedBy] = useState();


    const [loading, setLoading] = useState(false);

    const [convertedContentPersonOverview, setConvertedContentPersonOverview] = useState("");
    const [convertedContentPersonEarlyLife, setConvertedContentPersonEarlyLife] = useState("");
    const [convertedContentPersonRecognition, setConvertedContentPersonRecognition] = useState("");
    const [convertedContentPersonActivities, setConvertedContentPersonActivities] = useState("");

    const handleKnownFor = () => {
        setKnownForList(prev => [...prev, knownFor])
    }

    const handleDeleteKnownFor = (e) => {
        setKnownForList(knownForList.filter((a) => a !== e))
    }

    const handleEditorChangePersonOverview = (state) => {
        if (state) {
            setPersonOverviewState(state);
            convertContentToHTMLPersonOverview();
        }
    }
    const convertContentToHTMLPersonOverview = () => {
        let currentContentAsHTML = draftToHtml(convertToRaw(personOverviewState.getCurrentContent()));
        setConvertedContentPersonOverview(currentContentAsHTML);
        setOverview(currentContentAsHTML);
    }

    const handleEditorChangePersonEarlyLife = (state) => {
        if (state) {
            setPersonEarlyLifeState(state);
            convertContentToHTMLPersonEarlyLife();
        }
    }

    const convertContentToHTMLPersonEarlyLife = () => {
        let currentContentAsHTML = draftToHtml(convertToRaw(personEarlyLifeState.getCurrentContent()));
        setConvertedContentPersonEarlyLife(currentContentAsHTML);
        setEarlyLife(currentContentAsHTML);
    }

    const handleEditorChangePersonRecognition = (state) => {
        if (state) {
            setPersonRecognitionState(state);
            convertContentToHTMLPersonRecognition();
        }
    }

    const convertContentToHTMLPersonRecognition = () => {
        let currentContentAsHTML = draftToHtml(convertToRaw(personRecognitionState.getCurrentContent()));
        setConvertedContentPersonRecognition(currentContentAsHTML);
        SetRecognition(currentContentAsHTML);
    }

    const handleEditorChangePersonActivities = (state) => {
        if (state) {
            setPersonActivitiesState(state);
            convertContentToHTMLPersonActivities();
        }
    }

    const convertContentToHTMLPersonActivities = () => {
        let currentContentAsHTML = draftToHtml(convertToRaw(personActivitiesState.getCurrentContent()));
        setConvertedContentPersonActivities(currentContentAsHTML);
        setOtherActivities(currentContentAsHTML);
    }

    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }

    const uploadProfilePicture = async (e) => {
        e.preventDefault();

        let data = new FormData();
        data.append('image_for', 'profile');
        // data.append('array_index', null)
        data.append('picture_path', e.target.files[0]);
        setProfilePhotoPreview(e.target.files[0])
        setProfilePictureFile(URL.createObjectURL(e.target.files[0]))

        data.forEach(d => console.log('FILE UPLOAD DATA ::::::: ', d))
    };

    const handleParentCompanyList = (data) => {
        console.log('Selected Company From DropDown ::: ', data)
        setParentCompanyList(prev => [...prev, data])
    }

    const handleDeleteParentCompany = (e) => {
        setParentCompanyList(n => n.filter((a) => a.cik !== e.cik))
    }

    const handleAka = (aliaz) => {
        setAkaList(prev => [...prev, aliaz])
    }

    const handleRelevancy = (event) => {
        const { target: { value }, } = event;
        setRelevancyList(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleEducation = async (idx, event) => {
        console.log('handleEducation OBJ === ', idx, event.target.name, event.target.value);
        setEducation(prev => {
            if (prev.length) {
                const newState = prev?.map((obj, i) => {
                    if (i === idx)
                        return { ...obj, [event.target.name]: event.target.value }
                    return obj;
                })
                return newState
            } else {
                console.log(idx, event.target.name, event.target.value)
            }
        });
        // setPersonProfile(prev => {
        //     const nState = prev.map((personObj, i) => {
        //         let obj = personObj.insider_education;
        //         let nIns;
        //         console.log('NEW EDU OBJ === ', personObj.insider_education, obj)
        //         if (obj && obj.length) {
        //             nIns = obj.map((obj, j) => {
        //                 if (j === idx) {
        //                     console.log("insider_education @", i, obj, event.target.name, event.target.value)
        //                     return { ...obj, [event.target.name]: event.target.value }
        //                 }
        //                 return obj;
        //             })
        //         } else {
        //             nIns = obj;
        //         }
        //         return { ...personObj, insider_education: nIns }
        //     })
        //     console.log('NEW STATE ========= ', nState)
        //     return nState;
        // });
    };

    const handleDeleteEducation = (idx) => {
        setEducation(prev => prev.filter((d, i) => i !== idx));
    }

    const handleCareer = async (idx, event) => {
        console.log('handleEducation OBJ === ', idx, event.target.name, event.target.value);
        setCareer(prev => {
            if (prev.length) {
                const newState = prev?.map((obj, i) => {
                    if (i === idx)
                        return { ...obj, [event.target.name]: event.target.value }
                    return obj;
                })
                return newState
            } else {
                console.log(idx, event.target.name, event.target.value)
            }
        });
        // setPersonProfile(prev => {
        //     const nState = prev.map((personObj, i) => {
        //         let obj = personObj.insider_career;
        //         let nIns;
        //         if (obj.length)
        //             nIns = obj?.map((obj, j) => {
        //                 if (j === idx) {
        //                     console.log("insider_education @", i, obj, event.target.name, event.target.value)
        //                     return { ...obj, [event.target.name]: event.target.value }
        //                 }
        //                 return obj;
        //             })
        //         else
        //             nIns = obj;
        //         return { ...personObj, insider_career: nIns }
        //     })
        //     console.log('NEW STATE ========= ', nState)
        //     return nState;
        // });
    };

    const handleDeleteCareer = (idx) => {
        setCareer(prev => prev.filter((d, i) => i !== idx));
    }

    const handleCompensation = async (idx, event) => {
        console.log('handleEducation OBJ === ', idx, event.target.name, event.target.value);
        setCompensation(prev => {
            if (prev.length) {
                const newState = prev.map((obj, i) => {
                    if (i === idx)
                        return { ...obj, [event.target.name]: event.target.value }
                    return obj;
                })
                return newState
            } else {
                console.log(idx, event.target.name, event.target.value)
            }
        });
        // setPersonProfile(prev => {
        //     const nState = prev.map((personObj, i) => {
        //         let obj = personObj.insider_compensation;
        //         let nlns;
        //         if (obj.length)
        //             nlns = obj?.map((obj, j) => {
        //                 if (j === idx) {
        //                     console.log("insider_education @", j, obj, event.target.name, event.target.value)
        //                     return { ...obj, [event.target.name]: event.target.value }
        //                 }
        //                 return obj;
        //             })
        //         else
        //             nlns = obj;
        //         return { ...personObj, insider_compensation: nlns }
        //     })
        //     console.log('NEW STATE ========= ', nState)
        //     return nState;
        // });
    };

    const handleDeleteCompensation = (idx) => {
        setCompensation(prev => prev.filter((d, i) => i !== idx));
    }

    const handleReferences = async (idx, event) => {
        console.log('handleReferences OBJ === ', idx, event.target.name, event.target.value);
        setReferences(prev => {
            if (prev.length) {
                const newState = prev.map((obj, i) => {
                    if (i === idx)
                        return { ...obj, [event.target.name]: event.target.value }
                    return obj;
                })
                return newState
            } else {
                console.log(idx, event.target.name, event.target.value)
            }
        });
        // setPersonProfile(prev => {
        //     const nState = prev.map((personObj, i) => {
        //         let obj = personObj.insider_references;
        //         let nlns;
        //         if (Array.isArray(obj))
        //             nlns = obj.map((obj, j) => {
        //                 if (j === idx) {
        //                     console.log("insider_references @", j, obj, event.target.name, event.target.value)
        //                     return { ...obj, [event.target.name]: event.target.value }
        //                 }
        //                 return obj;
        //             })
        //         else
        //             nlns = obj;
        //         return { ...personObj, insider_references: nlns }
        //     })
        //     console.log('NEW STATE ========= ', nState)
        //     return nState;
        // });
    };

    const handleDeleteReference = (idx) => {
        let nRef = references.filter((a, i) => i !== idx);
        console.log('AFTER DELETED REFERENCES === ', nRef);
        setReferences(nRef);
    }

    const handleSave = async () => {
        setLoading(true);
        let data = new FormData();
        parentCompanyList.length > 0 ? parentCompanyList.forEach(a => data.append("parent_companies", JSON.stringify(a))) : data.append("parent_companies", null)
        akaList.length > 0 ? akaList.forEach(a => data.append("aliaz", a)) : data.append("aliaz", akaList)
        relevancyList.length > 0 ? relevancyList.forEach(a => data.append("relevancy", a)) : data.append("relevancy", relevancyList)
        knownForList.length > 0 ? knownForList.forEach(a => data.append("known_for", a)) : data.append("known_for", knownForList)
        education.length > 0 ? education.forEach(a => data.append("education", JSON.stringify(a))) : data.append("education", education)
        career.length > 0 ? career.forEach(a => data.append("career", JSON.stringify(a))) : data.append("career", career)
        compensation.length > 0 ? compensation.forEach(a => data.append("compensation", JSON.stringify(a))) : data.append("compensation", compensation)
        references.length > 0 ? references.forEach(a => data.append("references", JSON.stringify(a))) : data.append("references", references)
        data.append("insider_name", personName)
        data.append("insider_cik", personCIK)
        data.append("overview", convertedContentPersonOverview)
        data.append("earlyLife", convertedContentPersonEarlyLife)
        data.append("otherActivities", convertedContentPersonActivities)
        data.append("recognition", convertedContentPersonRecognition)
        data.append("profilePicture", profilePicturePath);
        data.append("image_for", 'profile');
        data.append('picture_path', profilePhotoPreview);
        data.append("is_curated", isCurated);
        data.append("last_updated_by", currentUser.name)

        data.forEach(d => console.log('FINAL DATA READY TO SAVE ::::::: ', d))

        await axios({
            method: 'POST',
            url: '/api/people/save-person-profile/' + params.id,
            Headers: { 'Content-Type': 'multipart/form-data; boundary="boundary";' },
            data: data
        }).then(resp => {
            console.log('RESP FROM save-person-profile API ::: ', resp.data);
            setPersonProfile(resp.data.message); window.alert("Profile Saved");
            window.location.reload();
        }).catch(error => {
            console.error(' ERROR RESP FROM save-person-profile API ::: ', error); window.alert("ERROR  :", error)
        }).finally(() => setLoading(false));
    };

    useEffect(() => {
        setLoading(true);
        // setScreenLoading(true);
        const fetchPerson = async () => {
            console.log('Calling Person By CIK API... ', params)
            await axios.get(`/api/people/search/${params.id}`).then(resp => {
                console.log('API RECVD PERSON ', resp.data.message)
                setPersonProfile(resp.data.message);

                if (!resp.data.message.length) {
                    setPersonProfile([])
                    setLoading(false)
                    // setScreenLoading(false)
                    return false;
                }

                if (resp.data.message[0].insider_name)
                    setPersonName(resp.data.message[0].insider_name)

                if (resp.data.message[0].insider_cik)
                    setPersonCIK(resp.data.message[0].insider_cik)

                if (resp.data.message[0].parent_companies && resp.data.message[0].parent_companies.length) {
                    console.log('resp.data.message[0].parent_companies.length === ', resp.data.message[0].parent_companies.length, resp.data.message[0].parent_companies)
                    setParentCompanyList(resp.data.message[0].parent_companies.map(e => e))
                } else if (resp.data.message[0].parent_companies) {
                    setParentCompanyList([resp.data.message[0].parent_companies])
                }
                else {
                    setParentCompanyList([])
                }

                if (resp.data.message[0].insider_aliaz && resp.data.message[0].insider_aliaz.length) {
                    console.log('resp.data.message[0].insider_aliaz.length === ', resp.data.message[0].insider_aliaz.length, resp.data.message[0].insider_aliaz)
                    setAkaList(prev => [...prev, ...resp.data.message[0].insider_aliaz])
                } else if (resp.data.message[0].insider_aliaz) {
                    setAkaList([resp.data.message[0].insider_aliaz])
                } else {
                    setAkaList([])
                }

                if (resp.data.message[0].known_for && resp.data.message[0].known_for.length) {
                    console.log('resp.data.message[0].known_for.length === ', resp.data.message[0].known_for.length)
                    setKnownForList(prev => [...prev, ...resp.data.message[0].known_for])
                } else if (resp.data.message[0].known_for) {
                    setKnownForList([resp.data.message[0].known_for])
                } else {
                    setKnownForList([])
                }

                if (resp.data.message[0].insider_profile_picture_path) {
                    setProfilePicturePath(resp.data.message[0].insider_profile_picture_path)
                    setProfilePictureFile(resp.data.message[0].insider_profile_picture_path)
                    // setProfilePhoto(resp.data.message[0].insider_profile_picture_path)
                }

                if (resp.data.message[0].insider_relevancy && resp.data.message[0].insider_relevancy.length) {
                    console.log('resp.data.message[0].insider_relevancy.length === ', resp.data.message[0].insider_relevancy.length, resp.data.message[0].insider_relevancy)
                    setRelevancyList(prev => [...prev, ...resp.data.message[0].insider_relevancy])
                } else if (resp.data.message[0].insider_relevancy) {
                    setRelevancyList([resp.data.message[0].insider_relevancy])
                } else {
                    setRelevancyList([])
                }

                if (resp.data.message[0].insider_overview !== undefined && resp.data.message[0].insider_overview !== null) {
                    setOverview(resp.data.message[0].insider_overview)
                    let blocksFromHTMLpersonOverview = htmlToDraft(resp.data.message[0].insider_overview);
                    let formattedTextPersonOverview = ContentState.createFromBlockArray(
                        blocksFromHTMLpersonOverview.contentBlocks,
                        blocksFromHTMLpersonOverview.entityMap,
                    );
                    setPersonOverviewState(EditorState.createWithContent(formattedTextPersonOverview))
                    setConvertedContentPersonOverview(resp.data.message[0].insider_overview)
                }

                if (resp.data.message[0].insider_early_life !== undefined && resp.data.message[0].insider_early_life !== null) {
                    setEarlyLife(resp.data.message[0].insider_early_life)
                    let blocksFromHTMLPersonEarlyLife = htmlToDraft(resp.data.message[0].insider_early_life);
                    let formattedTextPersonEarlyLife = ContentState.createFromBlockArray(
                        blocksFromHTMLPersonEarlyLife.contentBlocks,
                        blocksFromHTMLPersonEarlyLife.entityMap,
                    );
                    setPersonEarlyLifeState(EditorState.createWithContent(formattedTextPersonEarlyLife))
                    setConvertedContentPersonEarlyLife(resp.data.message[0].insider_early_life)
                }

                if (resp.data.message[0].insider_education && Array.isArray(resp.data.message[0].insider_education)) {
                    let edu = resp.data.message[0].insider_education.map(edu => edu)
                    console.log('resp.data.message[0].insider_education.length === ', edu.length, edu)
                    setEducation(edu);
                } else if (resp.data.message[0].insider_education && !Array.isArray(resp.data.message[0].insider_education))
                    setEducation([JSON.parse(resp.data.message[0].insider_education)]);

                console.log('resp.data.message[0].insider_career.length === ', Array.isArray(resp.data.message[0].insider_career), resp.data.message[0].insider_career)
                if (resp.data.message[0].insider_career && Array.isArray(resp.data.message[0].insider_career)) {
                    let car = resp.data.message[0].insider_career.map(c => c)
                    setCareer(car)
                } else if (resp.data.message[0].insider_career && !Array.isArray(resp.data.message[0].insider_career))
                    setCareer([JSON.parse(resp.data.message[0].insider_career)])

                if (resp.data.message[0].insider_compensation && Array.isArray(resp.data.message[0].insider_compensation)) {
                    let comp = resp.data.message[0].insider_compensation.map(c => c)
                    console.log('resp.data.message[0].insider_compensation.length === ', comp.length, comp)
                    setCompensation(comp)
                } else if (resp.data.message[0].insider_compensation && !Array.isArray(resp.data.message[0].insider_compensation))
                    setCompensation([JSON.parse(resp.data.message[0].insider_compensation)])

                if (resp.data.message[0].insider_other_activities !== undefined && resp.data.message[0].insider_other_activities !== null) {
                    setOtherActivities(resp.data.message[0].insider_other_activities)
                    let blocksFromHTMLPersonActivities = htmlToDraft(resp.data.message[0].insider_other_activities);
                    let formattedTextPersonActivities = ContentState.createFromBlockArray(
                        blocksFromHTMLPersonActivities.contentBlocks,
                        blocksFromHTMLPersonActivities.entityMap,
                    );
                    setPersonActivitiesState(EditorState.createWithContent(formattedTextPersonActivities))
                    setConvertedContentPersonActivities(resp.data.message[0].insider_other_activities)
                }

                if (resp.data.message[0].insider_recognitions !== undefined && resp.data.message[0].insider_recognitions !== null) {
                    // SetRecognition(resp.data.message[0].insider_recognitions)
                    let blocksFromHTMLPersonRecognition = htmlToDraft(resp.data.message[0].insider_recognitions);
                    let formattedTextPersonRecognition = ContentState.createFromBlockArray(
                        blocksFromHTMLPersonRecognition.contentBlocks,
                        blocksFromHTMLPersonRecognition.entityMap,
                    );
                    setPersonRecognitionState(EditorState.createWithContent(formattedTextPersonRecognition))
                    setConvertedContentPersonRecognition(resp.data.message[0].insider_recognitions)
                }

                console.log('resp.data.message[0].insider_references === ', Array.isArray(resp.data.message[0].insider_references), resp.data.message[0].insider_references)
                if (resp.data.message[0].insider_references && Array.isArray(resp.data.message[0].insider_references)) {
                    let ref = resp.data.message[0].insider_references.map(e => e)
                    setReferences(ref)
                } else if (resp.data.message[0].insider_references && !Array.isArray(resp.data.message[0].insider_references))
                    setReferences([JSON.parse(resp.data.message[0].insider_references)])

                console.log('resp.data.message[0].is_curated :::::::::::::::::: ', resp.data.message[0].is_curated)
                if (resp.data.message[0].is_curated)
                    setIsCurated(resp.data.message[0].is_curated)

                if (resp.data.message[0].last_updated_by)
                    setLastUpdatedBy(resp.data.message[0].last_updated_by)

                if (resp.data.message[0].last_updated_at)
                    setLastUpdatedAt(resp.data.message[0].last_updated_at)

            }).then(() => setLoading(false))
                .catch(error => console.error(`Error While Fetching Person By CIK, `, error))
        };

        fetchPerson().finally(() => setScreenLoading(false));

        return () => clearImmediate();
    }, [params.id]);

    // console.log('PERSON PAGE PERSON-PROFILE === ', personProfile)

    const handleDeleteAliaz = (e) => {
        setAkaList(akaList.filter((a) => a !== e))
    }

    const handleDeleteRelevancy = (e) => {
        setRelevancyList(relevancyList.filter((a) => a !== e))
    }

    const handleCompanyData = (inputData) => {
        if (inputData.length > 2) {
            setSuggLoading(true);
            setCompanySuggestions([]);
            axios.get('/api/people/search/companies', { params: { s: inputData } }).then((resp) => {
                SetSuggDropDownState(true);
                setCompanySuggestions(resp.data.message);
                setSuggLoading(false);
            });
        } else {
            setCompanySuggestions([]);
            SetSuggDropDownState(false);
        }
    };

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 400);
        };
    };

    const optimizedFn = useCallback(debounce(handleCompanyData), []);

    return (
        <>
            <Header />
            {loading && <span><LinearProgress variant='indeterminate' color='secondary' /></span>}
            {/* {personProfile && !personProfile.length && <span>No Profile Information Available</span>} */}
            {!loading && !personProfile && !personProfile.length && <Box width="100%" mt={3} mb={3}><Typography variant='body1' color={colors.deepOrange[800]}>Opps, Somthing Went Wrong, the Person Details you are looking for Could Not Be fetched!</Typography></Box>}
            {/* ##################### */}
            {/* EDIT PROFILE COMPONENT */}
            {/* ##################### */}
            {
                personProfile &&
                <>
                    {loading ? <LinearProgress color='secondary' /> : <>
                        {
                            personProfile.map((profile, i) => <Box key={i}>
                                <Box display="flex" flexDirection="row" justifyContent={"space-between"} alignContent="center" alignItems="center" p={1}>
                                    <Button variant='contained' color='inherit' size='small' onClick={() => history.push("/realtime-people")}>&#10094;{' back'}</Button>
                                    <Box justifyContent="flex-end" mx={5}>
                                        <Button variant='contained' color='primary' size='small' onClick={() => handleSave()} sx={{ mr: 3 }}>Save</Button>
                                        <FormControlLabel
                                            label={<Typography fontWeight={700}>Is Curated?</Typography>}
                                            labelPlacement='start'
                                            style={{ color: '#9c27b0', fontWeight: 700, fontSize: '21px' }}
                                            control={
                                                <Checkbox
                                                    size='medium'
                                                    checked={isCurated}
                                                    color='secondary'
                                                    onChange={(e) => setIsCurated(e.target.checked)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />}
                                        />
                                    </Box>
                                </Box>
                                <hr />
                                <Box display="flex" width="100%" justifyContent="space-around">
                                    <Box width="32%" m={1} display="flex" flexDirection="column">
                                        <Box my={0}>
                                            <TextField
                                                id="PersonName"
                                                label="Person Name"
                                                variant="standard"
                                                fullWidth
                                                // defaultValue={profile.insider_name}
                                                value={personName}
                                                onChange={(e) => setPersonName(e.target.value)}
                                            />
                                        </Box>
                                        <Box mt={5}>
                                            <TextField
                                                variant='standard'
                                                name='Person CIK'
                                                label="Person CIK"
                                                placeholder='Optional: SEC CIK'
                                                fullWidth
                                                // defaultValue={profile.insider_cik}
                                                value={personCIK}
                                                onChange={(e) => setPersonCIK(e.target.value)}
                                            />
                                        </Box>
                                        <Box mt={5}>
                                            <TextField
                                                id="knownFor"
                                                name="known-for"
                                                variant='standard'
                                                placeholder='Known For...'
                                                label="Known For"
                                                fullWidth
                                                value={knownFor}
                                                onChange={(e) => setKnownFor(e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <>
                                                            <IconButton sx={{ visibility: knownFor ? "visible" : "hidden" }} onClick={() => setKnownFor("")} size="small">
                                                                <ClearIcon />
                                                            </IconButton>
                                                            <IconButton sx={{ visibility: knownFor ? "visible" : "hidden" }} onClick={() => handleKnownFor()} size="small">
                                                                <AddBoxIcon />
                                                            </IconButton>
                                                        </>
                                                    ),
                                                }}
                                                sx={{ "& .Mui-focused .MuiIconButton-root": { color: "primary.main" } }}
                                            />
                                            <Box display="flex" flexWrap="wrap">
                                                {knownForList?.map((a, i) => (
                                                    a && <Chip key={i} label={a} style={{ margin: '3px' }} onDelete={(e) => handleDeleteKnownFor(a)} />
                                                )
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box width="32%" m={1} display="flex" flexDirection="column">
                                        <Box display="flex" flexDirection="column" position="relative">
                                            <Box>
                                                <TextField
                                                    type="text"
                                                    variant='standard'
                                                    className="search"
                                                    placeholder="Enter Parent Companies Name..."
                                                    label='Parent Companies'
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(e) => {
                                                        if (e.target.value) {
                                                            setParentCompany(e.target.value)
                                                            optimizedFn(e.target.value)
                                                        } else
                                                            setCompanySuggestions([])
                                                    }}
                                                    onFocus={() => SetSuggDropDownState(true)}
                                                    // onBlur={() => setTimeout(() => {
                                                    //     SetSuggDropDownState(false)
                                                    // }, 100)}
                                                    // defaultValue={parentCompany}
                                                    value={parentCompany}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <>
                                                                {suggLoading ? <HourglassTopRounded size="small" color="info" /> : null}
                                                                <IconButton onClick={() => { setParentCompany(""); setCompanySuggestions([]) }} size="small">
                                                                    <ClearIcon />
                                                                </IconButton>
                                                                <IconButton onClick={() => handleParentCompanyList({ cik: "", company_name: parentCompany })} size="small">
                                                                    <AddBoxIcon />
                                                                </IconButton>
                                                            </>
                                                        )
                                                    }}
                                                />
                                                {companySuggestion.length && suggDropDownState
                                                    ? <Box style={{ maxHeight: '180px', backgroundColor: 'rgb(243 246 249 / 90%)', position: 'absolute', width: '100%', zIndex: 10, overflow: "hidden", overflowY: "scroll", boxShadow: '#C5C5C5 10px 10px 30px 0px', paddingBottom: '5px' }}>
                                                        {companySuggestion.map((option, idx) =>
                                                            <MenuItem
                                                                sx={{ fontSize: 13 }}
                                                                key={option.cik || idx}
                                                                value={option.company_name}
                                                                onClick={() => {
                                                                    handleParentCompanyList(option);
                                                                    setTimeout(() => {
                                                                        SetSuggDropDownState(false);
                                                                    }, 100);
                                                                }}
                                                            >
                                                                {option.company_name}
                                                            </MenuItem>
                                                        )}
                                                    </Box>
                                                    : null}
                                            </Box>
                                        </Box>
                                        <Box>
                                            {parentCompanyList.length ? parentCompanyList.map((c, i) =>
                                                c && <Chip key={i} label={c.company_name} style={{ margin: '3px' }} onDelete={() => handleDeleteParentCompany(c)} />
                                            ) : null}
                                        </Box>
                                        <Box mt={5}>
                                            <TextField
                                                id="aka"
                                                name="aka"
                                                label="aka (Also Known As)"
                                                variant='standard'
                                                placeholder='aka'
                                                // defaultValue={aka}
                                                value={aka}
                                                onChange={(event) => setAka(event.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <>
                                                            <IconButton sx={{ visibility: aka ? "visible" : "hidden" }} onClick={() => setAka("")} size="small">
                                                                <ClearIcon />
                                                            </IconButton>
                                                            <IconButton sx={{ visibility: aka ? "visible" : "hidden" }} onClick={() => handleAka(aka)} size="small">
                                                                <AddBoxIcon />
                                                            </IconButton>
                                                        </>
                                                    ),
                                                }}
                                                sx={{ "& .Mui-focused .MuiIconButton-root": { color: "primary.main" } }}
                                            />
                                        </Box>
                                        <Box>
                                            {akaList?.map((a, i) => (
                                                a && <Chip key={i} label={a} style={{ margin: '3px' }} onDelete={(e) => handleDeleteAliaz(a)} />
                                            )
                                            )}
                                        </Box>
                                        <Box mt={5}>
                                            <FormControl sx={{ m: 1, width: 400 }}>
                                                <InputLabel id="person-category-multiple-checkbox-label">Person Relevant To</InputLabel>
                                                <Select
                                                    labelId="person-category-multiple-checkbox-label"
                                                    id="person-category-multiple-checkbox"
                                                    multiple
                                                    value={relevancyList}
                                                    onChange={handleRelevancy}
                                                    input={<OutlinedInput label="Person Relevant To" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                >
                                                    {relevancy.map((rel, i) => (
                                                        <MenuItem key={i} value={rel}>
                                                            <Checkbox checked={relevancyList.indexOf(rel) > -1} />
                                                            <ListItemText primary={rel} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box>
                                            {relevancyList?.map((r, i) => <Chip key={i} label={r} style={{ margin: '3px' }} onDelete={(e) => handleDeleteRelevancy(r)} />)}
                                        </Box>
                                    </Box>
                                    <Box width="32%" m={1} display="flex" flexDirection="column">
                                        <Box textAlign="center" position="relative">
                                            <IconButton title='Delete Image' sx={{ position: "absolute", top: -10, right: 0, visibility: profilePictureFile ? "visible" : "hidden" }} onClick={() => { setProfilePictureFile(null); setProfilePhotoPreview(null); setProfilePicturePath("") }} size="small">
                                                <ClearIcon color="error" size="small" />
                                            </IconButton>
                                            <img width="90%" src={profilePictureFile} />
                                        </Box>
                                        <Box textAlign="center">
                                            <Button variant="text" component="label" size='small' endIcon={<PhotoCamera fontSize='small' name='upload Pic' />}>
                                                Upload Profile Pic
                                                <input hidden accept="image/*" type="file" name="insider_profile_picture_path"
                                                    onChange={(e) => uploadProfilePicture(e)}
                                                />
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                                <hr />
                                <Box width="100%" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4}>
                                    <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>Overview</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" p={1} marginTop={1}>
                                    <Box width="100%">
                                        {/* <TextField id="overview" name="insider_overview" label="Person Overview" InputLabelProps={{ shrink: true }} fullWidth multiline rows={13} value={overview} onChange={e => handleOverview(e.target.name, e.target.value)} /> */}
                                        <Editor
                                            editorState={personOverviewState}
                                            wrapperClassName="wrapper-class"
                                            editorClassName="editor-class"
                                            toolbarClassName="toolbar-class"
                                            onEditorStateChange={handleEditorChangePersonOverview}
                                            toolbar={{
                                                link: { inDropdown: true },
                                                history: { inDropdown: true },
                                            }}
                                            placeholder={('Write here ...')}
                                            editorStyle={{ height: "350px" }}
                                        />
                                    </Box>
                                    {/* <Box width="30%" >
                                        <img src={profile.insider_profile_picture_path} width="95%" />
                                        <Button variant="text" component="label" size='small' endIcon={<PhotoCamera fontSize='small' name='upload Pic' />}>
                                            {profile.insider_profile_picture_path ? "Change Profile Pic" : "Upload Profile Pic"}
                                            <input hidden accept="image/*" type="file" name="insider_profile_picture_path" onChange={(e) => uploadProfilePicture(e)} />
                                        </Button>
                                    </Box> */}
                                </Box>
                                <hr />
                                <Box width="100%" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4}>
                                    <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>Early Life</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" p={1} marginTop={1} height="500px">
                                    {/* <TextField id="early-life" name="insider_early_life" label="Person Early Live" InputLabelProps={{ shrink: true }} fullWidth multiline rows={10} value={earlyLife} onChange={e => handleEarlyLife(e.target.name, e.target.value)} /> */}
                                    <Editor
                                        editorState={personEarlyLifeState}
                                        wrapperClassName="wrapper-class"
                                        editorClassName="editor-class"
                                        toolbarClassName="toolbar-class"
                                        onEditorStateChange={handleEditorChangePersonEarlyLife}
                                        toolbar={{
                                            link: { inDropdown: true },
                                            history: { inDropdown: true },
                                            image: {
                                                defaultSize: {
                                                    height: 'auto',
                                                    width: '300px',
                                                },
                                            }
                                        }}
                                        placeholder={('Write here ...')}
                                        editorStyle={{ maxHeight: "350px" }}
                                    />
                                </Box>
                                <hr />
                                <Box width="100%" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4}>
                                    <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>Education</Typography>
                                </Box>
                                <Box display="flex" flexDirection="column" justifyContent="space-evenly" p={1} marginTop={1}>
                                    {education && education.length > 0
                                        ? education.map((edu, idx) =>
                                            <Box key={idx} display="flex" justifyContent="space-evenly" alignContent="center" alignItems="center" mb={1} pb={1}>
                                                <Box width="5%" m={1}><Typography variant="body1">{idx + 1}:</Typography></Box>
                                                <Box width="30%" m={1}><TextField id="education-year-1" variant="standard" name="year" label="Year" InputLabelProps={{ shrink: true }} value={edu.year} onChange={e => handleEducation(idx, e)} /></Box>
                                                <Box width="30%" m={1}><TextField id="education-degree-1" variant="standard" name="degree" label="Degree" InputLabelProps={{ shrink: true }} value={edu.degree} onChange={e => handleEducation(idx, e)} /></Box>
                                                <Box width="30%" m={1}><TextField id="education-school-1" variant="standard" name="school" label="School" InputLabelProps={{ shrink: true }} value={edu.school} onChange={e => handleEducation(idx, e)} /></Box>
                                                <Box width="5%" m={1}><IconButton title='Delete Education' onClick={() => handleDeleteEducation(idx)} size="medium">
                                                    <DeleteForeverSharp color="error" fontSize='medium' />
                                                </IconButton></Box>
                                            </Box>)
                                        : null}
                                </Box>
                                <Box textAlign="center">
                                    <IconButton color='info' size='small' onClick={() => setEducation(prev => [...prev, { year: '', degree: '', school: '' }])}> <AddBoxIcon color='info' size='small' />Add Education</IconButton>
                                </Box>
                                <hr />
                                <Box width="100%" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4}>
                                    <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>Career</Typography>
                                </Box>
                                <Box display="flex" flexDirection="column" justifyContent="space-evenly" p={1} marginTop={1}>
                                    {career.length > 0
                                        ? career.map((car, idx) =>
                                            <Box key={idx} display="flex" justifyContent="space-evenly" alignContent="center" alignItems="center" mr={30} mb={1} pb={1}>
                                                <Box width="5%" m={1}><Typography variant="body1">{idx + 1}:</Typography></Box>
                                                <Box width="30%" m={1}><TextField id="company-1" variant="standard" name="company" label="Company" InputLabelProps={{ shrink: true }} value={car.company} onChange={e => handleCareer(idx, e)} /></Box>
                                                <Box width="30%" m={1}><TextField id="current-title-1" variant="standard" name="current_title" label="Title" InputLabelProps={{ shrink: true }} value={car.current_title} onChange={e => handleCareer(idx, e)} /></Box>
                                                <Box width="5%" m={1}><IconButton title='Delete Career' onClick={() => handleDeleteCareer(idx)} size="medium">
                                                    <DeleteForeverSharp color="error" fontSize='medium' />
                                                </IconButton></Box>
                                            </Box>)
                                        : null}
                                </Box>
                                <Box textAlign="center">
                                    <IconButton color='info' size='small' onClick={() => setCareer(prev => [...prev, { company: "", current_title: "" }])}><AddBoxIcon color='info' size='small' />Add Career</IconButton>
                                </Box>
                                <hr />
                                <Box width="100%" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4}>
                                    <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>Compensation</Typography>
                                </Box>
                                <Box display="flex" flexDirection="column" justifyContent="space-evenly" p={1} marginTop={1}>
                                    {compensation.length > 0
                                        ? compensation.map((comp, idx) =>
                                            <Box key={idx} display="flex" justifyContent="space-around" alignContent="center" alignItems="center" mb={1} pb={1}>
                                                <Box width="5%" m={1}><Typography variantt="body1">{idx + 1}:</Typography></Box>
                                                <Box width="30%" m={1}><TextField id="company-1" variant="standard" name="company" label="Company Name" InputLabelProps={{ shrink: true }} value={comp.company} onChange={e => handleCompensation(idx, e)} /></Box>
                                                <Box width="30%" m={1}><TextField id="compensation-1" variant="standard" name="compensation" label="Compensation" InputLabelProps={{ shrink: true }} value={comp.compensation} onChange={e => handleCompensation(idx, e)} /></Box>
                                                <Box width="30%" m={1}><TextField id="other-compensation-1" variant="standard" name="other_compensation" label="Other Compensation" InputLabelProps={{ shrink: true }} value={comp.other_compensation} onChange={e => handleCompensation(idx, e)} /></Box>
                                                <Box width="5%" m={1}><IconButton title='Delete Compensation' onClick={() => handleDeleteCompensation(idx)} size="medium">
                                                    <DeleteForeverSharp color="error" fontSize='medium' />
                                                </IconButton></Box>
                                            </Box>)
                                        : null}
                                </Box>
                                <Box textAlign="center">
                                    <IconButton color='info' size='small' onClick={() => setCompensation(prev => [...prev, { company: "", compensation: null, other_compensation: null }])}> <AddBoxIcon color='info' size='small' />Add Compensation</IconButton>
                                </Box>
                                <hr />
                                <Box width="100%" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4}>
                                    <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>Other Activities</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" p={1} marginTop={1} height='500px'>
                                    <Editor
                                        editorState={personActivitiesState}
                                        wrapperClassName="wrapper-class"
                                        editorClassName="editor-class"
                                        toolbarClassName="toolbar-class"
                                        onEditorStateChange={handleEditorChangePersonActivities}
                                        toolbar={{
                                            link: { inDropdown: true },
                                            history: { inDropdown: true },
                                        }}
                                        placeholder={('Write here ...')}
                                        editorStyle={{ maxHeight: "350px" }}
                                    />
                                </Box>
                                <hr />
                                <Box width="100%" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4}>
                                    <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>Recognition</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" p={1} marginTop={1} height='500px'>
                                    {/* <TextField id="recognition" name="insider_recognitions" label="Recognition" InputLabelProps={{ shrink: true }} fullWidth multiline rows={10} value={recognition} onChange={e => handleRecognition(e.target.name, e.target.value)} /> */}
                                    <Editor
                                        editorState={personRecognitionState}
                                        wrapperClassName="wrapper-class"
                                        editorClassName="editor-class"
                                        toolbarClassName="toolbar-class"
                                        onEditorStateChange={handleEditorChangePersonRecognition}
                                        toolbar={{
                                            link: { inDropdown: true },
                                            history: { inDropdown: true },
                                        }}
                                        placeholder={('Write here ...')}
                                        editorStyle={{ maxHeight: "350px" }}
                                    />
                                </Box>
                                <hr />
                                <Box width="100%" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4}>
                                    <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>References</Typography>
                                </Box>
                                {references.length > 0
                                    ? <Box display="flex" flexDirection="column" justifyContent="flex-start" p={1} marginTop={1} marginBottom={5}>
                                        {references.map((ref, idx) =>
                                            <Box key={idx} display="flex" justifyContent="flex-start" alignContent="center" alignItems="center">
                                                <Box width="5%" m={1}><Typography variantt="body1">{idx + 1}:</Typography></Box>
                                                <Box width="50%" m={1}><TextField id="title-1" variant="standard" name="title" label="Title" InputLabelProps={{ shrink: true }} fullWidth value={ref.title} onChange={e => handleReferences(idx, e)} /></Box>
                                                <Box width="40%" m={1}><TextField id="url-1" variant="standard" name="url" label="URL" InputLabelProps={{ shrink: true }} fullWidth value={ref.url} onChange={e => handleReferences(idx, e)} /></Box>
                                                <Box width="5%" m={1}><IconButton title='Delete Reference' onClick={() => handleDeleteReference(idx)} size="medium">
                                                    <DeleteForeverSharp color="error" fontSize='medium' />
                                                </IconButton></Box>
                                            </Box>
                                        )}
                                    </Box>
                                    : null}
                                <Box textAlign="center">
                                    <IconButton color='info' size='small' onClick={() => setReferences(prev => [...prev, { title: "", url: "" },])}> <AddBoxIcon color='info' fontSize='small' />Add References</IconButton>
                                </Box>
                                <hr />
                                <Box display="flex" flexDirection="row" justifyContent={"space-between"} alignContent="center" alignItems="center" p={1}>
                                    <Button variant='outlined' color='warning' size='small' onClick={() => history.push("/realtime-people")}>{'<< back'}</Button>
                                    <Button variant='contained' color='primary' size='small' onClick={() => handleSave()}>Save</Button>
                                </Box>
                            </Box>
                            )
                        }
                    </>}
                </>
            }
        </>
    )
}

export default EditPerson