import React, { useState, useEffect, useCallback } from 'react'
import { Box, Button, Checkbox, Chip, CircularProgress, colors, Dialog, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import { ManageSearchSharp } from '@mui/icons-material'
import axios from 'axios'
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { useHistory } from 'react-router-dom';
import { PhotoCamera } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import { HourglassTopRounded } from '@mui/icons-material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SimpleImageSlider from "react-simple-image-slider";

import Header from '../components/Header'
import ManageCategoriesComp from '../components/ManageCategoriesComp';
import ManageMacroIndicatorsComp from '../components/ManageMacroIndicatorsComp'

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useStore } from '../store/StoredData';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 3;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const AddArticle = () => {

    const history = useHistory();
    const { currentUser, setCurrentUser,
        masterCategoriesSubCategoriesTree, setMasterCategoriesSubCategoriesTree
    } = useStore();

    const [startDate, setStartDate] = useState(new Date());
    const [loading, setLoading] = useState(false)
    const [author, setAuthor] = useState(JSON.parse(localStorage.getItem('user')).name)
    const [articleTitle, setArticleTitle] = useState("")
    const [articleSummary, setArticleSummary] = useState("")
    const [articleDescriptionState, setArticleDescriptionState] = useState("");
    const [convertedContentArticleDescription, setConvertedContentArticleDescription] = useState('');
    const [selectedPriority, setSelectedPriority] = useState('');
    const priorityList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    const [isFeatured, setIsFeatured] = useState(false)
    const [masterCategories, setMasterCategories] = useState([])
    const [masterSubCategories, setMasterSubCategories] = useState([])
    const [assignedMasterCategoriesSubCategoriesTree, setAssignedMasterCategoriesSubCategoriesTree] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedCategoryList, setSelectedCategoryList] = useState([])
    const [selectedSubCatList, setSelectedSubCatList] = useState([])
    const [articleVersion, setArticleVersion] = useState('');
    const [articlePhoto, setArticlePhoto] = useState([])
    const [articlePictureFile, setArticlePictureFile] = useState()
    const [imageAltTag, setImageAltTag] = useState("")
    const [imageCaption, setImageCaption] = useState("")
    const [imageList, setImageList] = useState([
        // { url: "images/1.jpg" },
    ]);
    const [openCategoryModal, setOpenCategoryModal] = useState(false)
    const [openIndicatorsModal, setOpenIndicatorsModal] = useState(false)

    const [location, setLocation] = useState("")
    const [locationList, setLocationList] = useState([])
    const [locationSuggestion, setLocationSuggestions] = useState([]);
    const [locationSuggDropDownState, setLocationSuggDropDownState] = useState(false);
    const [locationSuggLoading, setLocationSuggLoading] = useState(false);

    const [peopleNames, setPeopleNames] = useState("")
    const [peopleNamesList, setPeopleNamesList] = useState([])
    const [peopleSuggestion, setPeopleSuggestions] = useState([]);
    const [peopleSuggDropDownState, setPeopleSuggDropDownState] = useState(false);
    const [peopleSuggLoading, setPeopleSuggLoading] = useState(false);

    const [industry, setIndustry] = useState("")
    const [industryList, setIndustryList] = useState([])
    const [industrySuggestion, setIndustrySuggestions] = useState([]);
    const [industrySuggDropDownState, setIndustrySuggDropDownState] = useState(false);
    const [industrySuggLoading, setIndustrySuggLoading] = useState(false);

    const [company, setCompany] = useState("")
    // const [associatedCompanies, setAssociatedCompanies] = useState([])
    const [companyList, setCompanyList] = useState([])
    const [companySuggestion, setCompanySuggestions] = useState([]);
    const [companySuggDropDownState, setCompanySuggDropDownState] = useState(false);
    const [companySuggLoading, setCompanySuggLoading] = useState(false);

    const [tickersList, setTickersList] = useState([]);

    const [assignedMasterMacroIndicatorTree, setAssignedMasterMacroIndicatorTree] = useState([]);
    const [selectedMacroIndicator, setSelectedMacroIndicator] = useState();
    const [selectedMacroIndicatorList, setSelectedMacroIndicatorList] = useState([])
    const [selectedMacroSubIndicatorList, setSelectedMacroSubIndicatorList] = useState([])

    const handleMacroIndicatorChange = async (e, indicator, selectedcategory) => {
        console.log('Selected CAT', selectedcategory, 'Indicator Value ::: ', indicator, 'EVENT ::: ', e.target.checked);
        let mVal = assignedMasterMacroIndicatorTree.map(cat => {
            if (cat.category_id === selectedMacroIndicator.category_id) {
                let fsc = cat.indicators.map(ind => {
                    if (ind.indicator_id === indicator.indicator_id) {
                        return { ...ind, isChecked: !ind.isChecked };
                    }
                    return ind;
                })
                setSelectedMacroSubIndicatorList(fsc.filter(f => f.isChecked));
                let someSelectedIndicators = fsc.some(obj => obj.isChecked);
                someSelectedIndicators ? cat.isChecked = true : cat.isChecked = false;
                return { ...cat, indicators: fsc };
            }
            return cat;
        });
        setAssignedMasterMacroIndicatorTree(mVal);
        setSelectedMacroIndicator(mVal.filter(cat => cat.category_id === selectedcategory.category_id)[0]);
    };

    const handleAssignMacroIndicator = () => {
        let fCats = assignedMasterMacroIndicatorTree
            .filter(cat => cat.isChecked === true)
            .map(cat => ({ ...cat, indicators: cat.indicators.filter(ind => ind.isChecked === true) }))
        console.log('Final Filtered ASSIGNED MACRO INDICATORS :::::: ', fCats);
        setSelectedMacroIndicatorList(fCats);
    }

    const handleEditorArticleDescription = (state) => {
        if (state) {
            setArticleDescriptionState(state);
            convertContentToHTMLArticleDescription();
        }
    }

    const convertContentToHTMLArticleDescription = () => {
        let currentContentAsHTML = draftToHtml(convertToRaw(articleDescriptionState.getCurrentContent()));
        setConvertedContentArticleDescription(currentContentAsHTML);
    }

    const updateSubCategoriesList = async (category) => {
        // setMasterSubCategories([]);
        // setSelectedSubCategory(null);
        // let filteredCat = assignedMasterCategoriesSubCategoriesTree.filter(obj => parseInt(obj.cat_id) === parseInt(category.id));
        // console.log('Filtered Sub Categories List ::::: ', filteredCat)
        // if (filteredCat.length)
        //     setMasterSubCategories(filteredCat[0].subcats.map((s) => ({ ...s, isChecked: false })))

        // setSelectedCategory(category);
        // setAssignedMasterCategoriesSubCategoriesTree(prev => {
        //     return prev.map(obj => {
        //         if (obj.cat_id === category.cat_id) {
        //             return { ...obj, isChecked: true }
        //         } else {
        //             return obj;
        //         }
        //     })
        // })
    };


    useEffect(() => {
        setLoading(true);
        const fetchCategories = async () => {
            console.log('Calling Categories API...');
            await axios.get("/api/categories").then(async resp => {
                console.log('RECVD FROM API Master Categories ::: ', resp.data);
                if (resp.data.message.length)
                    setMasterCategories(resp.data.message)
            }).catch(console.error)
        };
        const fetchSubCategories = async () => {
            console.log('Calling Categories API...');
            await axios.get("/api/sub-categories").then(async resp => {
                console.log('RECVD FROM API Master Sub Categories ::: ', resp.data.message);
                if (resp.data.message.length)
                    setMasterSubCategories(resp.data.message)
            }).catch(console.error)
        };
        const fetchCategoriesSubCategoriesMapping = async () => {
            console.log('Calling Categories API...');
            await axios.get("/api/categories-subcategories").then(async resp => {
                console.log('RECVD FROM API Categories & Sub Categories Mapping ::: ', resp.data.message);
                if (resp.data.message.length) {
                    setMasterCategoriesSubCategoriesTree(resp.data.message);
                    let data = resp.data.message.map(cats => {
                        let mapped = cats.subcats.map(obj => {
                            return { ...obj, isChecked: false };
                        });
                        return { ...cats, isChecked: false, subcats: mapped };
                    })
                    setAssignedMasterCategoriesSubCategoriesTree(data);
                }
            }).catch(console.error)
        };

        const fetchMasterMacroIndicatorTree = () => {
            axios.get(`/api/master-macro-indicators-tree`)
                .then(async (resp) => {
                    console.log("master-macro-indicators-tree :::", resp.data.message);
                    // setMasterIndicatorSubTree(resp.data.message)
                    let data = await resp.data.message.map(cats => {
                        let mapped = cats.indicators.map(obj => {
                            return { ...obj, isChecked: false };
                        });
                        return { ...cats, isChecked: false, indicators: mapped };
                    });

                    // if (articleContent.macro_indicators)
                    //     for (const obj of articleContent.macro_indicators) {
                    //         data.map(o => {
                    //             if (obj.category_id === o.category_id) {
                    //                 o.isChecked = true;
                    //                 for (const ind of obj.indicators) {
                    //                     o.indicators.filter(x => x.indicator_id === ind.indicator_id).map(y => {
                    //                         y.isChecked = true;
                    //                     });
                    //                 }
                    //             }
                    //         });
                    //     };
                    console.log('MAPPED MACRO INDICATORS DATA :::: ', data);
                    setAssignedMasterMacroIndicatorTree(data);
                }).catch((err) => console.log("Error :::", err))
        }

        fetchCategories();
        fetchSubCategories();
        fetchCategoriesSubCategoriesMapping();
        fetchMasterMacroIndicatorTree();
        setLoading(false);

        return () => clearImmediate();;

    }, [])

    useEffect(() => {
        const fetchCategoriesSubCategoriesMapping = async () => {
            console.log('Calling Categories API...');
            await axios.get("/api/categories-subcategories").then(async resp => {
                console.log('RECVD FROM API Categories & Sub Categories Mapping ::: ', resp.data.message);
                if (resp.data.message.length)
                    setMasterCategoriesSubCategoriesTree(resp.data.message);
            }).catch(console.error)
        };

        fetchCategoriesSubCategoriesMapping();

        return () => clearImmediate();

    }, []);


    const saveArticle = async () => {
        setLoading(true);
        let formData = new FormData();

        formData.append("title", articleTitle)
        formData.append("summary", articleSummary)
        formData.append("image_alt_text", imageAltTag)
        formData.append("image_caption_text", imageCaption)
        formData.append("description", convertedContentArticleDescription)
        formData.append("author_name", author)
        formData.append("author_id", currentUser.id)
        formData.append("date", new Date(startDate).toISOString())
        formData.append("item_version", articleVersion)
        formData.append("news_priority", selectedPriority)
        formData.append("is_featured", isFeatured)
        formData.append("news_type", "News Desk")
        formData.append("content_type", "image")
        // selectedCategoryList.length > 0
        //     ? selectedCategoryList.forEach(cat => formData.append("assigned_cats_subcats", JSON.stringify({ cat_id: cat.cat_id, cat_name: cat.cat_name, subcats: cat.subcats?.map(sc => ({ sub_cat_id: sc.sub_cat_id, sub_cat_name: sc.sub_cat_name })) })))
        //     : formData.append("assigned_cats_subcats", '')

        let fCats = assignedMasterCategoriesSubCategoriesTree.filter(x => x.isChecked)
        let fAssignedCats = [], MasterCatIds = [], MasterSubCatIds = [];
        for await (const cat of fCats) {
            console.log('F CAT = ', cat)
            MasterCatIds.push(cat.cat_id)
            let fsc = cat.subcats.filter(x => x.isChecked)
            fsc.map(x => MasterSubCatIds.push(x.sub_cat_id))
            console.log('F SUB-CAT = ', fsc)
            fAssignedCats.push({ ...cat, subcats: fsc })
        }
        console.log('F fAssignedCats = ', fAssignedCats, MasterCatIds, MasterSubCatIds)
        fAssignedCats.forEach(a => formData.append("assigned_cats_subcats", JSON.stringify(a)))
        MasterCatIds.forEach(x => formData.append("master_category_ids", x))
        MasterSubCatIds.forEach(x => formData.append("master_subcategory_ids", x))

        locationList.length ? locationList.map(o => formData.append("places", JSON.stringify(o))) : formData.append("places", '');
        peopleNamesList.length ? peopleNamesList.map(o => formData.append("people", JSON.stringify(o))) : formData.append("people", '');
        industryList.length ? industryList.map(o => formData.append("industry", JSON.stringify(o))) : formData.append("industry", '');
        companyList.length ? companyList.map(o => formData.append("company", o)) : formData.append("company", '');
        // associatedCompanies.length ? associatedCompanies.map(o => formData.append("company", o)) : formData.append("company", '');
        tickersList.length ? tickersList.map(o => formData.append("tickers", o)) : formData.append("tickers", tickersList);
        selectedMacroIndicatorList.length ? selectedMacroIndicatorList.map(o => formData.append("macro_indicators", JSON.stringify(o))) : formData.append("macro_indicators", '');
        imageList.length ? imageList.map(o => formData.append("article_photo", o.file)) : formData.append("article_photo", '');

        formData.forEach(d => console.log('Final FORM Data ::: ', d));

        await axios({
            method: 'POST',
            url: '/api/article',
            headers: { 'Content-Type': 'multipart/form-data; boundary="boundary"' },
            data: formData
        }).then(resp => {
            alert(`News Article Saved Successfully!\nNews Article ID - ${JSON.stringify(resp.data.message.id)}`)
            history.push('/home')
        }).catch(error => {
            console.log('API ERROR ::: ', error.response.data);
            alert(`${JSON.stringify(error.response.data.message)}`)
        }).finally(() => setLoading(false))
    }


    const uploadProfilePicture = async (e) => {
        e.preventDefault();

        let data = new FormData();
        data.append('image_for', 'profile');
        // data.append('array_index', null)
        data.append('picture_path', e.target.files[0]);
        // setArticlePhoto(e.target.files[0])
        setArticlePictureFile(URL.createObjectURL(e.target.files[0]))

        for (let i = 0; i <= e.target.files.length; i++) {
            imageList.push({ url: URL.createObjectURL(e.target.files[i]), name: e.target.files[i].name, file: e.target.files[i] })
        }

        data.forEach(d => console.log('FILE UPLOAD DATA ::::::: ', d))
    };

    console.log("Image List / Article Photo:::", imageList);

    const handleSubCatList = (event) => {
        const { value } = event.target;
        // console.log("Checkbox Selected Val before ::: ", value)
        value[0].isChecked = !value[0].isChecked;
        // console.log("Checkbox Selected Val", value)
        setSelectedSubCatList(value);
    }

    const handleCatChange = async (val) => {
        console.log('Recieved CAT to Update === ', val)
        let mVal = assignedMasterCategoriesSubCategoriesTree.map(cat => cat.cat_name === selectedCategory.cat_name)
        setAssignedMasterCategoriesSubCategoriesTree(mVal)
        const nVal = assignedMasterCategoriesSubCategoriesTree.filter(x => x.cat_name === val)
        setSelectedCategory(nVal[0])
    }

    const handleSubCatChange = async (e, subCatObj, selectedcategory) => {
        console.log('Selected CAT', selectedcategory, 'SUB-CAT Value ::: ', subCatObj, 'EVENT ::: ', e.target.checked);
        let mVal = assignedMasterCategoriesSubCategoriesTree.map(cat => {
            if (cat.cat_id === selectedCategory.cat_id) {
                let fsc = cat.subcats.map(subcat => {
                    if (subcat.sub_cat_id === subCatObj.sub_cat_id) {
                        return { ...subcat, isChecked: !subcat.isChecked };
                    }
                    return subcat;
                });
                setSelectedSubCatList(fsc.filter(f => f.isChecked));
                let someSelectedSubCats = fsc.some(obj => obj.isChecked);
                someSelectedSubCats ? cat.isChecked = true : cat.isChecked = false;
                return { ...cat, subcats: fsc };
            }
            return cat;
        });
        setAssignedMasterCategoriesSubCategoriesTree(mVal);
        setSelectedCategory(mVal.filter(cat => cat.cat_id === selectedcategory.cat_id)[0]);
    };

    const handleAssignCategories = () => {
        let fCats = assignedMasterCategoriesSubCategoriesTree
            .filter(cat => cat.isChecked === true)
        // .map(cat => ({ ...cat, subcats: cat.subcats.filter(subcat => subcat.isChecked === true) }))
        console.log('FINAL FILTERED ASSIGNED CATS & SUB-CATS :::::: ', assignedMasterCategoriesSubCategoriesTree, fCats, selectedCategory);
        setSelectedCategoryList(fCats);
    }


    // console.log("MAster Subcate", masterSubCategories);
    // console.log("SubCatLIst", subCatList);
    // console.log('FINAL ASSIGNED CAT, SUB-CATS :::: ', selectedCategoryList);
    // console.log('assignedMasterCategoriesSubCategoriesTree =', assignedMasterCategoriesSubCategoriesTree)

    const handleLocationList = (selectedLocation) => {
        console.log("selected Location :::", selectedLocation);
        setLocationList(prev => [...prev, selectedLocation])
    }

    const handleDeleteLocation = (loc) => {
        console.log("Deleting Location :::", loc)
        setLocationList(locationList.filter(location => location.name !== loc.name))
    }
    const handlePeopleNamesList = (selectedPeople) => {
        setPeopleNamesList(prev => [...prev, selectedPeople])
    }

    const handleDeletePeople = (ppl) => {
        console.log("Deleting People :::", ppl)
        setPeopleNamesList(peopleNamesList.filter(people => people.name !== ppl.name))
    }

    const handleIndustryList = (selectedIndustry) => {
        console.log('Selected Industry === ', selectedIndustry)
        setIndustryList(prev => [...prev, { code: selectedIndustry.sic_grp_code, name: selectedIndustry.sic_grp_name }])
    }

    const handleDeleteIndustry = (ind) => {
        console.log("Deleting Industry :::", ind)
        setIndustryList(industryList.filter(industry => industry.code !== ind.code))
    }

    console.log('INDUSTRIES ::: ', industryList);

    const handleCompanyList = (selectedCompany) => {
        console.log('Selected Company === ', selectedCompany)
        setCompanyList(prev => [...prev, selectedCompany.name])
        // setAssociatedCompanies(prev => [...prev, selectedCompany])
        console.log('RECVD TICKER ::: ', selectedCompany.ticker);
        setTickersList(prev => [...prev, selectedCompany.ticker ? selectedCompany.ticker : ''])
    }

    // console.log("Associated Company List :::", associatedCompanies)
    const handleDeleteCompany = (comp) => {
        console.log("Deleting Company & Ticker :::", comp)
        setCompanyList(companyList.filter(company => company !== comp))
        if (tickersList.length)
            setTickersList(prev => prev.filter(o => o !== comp.ticker))
        // setAssociatedCompanies(prev => prev.filter(o => o.cik !== comp.cik))
    }

    console.log('Company List ::: ', companyList);
    console.log('Tickers List ::: ', tickersList);

    const handlePeopleData = (inputData) => {
        if (inputData.length > 2) {
            setPeopleSuggLoading(true);
            setPeopleSuggestions([]);
            axios.get('/api/person', { params: { s: inputData } }).then((resp) => {
                setPeopleSuggDropDownState(true);
                setPeopleSuggestions(resp.data.message);
                setPeopleSuggLoading(false);
            });
        } else {
            setPeopleSuggestions([]);
            setPeopleSuggDropDownState(false);
        }
    };

    const handleIndustryTypeahead = (inputData) => {
        if (inputData.length > 2) {
            setIndustrySuggLoading(true);
            setIndustrySuggestions([]);
            axios.get('/api/industries', { params: { s: inputData } }).then((resp) => {
                setIndustrySuggDropDownState(true);
                setIndustrySuggestions(resp.data.message);
                setIndustrySuggLoading(false);
            });
        } else {
            setIndustrySuggestions([]);
            setIndustrySuggDropDownState(false);
        }
    };

    const handleCompanyTypeahead = (inputData) => {
        if (inputData.length > 2) {
            setCompanySuggLoading(true);
            setCompanySuggestions([]);
            axios.get('/api/companies', { params: { s: inputData } }).then((resp) => {
                setCompanySuggDropDownState(true);
                setCompanySuggestions(resp.data.message);
                setCompanySuggLoading(false);
            });
        } else {
            setCompanySuggestions([]);
            setCompanySuggDropDownState(false);
        }
    };

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 400);
        };
    };

    const optimizedPeopleFn = useCallback(debounce(handlePeopleData), []);
    const optimizedIndustryeFn = useCallback(debounce(handleIndustryTypeahead), []);
    const optimizedCompanyFn = useCallback(debounce(handleCompanyTypeahead), []);

    return (
        <>
            <Header />
            {loading ? <Box display='flex' justifyContent='center' alignItems='center' height='80vh'><CircularProgress /></Box> :
                <Box mx={3} boxShadow={2} py={1} >
                    <Box display='flex' justifyContent='space-between' mx={2}>
                        <Button variant='contained' color='inherit' onClick={() => history.push('/home')}>Cancel Go Back</Button>
                        <Button variant='contained' color='primary' onClick={saveArticle}>Save</Button>
                    </Box>
                    <Box mx={1} >
                        {/* Article Title */}
                        <Box my={1} p={2} >
                            {/* <Box width="100%" flexDirection={"column"} justifyContent="flex-start" mb={1}>
                                <Typography variant='h3' fontSize={15} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }}>Title</Typography>
                            </Box> */}
                            <TextField
                                id="standard-basic"
                                label="News Title"
                                variant="outlined"
                                style={{ width: "100%" }}
                                value={articleTitle}
                                onChange={(e) => setArticleTitle(e.target.value)}
                            />
                        </Box>
                        <hr color={colors.indigo[300]} />
                        {/* Article Summary */}
                        <Box my={1} p={2}>
                            {/* <Box flexDirection={"column"} justifyContent="flex-start" mb={1}>
                                <Typography variant='h3' fontSize={15} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }}>Summary</Typography>
                            </Box> */}
                            <TextField
                                id="standard-basic"
                                label="News Summary"
                                variant="outlined"
                                style={{ width: "100%" }}
                                multiline
                                rows={3}
                                value={articleSummary}
                                onChange={(e) => setArticleSummary(e.target.value)}
                            />
                        </Box>
                        <hr color={colors.indigo[300]} />
                        {/* Article Photo */}
                        <Box my={1} p={2} display='flex' justifyContent='space-between' alignItems='center'>
                            <Box width='49%'>
                                <Box position="relative" display='flex' justifyContent='flex-start' alignItems='center'>
                                    {/* <IconButton sx={{ position: "absolute", top: -10, right: 90, visibility: articlePictureFile ? "visible" : "hidden" }} onClick={() => { setArticlePhoto(null); setArticlePictureFile(null) }} size="small">
                                        <ClearIcon color="error" size="small" />
                                    </IconButton>
                                    <img width="80%" height='50%' src={articlePictureFile} /> */}
                                    {imageList.length > 0 &&
                                        <>
                                            <Box display='flex' flexDirection='column' width='100%' overflow="none" >
                                                <Box>
                                                    <SimpleImageSlider
                                                        width={350}
                                                        height={200}
                                                        images={imageList}
                                                        showBullets={true}
                                                    // showNavs={true}
                                                    />
                                                </Box>
                                                <Box>
                                                    {imageList.map(img => <Chip label={img.name || img.url} size='small' onDelete={(e) => { setImageList(imageList.filter(fimg => fimg.url !== img.url)) }} />)}
                                                </Box>
                                            </Box>
                                        </>
                                    }
                                </Box>
                                <Box display='flex' justifyContent='flex-start' alignItems='center'>
                                    <Button variant="text" component="label" size='small' endIcon={<PhotoCamera fontSize='small' name='upload Pic' />}>
                                        Upload Article Photo
                                        <input hidden multiple accept="image/*" type="file" name="insider_profile_picture_path"
                                            onChange={(e) => uploadProfilePicture(e)}
                                        />
                                    </Button>
                                </Box>
                            </Box>
                            <Box width='49%'>
                                <Box my={2}>
                                    <TextField
                                        id="standard-basic"
                                        label="Alt Tag"
                                        variant="standard"
                                        style={{ width: "100%" }}
                                        value={imageAltTag}
                                        // disabled={!articlePictureFile}
                                        onChange={(e) => setImageAltTag(e.target.value)}
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        id="standard-basic"
                                        label="Image Caption"
                                        variant="standard"
                                        style={{ width: "100%" }}
                                        value={imageCaption}
                                        // disabled={!articlePictureFile}
                                        onChange={(e) => setImageCaption(e.target.value)}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <hr color={colors.indigo[300]} />
                        {/* Article Description */}
                        <Box my={1} p={2}>
                            {/* <Box width="100%" flexDirection={"column"} justifyContent="flex-start" mb={1}>
                                <Typography variant='h3' fontSize={15} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }}> Description</Typography>
                            </Box> */}
                            <Editor
                                editorState={articleDescriptionState}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                onEditorStateChange={handleEditorArticleDescription}
                                toolbar={{
                                    link: { inDropdown: true },
                                    history: { inDropdown: true },
                                }}
                                placeholder={('News Description...')}
                                editorStyle={{ height: "350px" }}
                            />
                        </Box>
                        <hr color={colors.indigo[300]} />
                        {/* Location--People-Industry */}
                        <Box display="flex" flexDirection="row" justifyContent="space-between" my={2} p={2}>
                            <Box display="flex" flexDirection="column" position="relative" width='250px'>
                                <Box>
                                    <TextField
                                        type="text"
                                        variant='standard'
                                        className="search"
                                        placeholder="Enter Location Name..."
                                        label='Location'
                                        fullWidth
                                        // style={{ width: "250px" }}
                                        InputLabelProps={{ shrink: true }}
                                        // onChange={(e) => {
                                        //     if (e.target.value) {
                                        //         setParentCompany(e.target.value)
                                        //         optimizedFn(e.target.value)
                                        //     } else
                                        //         setCompanySuggestions([])
                                        // }}
                                        // onFocus={() => SetSuggDropDownState(true)}
                                        // onBlur={() => setTimeout(() => {
                                        //     SetSuggDropDownState(false)
                                        // }, 100)}
                                        // defaultValue={location}
                                        onChange={(e) => setLocation(e.target.value)}
                                        value={location}
                                        InputProps={{
                                            endAdornment: (
                                                <>
                                                    {locationSuggLoading ? <HourglassTopRounded size="small" color="info" /> : null}
                                                    <IconButton onClick={() => { setLocation(""); setLocationSuggestions([]) }} size="small">
                                                        <ClearIcon />
                                                    </IconButton>
                                                    <IconButton onClick={() => handleLocationList({ code: "", name: location })} size="small">
                                                        <AddBoxIcon />
                                                    </IconButton>
                                                </>
                                            )
                                        }}
                                    />
                                    {locationSuggestion.length && locationSuggDropDownState
                                        ? <Box style={{ maxHeight: '180px', backgroundColor: 'rgb(243 246 249 / 90%)', position: 'absolute', width: '100%', zIndex: 10, overflow: "hidden", overflowY: "scroll", boxShadow: '#C5C5C5 10px 10px 30px 0px', paddingBottom: '5px' }}>
                                            {locationSuggestion.map((option, idx) =>
                                                <MenuItem
                                                    sx={{ fontSize: 13 }}
                                                    key={option.code || idx}
                                                    value={option.name}
                                                    onClick={() => {
                                                        handleLocationList(option);
                                                        setTimeout(() => {
                                                            setLocationSuggDropDownState(false);
                                                        }, 100);
                                                    }}
                                                >
                                                    {option.name}
                                                </MenuItem>
                                            )}
                                        </Box>
                                        : null}
                                </Box>
                                <Box>
                                    {locationList.length ? locationList.map((loc, i) =>
                                        loc && <Chip key={i} label={loc.name} size='small' style={{ margin: '3px' }} onDelete={() => handleDeleteLocation(loc)} />
                                    ) : null}
                                </Box>
                            </Box>

                            {/* ASSOCIATED PEOPLE */}
                            <Box display="flex" flexDirection="column" position="relative" width='250px'>
                                <Box>
                                    <TextField
                                        type="text"
                                        variant='standard'
                                        className="search"
                                        placeholder="Enter People Name..."
                                        label='People Name'
                                        fullWidth
                                        // style={{ width: "250px" }}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => {
                                            if (e.target.value) {
                                                setPeopleNames(e.target.value)
                                                optimizedPeopleFn(e.target.value)
                                            } else
                                                setPeopleNames("")
                                            setPeopleSuggestions([])
                                        }}
                                        onFocus={() => setPeopleSuggDropDownState(true)}
                                        // onBlur={() => setTimeout(() => {
                                        //     setPeopleSuggDropDownState(false)
                                        // }, 100)}
                                        // defaultValue={peopleNames}
                                        value={peopleNames}
                                        InputProps={{
                                            endAdornment: (
                                                <>
                                                    {peopleSuggLoading ? <HourglassTopRounded size="small" color="info" /> : null}
                                                    <IconButton onClick={() => { setPeopleNames(""); setPeopleSuggestions([]) }} size="small">
                                                        <ClearIcon />
                                                    </IconButton>
                                                    {/* <IconButton onClick={() => handlePeopleNamesList()} size="small">
                                                            <AddBoxIcon />
                                                        </IconButton> */}
                                                </>
                                            )
                                        }}
                                    />
                                    {peopleSuggestion.length && peopleSuggDropDownState
                                        ? <Box style={{ maxHeight: '180px', backgroundColor: 'rgb(243 246 249 / 90%)', position: 'absolute', width: 'auto', zIndex: 10, overflow: "hidden", overflowY: "scroll", boxShadow: '#C5C5C5 10px 10px 30px 0px', paddingBottom: '5px' }}>
                                            {peopleSuggestion.map((option, idx) =>
                                                <MenuItem
                                                    sx={{ fontSize: 13 }}
                                                    key={option.insider_cik || idx}
                                                    value={option.name}
                                                    onClick={() => {
                                                        setTimeout(() => {
                                                            setPeopleSuggDropDownState(false);
                                                        }, 250);
                                                        handlePeopleNamesList(option);
                                                    }}
                                                >
                                                    {option.name}&nbsp;{option.relatedto && <span style={{ color: colors.blue[400], fontWeight: 500 }}>({option.relatedto})</span>}
                                                </MenuItem>
                                            )}
                                        </Box>
                                        : null}
                                </Box>
                                <Box>
                                    {peopleNamesList?.length
                                        ? peopleNamesList.map((ppl, i) =>
                                            ppl && <Chip key={ppl.id} label={ppl.name} size='small' style={{ margin: '3px' }} onDelete={() => handleDeletePeople(ppl)} />
                                        ) : null}
                                </Box>
                            </Box>

                            {/* ASSOCIATED COMPANIES SECTION */}
                            <Box display="flex" flexDirection="column" position="relative" width='250px'>
                                <Box>
                                    <TextField
                                        type="text"
                                        variant='standard'
                                        className="search"
                                        placeholder="Enter Ticker Company Name..."
                                        label='Associated Companies'
                                        fullWidth
                                        // style={{ width: "250px" }}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => {
                                            if (e.target.value) {
                                                setCompany(e.target.value)
                                                optimizedCompanyFn(e.target.value)
                                            } else
                                                setCompany("")
                                            setCompanySuggestions([])
                                        }}
                                        onFocus={() => setCompanySuggDropDownState(true)}
                                        // onBlur={() => setTimeout(() => {
                                        //     setCompanySuggDropDownState(false)
                                        // }, 100)}
                                        // defaultValue={company}
                                        value={company}
                                        InputProps={{
                                            endAdornment: (
                                                <>
                                                    {companySuggLoading ? <HourglassTopRounded size="small" color="info" /> : null}
                                                    <IconButton onClick={() => { setCompany(""); setCompanySuggestions([]) }} size="small">
                                                        <ClearIcon />
                                                    </IconButton>
                                                    {/* <IconButton onClick={() => handlecompanyList()} size="small">
                                                            <AddBoxIcon />
                                                        </IconButton> */}
                                                </>
                                            )
                                        }}
                                    />
                                    {companySuggestion.length && companySuggDropDownState
                                        ? <Box style={{ maxHeight: '180px', backgroundColor: 'rgb(243 246 249 / 90%)', position: 'absolute', width: 'auto', zIndex: 10, overflow: "hidden", overflowY: "scroll", boxShadow: '#C5C5C5 10px 10px 30px 0px', paddingBottom: '5px' }}>
                                            {companySuggestion.map((option, idx) =>
                                                <MenuItem
                                                    sx={{ fontSize: 13 }}
                                                    key={option.cik || idx}
                                                    value={option.name}
                                                    onClick={() => {
                                                        setTimeout(() => {
                                                            setCompanySuggDropDownState(false);
                                                        }, 250);
                                                        handleCompanyList(option);
                                                    }}
                                                >
                                                    {option.name || option}&nbsp;
                                                    {option.ticker && <span style={{ color: colors.blue[400], fontWeight: 500 }}>[{option.ticker}]</span>}
                                                </MenuItem>
                                            )}
                                        </Box>
                                        : null}
                                </Box>
                                <Box>
                                    {companyList?.length ? companyList.map((comp, i) =>
                                        <Chip key={comp.name || comp} label={comp.name || comp} size='small' style={{ margin: '3px' }} onDelete={() => handleDeleteCompany(comp)} />
                                    ) : null}
                                </Box>
                            </Box>

                            {/* ASSOCIATED SIC OR INDUSTRIES */}
                            <Box display="flex" flexDirection="column" position="relative" width='250px'>
                                <Box>
                                    <TextField
                                        type="text"
                                        variant='standard'
                                        className="search"
                                        placeholder="Enter Industry Name..."
                                        label='SIC | Industry'
                                        fullWidth
                                        // style={{ width: "250px" }}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => {
                                            if (e.target.value) {
                                                setIndustry(e.target.value)
                                                optimizedIndustryeFn(e.target.value)
                                            } else
                                                setIndustry("")
                                            setIndustrySuggestions([])
                                        }}
                                        onFocus={() => setIndustrySuggDropDownState(true)}
                                        // onBlur={() => setTimeout(() => {
                                        //     setIndustrySuggDropDownState(false)
                                        // }, 100)}
                                        // defaultValue={industry}
                                        value={industry}
                                        InputProps={{
                                            endAdornment: (
                                                <>
                                                    {industrySuggLoading ? <HourglassTopRounded size="small" color="info" /> : null}
                                                    <IconButton onClick={() => { setIndustry(""); setIndustrySuggestions([]) }} size="small">
                                                        <ClearIcon />
                                                    </IconButton>
                                                    {/* <IconButton onClick={() => handleIndustryList()} size="small">
                                                            <AddBoxIcon />
                                                        </IconButton> */}
                                                </>
                                            )
                                        }}
                                    />
                                    {industrySuggestion.length && industrySuggDropDownState
                                        ? <Box style={{ maxHeight: '180px', backgroundColor: 'rgb(243 246 249 / 90%)', position: 'absolute', width: 'auto', zIndex: 10, overflow: "hidden", overflowY: "scroll", boxShadow: '#C5C5C5 10px 10px 30px 0px', paddingBottom: '5px' }}>
                                            {industrySuggestion.map((option, idx) =>
                                                <MenuItem
                                                    sx={{ fontSize: 13 }}
                                                    key={option.code || idx}
                                                    value={option.sic_name}
                                                    onClick={() => {
                                                        handleIndustryList(option);
                                                        setTimeout(() => {
                                                            setIndustrySuggDropDownState(false);
                                                        }, 100);
                                                    }}
                                                >
                                                    {option.sic_name}&nbsp;<span style={{ color: colors.blue[400], fontWeight: 500 }}>[{option.sic_grp_name}]</span>
                                                </MenuItem>
                                            )}
                                        </Box>
                                        : null}
                                </Box>
                                <Box>
                                    {industryList?.length ? industryList.map((ind, i) =>
                                        <Chip key={ind.code} label={ind.name} size='small' style={{ margin: '3px' }} onDelete={() => handleDeleteIndustry(ind)} />
                                    ) : null}
                                </Box>
                            </Box>
                        </Box>
                        <hr color={colors.indigo[300]} />
                        <Box display="flex" flexDirection="row" justifyContent="space-between" alignContent="center" alignItems="center">
                            {/* Manage Categories */}
                            <Box width='25%' my={1} p={2} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                                <Button variant='outlined' size='large' onClick={() => setOpenCategoryModal(p => !p)} startIcon={<ManageSearchSharp fontSize='large' color='secondary' />}>Manage Categories</Button>
                            </Box>
                            {/* Manage Categories */}
                            {/* <Box width='25%' my={1} p={2} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                                <Button variant='outlined' size='large' onClick={() => setOpenIndicatorsModal(p => !p)} startIcon={<ManageSearchSharp fontSize='large' color='secondary' />}>Manage Macro Indicators</Button>
                            </Box> */}
                            <Box width='25%' my={1} p={2} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                                <TextField
                                    select
                                    id="priority"
                                    label="News Priority"
                                    variant="standard"
                                    size="small"
                                    value={selectedPriority}
                                    onChange={(e) => setSelectedPriority(e.target.value)}
                                    style={{ margin: 12, width: '90%', textAlign: 'left', width: '150px' }}
                                    inputProps={{ style: { height: "200px" } }}
                                >
                                    {priorityList.length > 0 && priorityList.map((priority, idx) => <MenuItem key={idx} id={idx} value={priority}>{priority}</MenuItem>)}
                                </TextField>
                            </Box>
                            <Box width='25%' my={1} p={2} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                                <TextField
                                    id="standard-basic"
                                    label="Article Version"
                                    variant="standard"
                                    style={{ width: "150px" }}
                                    value={articleVersion}
                                    onChange={(e) => setArticleVersion(e.target.value)}
                                />
                            </Box>
                            <Box width='25%' my={1} p={2} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                                <FormControlLabel
                                    label="Featured News"
                                    control={
                                        <Checkbox
                                            size='small'
                                            checked={isFeatured}
                                            onChange={() => setIsFeatured(!isFeatured)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                />
                            </Box>
                        </Box>
                        <hr color={colors.indigo[300]} />
                        {/* Macro-Indicator Drop Down */}
                        <Box display="flex" flexDirection="row" justifyContent="space-between" alignContent="center" alignItems="center" my={2} p={2}>
                            <Box width="35%">
                                <FormControl fullWidth>
                                    <InputLabel id="article-sub-category-multiple-checkbox-label">Macro Indicator Category</InputLabel>
                                    <Select
                                        fullWidth
                                        variant='outlined'
                                        label="Select Macro Indicator Category"
                                        InputLabelProps={{ shrink: true }}
                                        value={selectedMacroIndicator ?? null}
                                        MenuProps={MenuProps}
                                        input={<OutlinedInput label="Article Sub Category " />}
                                        onChange={(e) => setSelectedMacroIndicator(e.target.value)}
                                    >
                                        {assignedMasterMacroIndicatorTree.length > 0 &&
                                            assignedMasterMacroIndicatorTree.map((category, idx) =>
                                                <MenuItem key={idx} id={category.category_id} value={category} sx={{ fontSize: 14, padding: 1 }}>
                                                    {category.category_name}
                                                </MenuItem>
                                            )}
                                    </Select>
                                </FormControl>
                            </Box>

                            {/* Macro-Indicator Sub Drop Down */}
                            <Box width="45%">
                                <FormControl fullWidth>
                                    <InputLabel id="article-sub-category-multiple-checkbox-label">Macro Indicators</InputLabel>
                                    <Select
                                        multiple
                                        fullWidth
                                        disabled={!selectedMacroIndicator}
                                        label="Select Macro Indicator Category"
                                        value={selectedMacroSubIndicatorList ?? null}
                                        renderValue={selectedIndicators => selectedIndicators.map(ind => ind.indicator_name).join(", ")}
                                        onChange={(e) => setSelectedMacroSubIndicatorList(e.target.value)}
                                        input={<OutlinedInput label="Article Sub Category " />}
                                        MenuProps={MenuProps}>
                                        {selectedMacroIndicator && assignedMasterMacroIndicatorTree
                                            .filter(f => f.category_id === selectedMacroIndicator.category_id)[0].indicators
                                            .map((ind) =>
                                                <MenuItem key={ind.indicator_id} value={ind}
                                                    onChange={(e) => handleMacroIndicatorChange(e, ind, selectedMacroIndicator)}
                                                    sx={{ fontSize: 14, padding: 0 }}>
                                                    <Checkbox checked={ind.isChecked} value={ind.indicator_name} />{ind.indicator_name}
                                                </MenuItem>
                                            )}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box width="5%">
                                <Button size='small' variant='outlined' onClick={handleAssignMacroIndicator} >Assign</Button>
                            </Box>
                        </Box>
                        <Box width="100%" display='flex' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' flexWrap='wrap' m={1}>
                            {selectedMacroIndicatorList.length
                                ? selectedMacroIndicatorList.map((cat) => {
                                    if (cat.isChecked) {
                                        console.log('CHECKED INDICATOR === ', cat)
                                        let ind = cat.indicators.filter(ind => ind.isChecked);
                                        return <Chip label={`${cat.category_name} > ${ind.map(o => o.indicator_name)}`} sx={{ m: 1 }} size='small' />
                                    }
                                })
                                : null}
                        </Box>
                        <hr color={colors.indigo[300]} />
                        <Box display='flex' justifyContent='space-between' alignContent='center' alignItems='center'>
                            <Box display="flex" flexDirection="row" justifyContent="flex-start" alignContent="center" alignItems="center">
                                <Typography variant='h3' fontSize={15} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }}>Published Date:</Typography>
                                &emsp;
                                <Typography variant="body1" color={colors.blueGrey[800]} sx={{ textDecorationLine: 'none', textAlign: 'right' }}>{new Date(startDate).toString()}</Typography>
                            </Box>
                            <Box display="flex" flexDirection="row" justifyContent="flex-start" alignContent="center" alignItems="center">
                                <Typography variant='h3' fontSize={15} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }}>Author Name:</Typography>
                                &emsp;
                                {/* <Typography variant="body1" color={colors.blueGrey[800]} sx={{ textDecorationLine: 'none', textAlign: 'right' }}>{author}</Typography> */}
                                <TextField
                                    id="standard-basic"
                                    label=""
                                    variant="outlined"
                                    size='small'
                                    style={{ width: "250px" }}
                                    value={author}
                                    onChange={(e) => setAuthor(e.target.value)}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <hr color={colors.indigo[300]} />
                    <Box display='flex' justifyContent='space-between' p={2} my={3} bgcolor={colors.blueGrey[50]}>
                        <Button variant='contained' color='inherit' onClick={() => history.push('/home')}>Cancel Go Back</Button>
                        <Button variant='contained' color='primary' onClick={saveArticle}>Save</Button>
                    </Box>
                </Box>
            }

            {/* MANAGE CATEGORIES DIALOG */}
            <Dialog
                open={openCategoryModal}
                onClose={() => setOpenCategoryModal(false)}
                maxWidth='lg'
            >
                <Box sx={{
                    columnCount: { xs: 1, sm: 2, md: 3 }, // Adjust columns based on screen size
                    columnGap: '20px', // Smaller gap between columns
                    padding: 1,
                    margin: 0
                }} boxShadow={5}>
                    {assignedMasterCategoriesSubCategoriesTree.map(category => (
                        <Box sx={{
                            backgroundColor: colors.blueGrey[50],
                            borderRadius: '8px',
                            marginBottom: '15px', // Gap between cards in the same column
                            breakInside: 'avoid', // Prevents breaking within columns
                            padding: 2,
                            display: 'inline-block', // Required for column layout
                            width: '90%', // Ensures full width within the column
                        }}>
                            <ManageCategoriesComp
                                category={category}
                                assignedMasterCategoriesSubCategoriesTree={assignedMasterCategoriesSubCategoriesTree}
                                setAssignedMasterCategoriesSubCategoriesTree={setAssignedMasterCategoriesSubCategoriesTree} />
                        </Box>
                    ))}
                </Box>
            </Dialog>

            {/* MANAGE MACRO-INDICATORS DIALOG */}
            {/* <Dialog
                open={openIndicatorsModal}
                onClose={() => setOpenIndicatorsModal(false)}
                maxWidth='lg'
            >
                <Box sx={{
                    columnCount: { xs: 1, sm: 2, md: 3 }, // Adjust columns based on screen size
                    columnGap: '20px', // Smaller gap between columns
                    padding: 1,
                    margin: 0
                }} boxShadow={5}>
                    {assignedMasterMacroIndicatorTree.map(indicator => (
                        <Box sx={{
                            backgroundColor: colors.blueGrey[50],
                            borderRadius: '8px',
                            marginBottom: '15px', // Gap between cards in the same column
                            breakInside: 'avoid', // Prevents breaking within columns
                            padding: 2,
                            display: 'inline-block', // Required for column layout
                            width: '90%', // Ensures full width within the column
                        }}>
                            <ManageMacroIndicatorsComp
                                indicator={indicator}
                                assignedMasterMacroIndicatorTree={assignedMasterMacroIndicatorTree}
                                setAssignedMasterMacroIndicatorTree={setAssignedMasterMacroIndicatorTree} />
                        </Box>
                    ))}
                </Box>
            </Dialog> */}
        </>
    )
}

export default AddArticle