import Header from '../components/Header'
import SearchBar from '../components/SearchBar'
import Tabs from '../components/Tabs'
import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { Box, colors, TextField, IconButton, Button, FormControlLabel, Checkbox, Typography, alertTitleClasses, Chip, CircularProgress, Modal, FormControl, MenuItem, OutlinedInput, InputLabel, Select } from '@mui/material';
import { ClearSharp, EditSharp, SearchSharp } from "@mui/icons-material"
import { format } from 'date-fns'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroll-component';
import ClearIcon from '@mui/icons-material/Clear';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useStore } from '../store/StoredData';

import "../../src/App.css";
import "react-datepicker/dist/react-datepicker.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 3;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const NewsSummary = () => {

    const { masterCategoriesSubCategoriesTree, setMasterCategoriesSubCategoriesTree,
        masterCategories, setMasterCategories,
        masterSubCategories, setMasterSubCategories
    } = useStore()

    const [loading, setLoading] = useState(false)
    const [searchOn, setSearchOn] = useState(true)
    const [initDateRange, setInitDateRange] = useState([new Date(), new Date()]);
    const [dateRange, setDateRange] = useState(initDateRange);
    const [headline, setHeadline] = useState("");
    const [headlineText, setHeadlineText] = useState("")
    const [companyTicker, setCompanyTicker] = useState("")
    const [keywordText, setKeywordText] = useState("");
    const [macroIndicatorName, setMacroIndicatorName] = useState("");
    const [articleIndex, setArticleIndex] = useState(0)


    const [hasMore, setHasMore] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [articlesList, setArticlesList] = useState([])
    const [newsFeatured, setNewsFeatured] = useState(false)

    // Modal
    const [openDescModal, setOpenDescModal] = useState(false)
    const [openTitleModal, setOpenTitleModal] = useState(false)
    const [openSummaryModal, setOpenSummaryModal] = useState(false)
    const [openAuhorModal, setOpenAuthorModal] = useState(false)
    const [openArticleVerModal, setOpenArticleVerModal] = useState(false)
    const [openOrganizationModal, setOpenOrganizationModal] = useState(false)
    const [openAssociatedImageModal, setOpenAssociatedImageModal] = useState(false)
    const [openAssociatedKeywordsModal, setOpenAssociatedKeywordsModal] = useState(false)
    const [openCatSubCatModal, setOpenCatSubCatModal] = useState(false)

    // Modal States
    const [currentArticleID, setCurrentArticleID] = useState("")
    const [author, setAuthor] = useState("");
    const [articleVersion, setArticleVersion] = useState(null);
    const [datePublished, setDatePublished] = useState();
    const [lastUpdated, setLastUpdated] = useState();
    const [articleTitle, setArticleTitle] = useState("")
    const [articleSummary, setArticleSummary] = useState("")
    const [articleDescriptionState, setArticleDescriptionState] = useState("");
    const [convertedContentArticleDescription, setConvertedContentArticleDescription] = useState();
    const [articlePhoto, setArticlePhoto] = useState()
    const [articlePictureFile, setArticlePictureFile] = useState()
    const [articleImageURL, setArticleImageURL] = useState()
    const [imageAltTag, setImageAltTag] = useState("")
    const [imageCaption, setImageCaption] = useState("")
    // const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedSubCategory, setSelectedSubCategory] = useState("");
    const [articleKeywords, setArticlekeywords] = useState("");
    const [articleKeywordsList, setArticlekeywordsList] = useState([]);
    const [articleOrganization, setArticleOrganization] = useState("")
    const [articleOrganizationList, setArticleOrganizationList] = useState([])
    const [articleAssociatedNews, setArticleAssociatedNews] = useState("")
    const [articleAssociatedNewsList, setArticleAssociatedNewsList] = useState([])

    // const [masterCategories, setMasterCategories] = useState([])
    // const [masterSubCategories, setMasterSubCategories] = useState([])
    const [assignedMasterCategoriesSubCategoriesTree, setAssignedMasterCategoriesSubCategoriesTree] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedCategoryList, setSelectedCategoryList] = useState([])
    const [selectedSubCatList, setSelectedSubCatList] = useState([])

    const handleClose = () => {
        setOpenDescModal(false);
        setOpenTitleModal(false);
        setOpenSummaryModal(false);
        setOpenAuthorModal(false);
        setOpenArticleVerModal(false);
        setOpenOrganizationModal(false);
        setOpenAssociatedImageModal(false);
        setOpenAssociatedKeywordsModal(false);
        setOpenCatSubCatModal(false);
    }

    const handleEditorArticleDescription = (state) => {
        if (state) {
            setArticleDescriptionState(state);
            convertContentToHTMLArticleDescription();
        }
    }
    const convertContentToHTMLArticleDescription = () => {
        let currentContentAsHTML = draftToHtml(convertToRaw(articleDescriptionState.getCurrentContent()));
        setConvertedContentArticleDescription(currentContentAsHTML);
    }

    const beforeOpenEditDesc = (desc) => {
        let blocksFromHTMLpersonOverview = htmlToDraft(desc);
        let formattedTextPersonOverview = ContentState.createFromBlockArray(
            blocksFromHTMLpersonOverview.contentBlocks,
            blocksFromHTMLpersonOverview.entityMap,
        );
        setArticleDescriptionState(EditorState.createWithContent(formattedTextPersonOverview))
        setConvertedContentArticleDescription(desc)
    }

    const selectDateRange = (daterange) => {
        // console.log('STORE RECEIVED DATE-RANGE ==================', daterange)
        setInitDateRange(daterange)
    }

    const handleDateRange = (currDt) => {
        setDateRange(prvDt => {
            if (prvDt === currDt) {
                selectDateRange(currDt);
                return currDt
            } else {
                setCurrentPage(0)
                selectDateRange(currDt);
                return currDt
            }
        });
    }

    const handleHeadlineStringChange = (currStr) => {
        setHeadlineText(prevStr => {
            if (prevStr === currStr) {
                return currStr;
            } else {
                setCurrentPage(0);
                return currStr;
            }
        })
    }

    const handleCompanyStringChange = (currStr) => {
        setCompanyTicker(prevStr => {
            if (prevStr === currStr) {
                return currStr;
            } else {
                setCurrentPage(0);
                return currStr;
            }
        })
    }


    const fetchMoreArticles = async () => {
        const resp = await axios.get("/api/articles/summary", {
            params: {
                startdate: format(initDateRange[0], "yyyy-MM-dd"),
                enddate: format(initDateRange[1], "yyyy-MM-dd"),
                headline: headlineText,
                company_name: companyTicker,
                page: currentPage
            }
        })
        console.log("fetchArticlesData :::", resp.data.message)
        const { count, rows } = resp.data.message;

        if (count < pageSize) {
            console.log('Looks Like No More Rows From Server...., Stopping On Page - ', currentPage)
            setHasMore(false);
        } else {
            setHasMore(true);
            console.log('*** Looks Like More Rows In Server...., Increasing the Page Count from = ', currentPage)
            setCurrentPage(currentPage => currentPage + 1);
        }
        return rows;
    };

    const fetchMoreData = async () => {
        // setLoading(true);
        console.log('Trying To Fetch More Data......... for page - ', currentPage)
        const moreRows = await fetchMoreArticles();
        console.log('fetchMoreArticles() LEN = ', moreRows.length, moreRows)
        setArticlesList(prev => [...prev, ...moreRows]);
        // setLoading(false);
    }


    const resetSearchBar = () => {
        setCompanyTicker('')
        setHeadlineText('')
    }

    console.log("Article List :::", articlesList)

    useEffect(() => {
        const fetchArticles = async () => {
            setLoading(true);
            setArticlesList([]);
            setCurrentPage(0);

            await axios.get("/api/articles/summary", {
                params: {
                    startdate: format(initDateRange[0], "yyyy-MM-dd"),
                    enddate: format(initDateRange[1], "yyyy-MM-dd"),
                    headline: headlineText,
                    company_name: companyTicker,
                    page: currentPage
                }
            }).then(async resp => {
                let { count, rows } = resp.data.message;
                console.log('RECVD FROM API Articles ::: ', count, rows);
                if (count) {
                    setLoading(false);
                    setArticlesList(rows);
                    setArticleIndex(0);

                    /* MANAGE NEXT PAGE CALL OR NOT */
                    if (count < pageSize) {
                        console.log('Looks Like No More Rows From Server...., Stopping On Page - ', currentPage)
                        setHasMore(false);
                    } else {
                        setHasMore(true);
                        console.log('*** Looks Like More Rows In Server...., Increasing the Page Count from = ', currentPage)
                        setCurrentPage(1);
                    }
                } else {
                    setLoading(false);
                    setHasMore(false);
                    setArticlesList([]);
                    setArticleIndex(0);
                }
            }).catch(console.error).finally(() => {
                setHasMore(false);
                setLoading(false);
                setSearchOn(false)
            });
        };

        if (initDateRange[1] >= initDateRange[0] && searchOn) {
            fetchArticles();
        }
    }, [searchOn])

    // useEffect(() => {
    //     setLoading(true);
    //     const fetchCategories = async () => {
    //         console.log('Calling Categories API...');
    //         await axios.get("/api/categories").then(async resp => {
    //             console.log('RECVD FROM API Master Categories ::: ', resp.data);
    //             if (resp.data.message.length)
    //                 setMasterCategories(resp.data.message)
    //         }).catch(console.error)
    //     };
    //     const fetchSubCategories = async () => {
    //         console.log('Calling Categories API...');
    //         await axios.get("/api/sub-categories").then(async resp => {
    //             console.log('RECVD FROM API Master Sub Categories ::: ', resp.data.message);
    //             if (resp.data.message.length)
    //                 setMasterSubCategories(resp.data.message)
    //         }).catch(console.error)
    //     };
    //     const fetchCategoriesSubCategoriesMapping = async () => {
    //         console.log('Calling Categories API...');
    //         await axios.get("/api/categories-subcategories").then(async resp => {
    //             console.log('RECVD FROM API Categories & Sub Categories Mapping ::: ', resp.data.message);
    //             if (resp.data.message.length) {
    //                 setMasterCategoriesSubCategoriesTree(resp.data.message);
    //                 let data = resp.data.message.map(cats => {
    //                     let mapped = cats.subcats.map(obj => {
    //                         return { ...obj, isChecked: false };
    //                     });
    //                     return { ...cats, isChecked: false, subcats: mapped };
    //                 })
    //                 setAssignedMasterCategoriesSubCategoriesTree(data);
    //             }
    //         }).catch(console.error)
    //     };


    //     (async () => {
    //         await fetchCategories();
    //         await fetchSubCategories();
    //         await fetchCategoriesSubCategoriesMapping();
    //         setLoading(false);
    //     })()

    //     return () => clearImmediate();;

    // }, [])

    // useEffect(() => {
    //     const fetchCategoriesSubCategoriesMapping = async () => {
    //         console.log('Calling Categories API...');
    //         await axios.get("/api/categories-subcategories").then(async resp => {
    //             console.log('RECVD FROM API Categories & Sub Categories Mapping ::: ', resp.data.message);
    //             if (resp.data.message.length)
    //                 setMasterCategoriesSubCategoriesTree(resp.data.message);
    //         }).catch(console.error)
    //     };

    //     fetchCategoriesSubCategoriesMapping();

    //     return () => clearImmediate();

    // }, []);

    const handleAssignCategories = () => {
        setSelectedCategoryList([]);
        let fCats = assignedMasterCategoriesSubCategoriesTree
            .filter(cat => cat.isChecked === true)
            .map(cat => ({ ...cat, subcats: cat.subcats.filter(subcat => subcat.isChecked === true) }))
        console.log('Final Filtered ASSIGNED CATS & SUB-CATS :::::: ', fCats);
        setSelectedCategoryList(fCats);
    }

    const handleSubCatChange = async (e, subCatObj, selectedcategory) => {
        console.log('Selected CAT', selectedcategory, 'SUB-CAT Value ::: ', subCatObj, 'EVENT ::: ', e.target.checked);
        let mVal = assignedMasterCategoriesSubCategoriesTree.map(cat => {
            if (cat.cat_id === selectedCategory.cat_id) {
                let fsc = cat.subcats.map(subcat => {
                    if (subcat.sub_cat_id === subCatObj.sub_cat_id) {
                        return { ...subcat, isChecked: !subcat.isChecked };
                    }
                    return subcat;

                })
                setSelectedSubCatList(fsc.filter(f => f.isChecked));
                let someSelectedSubCats = fsc.some(obj => obj.isChecked);
                someSelectedSubCats ? cat.isChecked = true : cat.isChecked = false;
                return { ...cat, subcats: fsc };
            }
            return cat;
        });

        setAssignedMasterCategoriesSubCategoriesTree(mVal);
        setSelectedCategory(mVal.filter(cat => cat.cat_id === selectedcategory.cat_id)[0]);
    };

    const saveSelectedCatsSubCats = async () => {
        console.log("FINAL SELECTED CATS & SUB-CATS :::", selectedCategoryList);
        await axios.put(`/api/articles/summary/categories/${currentArticleID}`, {
            selectedCatsSubcats: selectedCategoryList
        }).then(resp => {
            console.log(resp.data.message);
            // document.location.reload()
            alert(resp.data.message)
        }).catch(error => alert(error.data.message))

    }
    const filterSelectedCatSubcat = (currentCat, currentSubcat) => {
        console.log("MAster CatSUbcat ::", masterCategoriesSubCategoriesTree)
        console.log("currentCat ::", currentCat)
        console.log("currentSubcat ::", currentSubcat)
        // setAssignedMasterCategoriesSubCategoriesTree([])
        let selectedCatSubCat = [];
        selectedCatSubCat = masterCategoriesSubCategoriesTree.map((mCat) => {
            if (currentCat.includes(mCat.cat_id)) {
                let fmCat = { ...mCat, isChecked: true }
                console.log('FOUND MATCHING CAT = ', fmCat);
                let fSubCat = mCat.subcats.map(subcat => {
                    if (currentSubcat.includes(subcat.sub_cat_id)) {
                        let fsc = { ...subcat, isChecked: true };
                        console.log('FOUND MATCHING SUB CAT = ', fsc);
                        return fsc;
                    } else {
                        let fsc = { ...subcat, isChecked: false };
                        // console.log('NOT FOUND MATCHING SUB CAT = ', fsc);
                        return fsc;
                    }
                })
                return { ...fmCat, subcats: fSubCat };
            } else {
                let fmCat = { ...mCat, isChecked: false, subcats: mCat.subcats.map(o => ({ ...o, isChecked: false })) }
                // console.log('NOT FOUND MATCHING CAT = ', fmCat)
                return fmCat;
            }
        })
        console.log("selectedCatSubCat", selectedCatSubCat);
        setAssignedMasterCategoriesSubCategoriesTree(prev => prev = selectedCatSubCat)
    }

    const EditHeadline = () => {
        axios.post(`/api/articles/summary/headline/${currentArticleID}`, {
            headline: articleTitle
        })
            .then((resp) => { alert("Headline Saved"); console.log(resp.data.message) })
            .catch(err => alert("Error While Saving", err))
    }

    const EditSummary = () => {
        axios.post(`/api/articles/summary/summary/${currentArticleID}`, {
            summary: articleSummary
        })
            .then((resp) => { alert("Summary Saved"); console.log(resp.data.message) })
            .catch(err => alert("Error While Saving", err))
    }

    const EditDescription = () => {
        axios.post(`/api/articles/summary/description/${currentArticleID}`, {
            description: convertedContentArticleDescription
        })
            .then((resp) => { alert("Description Saved"); console.log(resp.data.message) })
            .catch(err => alert("Error While Saving", err))
    }

    const EditAuthor = () => {
        axios.post(`/api/articles/summary/author/${currentArticleID}`, {
            author_name: author
        }).then((resp) => { alert("Author Saved"); console.log(resp.data.message) })
            .catch(err => alert("Error While Saving", err))
    }


    return (
        <>
            <Box width='100%' position='fixed' zIndex={3} >
                <Header />
                <Tabs />
                <Box boxShadow={1} border={1} bgcolor="#fff" borderColor={colors.blueGrey[100]} display="flex" flexDirection="row" justifyContent="space-between" alignContent="center" alignItems="center" >
                    <Box m={0.5}>
                        <ReactDatePicker id="ReactDatePicker"
                            disabled={newsFeatured}
                            className='ReactDatePicker'
                            dateFormat="MMM dd, yyyy"
                            selectsRange={true}
                            startDate={dateRange[0]}
                            endDate={dateRange[1]}
                            maxDate={new Date()}
                            onChange={date_range => handleDateRange(date_range)}
                        />
                    </Box>
                    <Box m={0.5}>
                        <TextField
                            disabled={newsFeatured}
                            name='headline'
                            title='Enter News Headline word(s) to search'
                            variant='standard'
                            placeholder='Enter Headline Word/String'
                            helperText={`Use & to combine words; | to Option words`}
                            // defaultValue={headlineText}
                            value={headlineText}
                            onChange={(event) => handleHeadlineStringChange(event.target.value)}
                        />
                    </Box>
                    <Box m={0.5}>
                        <TextField
                            disabled
                            name='keyword'
                            title='Keyword Word/String'
                            variant='standard'
                            placeholder='Enter keyword Word/String'
                            helperText={`Use & to combine words; | to Option words`}
                            // defaultValue={keywordText}
                            value={keywordText}
                            onChange={(event) => setKeywordText(event.target.value)}
                        />
                    </Box>
                    <Box m={0.5}>
                        <TextField
                            disabled={newsFeatured}
                            name='company'
                            title='company Name'
                            variant='standard'
                            placeholder='Enter company Ticker / Name'
                            helperText={`Full or Partial Company Name`}
                            // defaultValue={companyTicker}
                            value={companyTicker}
                            onChange={(event) => handleCompanyStringChange(event.target.value)}
                        />
                    </Box>
                    <Box m={0.5}>
                        <TextField
                            disabled
                            name='indicator'
                            title='Macro Indicator Name'
                            variant='standard'
                            placeholder='Enter Macro Indicator Name'
                            helperText={`Full or Partial Macro Indicator Name`}
                            // defaultValue={macroIndicatorName}
                            value={macroIndicatorName}
                            onChange={(event) => setMacroIndicatorName(event.target.value)}
                        />
                    </Box>
                    {/* <Box width="15%" >
                        <FormControlLabel
                            label={<Typography variant="body2" fontWeight={600} color={colors.deepOrange[800]}>&nbsp;Featured News Only</Typography>}
                            sx={{ margin: 'auto 0' }}
                            control={
                                <Checkbox
                                    checked={newsFeatured}
                                    sx={{ paddingRight: 0 }}
                                    onChange={() => setNewsFeatured(!newsFeatured)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />}
                        />
                    </Box> */}
                    <Box m={0.5}>
                        <Button type='submit' onClick={() => setSearchOn(true)} startIcon={<SearchSharp color='secondary' />} title='Search For Resutls'>search</Button>
                    </Box>
                    <Box m={0.5}>
                        <Button type='submit' onClick={resetSearchBar} startIcon={<ClearSharp color='secondary' />} title='Reset Search Bar'>clear</Button>
                    </Box>
                </Box>
            </Box>

            {/* {!loading && !articlesList.length ? <Box width='50%' position='absolute' top={290} display='flex' justifyContent='center' alignItems='center'>"No Data to Display"</Box> : null} */}
            {/* Articles List */}
            <Box width='200%' position='absolute' top={190}>
                {loading
                    ? <Box width='50%' height='80vh' display='flex' justifyContent='center' alignItems='center' >
                        <CircularProgress />
                    </Box>
                    : <Box id="scrollableDiv" height={420} overflow="auto" px={1}>
                        <InfiniteScroll
                            dataLength={articlesList.length}
                            next={fetchMoreData}
                            hasMore={hasMore}
                            style={{ overflowY: 'hidden' }}
                            loader={loading ? <Box width='50%' height='80vh' display='flex' justifyContent='center' alignItems='center'><CircularProgress /></Box> : null}
                            scrollableTarget="scrollableDiv"
                            endMessage={
                                articlesList.length
                                    ? <Box width='50%' display='flex' justifyContent='center' alignItems='center'><p style={{ textAlign: 'center', fontSize: 16, fontWeight: 400, color: colors.blue[600] }}>
                                        Yay! You have seen it all
                                    </p></Box>
                                    : <Box width='50%' display='flex' justifyContent='center' alignItems='center'><p style={{ textAlign: 'center', fontSize: 16, fontWeight: 400, color: colors.red[400] }}>
                                        No Data to Display from Infinite Scroll...
                                    </p></Box>
                            }
                        >
                            {articlesList.length ? articlesList.map((article, idx) => (
                                <Box key={idx} display='flex' textAlign='center' fontSize={12} border={1} borderColor={colors.grey[400]} borderRadius={2} boxShadow={1} my={2}>
                                    {/* Headline */}
                                    <Box display='flex' flexDirection='column' width='40%'>
                                        <Box height='200px'>
                                            <Box p={.5} bgcolor={colors.blue[100]} height="30px" display='flex' justifyContent='center' alignItems='center'><strong>Feed Headline</strong></Box>
                                            <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]} height='200px' fontSize={12} textAlign="left">
                                                {article.feed_title}
                                            </Box>
                                        </Box>
                                        <Box height='200px'>
                                            <Box p={.5} bgcolor={colors.red[100]} height="30px" display='flex' justifyContent='center' alignItems='center'>
                                                <Box display='flex' flexBasis="100%" justifyContent='center' alignItems='center' ><strong>Our Headline</strong></Box>
                                                <Box mr={2} title="Edit Headline">
                                                    <EditSharp onClick={() => { setOpenTitleModal(true); setArticleTitle(article.our_title); setCurrentArticleID(article.db_id) }} style={{ cursor: 'pointer', background: '#fff', fontSize: '20px', padding: "3px", borderRadius: "5px", boxShadow: '1px 1px 3px #CCC' }} color="warning" />
                                                </Box>
                                            </Box>
                                            <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]} height='200px' fontSize={12} textAlign="left">
                                                {article.our_title}
                                            </Box>
                                        </Box>
                                    </Box>

                                    {/* Summmary */}
                                    <Box display='flex' flexDirection='column' width='40%'>
                                        <Box height='200px'>
                                            <Box p={.5} bgcolor={colors.blue[100]} height="30px" display='flex' justifyContent='center' alignItems='center'><strong>Summary</strong></Box>
                                            <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]} height='200px' fontSize={12} textAlign="left">
                                                {article.feed_summary}
                                            </Box>
                                        </Box>
                                        <Box height='200px'>
                                            <Box p={.5} bgcolor={colors.red[100]} height="30px" display='flex' justifyContent='center' alignItems='center'>
                                                <Box display='flex' flexBasis="100%" justifyContent='center' alignItems='center' ><strong>Our Summary</strong></Box>
                                                <Box mr={2} title="Edit Summary">
                                                    <EditSharp onClick={() => { setOpenSummaryModal(true); setArticleSummary(article.our_summary); setCurrentArticleID(article.db_id) }} style={{ cursor: 'pointer', background: '#fff', fontSize: '20px', padding: "3px", borderRadius: "5px", boxShadow: '1px 1px 3px #CCC' }} color="warning" />
                                                </Box>
                                            </Box>
                                            <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]} height='200px' fontSize={12} textAlign="left">
                                                {article.our_summary}
                                            </Box>
                                        </Box>
                                    </Box>

                                    {/* Description */}
                                    <Box display='flex' flexDirection='column' width='50%'>
                                        <Box height='200px'>
                                            <Box p={.5} bgcolor={colors.blue[100]} height="30px" display='flex' justifyContent='center' alignItems='center'><strong>Description</strong></Box>
                                            <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]} style={{ overflowY: "scroll" }} height='120px' fontSize={12} textAlign="left">
                                                <div dangerouslySetInnerHTML={{ __html: article.feed_content }}></div>
                                            </Box>
                                        </Box>
                                        <Box height='200px'>
                                            <Box p={.5} bgcolor={colors.red[100]} height="30px" display='flex' justifyContent='center' alignItems='center'>
                                                <Box display='flex' flexBasis="100%" justifyContent='center' alignItems='center' ><strong>Our Description</strong></Box>
                                                <Box mr={2} title="Edit Description">
                                                    <EditSharp onClick={() => { setOpenDescModal(true); beforeOpenEditDesc(article.our_description); setCurrentArticleID(article.db_id) }} style={{ cursor: 'pointer', background: '#fff', fontSize: '20px', padding: "3px", borderRadius: "5px", boxShadow: '1px 1px 3px #CCC' }} color="warning" />
                                                </Box>
                                            </Box>
                                            <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]} style={{ overflowY: "scroll" }} height='120px' fontSize={12} textAlign="left">
                                                <div dangerouslySetInnerHTML={{ __html: article.our_description }}></div>
                                            </Box>
                                        </Box>
                                    </Box>
                                    {/* Date Published */}
                                    <Box display='flex' flexDirection='column' width='20%'>
                                        <Box height='200px'>
                                            <Box p={.5} bgcolor={colors.blue[100]} height="30px" display='flex' justifyContent='center' alignItems='center'><strong>Date Published</strong></Box>
                                            <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]} fontSize={12} textAlign="left">
                                                {article.feed_published_date ? format(new Date(article.feed_published_date), "MMM dd, yy HH:MMSS a") : null}
                                            </Box>
                                        </Box>
                                        <Box height='200px'>
                                            <Box p={.5} bgcolor={colors.red[100]} height="30px" display='flex' justifyContent='center' alignItems='center'><strong>Our Date Published</strong></Box>
                                            <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]} fontSize={12} textAlign="left">
                                                {/* {new Date(article.our_published_date).toLocaleString()} */}
                                                {article.our_published_date ? format(new Date(article.our_published_date), "MMM dd, yy HH:MMSS a") : null}
                                            </Box>
                                        </Box>
                                    </Box>
                                    {/* Author */}
                                    <Box display='flex' flexDirection='column' width='20%'>
                                        <Box height='200px'>
                                            <Box p={.5} bgcolor={colors.blue[100]} height="30px" display='flex' justifyContent='center' alignItems='center'><strong>Author</strong></Box>
                                            <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]} fontSize={12} textAlign="left">
                                                {article.feed_author}
                                            </Box>
                                        </Box>
                                        <Box height='200px'>
                                            <Box p={.5} bgcolor={colors.red[100]} height="30px" display='flex' justifyContent='center' alignItems='center'>
                                                <Box display='flex' flexBasis="100%" justifyContent='center' alignItems='center' ><strong>Our Author</strong></Box>
                                                <Box mr={2} title="Edit Auhor">
                                                    <EditSharp onClick={() => { setOpenAuthorModal(true); setAuthor(article.our_author); setCurrentArticleID(article.db_id) }} style={{ cursor: 'pointer', background: '#fff', fontSize: '20px', padding: "3px", borderRadius: "5px", boxShadow: '1px 1px 3px #CCC' }} color="warning" />
                                                </Box>
                                            </Box>
                                            <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]} fontSize={12} textAlign="left">
                                                {article.our_author}
                                            </Box>
                                        </Box>
                                    </Box>
                                    {/* Article Version */}
                                    <Box display='flex' flexDirection='column' width='20%'>
                                        <Box height='200px'>
                                            <Box p={.5} bgcolor={colors.blue[100]} height="30px" display='flex' justifyContent='center' alignItems='center'><strong>Article Version</strong></Box>
                                            <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]} fontSize={12} textAlign="left">
                                                {article.feed_version}
                                            </Box>
                                        </Box>
                                        <Box height='200px'>
                                            <Box p={.5} bgcolor={colors.red[100]} height="30px" display='flex' justifyContent='center' alignItems='center'>
                                                <Box display='flex' flexBasis="100%" justifyContent='center' alignItems='center' ><strong>Our Aricle Version</strong></Box>
                                                <Box mr={2} title="Edit Article Version">
                                                    {/* <EditSharp onClick={() => { setOpenArticleVerModal(true); setArticleVersion(article.our_version) }} style={{ cursor: 'pointer', background: '#fff', fontSize: '20px', padding: "3px", borderRadius: "5px", boxShadow:'1px 1px 3px #CCC'}} color="warning" /> */}
                                                </Box>
                                            </Box>
                                            <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]} fontSize={12} textAlign="left">
                                                {article.our_version}
                                            </Box>
                                        </Box>
                                    </Box>
                                    {/* Organization */}
                                    <Box display='flex' flexDirection='column' width='20%'>
                                        <Box height='200px'>
                                            <Box p={.5} bgcolor={colors.blue[100]} height="30px" display='flex' justifyContent='center' alignItems='center'><strong>Organization</strong></Box>
                                            <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]} fontSize={12} textAlign="left">
                                                {article.organisations && article.organisations.length ? article.organisations.map((o) => <Chip label={o.name} size='small' sx={{ margin: "3px" }} />) : null}
                                            </Box>
                                        </Box>
                                        <Box height='200px'>
                                            <Box p={.5} bgcolor={colors.red[100]} height="30px" display='flex' justifyContent='center' alignItems='center'>
                                                <Box display='flex' flexBasis="100%" justifyContent='center' alignItems='center' ><strong>Our Organization</strong></Box>
                                                <Box mr={2} title="Edit Organizaion">
                                                    {/* <EditSharp onClick={() => { setOpenOrganizationModal(true); setArticleOrganizationList(article.our_organisations) }} style={{ cursor: 'pointer', background: '#fff', fontSize: '20px', padding: "3px", borderRadius: "5px", boxShadow:'1px 1px 3px #CCC'}} color="warning" /> */}
                                                </Box>
                                            </Box>
                                            <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]} fontSize={12} textAlign="left">
                                                {article.our_organisations && article.our_organisations.length ? article.our_organisations.map((o) => <Chip label={o.name} size='small' sx={{ margin: "3px" }} />) : null}
                                            </Box>
                                        </Box>
                                    </Box>
                                    {/* Associated News */}
                                    <Box display='flex' flexDirection='column' width='20%'>
                                        <Box height='200px'>
                                            <Box p={.5} bgcolor={colors.blue[100]} height="30px" display='flex' justifyContent='center' alignItems='center'><strong>Associated News</strong></Box>
                                            <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]} fontSize={12} textAlign="left">
                                            </Box>
                                        </Box>
                                        <Box height='200px'>
                                            <Box p={.5} bgcolor={colors.red[100]} height="30px" display='flex' justifyContent='center' alignItems='center'><strong>Our Associated News</strong></Box>
                                            <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]} fontSize={12} textAlign="left">
                                            </Box>
                                        </Box>
                                    </Box>
                                    {/* Associated ImageUrl */}
                                    <Box display='flex' flexDirection='column' width='30%'>
                                        <Box height='200px'>
                                            <Box p={.5} bgcolor={colors.blue[100]} height="30px" display='flex' justifyContent='center' alignItems='center'><strong>Associated Image URL</strong></Box>
                                            <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]} fontSize={12} textAlign="left" style={{ wordWrap: 'break-word' }}>
                                            </Box>
                                        </Box>
                                        <Box height='200px'>
                                            <Box p={.5} bgcolor={colors.red[100]} height="30px" display='flex' justifyContent='center' alignItems='center'>
                                                <Box display='flex' flexBasis="100%" justifyContent='center' alignItems='center' ><strong>Our Associated Image URL</strong></Box>
                                                <Box mr={2} title="Edit Image URL">
                                                    {/* <EditSharp onClick={() => { setOpenAssociatedImageModal(true); setArticleImageURL("") }} style={{ cursor: 'pointer', background: '#fff', fontSize: '20px', padding: "3px", borderRadius: "5px", boxShadow:'1px 1px 3px #CCC'}} color="warning" /> */}
                                                </Box>
                                            </Box>
                                            <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]} fontSize={12} textAlign="left" style={{ wordWrap: 'break-word' }}>
                                                {/* <a href={articleImageURL} target="_blank" >{articleImageURL}</a> */}
                                            </Box>
                                        </Box>
                                    </Box>
                                    {/* Associated Keywords */}
                                    <Box display='flex' flexDirection='column' width='30%' sx={{ overflowX: 'scroll' }}>
                                        <Box height='200px'>
                                            <Box p={.5} bgcolor={colors.blue[100]} height="30px" display='flex' justifyContent='center' alignItems='center'><strong>Associated Keywords</strong></Box>
                                            <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]} fontSize={12} textAlign="left" display='flex' justifyContent='flex-start' flexWrap='wrap'>
                                                {article.feed_keywords?.length ? article.feed_keywords.map((k) => <Chip label={k} size='small' sx={{ margin: "2px" }} />) : null}
                                            </Box>
                                        </Box>
                                        <Box height='200px'>
                                            <Box p={.5} bgcolor={colors.red[100]} height="30px" display='flex' justifyContent='center' alignItems='center'>
                                                <Box display='flex' flexBasis="100%" justifyContent='center' alignItems='center' ><strong>Our Associated Keywords</strong></Box>
                                                <Box mr={2} title="Edit Keywords">
                                                    {/* <EditSharp onClick={() => { setOpenAssociatedKeywordsModal(true); setArticlekeywordsList(article.our_keywords) }} style={{ cursor: 'pointer', background: '#fff', fontSize: '20px', padding: "3px", borderRadius: "5px", boxShadow:'1px 1px 3px #CCC'}} color="warning" /> */}
                                                </Box>
                                            </Box>
                                            <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]} fontSize={12} textAlign="left">
                                                {article.our_keywords && article.our_keywords.length ?
                                                    article.our_keywords.map((keyword, idx) =>
                                                        <Chip id={idx} key={idx} label={keyword} sx={{ margin: 1, backgroundColor: colors.indigo[400], color: colors.common.white }} size="small" variant='filled' />
                                                    ) : null}
                                            </Box>
                                        </Box>
                                    </Box>
                                    {/* Category Sub Category */}
                                    <Box display='flex' flexDirection='column' width='30%'>
                                        <Box height='200px'>
                                            <Box p={.5} bgcolor={colors.blue[100]} height="30px" display='flex' justifyContent='center' alignItems='center'><strong>Feed Subjects</strong></Box>
                                            <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]} fontSize={12} textAlign="left" height="100px" overflow="auto">
                                                {article.feed_subjects.map((sub, idx) => <Chip key={idx} id={sub.code} size="small" color='default' label={sub.name} sx={{ m: 0.25 }} />)}
                                            </Box>
                                        </Box>
                                        <Box height='200px'>
                                            <Box p={.5} bgcolor={colors.red[100]} height="30px" display='flex' justifyContent='center' alignItems='center'>
                                                <Box display='flex' flexBasis="100%" justifyContent='center' alignItems='center' ><strong>Our Categories/subs</strong></Box>
                                                <Box mr={2} title="Edit Categoryies/subs">
                                                    <EditSharp onClick={() => { filterSelectedCatSubcat(article.our_categories, article.our_subcategories); setOpenCatSubCatModal(true); setCurrentArticleID(article.db_id) }} style={{ cursor: 'pointer', background: '#fff', fontSize: '20px', padding: "3px", borderRadius: "5px", boxShadow: '1px 1px 3px #CCC' }} color="warning" />
                                                </Box>
                                            </Box>
                                            <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]} textAlign="left">
                                                {article?.our_categories?.length && masterCategories.length
                                                    ? article.our_categories.map((cat, idx) => {
                                                        let fm = masterCategories.filter(mCat => parseInt(mCat.id) === parseInt(cat));
                                                        if (fm.length) {
                                                            // console.log('FOUND MATCHING CAT OBJ === ', fm)
                                                            return <Chip key={fm[0].id} id={idx} label={fm[0].name} size="small" color="default" sx={{ m: 0.25 }} />
                                                        }
                                                    })
                                                    : null
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            )) : null}
                        </InfiniteScroll>
                    </Box>
                }


                {/* ########################################################### */}
                {/* ####################----MODALs----######################### */}
                {/* ########################################################### */}

                {/* Edit Title Modal  */}
                <Modal
                    open={openTitleModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 720,
                        height: "40vh",
                        bgcolor: 'background.paper',
                        // border: '2px solid #000',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: 3
                    }}>
                        <Box display='flex' justifyContent='center' alignItems="center" height="100%">
                            <Box mx={1} width='100%'>
                                <Box p={1} bgcolor={colors.deepOrange[50]} display="flex" justifyContent='space-between'>
                                    <Box>
                                        <strong>Our Headline</strong>
                                    </Box>
                                    < Box display='flex'>
                                        <Button variant='outlined' size='small' color='secondary' sx={{ marginRight: 2 }} onClick={EditHeadline}>Save</Button>
                                        <Button variant='outlined' size='small' color='error' onClick={handleClose}>Close</Button>
                                    </Box>
                                </Box>
                                <Box my={2}>
                                    <TextField
                                        id="standard-basic"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={2}
                                        value={articleTitle}
                                        onChange={(e) => setArticleTitle(e.target.value)}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal>

                {/* Edit Summary Modal  */}
                <Modal
                    open={openSummaryModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 720,
                        height: "45vh",
                        bgcolor: 'background.paper',
                        // border: '2px solid #000',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: 3
                    }}>
                        <Box display='flex' justifyContent='center' alignItems="center" height="100%">
                            <Box mx={1} width='100%'>
                                <Box p={1} bgcolor={colors.deepOrange[50]} display="flex" justifyContent='space-between'>
                                    <Box>
                                        <strong>Our Summary</strong>
                                    </Box>
                                    < Box display='flex'>
                                        <Button variant='outlined' size='small' color='secondary' sx={{ marginRight: 2 }} onClick={EditSummary}>Save</Button>
                                        <Button variant='outlined' size='small' color='error' onClick={handleClose}>Close</Button>
                                    </Box>
                                </Box>
                                <Box my={2}>
                                    <TextField
                                        id="standard-basic"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={5}
                                        value={articleSummary}
                                        onChange={(e) => setArticleSummary(e.target.value)}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal>

                {/* Edit Image URL  */}
                <Modal
                    open={openAssociatedImageModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 720,
                        height: "40vh",
                        bgcolor: 'background.paper',
                        // border: '2px solid #000',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: 3
                    }}>
                        <Box display='flex' justifyContent='center' alignItems="center" height="100%">
                            <Box mx={1} width='100%'>
                                <Box p={1} bgcolor={colors.deepOrange[50]} display="flex" justifyContent='space-between'>
                                    <Box>
                                        <strong>Our Image URL</strong>
                                    </Box>
                                    < Box display='flex'>
                                        <Button variant='outlined' size='small' color='secondary' sx={{ marginRight: 2 }}>Save</Button>
                                        <Button variant='outlined' size='small' color='error' onClick={handleClose}>Close</Button>
                                    </Box>
                                </Box>
                                <Box my={2}>
                                    <TextField
                                        id="standard-basic"
                                        // label="Our Article Image URL"
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                        fullWidth
                                        value={articleImageURL}
                                        onChange={(e) => setArticleImageURL(e.target.value)}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal>

                {/* Edit Author  */}
                <Modal
                    open={openAuhorModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 500,
                        height: "30vh",
                        bgcolor: 'background.paper',
                        // border: '2px solid #000',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: 3
                    }}>
                        <Box display='flex' justifyContent='center' alignItems="center" height="100%">
                            <Box mx={1} width="100%">
                                <Box p={1} bgcolor={colors.deepOrange[50]} display="flex" justifyContent='space-between'>
                                    <Box>
                                        <strong>Our Auhor</strong>
                                    </Box>
                                    < Box display='flex'>
                                        <Button variant='outlined' size='small' color='secondary' sx={{ marginRight: 2 }} onClick={EditAuthor}>Save</Button>
                                        <Button variant='outlined' size='small' color='error' onClick={handleClose}>Close</Button>
                                    </Box>
                                </Box>
                                <Box my={2}>
                                    <TextField
                                        id="standard-basic"
                                        label="Our Author"
                                        variant="standard"
                                        fullWidth
                                        value={author}
                                        onChange={(e) => setAuthor(e.target.value)}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal>

                {/* Edit Article Version  */}
                <Modal
                    open={openArticleVerModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 500,
                        height: "30vh",
                        bgcolor: 'background.paper',
                        // border: '2px solid #000',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: 3
                    }}>
                        <Box display='flex' justifyContent='center' alignItems="center" height="100%">
                            <Box mx={1} width="100%">
                                <Box p={1} bgcolor={colors.deepOrange[50]} display="flex" justifyContent='space-between'>
                                    <Box>
                                        <strong>Our Aricle Version</strong>
                                    </Box>
                                    < Box display='flex'>
                                        <Button variant='outlined' size='small' color='secondary' sx={{ marginRight: 2 }}>Save</Button>
                                        <Button variant='outlined' size='small' color='error' onClick={handleClose}>Close</Button>
                                    </Box>
                                </Box>
                                <Box my={2}>
                                    <TextField
                                        id="standard-basic"
                                        label="Our Article Version"
                                        variant="standard"
                                        fullWidth
                                        value={articleVersion}
                                        onChange={(e) => setArticleVersion(e.target.value)}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal>

                {/* Edit Organization  */}
                <Modal
                    open={openOrganizationModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 500,
                        height: "30vh",
                        bgcolor: 'background.paper',
                        // border: '2px solid #000',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: 3
                    }}>
                        <Box display='flex' justifyContent='center' alignItems="flex-start" height="100%">
                            <Box mx={1} width='100%'>
                                <Box p={1} bgcolor={colors.deepOrange[50]} display="flex" justifyContent='space-between'>
                                    <Box>
                                        <strong>Our Organization</strong>
                                    </Box>
                                    < Box display='flex'>
                                        <Button variant='outlined' size='small' color='secondary' sx={{ marginRight: 2 }}>Save</Button>
                                        <Button variant='outlined' size='small' color='error' onClick={handleClose}>Close</Button>
                                    </Box>
                                </Box>
                                <Box my={2}>
                                    <TextField
                                        id="Our Organizations"
                                        name="Our Organizations"
                                        variant='standard'
                                        placeholder='Our Organizations'
                                        label="Our Organizations"
                                        fullWidth
                                        value={articleOrganization}
                                        onChange={(e) => setArticleOrganization(e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (
                                                <>
                                                    <IconButton sx={{ visibility: articleOrganization ? "visible" : "hidden" }} onClick={() => setArticleOrganization("")} size="small">
                                                        <ClearIcon />
                                                    </IconButton>
                                                    <IconButton sx={{ visibility: articleOrganization ? "visible" : "hidden" }} onClick={() => setArticleOrganizationList(prev => [...prev, articleOrganization])} size="small">
                                                        <AddBoxIcon />
                                                    </IconButton>
                                                </>
                                            ),
                                        }}
                                        sx={{ "& .Mui-focused .MuiIconButton-root": { color: "primary.main" } }}
                                    />
                                    <Box display="flex" flexWrap="wrap" my={1}>
                                        {articleOrganizationList?.map((a, i) => (
                                            a && <Chip key={i} label={a.name} size='small' style={{ margin: '3px' }} onDelete={(e) => setArticleOrganizationList(articleOrganizationList.filter((k) => k !== a))} />
                                        )
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal>

                {/* Edit Associaed Keywords  */}
                <Modal
                    open={openAssociatedKeywordsModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 500,
                        height: "30vh",
                        bgcolor: 'background.paper',
                        // border: '2px solid #000',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: 3
                    }}>
                        <Box display='flex' justifyContent='center' alignItems="flex-start" height="100%">
                            <Box mx={1} width='100%'>
                                <Box p={1} bgcolor={colors.deepOrange[50]} display="flex" justifyContent='space-between'>
                                    <Box>
                                        <strong>Our Associated Keywords</strong>
                                    </Box>
                                    < Box display='flex'>
                                        <Button variant='outlined' size='small' color='secondary' sx={{ marginRight: 2 }}>Save</Button>
                                        <Button variant='outlined' size='small' color='error' onClick={handleClose}>Close</Button>
                                    </Box>
                                </Box>
                                <Box my={2}>
                                    <TextField
                                        id="Our Associated Keywords"
                                        name="Our Associated Keywords"
                                        variant='standard'
                                        // placeholder='Our Associated Keywords'
                                        label="Our Associated Keywords"
                                        fullWidth
                                        value={articleKeywords}
                                        onChange={(e) => setArticlekeywords(e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (
                                                <>
                                                    <IconButton sx={{ visibility: articleKeywords ? "visible" : "hidden" }} onClick={() => setArticlekeywords("")} size="small">
                                                        <ClearIcon />
                                                    </IconButton>
                                                    <IconButton sx={{ visibility: articleKeywords ? "visible" : "hidden" }} onClick={() => setArticlekeywordsList(prev => [...prev, articleKeywords])} size="small">
                                                        <AddBoxIcon />
                                                    </IconButton>
                                                </>
                                            ),
                                        }}
                                        sx={{ "& .Mui-focused .MuiIconButton-root": { color: "primary.main" } }}
                                    />
                                    <Box display="flex" flexWrap="wrap" my={1}>
                                        {articleKeywordsList?.map((a, i) => (
                                            a && <Chip key={i} label={a} size='small' style={{ margin: '3px' }} onDelete={(e) => setArticlekeywordsList(articleKeywordsList.filter((k) => k !== a))} />
                                        )
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal>

                {/* Edit Category SubCat  */}
                <Modal
                    open={openCatSubCatModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 500,
                        height: "55vh",
                        bgcolor: 'background.paper',
                        // border: '2px solid #000',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: 3
                    }}>
                        <Box display='flex' justifyContent='center' alignItems="flex-start" height="100%">
                            <Box mx={1} width='100%'>
                                <Box p={1} bgcolor={colors.deepOrange[50]} display="flex" justifyContent='space-between'>
                                    <Box>
                                        <strong>Our Cat Subcat</strong>
                                    </Box>
                                    < Box display='flex'>
                                        <Button variant='outlined' size='small' color='secondary' sx={{ marginRight: 2 }} onClick={saveSelectedCatsSubCats}>Save</Button>
                                        <Button variant='outlined' size='small' color='error' onClick={() => { handleClose(); setSelectedCategoryList([]) }}>Close</Button>
                                    </Box>
                                </Box>
                                <Box>
                                    <Box my={1} p={2} display='flex' flexDirection='column' justifyContent='center' alignItems='center' flexWrap='wrap'>
                                        <FormControl sx={{ m: 1, width: 400 }}>
                                            <TextField
                                                select
                                                variant='standard'
                                                labelId="article-category-multiple-checkbox-label"
                                                id="article-category-multiple-checkbox"
                                                label="Select Categories"
                                                InputLabelProps={{ shrink: true }}
                                                value={selectedCategory ?? null}
                                                MenuProps={MenuProps}
                                                input={<OutlinedInput label="Article Sub Category " />}
                                                onChange={(e) => {
                                                    setSelectedCategory(e.target.value)
                                                    // updateSubCategoriesList(e.target.value);
                                                    console.log("Selected Category :::", e.target.value)
                                                }}
                                            >
                                                {assignedMasterCategoriesSubCategoriesTree.length > 0 &&
                                                    assignedMasterCategoriesSubCategoriesTree.map((category, idx) =>
                                                        <MenuItem
                                                            key={idx}
                                                            id={category.cat_id}
                                                            disabled={category.cat_hide}
                                                            value={category}
                                                        >
                                                            {category.cat_name}
                                                        </MenuItem>
                                                    )}
                                            </TextField>
                                        </FormControl>
                                        <Box>
                                            <FormControl sx={{ m: 1, width: 400 }}>
                                                <InputLabel id="article-sub-category-multiple-checkbox-label">Select Sub Categories:</InputLabel>
                                                <Select
                                                    labelId="article-sub-category-multiple-checkbox-label"
                                                    id="article-sub-category-multiple-checkbox"
                                                    multiple
                                                    value={selectedSubCatList ?? null}
                                                    renderValue={selectedSubCats => selectedSubCats.map(sub => sub.sub_cat_name).join(", ")}
                                                    onChange={(e) => setSelectedSubCatList(e.target.value)}
                                                    input={<OutlinedInput label="Article Sub Category " />}
                                                    MenuProps={MenuProps}
                                                >
                                                    {selectedCategory && assignedMasterCategoriesSubCategoriesTree
                                                        .filter(f => f.cat_id === selectedCategory.cat_id)[0]?.subcats
                                                        .map((subcat) =>
                                                            <MenuItem
                                                                key={subcat.sub_cat_id}
                                                                value={subcat}
                                                                disabled={subcat.sub_cat_hide}
                                                                onChange={(e) => handleSubCatChange(e, subcat, selectedCategory)}
                                                            >
                                                                <Checkbox checked={subcat.isChecked} value={subcat.sub_cat_name} />{subcat.sub_cat_name}
                                                            </MenuItem>
                                                        )}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box>
                                            <Button variant='outlined' onClick={handleAssignCategories} >Assign Category</Button>
                                        </Box>
                                        <Box width="100%" display='flex' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' flexWrap='wrap' m={1}>
                                            {assignedMasterCategoriesSubCategoriesTree.length
                                                ? assignedMasterCategoriesSubCategoriesTree.map((cat) => {
                                                    if (cat.isChecked) {
                                                        let sc = cat.subcats.filter(sc => sc.isChecked);
                                                        return <Chip label={`${cat.cat_name} > ${sc.map(o => o.sub_cat_name).join("; ")}`} sx={{ m: 1 }} />
                                                    }
                                                })
                                                : null}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal>


                {/* Edit Description Modal  */}
                <Modal
                    open={openDescModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 1000,
                        height: "80vh",
                        bgcolor: 'background.paper',
                        // border: '2px solid #000',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: 3
                    }}>
                        <Box display='flex' justifyContent='center' alignItems="center" height="100%">
                            <>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '40ch', },
                                    }}
                                    autoComplete="off"
                                >
                                    <Box mx={1}>
                                        <Box p={1} bgcolor={colors.deepOrange[50]} display="flex" justifyContent='space-between'>
                                            <Box>
                                                <strong>Our Description</strong>
                                            </Box>
                                            < Box display='flex'>
                                                <Button variant='outlined' size='small' color='secondary' sx={{ marginRight: 2 }} onClick={EditDescription}>Save</Button>
                                                <Button variant='outlined' size='small' color='error' onClick={handleClose}>Close</Button>
                                            </Box>
                                        </Box>
                                        <Box p={1} border={1} borderBottom={0} borderColor={colors.grey[200]}>
                                            <Editor
                                                editorState={articleDescriptionState}
                                                wrapperClassName="wrapper-class"
                                                editorClassName="editor-class"
                                                toolbarClassName="toolbar-class"
                                                onEditorStateChange={handleEditorArticleDescription}
                                                toolbar={{
                                                    link: { inDropdown: true },
                                                    history: { inDropdown: true },
                                                    image: {
                                                        previewImage: false,
                                                        alt: { present: true, mandatory: true },
                                                    }
                                                }}
                                                placeholder={('News Description...')}
                                                editorStyle={{ height: "350px" }}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </>
                        </Box>
                    </Box>
                </Modal>
            </Box>
        </>
    )
}

export default NewsSummary