import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Box, Button, colors, IconButton, Typography } from "@mui/material"
import CategoryIcon from '@mui/icons-material/Category';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import AddBoxIcon from '@mui/icons-material/AddBox';

import authService from '../services/auth.service';

import { useStore } from '../store/StoredData';

const Header = () => {

    const history = useHistory();

    // const [currentUser, setCurrentUser] = useState();
    const { currentUser } = useStore();

    useEffect(() => { }, [currentUser])
    // useEffect(() => { setCurrentUser(authService.getCurrentUser()) }, [currentUser])

    return (
        <Box width="100%" boxShadow={2} display="flex" flexDirection="row" justifyContent="space-around" alignContent="center" alignItems="center" bgcolor="darkblue" color={colors.grey[50]}>
            <Box textAlign="left" fontSize={12} fontWeight={500} padding='2px'>
                <a
                    onClick={() => history.push("/home")}
                    // href='/home'
                    title={'Reload Page'}
                    style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}>
                    <p style={{ margin: 0, padding: 0, letterSpacing: 10 }}>NEWS</p>
                    <p style={{ fontSize: 13, margin: 0, padding: 0, letterSpacing: 1, color: colors.amber[800] }}>CURATOR</p>
                    <p style={{ margin: 0, padding: 0, letterSpacing: 11 }}>TOOL</p>
                </a>
            </Box>
            <Box width="30%">&nbsp;</Box>
            {/* <Box width="35%"><Tabs /></Box> */}
            <Box width="50%" display="flex">
                {currentUser
                    ? <>
                        <Box width="100%" display="flex" justifyContent="flex-end" alignContent="center" alignItems="center">
                            {(currentUser.id !== 2023032 && currentUser.id !== 2023033) && <>
                                <Button variant='text' sx={{ color: colors.common.white, marginRight: '10px' }} startIcon={<AddBoxIcon />} onClick={() => history.push("/articles/add")}>Add News</Button>
                                <Button variant='text' sx={{ color: colors.common.white }} startIcon={<CategoryIcon />} onClick={() => history.push("/manageCategories")}>A/C/D Cats &amp; Sub-Cats</Button>
                            </>}
                            <Box width="15%">&nbsp;</Box>
                            <Typography>Hi&nbsp;</Typography>
                            <Typography>{currentUser.name}</Typography>
                            <Typography>&nbsp;&nbsp;|</Typography>
                            <IconButton href='/login' title='Logout' onClick={() => authService.logout()}>
                                <PowerSettingsNewIcon color="error" />
                            </IconButton>
                        </Box>
                    </>
                    : <a href='/login' style={{ textDecoration: 'none', marginRight: 15, color: 'whitesmoke', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>Login</a>
                }
            </Box>
        </Box >
    )
}

export default Header