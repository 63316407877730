import { Box, Button, colors, IconButton, MenuItem, TextField } from '@mui/material'
import { CategorySharp } from "@mui/icons-material"
import React, { useEffect, useState } from 'react'
import { DeleteForeverOutlined } from '@mui/icons-material';
import { useParams } from 'react-router-dom';

import { useStore } from '../store/StoredData';
import axios from 'axios';

const AddChangeDeleteCategories = (props) => {

    const {
        masterCategories,
        masterSubCategories, setMasterSubCategories,
        masterCategoriesSubCategoriesTree,
        assignedCategoriesAndSubCategories, setAssignedCategoriesAndSubCategories
    } = useStore();

    const [toggleAddCategorySection, setToggleAddCategorySection] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedSubCategory, setSelectedSubCategory] = useState("");


    useEffect(() => {
        setAssignedCategoriesAndSubCategories(props.assignedCategoriesAndSubCategories)
    }, [props.masterCategories, props.masterSubCategories, props.assignedCategoriesAndSubCategories])

    const params = useParams();

    const resetCatSubCats = async () => {
        await axios.delete(`/api/reset-article-cats/${params.id}`)
            .then(resp => {
                console.log(resp.data.message);
                document.location.reload()
            }).catch(error => alert(error))
    };

    const removeAssignedCategory = async (master_category_id) => {
        await axios.put(`/api/remove-article-cat/${params.id}`, { master_category_id: master_category_id })
            .then(resp => {
                console.log(resp.data.message);
                document.location.reload()
            }).catch(error => alert(error))
    };

    const removeAssignedSubCategory = async (sub_category_id) => {
        await axios.put(`/api/remove-article-subcat/${params.id}`, { sub_category_id: sub_category_id })
            .then(resp => {
                console.log(resp.data.message);
                document.location.reload()
            }).catch(error => alert(error))
    };

    const updateSubCategoriesList = async (category_id) => {
        console.log('category_id == ', category_id)
        setMasterSubCategories([]);
        setSelectedSubCategory(null);
        let filteredCat = masterCategoriesSubCategoriesTree.filter(obj => parseInt(obj.cat_id) === parseInt(category_id));
        if (filteredCat.length)
            setMasterSubCategories(filteredCat[0].subcats)
        console.log('Filtered Sub Categories List ::::: ', filteredCat)
    };

    const assignNewCategory = async () => {
        await axios.post(`/api/v2/assign-category/${params.id}`, {
            master_category_id: selectedCategory,
            sub_category_id: selectedSubCategory
        }).then(resp => {
            console.log(resp.data.message);
            document.location.reload()
        }).catch(error => alert(error))
    };

    return (
        // <div style={{ border: '1px solid #CCC', boxShadow: '1px 2px 2px #CCC', height: pageHeight, overflowY: 'auto' }}>
        <div style={{ border: '1px solid #CCC', boxShadow: '1px 2px 2px #CCC', overflowY: 'auto', marginTop: 15 }}>
            {props.isEditable && <Box display="flex" flexDirection="column" justifyContent='center' alignContent='center' alignItems='center' border={1} borderColor={colors.deepPurple[700]} borderRadius={1} margin={1} padding={1}>
                <Button variant="text" size='small' style={{ width: '90%' }} endIcon={<CategorySharp />} onClick={() => setToggleAddCategorySection(!toggleAddCategorySection)}>
                    Assign Category
                </Button>
                {toggleAddCategorySection &&
                    <Box width="100%">
                        <TextField
                            select
                            fullWidth
                            id="category"
                            name="category"
                            InputLabelProps={{ shrink: true }}
                            label="Select Category"
                            variant="standard"
                            size="small"
                            value={selectedCategory}
                            onChange={(e) => {
                                setSelectedCategory(e.target.value);
                                updateSubCategoriesList(e.target.value)
                            }}
                            sx={{ maxHeight: 100 }}
                            style={{ margin: 15, width: '90%', textAlign: 'left' }}
                            MenuProps={{ PaperProps: { style: { maxHeight: 100 } } }}
                        >
                            {masterCategories.length > 0 &&
                                masterCategories.map((category, idx) =>
                                    <MenuItem
                                        key={category.id}
                                        id={category.id}
                                        value={category.id}
                                        disabled={category.hide}>
                                        {category.name}{category.hide ? " (Not Displayed)" : null}
                                    </MenuItem>
                                )}
                        </TextField>
                        <TextField
                            select
                            fullWidth
                            id="category"
                            InputLabelProps={{ shrink: true }}
                            label="Select Sub-Category"
                            variant="standard"
                            size="small"
                            value={selectedSubCategory}
                            onChange={(e) => setSelectedSubCategory(e.target.value)}
                            sx={{ maxHeight: 100 }}
                            style={{ margin: 15, width: '90%', textAlign: 'left' }}
                            MenuProps={{ PaperProps: { style: { maxHeight: 100 } } }}
                        >
                            {masterSubCategories.length > 0 &&
                                masterSubCategories.map((subcat, idx) =>
                                    <MenuItem
                                        key={idx}
                                        id={idx}
                                        value={subcat.sub_cat_id}
                                        disabled={subcat.hide}>
                                        {subcat.sub_cat_name}{subcat.hide ? " (Not Displayed)" : null}
                                    </MenuItem>
                                )}
                        </TextField>
                        <Box display="flex" justifyContent="flex-end">
                            <Button
                                variant='contained'
                                size="small"
                                color="inherit"
                                style={{ fontSize: 12, marginRight: 15 }}
                                onClick={() => setToggleAddCategorySection(false)}>cancel</Button>
                            <Button
                                variant='contained'
                                size="small"
                                color="primary"
                                style={{ fontSize: 12, marginRight: 15, backgroundColor: colors.deepPurple[800] }}
                                onClick={() => assignNewCategory()}>save</Button>
                        </Box>
                    </Box>}
            </Box>}

            <h4 style={{ textAlign: 'left', margin: 0, padding: 5, color: colors.deepPurple[800] }}>{props.title}</h4>
            {assignedCategoriesAndSubCategories && assignedCategoriesAndSubCategories.length
                ? <ul style={{ listStyle: 'none', textAlign: 'left', margin: 0, padding: 0 }}>
                    {assignedCategoriesAndSubCategories.map(category =>
                        <li key={category.category_id} style={{ fontSize: 15, fontWeight: 600, border: '1px solid #555', borderRadius: 3, margin: 15, padding: '5px 10px', backgroundColor: colors.deepPurple[50] }}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <span style={{ lineHeight: 2, color: colors.deepPurple[700] }}>{category.category_name}</span>
                                {props.isEditable && <IconButton size='small' onClick={() => {
                                    alert(`Deleting Category - "${category.category_name}" ID - ${category.category_id}
                                        \nFor Article ID - ${params.id}`);
                                    removeAssignedCategory(category.category_id)
                                }}>
                                    <DeleteForeverOutlined fontSize='10px' style={{ color: colors.deepPurple[500] }} />
                                </IconButton>}
                            </Box>
                            {
                                category.subcats && category.subcats.length && <ul style={{ listStyle: 'none', textAlign: 'left', margin: 0, padding: 0 }}>
                                    {category.subcats.map(subcat =>
                                        <li key={subcat.sub_category_id} style={{ fontSize: 13, color: colors.deepPurple[500], border: '1px solid #999', borderRadius: 3, margin: 3, padding: '0 5px', backgroundColor: colors.common.white }}>
                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                {subcat.sub_category_name}
                                                {props.isEditable && <IconButton size='small' onClick={() => {
                                                    alert(`Deleting Sub Category "${subcat.sub_category_name}", With ID - ${subcat.sub_category_id}
                                                    \nUnder Category - "${category.category_name}" ID - ${category.category_id}
                                                    \nFor Article ID - ${params.id}`);
                                                    removeAssignedSubCategory(subcat.sub_category_id);
                                                }
                                                }>
                                                    {props.isEditable && <DeleteForeverOutlined fontSize='10px' style={{ color: colors.deepPurple[500] }} />}
                                                </IconButton>}
                                            </Box>
                                        </li>
                                    )}
                                </ul>
                            }
                        </li>
                    )}
                </ul>
                : <span style={{ display: 'block', width: '100%', fontSize: 13, color: 'orangered', margin: '10px' }}>No Assigned Cats / Sub-Cats Data</span>
            }
            {/* <hr /> */}
            <Box display="flex" width="80%" margin='5px 10px' padding='0 20px' justifyContent="flex-end">
                <Button
                    variant="text"
                    size='small'
                    style={{ color: colors.deepOrange[800] }}
                    onClick={() => {
                        alert('Deleting All Assigned Cat/Sub-Cat Mapping from Article ', params.id);
                        resetCatSubCats();
                    }}>
                    Reset Categories
                </Button>
            </Box>
        </div >
    )
}

export default AddChangeDeleteCategories