import React, { useState, useEffect, useCallback } from 'react'
import { Box, Button, Checkbox, colors, FormControl, FormControlLabel, IconButton, InputLabel, LinearProgress, MenuItem, OutlinedInput, Select, TextField, Typography, Chip, Dialog } from '@mui/material';
import { ManageSearchSharp } from '@mui/icons-material'
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useStore } from '../store/StoredData';
import htmlToDraft from 'html-to-draftjs';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { PhotoCamera } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import { HourglassTopRounded } from '@mui/icons-material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SimpleImageSlider from "react-simple-image-slider";
import ManageCategoriesComp from './ManageCategoriesComp';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 3;
const menuprops = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Article = (props) => {

    const { articleContent, setMasterIndicatorSubTree, masterCategoriesSubCategoriesTree, } = useStore();
    const history = useHistory();
    const params = useParams();

    const [author, setAuthor] = useState("");
    const [articleVersion, setArticleVersion] = useState(null);
    const [datePublished, setDatePublished] = useState();
    const [lastUpdated, setLastUpdated] = useState();
    const [articleTitle, setArticleTitle] = useState("")
    const [articleSummary, setArticleSummary] = useState("")
    const [articleDescriptionState, setArticleDescriptionState] = useState("");
    const [convertedContentArticleDescription, setConvertedContentArticleDescription] = useState("");
    const [articlePhoto, setArticlePhoto] = useState("")
    const [articlePictureFile, setArticlePictureFile] = useState("")
    const [articleImageURL, setArticleImageURL] = useState("")
    const [imageAltTag, setImageAltTag] = useState("")
    const [imageCaption, setImageCaption] = useState("")
    const [imageList, setImageList] = useState([]);

    const [location, setLocation] = useState("")
    const [locationList, setLocationList] = useState([])
    const [locationSuggestion, setLocationSuggestions] = useState([]);
    const [locationSuggDropDownState, setLocationSuggDropDownState] = useState(false);
    const [locationSuggLoading, setLocationSuggLoading] = useState(false);

    const [peopleNames, setPeopleNames] = useState("")
    const [peopleNamesList, setPeopleNamesList] = useState([])
    const [peopleSuggestion, setPeopleSuggestions] = useState([]);
    const [peopleSuggDropDownState, setPeopleSuggDropDownState] = useState(false);
    const [peopleSuggLoading, setPeopleSuggLoading] = useState(false);

    const [industry, setIndustry] = useState("")
    const [industryList, setIndustryList] = useState([])
    const [industrySuggestion, setIndustrySuggestions] = useState([]);
    const [industrySuggDropDownState, setIndustrySuggDropDownState] = useState(false);
    const [industrySuggLoading, setIndustrySuggLoading] = useState(false);

    const [company, setCompany] = useState("")
    const [companyList, setCompanyList] = useState([])
    const [companySuggestion, setCompanySuggestions] = useState([]);
    const [companySuggDropDownState, setCompanySuggDropDownState] = useState(false);
    const [companySuggLoading, setCompanySuggLoading] = useState(false);

    const [tickersList, setTickersList] = useState([]);

    const [assignedMasterMacroIndicatorTree, setAssignedMasterMacroIndicatorTree] = useState([]);
    const [selectedMacroIndicator, setSelectedMacroIndicator] = useState();
    const [selectedMacroIndicatorList, setSelectedMacroIndicatorList] = useState([])
    const [selectedMacroSubIndicatorList, setSelectedMacroSubIndicatorList] = useState([])

    const [assignedMasterCategoriesSubCategoriesTree, setAssignedMasterCategoriesSubCategoriesTree] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedCategoryList, setSelectedCategoryList] = useState([])
    const [selectedSubCatList, setSelectedSubCatList] = useState([])
    const [openCategoryModal, setOpenCategoryModal] = useState(false)

    const [selectedPriority, setSelectedPriority] = useState();
    const priorityList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    const [isFeatured, setIsFeatured] = useState(false)

    const handleAssignCategories = () => {
        let fCats = assignedMasterCategoriesSubCategoriesTree
            .filter(cat => cat.isChecked === true)
            .map(cat => ({ ...cat, subcats: cat.subcats.filter(subcat => subcat.isChecked && subcat.isChecked === true) }))
        console.log('FINAL FILTERED ASSIGNED CATS & SUB-CATS :::::: ', assignedMasterCategoriesSubCategoriesTree, fCats, selectedCategory);
        setSelectedCategoryList(fCats);
    }

    const handleCatChange = async (val) => {
        console.log('Recieved CAT to Update === ', val)
        let mVal = assignedMasterCategoriesSubCategoriesTree.map(cat => cat.cat_name === selectedCategory.cat_name)
        setAssignedMasterCategoriesSubCategoriesTree(mVal)
        const nVal = assignedMasterCategoriesSubCategoriesTree.filter(x => x.cat_name === val)
        setSelectedCategory(nVal[0])
    }

    const handleSubCatChange = async (e, subCatObj, selectedcategory) => {
        console.log('Selected CAT', selectedcategory, 'SUB-CAT Value ::: ', subCatObj, 'EVENT ::: ', e.target.checked);
        let mVal = assignedMasterCategoriesSubCategoriesTree.map(cat => {
            if (cat.cat_id === selectedCategory.cat_id) {
                let fsc = cat?.subcats?.map(subcat => {
                    if (subcat.sub_cat_id === subCatObj.sub_cat_id) {
                        return { ...subcat, isChecked: !subcat.isChecked };
                    }
                    return subcat;
                }) || [];
                setSelectedSubCatList(fsc.filter(f => f.isChecked));
                let someSelectedSubCats = fsc.some(obj => obj.isChecked);
                someSelectedSubCats ? cat.isChecked = true : cat.isChecked = false;
                return { ...cat, subcats: fsc };
            }
            return cat;
        });
        setAssignedMasterCategoriesSubCategoriesTree(mVal);
        setSelectedCategory(mVal.filter(cat => cat.cat_id === selectedcategory.cat_id)[0]);
    };

    useEffect(() => {
        let blocksFromHTMLpersonOverview = htmlToDraft(articleContent.hyperlinked_description || articleContent.description);
        let formattedTextPersonOverview = ContentState.createFromBlockArray(
            blocksFromHTMLpersonOverview.contentBlocks,
            blocksFromHTMLpersonOverview.entityMap,
        );
        setArticleDescriptionState(EditorState.createWithContent(formattedTextPersonOverview))
        setConvertedContentArticleDescription(articleContent.description)
        setArticleTitle(articleContent.title)
        setArticleSummary(articleContent.summary)
        setArticlePictureFile(articleContent.image_url)
        setArticlePhoto(articleContent.image_url)
        setArticleImageURL(articleContent.image_url)
        setImageList(articleContent.associated_images ? articleContent.associated_images.map(img => JSON.parse(img)) : []);
        setSelectedPriority(articleContent.news_priority)
        setIsFeatured(articleContent.is_featured)

        // if (articleContent.associated_images !== null && articleContent.associated_images.length) {
        //     let imgls = [];
        //     for (const key in articleContent.associated_images) {
        //         if (Object.hasOwnProperty.call(articleContent.associated_images, key)) {
        //             if (JSON.parse(articleContent.associated_images[key]).renditions) {
        //                 const url = JSON.parse(articleContent.associated_images[key]).renditions?.preview?.href || articleContent.associated_images[key].url;
        //                 const name = JSON.parse(articleContent.associated_images[key]).renditions?.preview?.originalfilename || articleContent.associated_images[key].name;
        //                 imgls.push({ url, name })

        //             } else {
        //                 const url = JSON.parse(articleContent.associated_images[key]).url;
        //                 const name = JSON.parse(articleContent.associated_images[key]).name;
        //                 imgls.push({ url, name })
        //             }
        //         }
        //     }
        //     console.log('prepared image list ==== ', imgls);
        //     setImageList(imgls);
        //     setArticleImageURL(imgls[0]?.url)
        // } else {
        //     axios.get(`/api/article/images/${articleContent.item_id}`).then(resp => {
        //         let iList = resp.data.message.map(img => ({ name: img.item_renditions_preview.originalfilename, url: img.item_renditions_preview.href }));
        //         console.log('RECVD IMAGE INFO ==== ', iList);
        //         setImageList(iList);
        //         setArticleImageURL(iList[0]?.url);
        //     });
        // }

        setImageAltTag(articleContent.image_alt_text)
        setImageCaption(articleContent.image_caption_text)
        setAuthor(articleContent.author_name);
        setArticleVersion(articleContent.item_version);
        setDatePublished(articleContent.gmt_logtime);
        setLastUpdated(articleContent?.updated_time);

        if (articleContent.places && articleContent.places.length)
            setLocationList(articleContent.places)

        if (articleContent.company_name && articleContent.company_name.length)
            setCompanyList(articleContent.company_name?.filter(c => c))
        console.log('articleContent.company_name - ', companyList)
        // setAssociatedCompanies()

        if (articleContent.ticker && articleContent.ticker.length)
            setTickersList(articleContent.ticker)

        /* if (articleContent.organisations && articleContent.organisations.length) {
        let comps = articleContent.organisations.map(obj => ({ name: obj.name, ticker: obj.symbols[0].ticker, exchange: obj.symbols[0].exchange }));
        let industries = articleContent.organisations.map(obj => obj.industries.map(ind => ({ name: ind.name, code: ind.code })));
        industries = [...new Set(...industries)].flatMap(o => o);
        setCompanyList(comps)
        setIndustryList(industries)
        } */

        if (articleContent.persons && articleContent.persons.length)
            setPeopleNamesList(articleContent.persons)

        if (articleContent.macro_indicators && articleContent.macro_indicators.length)
            setSelectedMacroIndicatorList(articleContent.macro_indicators);


        if (props.isEditable) {
            const fetchMasterMacroIndicatorTree = () => {
                axios.get(`/api/master-macro-indicators-tree`)
                    .then(async (resp) => {
                        // console.log("master-macro-indicators-tree :::", resp.data.message);
                        setMasterIndicatorSubTree(resp.data.message || [])
                        let data = await resp.data.message.map(cats => {
                            let mapped = cats.indicators.map(obj => {
                                return { ...obj, isChecked: false };
                            });
                            return { ...cats, isChecked: false, indicators: mapped };
                        });

                        if (articleContent.macro_indicators)
                            for (const obj of articleContent.macro_indicators) {
                                data.map(o => {
                                    if (obj.category_id === o.category_id) {
                                        o.isChecked = true;
                                        for (const ind of obj.indicators) {
                                            o.indicators.filter(x => x.indicator_id === ind.indicator_id).map(y => {
                                                y.isChecked = true;
                                            });
                                        }
                                    }
                                });
                            };
                        // console.log('MAPPED MACRO INDICATORS DATA :::: ', data);
                        setAssignedMasterMacroIndicatorTree(...data);
                    }).catch((err) => console.log("Error :::", err))
            }
            fetchMasterMacroIndicatorTree();
        }
    }, []);


    useEffect(() => {
        const assignCategoryTree = () => {
            let data = masterCategoriesSubCategoriesTree.filter((cats) => cats.cat_hide === false).map(cats => {
                let mapped = cats?.subcats?.filter((subcats) => subcats.sub_cat_hide === false)?.map(obj => {
                    return { ...obj, isChecked: false };
                }) || [];
                return { ...cats, isChecked: false, subcats: mapped };
            })

            if (articleContent.master_category_id)
                for (const catid of articleContent.master_category_id) {
                    data.map(o => {
                        if (catid == o.cat_id) {
                            o.isChecked = true;
                            for (const subcat of articleContent.sub_category_id) {
                                o.subcats.filter(x => x.sub_cat_id == subcat).map(y => {
                                    y.isChecked = true;
                                });
                            }
                        }
                    });
                };
            // console.log('MAPPED CATEGORY DATA :::: ', data);
            setAssignedMasterCategoriesSubCategoriesTree(data);
            let checkedCategories = data.filter((cat) => cat.isChecked === true
                // {
                //     let f = []
                //     if (cat.isChecked === true) {
                //         console.log("PRE Filtered SUB CATS ::", cat.subcats.filter(subcat => subcat.isChecked === true))
                //         f = cat.subcats.filter(subcat => subcat.isChecked === true)
                //         console.log('POST FILTER  ========== ', f)
                //         return { ...cat }
                //     }
                // }
            )
            // console.log("Pre-Checked Categories :::", checkedCategories)

            let filterSubcatsChecked = checkedCategories.map((cat) => {
                let f = []
                f = cat.subcats.filter((sub) => sub.isChecked === true)
                return { ...cat, subcats: f }
            })

            // console.log("Pre-Checked Sub Categories :::", filterSubcatsChecked)

            setSelectedCategoryList(filterSubcatsChecked)
        }

        if (props.isEditable) {
            assignCategoryTree();
        }

    }, [masterCategoriesSubCategoriesTree])

    // const updateArticleImageUrl = () => {
    //     if (imageList.length > 0)
    //         updateArticleImageUrl(imageList[0].url)
    //     else
    //         updateArticleImageUrl("")
    // }

    const handleImageDelete = (img) => {
        setImageList(imgs => imgs.filter(fimg => fimg.url !== img.url));
        // updateArticleImageUrl();
    }

    useEffect(() => {
        if (imageList.length > 0)
            setArticleImageURL(imageList[0].url)
        else
            setArticleImageURL("")
    }, [imageList.length])
    // console.log("Update IMAGE URL POST DELETE === ", articleImageURL)

    const handleMacroIndicatorChange = async (e, indicator, selectedcategory) => {
        // console.log('Selected CAT', selectedcategory, 'Indicator Value ::: ', indicator, 'EVENT ::: ', e.target.checked);
        let mVal = assignedMasterMacroIndicatorTree.map(cat => {
            if (cat.category_id === selectedMacroIndicator.category_id) {
                let fsc = cat.indicators.map(ind => {
                    if (ind.indicator_id === indicator.indicator_id) {
                        return { ...ind, isChecked: !ind.isChecked };
                    }
                    return ind;
                })
                setSelectedMacroSubIndicatorList(fsc.filter(f => f.isChecked));
                let someSelectedIndicators = fsc.some(obj => obj.isChecked);
                someSelectedIndicators ? cat.isChecked = true : cat.isChecked = false;
                return { ...cat, indicators: fsc };
            }
            return cat;
        });

        setAssignedMasterMacroIndicatorTree(mVal);
        setSelectedMacroIndicator(mVal.filter(cat => cat.category_id === selectedcategory.category_id)[0]);
    };

    const handleAssignMacroIndicator = () => {
        let fCats = assignedMasterMacroIndicatorTree
            .filter(cat => cat.isChecked === true)
            .map(cat => ({ ...cat, indicators: cat.indicators.filter(ind => ind.isChecked === true) }))
        // console.log('Final Filtered ASSIGNED MACRO INDICATORS :::::: ', fCats);
        setSelectedMacroIndicatorList(fCats);
    }

    const handleEditorArticleDescription = (state) => {
        if (state) {
            setArticleDescriptionState(state);
            convertContentToHTMLArticleDescription();
        }
    }
    const convertContentToHTMLArticleDescription = () => {
        let currentContentAsHTML = draftToHtml(convertToRaw(articleDescriptionState.getCurrentContent()));
        setConvertedContentArticleDescription(currentContentAsHTML);
    }

    const saveArticle = async () => {

        let formData = new FormData();

        formData.append("title", articleTitle || '')
        formData.append("summary", articleSummary || '')
        formData.append("description", convertedContentArticleDescription || '')
        formData.append("author_name", author || '')
        formData.append("image_alt_text", imageAltTag || '')
        formData.append("image_caption_text", imageCaption || '')
        formData.append("news_priority", selectedPriority || '')
        formData.append("is_featured", isFeatured)
        formData.append("gmt_logtime", articleContent.gmt_logtime)
        // selectedCategoryList.length > 0
        //     ? selectedCategoryList.forEach(cat => formData.append("assigned_cats_subcats", JSON.stringify({ cat_id: cat.cat_id, cat_name: cat.cat_name, subcats: cat.subcats?.map(sc => ({ sub_cat_id: sc.sub_cat_id, sub_cat_name: sc.sub_cat_name })) })))
        //     : formData.append("assigned_cats_subcats", '');

        // assignedMasterCategoriesSubCategoriesTree.forEach(cat => formData.append("assigned_cats_subcats", JSON.stringify({ cat_id: cat.cat_id, cat_name: cat.cat_name, subcats: cat.subcats?.map(sc => ({ sub_cat_id: sc.sub_cat_id, sub_cat_name: sc.sub_cat_name })) })))
        let fCats = assignedMasterCategoriesSubCategoriesTree.filter(x => x.isChecked)
        let fAssignedCats = [], MasterCatIds = [], MasterSubCatIds = [];
        for await (const cat of fCats) {
            console.log('F CAT = ', cat)
            MasterCatIds.push(cat.cat_id)
            let fsc = cat.subcats.filter(x => x.isChecked)
            fsc.map(x => MasterSubCatIds.push(x.sub_cat_id))
            console.log('F SUB-CAT = ', fsc)
            fAssignedCats.push({ ...cat, subcats: fsc })
        }
        console.log('F fAssignedCats = ', fAssignedCats, MasterCatIds, MasterSubCatIds)
        fAssignedCats.forEach(a => formData.append("assigned_cats_subcats", JSON.stringify(a)))
        MasterCatIds.forEach(x => formData.append("master_category_ids", x))
        MasterSubCatIds.forEach(x => formData.append("master_subcategory_ids", x))

        locationList.length ? locationList.map(o => formData.append("places", JSON.stringify(o))) : formData.append("places", '');
        peopleNamesList.length ? peopleNamesList.map(o => formData.append("people", JSON.stringify(o))) : formData.append("people", '');
        industryList.length ? industryList.map(o => formData.append("industry", JSON.stringify(o))) : formData.append("industry", '');
        companyList.length ? companyList.map(o => formData.append("company", o)) : formData.append("company", '');
        // associatedCompanies.length ? associatedCompanies.map(o => formData.append("company", o)) : formData.append("company", '');
        tickersList.length ? tickersList.map(o => formData.append("tickers", o)) : formData.append("tickers", '');
        selectedMacroIndicatorList.length ? selectedMacroIndicatorList.map(o => formData.append("macro_indicators", JSON.stringify(o))) : formData.append("macro_indicators", '');

        !!articleImageURL ? formData.append("image_url", articleImageURL) : formData.append("image_url", "");
        imageList.length ? imageList.map(img => formData.append("article_photo", img.file || JSON.stringify(img))) : formData.append("article_photo", '');

        console.log("Article Image URL before sending :::", articleImageURL)
        console.log("imageList before sending :::", imageList)
        console.log("selectedCategoryList before sending :::", selectedCategoryList)

        for (const val of formData) console.log('Final Subit API DAta::: ', val[0], val[1])

        await axios({
            method: 'PUT',
            url: `/api/article/${params.id}`,
            headers: { 'Content-Type': 'multipart/form-data; boundary="boundary"' },
            data: formData
        }).then(resp => {
            console.log('Saved Article Successfully... ', resp.data.message);
            alert('Article Updated Successfully!');
            window.location.reload()
        }).catch(error => console.error(`Error While Saving Article, ${error}`));
    }

    const uploadProfilePicture = async (e) => {
        e.preventDefault();
        // setArticlePhoto(e.target.files[0])
        setArticlePictureFile(URL.createObjectURL(e.target.files[0]))
        for (let i = 0; i < e.target.files.length; i++) {
            // console.log('uploaded photo info ::: ', e.target.files, URL.createObjectURL(e.target.files[i]), "Name ::", e.target.files[i].name, "file", e.target.files[i]);
            imageList.push({ url: URL.createObjectURL(e.target.files[i]), name: e.target.files[i].name, file: e.target.files[i] })
        }
        // articlePhoto.push(e.target.files[i])
    };

    // console.log("Image List ::: ", imageList, "\t|\tIMAGE_URL ::: ", articleImageURL);

    const handleLocationList = (selectedLocation) => {
        // console.log("selected Location :::", selectedLocation);
        setLocationList(prev => [...prev, selectedLocation])
    }

    const handleDeleteLocation = (loc) => {
        // console.log("Deleting Location :::", loc)
        setLocationList(locationList.filter(location => location.name !== loc.name))
    }
    const handlePeopleNamesList = (selectedPeople) => {
        setPeopleNamesList(prev => [...prev, selectedPeople])

    }

    const handleDeletePeople = (ppl) => {
        // console.log("Deleting People :::", ppl)
        setPeopleNamesList(peopleNamesList.filter(people => people.name !== ppl.name))
    }

    const handleIndustryList = (selectedIndustry) => {
        // console.log('Selected Industry === ', selectedIndustry)
        setIndustryList(prev => [...prev, { code: selectedIndustry.sic_grp_code, name: selectedIndustry.sic_grp_name }])
    }

    const handleDeleteIndustry = (ind) => {
        // console.log("Deleting Industry :::", ind)
        setIndustryList(industryList.filter(industry => industry.code !== ind.code))
    }

    const handleCompanyList = (selectedCompany) => {
        // console.log('Selected Company === ', selectedCompany)
        // setAssociatedCompanies(prev => [...prev, selectedCompany]);
        setCompanyList(prev => [...prev, selectedCompany.name]);
        // console.log('RECVD TICKER ::: ', selectedCompany.ticker);
        setTickersList(prev => [...prev, selectedCompany.ticker ? selectedCompany.ticker : ''])
    }

    const handleDeleteCompany = (comp) => {
        // console.log("Deleting Company :::", comp)
        setCompanyList(companyList.filter(company => company !== comp));
        if (tickersList.length)
            setTickersList(prev => prev.filter(o => o !== comp.ticker))
        // setAssociatedCompanies(prev => prev.filter(o => o.cik !== comp.cik))
    }

    // console.log('Company List ::: ', companyList);
    // console.log('Tickers List ::: ', tickersList);
    // console.log('Associated Companies List ::: ', associatedCompanies);

    const handlePeopleData = (inputData) => {
        if (inputData.length > 2) {
            setPeopleSuggLoading(true);
            setPeopleSuggestions([]);
            axios.get('/api/person', { params: { s: inputData } }).then((resp) => {
                setPeopleSuggDropDownState(true);
                setPeopleSuggestions(resp.data.message);
                setPeopleSuggLoading(false);
            });
        } else {
            setPeopleSuggestions([]);
            setPeopleSuggDropDownState(false);
        }
    };

    const handleIndustryTypeahead = (inputData) => {
        if (inputData.length > 2) {
            setIndustrySuggLoading(true);
            setIndustrySuggestions([]);
            axios.get('/api/industries', { params: { s: inputData } }).then((resp) => {
                setIndustrySuggDropDownState(true);
                setIndustrySuggestions(resp.data.message);
                setIndustrySuggLoading(false);
            });
        } else {
            setIndustrySuggestions([]);
            setIndustrySuggDropDownState(false);
        }
    };

    const handleCompanyTypeahead = (inputData) => {
        if (inputData.length > 2) {
            setCompanySuggLoading(true);
            setCompanySuggestions([]);
            axios.get('/api/companies', { params: { s: inputData } }).then((resp) => {
                setCompanySuggDropDownState(true);
                setCompanySuggestions(resp.data.message);
                setCompanySuggLoading(false);
            });
        } else {
            setCompanySuggestions([]);
            setCompanySuggDropDownState(false);
        }
    };

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 400);
        };
    };

    const optimizedPeopleFn = useCallback(debounce(handlePeopleData), []);
    const optimizedIndustryeFn = useCallback(debounce(handleIndustryTypeahead), []);
    const optimizedCompanyFn = useCallback(debounce(handleCompanyTypeahead), []);

    // console.log("Final Selected Location :::", locationList)
    // console.log("Final Selected People :::", peopleNamesList)
    // console.log("Final Selected Industry :::", industryList)
    // console.log("Final Selected Companies :::", companyList)
    // console.log("Final Selected Tickers :::", tickersList)
    // console.log("Final Selected MacroIndicator :::", selectedMacroIndicatorList)

    return (
        <>
            {articleContent
                ? <>
                    {articleContent === null &&
                        <Box width="100%" textAlign="center" my={5} >
                            <Typography variant='body1' color={colors.deepOrange[300]}>No Article Details Available...</Typography>
                        </ Box >
                    }
                    {articleContent !== null && !props.isEditable
                        ? <div id="article" style={{ border: '2px solid #eaeaea', borderRadius: 5, width: '100%', textAlign: 'left' }}>
                            <Typography variant='h6' color={colors.deepOrange[800]}>Associated AP Topics:&ensp;</Typography>
                            <Box border={1} borderColor={colors.blue[200]} borderRadius={1} my={1} mx="auto" p={1}>
                                <Typography variant='h6' color={colors.deepOrange[800]}>Associated AP Subjects:&ensp;</Typography>
                                <Box display="flex" flexDirection="row" flexWrap='wrap' p={1} alignContent='center' alignItems="center">
                                    {articleContent.article_subjects && Array.from(articleContent.article_subjects).length > 0
                                        ? <>
                                            {Array.from(articleContent.article_subjects).map(subject => subject.name.length > 2 && <Chip key={subject.code} variant='outlined' size='small' sx={{color: colors.deepPurple[500], m: 0.25 }} label={`${subject.name} [${subject.code}] ${subject.relevance ? `(${subject.relevance})` : ''}`} />)}
                                        </>
                                        : null}
                                </Box>
                            </Box>
                            <Box border={1} borderColor={colors.blue[200]} borderRadius={1} my={1} mx="auto" p={1}>
                                {articleContent.product_ids && Array.from(articleContent.product_ids).length > 0
                                    ? <>{Array.from(articleContent.product_ids).map(keyword => <Chip variant='outlined' size='small' sx={{ color: colors.deepPurple[500], m: 0.25 }} label={`${keyword.name} [${keyword.id}]`} />)}</>
                                    : null}
                            </Box>
                            <hr />
                            <div style={{ padding: '0px 5px', margin: '20px 0px' }}>
                                <h5 style={{ margin: 0, padding: 0, color: colors.deepOrange[800] }}>TITLE:</h5>
                                <div>{articleContent.title}</div>
                            </div>
                            <div style={{ padding: '0px 5px', margin: '20px 0px' }}>
                                <h5 style={{ margin: 0, padding: 0, color: colors.deepOrange[800] }}>SUMMARY:</h5>
                                <div dangerouslySetInnerHTML={{ __html: articleContent.summary }}></div>
                            </div>
                            <Box position="relative" display='flex' justifyContent='center' alignItems='center'>
                                {/* <img width="100%" height='50%' src={articlePictureFile} /> */}
                                {imageList.length > 0 &&
                                    <Box>
                                        <SimpleImageSlider
                                            width={825}
                                            height={425}
                                            images={imageList}
                                            showBullets={true}
                                        // showNavs={true}
                                        />
                                    </Box>
                                }
                            </Box>
                            <div style={{ padding: '0px 5px', margin: '20px 0px' }}>
                                <h5 style={{ margin: 0, padding: 0, color: colors.deepOrange[800] }}>DESCRITPION:</h5>
                                <div dangerouslySetInnerHTML={{ __html: articleContent.description }}></div>
                            </div>
                        </div>
                        : <Box>
                            <Box border={1} borderColor={colors.blue[200]} borderRadius={1} my={1} mx="auto" p={1}>
                                <Typography variant='h6' color={colors.deepOrange[800]}>Associated AP Subjects:&ensp;</Typography>
                                <Box display="flex" flexDirection="row" flexWrap='wrap' p={1} alignContent='center' alignItems="center">
                                    {articleContent.article_subjects && Array.from(articleContent.article_subjects).length > 0
                                        ? <>
                                            {Array.from(articleContent.article_subjects).map(subject => subject.name.length > 2 && <Chip key={subject.code} variant='outlined' size='small' sx={{color: colors.deepPurple[500], m: 0.25 }} label={`${subject.name} [${subject.code}] ${subject.relevance ? `(${subject.relevance})` : ''}`} />)}
                                        </>
                                        : null}
                                </Box>
                            </Box>
                            <Box border={1} borderColor={colors.blue[200]} borderRadius={1} my={1} mx="auto" p={1}>
                                <Typography variant='h6' color={colors.deepOrange[800]}>Associated AP Topics:&ensp;</Typography>
                                <Box display="flex" flexDirection="row" flexWrap='wrap' p={1} alignContent='center' alignItems="center">
                                    {articleContent.product_ids && Array.from(articleContent.product_ids).length > 0
                                        ? <>
                                            {Array.from(articleContent.product_ids).map(keyword => <Chip variant='outlined' size='small' sx={{ color: colors.deepPurple[500], m: 0.25 }} label={`${keyword.name} [${keyword.id}]`} />)}
                                        </>
                                        : null}
                                </Box>
                            </Box>

                            <Box display="flex" flexDirection="row" justifyContent="flex-start" alignContent="center" alignItems="center" p={2} boxShadow={1} bgcolor={colors.blueGrey[50]}>
                                <Typography color={colors.indigo[900]}>Assigned Tabs & Subtabs:</Typography>&emsp;
                                {assignedMasterCategoriesSubCategoriesTree?.filter(x => x.isChecked).map((cat, idx) =>
                                    cat.subcats.some(y => y.isChecked)
                                        ? cat.subcats && cat.subcats.filter(y => y.isChecked).map(sc =>
                                            <Chip key={idx} size='small' variant='outlined' sx={{ color: colors.indigo[900], margin: '0 5px', px: 1, py: 2 }} label={cat.cat_name + ' > ' + sc.sub_cat_name} />)
                                        : <Chip key={idx} size='small' variant='outlined' sx={{ color: colors.indigo[900], margin: '0 5px', px: 1, py: 2 }} label={cat.cat_name} />)}
                            </Box>

                            <Box display="flex" flexDirection="row" justifyContent="space-between" alignContent="center" alignItems="center" my={2} p={2} boxShadow={1}>
                                <Box>
                                    <Button variant='outlined' size='large' onClick={() => setOpenCategoryModal(p => !p)} startIcon={<ManageSearchSharp fontSize='large' color='secondary' />}>Manage Categories</Button>
                                </Box>
                                <Box>
                                    <TextField
                                        select
                                        id="priority"
                                        label="News Priority"
                                        size="small"
                                        value={selectedPriority}
                                        onChange={(e) => setSelectedPriority(e.target.value)}
                                        style={{ margin: "5px 0px", textAlign: 'left', width: '150px' }}
                                        inputProps={{ style: { height: "200px" } }}
                                    >
                                        {priorityList.length > 0 &&
                                            priorityList.map((priority, idx) =>
                                                <MenuItem
                                                    key={idx}
                                                    id={idx}
                                                    value={priority}
                                                >
                                                    {priority}
                                                </MenuItem>
                                            )}
                                    </TextField>
                                </Box>
                                <Box>
                                    <FormControlLabel
                                        label="Featured News?"
                                        control={
                                            <Checkbox
                                                size='small'
                                                checked={isFeatured}
                                                onChange={() => setIsFeatured(!isFeatured)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />}
                                    />
                                </Box>
                            </Box>

                            {/* Article Title */}
                            <Box my={1} boxShadow={2} p={2} >
                                <Box width="100%" flexDirection={"column"} justifyContent="flex-start" mb={1}>
                                    <Typography variant='h6' color={colors.deepOrange[800]} sx={{ textAlign: 'left' }}>Headline</Typography>
                                </Box>
                                <TextField
                                    id="standard-basic"
                                    // label="News Title"
                                    variant="standard"
                                    style={{ width: "100%" }}
                                    value={articleTitle}
                                    onChange={(e) => setArticleTitle(e.target.value)}
                                />
                            </Box>
                            {/* Article Summary */}
                            <Box my={1} boxShadow={2} p={2} >
                                <Box width="100%" flexDirection={"column"} justifyContent="flex-start" mb={1}>
                                    <Typography variant='h6' color={colors.deepOrange[800]} sx={{ textAlign: 'left' }}>Summary</Typography>
                                </Box>
                                <TextField
                                    id="standard-basic"
                                    // label="News Summary"
                                    variant="standard"
                                    style={{ width: "100%" }}
                                    multiline
                                    rows={3}
                                    value={articleSummary}
                                    onChange={(e) => setArticleSummary(e.target.value)}
                                />
                            </Box>

                            {/* Article Photo */}
                            <Box my={1} boxShadow={2} p={2} display='flex' justifyContent='space-between' alignItems='center' >
                                <Box width='49%'>
                                    <Box position="relative" display='flex' justifyContent='flex-start' alignItems='center' border={1} borderColor={colors.blueGrey[100]} borderRadius={1} boxShadow={1}>
                                        {/* <IconButton sx={{ position: "absolute", top: 0, right: 0, visibility: articlePictureFile ? "visible" : "hidden" }} title="Delete Photo"
                                                onClick={() => { setArticlePhoto(null); setArticlePictureFile(null) }} size="small">
                                                <ClearIcon color="error" fontSize='medium' />
                                            </IconButton> */}
                                        {/* <img width="99%" src={articlePictureFile} /> */}

                                        {imageList?.length > 0 &&
                                            <>
                                                <Box display='flex' flexDirection='column' width='100%' overflow="none" >
                                                    <Box>
                                                        <SimpleImageSlider
                                                            width={'100%'}
                                                            height={200}
                                                            // images={imageList.map(img => img.uri || JSON.parse(img)?.renditions?.preview?.href)}
                                                            images={imageList}
                                                            showBullets={true}
                                                        // showNavs={true}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        {imageList?.map((img, idx) =>
                                                            img && <Chip key={idx} label={img.name || img.uri || img.url} size='small' onDelete={(e) => { handleImageDelete(img); }} />)}
                                                    </Box>
                                                </Box>
                                            </>
                                            // || JSON.parse(img).renditions.preview.originalfilename
                                        }
                                    </Box>
                                    <Box display='flex' justifyContent='flex-end' alignItems='center'>
                                        <Button variant="text" component="label" size='small' title='Upload Article Photo'
                                            endIcon={<PhotoCamera color='secondary' fontSize='small' name='Upload Article Photo' />}>
                                            Upload Article Photo
                                            <input hidden multiple accept="image/*" type="file" name="article_photos"
                                                onChange={(event) => uploadProfilePicture(event)}
                                            />
                                        </Button>
                                    </Box>
                                </Box>
                                <Box width='49%'>
                                    <Box my={2}>
                                        <TextField
                                            id="standard-basic"
                                            label="Image Alt Tag Text"
                                            variant="standard"
                                            style={{ width: "100%" }}
                                            fullWidth
                                            value={imageAltTag}
                                            // disabled={!articlePictureFile}
                                            onChange={(e) => setImageAltTag(e.target.value)}
                                        />
                                    </Box>
                                    <Box>
                                        <TextField
                                            id="standard-basic"
                                            label="Image Caption Text"
                                            variant="standard"
                                            style={{ width: "100%" }}
                                            value={imageCaption}
                                            // disabled={!articlePictureFile}
                                            onChange={(e) => setImageCaption(e.target.value)}
                                        />
                                    </Box>
                                </Box>
                            </Box>

                            {/* Article Description */}
                            <Box my={1} boxShadow={2} p={2}>
                                <Box width="100%" flexDirection={"column"} justifyContent="flex-start" mb={1}>
                                    <Typography variant='h6' color={colors.deepOrange[800]} sx={{ textAlign: 'left' }}>Description</Typography>
                                </Box>
                                <Editor
                                    editorState={articleDescriptionState}
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                    onEditorStateChange={handleEditorArticleDescription}
                                    toolbar={{
                                        link: { inDropdown: true },
                                        history: { inDropdown: true },
                                        image: {
                                            previewImage: false,
                                            alt: { present: true, mandatory: true },
                                        }
                                    }}
                                    placeholder={('News Description...')}
                                    editorStyle={{ height: "350px" }}
                                />
                            </Box>

                            {/* Location--People-Industry */}
                            <Box display="flex" flexDirection="row" justifyContent="space-evenly" boxShadow={2} my={2} mx={1} p={2}>
                                <Box display="flex" flexDirection="column" position="relative" width='250px'>
                                    <Box>
                                        <TextField
                                            type="text"
                                            variant='standard'
                                            className="search"
                                            placeholder="Enter Location Name..."
                                            label='Location'
                                            fullWidth
                                            // style={{ width: "250px" }}
                                            InputLabelProps={{ shrink: true }}
                                            // onChange={(e) => {
                                            //     if (e.target.value) {
                                            //         setParentCompany(e.target.value)
                                            //         optimizedFn(e.target.value)
                                            //     } else
                                            //         setCompanySuggestions([])
                                            // }}
                                            // onFocus={() => SetSuggDropDownState(true)}
                                            // onBlur={() => setTimeout(() => {
                                            //     SetSuggDropDownState(false)
                                            // }, 100)}
                                            // defaultValue={location}
                                            onChange={(e) => setLocation(e.target.value)}
                                            value={location}
                                            InputProps={{
                                                endAdornment: (
                                                    <>
                                                        {locationSuggLoading ? <HourglassTopRounded size="small" color="info" /> : null}
                                                        <IconButton onClick={() => { setLocation(""); setLocationSuggestions([]) }} size="small">
                                                            <ClearIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => handleLocationList({ code: "", name: location })} size="small">
                                                            <AddBoxIcon />
                                                        </IconButton>
                                                    </>
                                                )
                                            }}
                                        />
                                        {locationSuggestion.length && locationSuggDropDownState
                                            ? <Box style={{ maxHeight: '180px', backgroundColor: 'rgb(243 246 249 / 90%)', position: 'absolute', width: '100%', zIndex: 10, overflow: "hidden", overflowY: "scroll", boxShadow: '#C5C5C5 10px 10px 30px 0px', paddingBottom: '5px' }}>
                                                {locationSuggestion.map((option, idx) =>
                                                    <MenuItem
                                                        key={option.code || idx}
                                                        sx={{ fontSize: 13 }}
                                                        value={option.name}
                                                        onClick={() => {
                                                            handleLocationList(option);
                                                            setTimeout(() => {
                                                                setLocationSuggDropDownState(false);
                                                            }, 100);
                                                        }}
                                                    >
                                                        {option.name}
                                                    </MenuItem>
                                                )}
                                            </Box>
                                            : null}
                                    </Box>
                                    <Box>
                                        {locationList.length > 0 ? locationList.map((loc, idx) =>
                                            loc && <Chip key={idx} label={loc.name} size='small' style={{ margin: '3px' }} onDelete={() => handleDeleteLocation(loc)} />
                                        ) : null}
                                    </Box>
                                </Box>

                                {/* ASSOCIATED PEOPLE */}
                                <Box display="flex" flexDirection="column" position="relative" width='250px'>
                                    <Box>
                                        <TextField
                                            type="text"
                                            variant='standard'
                                            className="search"
                                            placeholder="Enter People Name..."
                                            label='People Name'
                                            fullWidth
                                            // style={{ width: "250px" }}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(e) => {
                                                if (e.target.value) {
                                                    setPeopleNames(e.target.value)
                                                    optimizedPeopleFn(e.target.value)
                                                } else
                                                    setPeopleNames("")
                                                setPeopleSuggestions([])
                                            }}
                                            onFocus={() => setPeopleSuggDropDownState(true)}
                                            // onBlur={() => setTimeout(() => {
                                            //     setPeopleSuggDropDownState(false)
                                            // }, 100)}
                                            // defaultValue={peopleNames}
                                            value={peopleNames}
                                            InputProps={{
                                                endAdornment: (
                                                    <>
                                                        {peopleSuggLoading ? <HourglassTopRounded size="small" color="info" /> : null}
                                                        <IconButton onClick={() => { setPeopleNames(""); setPeopleSuggestions([]) }} size="small">
                                                            <ClearIcon />
                                                        </IconButton>
                                                        {/* <IconButton onClick={() => handlePeopleNamesList()} size="small">
                                                            <AddBoxIcon />
                                                        </IconButton> */}
                                                    </>
                                                )
                                            }}
                                        />
                                        {peopleSuggestion.length && peopleSuggDropDownState
                                            ? <Box style={{ maxHeight: '180px', backgroundColor: 'rgb(243 246 249 / 90%)', position: 'absolute', width: 'auto', zIndex: 10, overflow: "hidden", overflowY: "scroll", boxShadow: '#C5C5C5 10px 10px 30px 0px', paddingBottom: '5px' }}>
                                                {peopleSuggestion.map((option, idx) =>
                                                    <MenuItem
                                                        key={option.insider_cik || idx}
                                                        sx={{ fontSize: 13 }}
                                                        value={option.name}
                                                        onClick={() => {
                                                            setTimeout(() => {
                                                                setPeopleSuggDropDownState(false);
                                                            }, 250);
                                                            handlePeopleNamesList(option);
                                                        }}
                                                    >
                                                        {option.name}&nbsp;{option.relatedto && <span style={{ color: colors.blue[400], fontWeight: 500 }}>({option.relatedto})</span>}
                                                    </MenuItem>
                                                )}
                                            </Box>
                                            : null}
                                    </Box>
                                    <Box>
                                        {peopleNamesList?.length
                                            ? peopleNamesList.map((ppl, idx) =>
                                                ppl && <Chip key={ppl.id || idx} label={ppl.name} size='small' style={{ margin: '3px' }} onDelete={() => handleDeletePeople(ppl)} />
                                            ) : null}
                                    </Box>
                                </Box>

                                {/* ASSOCIATED COMPANIES SECTION */}
                                <Box display="flex" flexDirection="column" position="relative" width='250px'>
                                    <Box>
                                        <TextField
                                            type="text"
                                            variant='standard'
                                            className="search"
                                            placeholder="Enter Ticker Company Name..."
                                            label='Associated Companies'
                                            fullWidth
                                            // style={{ width: "250px" }}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(e) => {
                                                if (e.target.value) {
                                                    setCompany(e.target.value)
                                                    optimizedCompanyFn(e.target.value)
                                                } else
                                                    setCompany("")
                                                setCompanySuggestions([])
                                            }}
                                            onFocus={() => setCompanySuggDropDownState(true)}
                                            // onBlur={() => setTimeout(() => {
                                            //     setCompanySuggDropDownState(false)
                                            // }, 100)}
                                            // defaultValue={company}
                                            value={company}
                                            InputProps={{
                                                endAdornment: (
                                                    <>
                                                        {companySuggLoading ? <HourglassTopRounded size="small" color="info" /> : null}
                                                        <IconButton onClick={() => { setCompany(""); setCompanySuggestions([]) }} size="small">
                                                            <ClearIcon />
                                                        </IconButton>
                                                        {/* <IconButton onClick={() => handlecompanyList()} size="small">
                                                            <AddBoxIcon />
                                                        </IconButton> */}
                                                    </>
                                                )
                                            }}
                                        />
                                        {companySuggestion.length && companySuggDropDownState
                                            ? <Box style={{ maxHeight: '180px', backgroundColor: 'rgb(243 246 249 / 90%)', position: 'absolute', width: 'auto', zIndex: 10, overflow: "hidden", overflowY: "scroll", boxShadow: '#C5C5C5 10px 10px 30px 0px', paddingBottom: '5px' }}>
                                                {companySuggestion.map((option, idx) =>
                                                    <MenuItem
                                                        key={option.cik || idx}
                                                        sx={{ fontSize: 13 }}
                                                        value={option.name}
                                                        onClick={() => {
                                                            setTimeout(() => {
                                                                setCompanySuggDropDownState(false);
                                                            }, 250);
                                                            handleCompanyList(option);
                                                        }}
                                                    >
                                                        {option.name || option}&nbsp;
                                                        {option.ticker && <span style={{ color: colors.blue[400], fontWeight: 500 }}>[{option.ticker}]</span>}
                                                    </MenuItem>
                                                )}
                                            </Box>
                                            : null}
                                    </Box>
                                    <Box>
                                        {companyList?.length > 0 ? companyList.map((comp, idx) =>
                                            comp && <Chip key={comp?.company_name || idx} label={comp?.company_name || comp} size='small' style={{ margin: '3px' }} onDelete={() => handleDeleteCompany(comp)} />
                                        ) : null}
                                    </Box>
                                </Box>

                                {/* ASSOCIATED SIC OR INDUSTRIES */}
                                <Box display="flex" flexDirection="column" position="relative" width='250px'>
                                    <Box>
                                        <TextField
                                            type="text"
                                            variant='standard'
                                            className="search"
                                            placeholder="Enter Industry Name..."
                                            label='SIC | Industry'
                                            fullWidth
                                            // style={{ width: "250px" }}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(e) => {
                                                if (e.target.value) {
                                                    setIndustry(e.target.value)
                                                    optimizedIndustryeFn(e.target.value)
                                                } else
                                                    setIndustry("")
                                                setIndustrySuggestions([])
                                            }}
                                            onFocus={() => setIndustrySuggDropDownState(true)}
                                            // onBlur={() => setTimeout(() => {
                                            //     setIndustrySuggDropDownState(false)
                                            // }, 100)}
                                            // defaultValue={industry}
                                            value={industry}
                                            InputProps={{
                                                endAdornment: (
                                                    <>
                                                        {industrySuggLoading ? <HourglassTopRounded size="small" color="info" /> : null}
                                                        <IconButton onClick={() => { setIndustry(""); setIndustrySuggestions([]) }} size="small">
                                                            <ClearIcon />
                                                        </IconButton>
                                                        {/* <IconButton onClick={() => handleIndustryList()} size="small">
                                                            <AddBoxIcon />
                                                        </IconButton> */}
                                                    </>
                                                )
                                            }}
                                        />
                                        {industrySuggestion.length && industrySuggDropDownState
                                            ? <Box style={{ maxHeight: '180px', backgroundColor: 'rgb(243 246 249 / 90%)', position: 'absolute', width: 'auto', zIndex: 10, overflow: "hidden", overflowY: "scroll", boxShadow: '#C5C5C5 10px 10px 30px 0px', paddingBottom: '5px' }}>
                                                {industrySuggestion.map((option, idx) =>
                                                    <MenuItem
                                                        sx={{ fontSize: 13 }}
                                                        key={option.code || idx}
                                                        value={option.sic_name}
                                                        onClick={() => {
                                                            handleIndustryList(option);
                                                            setTimeout(() => {
                                                                setIndustrySuggDropDownState(false);
                                                            }, 100);
                                                        }}
                                                    >
                                                        {option.sic_name}&nbsp;<span style={{ color: colors.blue[400], fontWeight: 500 }}>[{option.sic_grp_name}]</span>
                                                    </MenuItem>
                                                )}
                                            </Box>
                                            : null}
                                    </Box>
                                    <Box>
                                        {industryList?.length ? industryList.map((ind, idx) =>
                                            <Chip key={ind.code || idx} label={ind.name} size='small' style={{ margin: '3px' }} onDelete={() => handleDeleteIndustry(ind)} />
                                        ) : null}
                                    </Box>
                                </Box>
                            </Box>

                            {/* Macro-Indicator Drop Down */}
                            <Box display="flex" flexDirection="row" justifyContent="space-evenly" alignContent="center" alignItems="center" boxShadow={2} my={2} mx={1} p={2}>
                                <Box width="35%">
                                    <FormControl fullWidth>
                                        <InputLabel id="article-sub-category-multiple-checkbox-label">Macro Indicator Category</InputLabel>
                                        <Select
                                            fullWidth
                                            variant='outlined'
                                            label="Select Macro Indicator Category"
                                            InputLabelProps={{ shrink: true }}
                                            value={selectedMacroIndicator ?? null}
                                            MenuProps={menuprops}
                                            input={<OutlinedInput label="Article Sub Category " />}
                                            onChange={(e) => setSelectedMacroIndicator(e.target.value)}
                                        >
                                            {assignedMasterMacroIndicatorTree.length > 0 &&
                                                assignedMasterMacroIndicatorTree.map((category, idx) =>
                                                    <MenuItem key={idx} id={category.category_id} value={category} sx={{ fontSize: 14, padding: 1 }}>
                                                        {category.category_name}
                                                    </MenuItem>
                                                )}
                                        </Select>
                                    </FormControl>
                                </Box>

                                {/* Macro-Indicator Sub Drop Down */}
                                <Box width="45%">
                                    <FormControl fullWidth>
                                        <InputLabel id="article-sub-category-multiple-checkbox-label">Macro Indicators</InputLabel>
                                        <Select
                                            multiple
                                            fullWidth
                                            disabled={!selectedMacroIndicator}
                                            label="Select Macro Indicator Category"
                                            value={selectedMacroSubIndicatorList ?? null}
                                            renderValue={selectedIndicators => selectedIndicators.map(ind => ind.indicator_name).join(", ")}
                                            onChange={(e) => setSelectedMacroSubIndicatorList(e.target.value)}
                                            input={<OutlinedInput label="Article Sub Category " />}
                                            MenuProps={menuprops}>
                                            {selectedMacroIndicator && assignedMasterMacroIndicatorTree
                                                .filter(f => f.category_id === selectedMacroIndicator.category_id)[0].indicators
                                                .map((ind, idx) =>
                                                    <MenuItem key={ind.indicator_id || idx} value={ind} /* disabled={ind.sub_cat_hide} */
                                                        onChange={(e) => handleMacroIndicatorChange(e, ind, selectedMacroIndicator)}
                                                        sx={{ fontSize: 14, padding: 0 }}>
                                                        <Checkbox checked={ind.isChecked} value={ind.indicator_name} />{ind.indicator_name}
                                                    </MenuItem>
                                                )}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box width="5%">
                                    <Button size='small' variant='outlined' onClick={handleAssignMacroIndicator} >Assign</Button>
                                </Box>
                            </Box>
                            <Box width="100%" display='flex' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' flexWrap='wrap' m={1}>
                                {selectedMacroIndicatorList.length
                                    ? selectedMacroIndicatorList.map((cat, idx) => {
                                        if (cat.isChecked) {
                                            console.log('CHECKED INDICATOR === ', cat)
                                            let ind = cat.indicators.filter(ind => ind.isChecked);
                                            return <Chip key={cat.category_name || idx} label={`${cat.category_name} > ${ind.map(o => o.indicator_name)}`} sx={{ m: 1 }} size='small' />
                                        }
                                    })
                                    : null}
                            </Box>

                            {/* Author--version-Date */}
                            <Box display="flex" flexDirection="row" justifyContent="space-evenly" boxShadow={2} my={2} mx={1} p={2}>
                                <Box mx={1}>
                                    <TextField
                                        id="standard-basic"
                                        label="Author"
                                        variant="standard"
                                        // style={{ width: "250px" }}
                                        value={author}
                                        onChange={(e) => setAuthor(e.target.value)}
                                    />
                                </Box>
                                <Box mx={1}>
                                    <TextField
                                        disabled
                                        id="standard-basic"
                                        label="Article Version"
                                        variant="standard"
                                        // style={{ width: "250px" }}
                                        value={articleVersion}
                                        onChange={(e) => setArticleVersion(e.target.value)}
                                    />
                                </Box>
                                <Box mx={1}>
                                    <Typography variant='caption' sx={{ fontWeight: 700, color: colors.blueGrey[700], textAlign: 'left', lineHeight: 2.3 }}>First Published Date:&nbsp;
                                        <Typography variant="body2" color={colors.blueGrey[800]} sx={{ textAlign: 'left' }}>{new Date(datePublished).toLocaleString()}</Typography>
                                    </Typography>
                                </Box>
                                <Box mx={1}>
                                    <Typography variant='caption' sx={{ fontWeight: 700, color: colors.blueGrey[700], textAlign: 'left', lineHeight: 2.3 }}>Last Updated:&nbsp;
                                        <Typography variant="body2" color={colors.blueGrey[800]} sx={{ textAlign: 'left' }}>{new Date(lastUpdated).toLocaleString()}</Typography>
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display='flex' justifyContent='space-between' mx={1} mt={2} mb={3} >
                                <Button variant='contained' color='inherit' onClick={() => history.push('/home')}>Cancel</Button>
                                <Button variant='contained' color='primary' onClick={saveArticle}>Save</Button>
                            </Box>
                        </Box>
                    }
                </>
                : <LinearProgress />}
            <Dialog
                open={openCategoryModal}
                onClose={() => setOpenCategoryModal(false)}
                maxWidth='lg'
            >
                <Box sx={{
                    columnCount: { xs: 1, sm: 2, md: 4 }, // Adjust columns based on screen size
                    columnGap: '20px', // Smaller gap between columns
                    padding: 1,
                    margin: 0
                }} boxShadow={5}>
                    {assignedMasterCategoriesSubCategoriesTree.map(category => (
                        <Box sx={{
                            backgroundColor: colors.blueGrey[50],
                            borderRadius: '8px',
                            marginBottom: '15px', // Gap between cards in the same column
                            breakInside: 'avoid', // Prevents breaking within columns
                            padding: 2,
                            display: 'inline-block', // Required for column layout
                            width: '90%', // Ensures full width within the column
                        }}>
                            <ManageCategoriesComp
                                category={category}
                                assignedMasterCategoriesSubCategoriesTree={assignedMasterCategoriesSubCategoriesTree}
                                setAssignedMasterCategoriesSubCategoriesTree={setAssignedMasterCategoriesSubCategoriesTree} />
                        </Box>
                    ))}
                </Box>
            </Dialog>
        </>
    )
}

export default Article
