import React, { useState } from 'react'
import { Box, Button, Checkbox, colors, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Typography } from '@mui/material'
import { useParams } from 'react-router-dom';
import { useStore } from '../store/StoredData';
import axios from 'axios';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { SaveSharp } from "@mui/icons-material"

const NewsFeatured = (props) => {
    const { articleContent } = useStore()
    const params = useParams();
    const [isFeatured, setIsFeatured] = useState(articleContent.is_featured)
    const [openDeleteArticleDialog, setOpenDeleteArticleDialog] = useState(false);
    const handleClose = () => { setOpenDeleteArticleDialog(false) };

    console.log('NewsFeatured COMP articleContent = ', articleContent)

    const handleNewsFeatured = async () => {
        await axios.post(`/api/articles/is-featured/${params.id}`, { is_featured: isFeatured })
            .then(resp => {
                console.log(resp.data.message);
                setIsFeatured(resp.data.message.is_featured)
                document.location.reload()
            }).catch(error => alert(error))
    };

    const handleHideArticle = async () => {
        await axios.delete(`/api/articles/${params.id}`)
            .then(resp => {
                console.log(resp.data.message);
                document.location.reload()
            }).catch(error => alert(error))
    };

    return (
        <>
            <Box my={3} p={1}>
                <Button
                    variant='contained'
                    size="small"
                    color="error"
                    fullWidth
                    onClick={() => setOpenDeleteArticleDialog(true)}>Delete this Article</Button>
            </Box>
            {/* <Box width="100%" display="flex" mt={1.5} boxShadow={1} border={1} borderColor='#CCC'>
                <Box display='flex' justifyContent='space-around' alignItems='center' width="100%">
                    <Box p={1}>
                        <FormControlLabel
                            label="Featured News"
                            control={
                                <Checkbox
                                    size='small'
                                    checked={isFeatured}
                                    onChange={() => setIsFeatured(!isFeatured)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />}
                        />
                    </Box>
                    <Box>
                        <Button
                            variant='outlined'
                            size="small"
                            color="primary"
                            // startIcon={<SaveSharp fontSize='medium' color='primary'/>}
                            onClick={() => handleNewsFeatured()}>save</Button>
                    </Box>
                </Box>
            </Box> */}

            {/* Delete Article Dialog  */}
            <Dialog
                open={openDeleteArticleDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Box display='flex' alignItems='center' >
                        <Box>
                            <ReportGmailerrorredIcon sx={{ fontSize: "150px" }} color='warning' />
                        </Box>
                        <Box >
                            <h5>Are you sure you want to delete this article ?</h5>
                            <Box display='flex' justifyContent='flex-end'>
                                <Button onClick={handleClose} variant='contained' color='info' size='large' style={{ marginRight: "15px" }}>no</Button>
                                <Button onClick={handleHideArticle} variant='contained' color='error' size='large' >yes</Button>
                            </Box>
                        </Box>
                    </Box>
                </DialogTitle>
            </Dialog>
        </>
    )
}

export default NewsFeatured