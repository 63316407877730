import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { Box, colors, TextField, IconButton, Button, FormControlLabel, Checkbox, Typography, MenuItem } from '@mui/material';
import { ClearSharp, SearchSharp } from "@mui/icons-material"

import { useStore } from '../store/StoredData';

import "../../src/App.css";
import "react-datepicker/dist/react-datepicker.css";

const SearchBar = (props) => {
    const {
        initDateRange, selectDateRange,
        headlineText, setHeadlineText,
        keywordText, setKeywordText,
        companyTicker, setCompanyTicker,
        macroIndicatorName, setMacroIndicatorName,
        articleSourceName, setArticleSourceName,
        searchOn, setSearchOn,
        resetSearchBar,
        newsFeatured, setNewsFeatured,
        hasMore, setHasMore,
        currentPage, setCurrentPage,
        pageSize, setPageSize
    } = useStore();

    const [dateRange, setDateRange] = useState(initDateRange);
    const [headline, setHeadline] = useState("");
    const [sourceList, setSourceList] = useState([
        { label: 'AP News', value: 'AP News' },
        { label: 'NewsInMinutes', value: 'NewsInMinutes' },
        { label: 'Automated Article', value: 'Automated Article' },
        { label: 'News Desk', value: 'News Desk' },
        { label: 'Fedaral Reserve', value: 'FEDERAL RESERVE' },
        { label: 'Money Control', value: 'MONEY CONTROL' },
    ])

    const handleDateRange = (currDt) => {
        setDateRange(prvDt => {
            if (prvDt === currDt) {
                selectDateRange(currDt);
                return currDt
            } else {
                setCurrentPage(0)
                selectDateRange(currDt);
                return currDt
            }
        });
    }

    const handleHeadlineStringChange = (currStr) => {
        setHeadlineText(prevStr => {
            if (prevStr === currStr) {
                return currStr;
            } else {
                setCurrentPage(0);
                return currStr;
            }
        })
    }

    const handleCompanyStringChange = (currStr) => {
        setCompanyTicker(prevStr => {
            if (prevStr === currStr) {
                return currStr;
            } else {
                setCurrentPage(0);
                return currStr;
            }
        })
    }

    useEffect(() => {
        setDateRange(initDateRange);
    })

    // console.log('SEARCH-BAR DATERANGE ========== ', initDateRange, dateRange);

    return (
        <Box boxShadow={1} border={1} borderColor={colors.blueGrey[100]} display="flex" flexDirection="row" justifyContent="space-between" alignContent="center" alignItems="center" >
            <Box m={0.5}>
                <ReactDatePicker id="ReactDatePicker"
                    monthsShown={2}
                    disabled={newsFeatured}
                    className='ReactDatePicker'
                    dateFormat="MMM dd, yyyy"
                    selectsRange={true}
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    maxDate={new Date()}
                    onChange={date_range => handleDateRange(date_range)}
                />
            </Box>
            <Box m={0.5}>
                <TextField
                    disabled={newsFeatured}
                    name='headline'
                    title='Enter News Headline word(s) to search'
                    variant='standard'
                    placeholder='Enter Headline Word/String'
                    helperText={`Use & to combine words; | to Option words`}
                    // defaultValue={headlineText}
                    value={headlineText}
                    onChange={(event) => handleHeadlineStringChange(event.target.value)}
                />
            </Box>
            <Box m={0.5}>
                <TextField
                    disabled
                    name='keyword'
                    title='Keyword Word/String'
                    variant='standard'
                    placeholder='Enter keyword Word/String'
                    helperText={`Use & to combine words; | to Option words`}
                    // defaultValue={keywordText}
                    value={keywordText}
                    onChange={(event) => setKeywordText(event.target.value)}
                />
            </Box>
            <Box m={0.5}>
                <TextField
                    disabled={newsFeatured}
                    name='company'
                    title='company Name'
                    variant='standard'
                    placeholder='Enter company Ticker / Name'
                    helperText={`Full or Partial Company Name`}
                    // defaultValue={companyTicker}
                    value={companyTicker}
                    onChange={(event) => handleCompanyStringChange(event.target.value)}
                />
            </Box>
            <Box m={0.5}>
                <TextField
                    disabled
                    name='indicator'
                    title='Macro Indicator Name'
                    variant='standard'
                    placeholder='Enter Macro Indicator Name'
                    helperText={`Full or Partial Macro Indicator Name`}
                    // defaultValue={macroIndicatorName}
                    value={macroIndicatorName}
                    onChange={(event) => setMacroIndicatorName(event.target.value)}
                />
            </Box>
            {/* <Box m={0.5}>
                <TextField
                    // disabled
                    name='source'
                    title='Article Source'
                    variant='standard'
                    placeholder='Enter Article Source Name'
                    helperText={`Full or Partial Article Source Name`}
                    value={articleSourceName}
                    onChange={(event) => setArticleSourceName(event.target.value)}
                />
            </Box> */}

            <Box>
                <TextField
                    select
                    id="source"
                    label="Article Source"
                    variant="standard"
                    size="small"
                    // helperText={`Full or Partial Article Source Name`}
                    value={articleSourceName}
                    onChange={(e) => { setArticleSourceName(e.target.value); setSearchOn(true) }}
                    style={{ margin: 12, width: '90%', textAlign: 'left', width: '150px' }}
                    inputProps={{ style: { height: "200px" } }}
                >
                    <MenuItem key="-1" id="-1" value="" selected><em>Any Source</em></MenuItem>
                    {sourceList.length > 0 && sourceList.map((slist, idx) => <MenuItem key={idx} id={idx} value={slist.value}>{slist.label}</MenuItem>)}
                </TextField>
            </Box>

            <Box width="15%" >
                <FormControlLabel
                    label={<Typography variant="body2" fontWeight={600} color={colors.deepOrange[800]}>&nbsp;Featured News Only</Typography>}
                    sx={{ margin: 'auto 0' }}
                    control={
                        <Checkbox
                            checked={newsFeatured}
                            sx={{ paddingRight: 0 }}
                            onChange={() => { setNewsFeatured(!newsFeatured); setSearchOn(true) }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />}
                />
            </Box>
            <Box m={0.5}>
                <Button type='submit' onClick={() => setSearchOn(true)} startIcon={<SearchSharp color='secondary' />} title='Search For Resutls'>search</Button>
            </Box>
            <Box m={0.5}>
                <Button type='submit' onClick={() => resetSearchBar()} startIcon={<ClearSharp color='secondary' />} title='Reset Search Bar'>clear</Button>
            </Box>
        </Box>
    )
}

export default SearchBar