import React, { useEffect, useState } from 'react'

import Articles from "../components/Articles";
import SearchBar from "../components/SearchBar";
import Header from '../components/Header';
// import { useHistory, useLocation } from 'react-router-dom';
import Tabs from '../components/Tabs';
import { useStore } from '../store/StoredData';

const Home = () => {

    // const history = useHistory()
    // const location = useLocation()

    const { currentUser } = useStore();
    const [enableTab, setEnableTab] = useState(true)

    console.log("Current User Obj :::", currentUser)

    useEffect(() => {
        if (currentUser?.id === 2023032 || currentUser?.id === 2023033) {
            setEnableTab(false)
        }
    }, [currentUser])

    return (
        currentUser && <>
            <Header />
            {enableTab && <Tabs />}
            <SearchBar />
            <Articles />
        </>
    )
}

export default Home