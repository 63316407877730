import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Tabs from '../components/Tabs'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography, colors } from '@mui/material'
import axios from 'axios'
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const ChatBotPromptEditor = () => {

    const [editdata, setEditData] = useState('')
    const [testData, setTestData] = useState('')
    const [promptResponse, setPromptResponse] = useState('')
    const [promptResponseLoading, setPromptResponseLoading] = useState(false)
    const [isTested, setIsTested] = useState(false)
    const [showDialog, setShowDialog] = useState(false)

    const handleClose = () => {
        setShowDialog(false)
    }

    const handleTest = () => {
        setPromptResponseLoading(true)
        console.log("Testing Data....", testData)
        axios.post("/api/prompt/test", {
            "prompt_text": editdata,
            "fin_table": testData
        }).then((resp) => {
            console.log(resp.data.message)
            setPromptResponse(resp.data.message)
            setPromptResponseLoading(false)
            setIsTested(true)
        }).catch((err) => {
            setPromptResponseLoading(false)
            console.error(err)
        })
        // setTimeout(() => {
        //     setPromptResponse("<p> According to the Income Statement table of the annual report, the revenue for the current reporting period is $467 million, compared to $12, 710 million in the same period of the previous year.This represents a significant decrease in revenue.</>\n <p></>\n <p> Moving on to the cost of goods sold or operating income, for the current reporting period, it is not provided in the given data.Therefore, we are unable to compare it with the previous period's number.</p>\n<p></p>\n<p>Next, let's examine the net…e not given in the provided data, preventing us from comparing them with the previous period's numbers.</p>\n<p></p>\n<p>In conclusion, based on the available information from the Income Statement table, the revenue for the current reporting period has significantly decreased compared to the same period in the previous year. However, we are unable to assess the cost of goods sold or operating income, net income/net loss, and earnings/loss per share for basic and diluted amounts due to incomplete data.</p>")
        //     setPromptResponseLoading(false)
        //     setIsTested(true)
        // }, 3000)
    }
    const handleSavePrompt = () => {

        handleClose()
        // console.log("Save Prompt Data....", editdata)
        if (editdata?.sk_txn_chatgpt_prompt && editdata?.prompt_text && JSON.parse(localStorage.user)?.name) {
            axios.post('/api/prompt/save', {
                sk_txn_chatgpt_prompt: editdata.sk_txn_chatgpt_prompt,
                prompt_text: editdata.prompt_text,
                user_name: JSON.parse(localStorage.user).name
            }).then((resp) => {
                console.log(resp.data.success)
                if (resp.data.success) {
                    setEditData(resp.data?.message)
                    setIsTested(false)
                    alert("Prompt Saved !")
                }
            }).catch((err) => { console.error(err.response?.data?.message); alert(`Error :: ${err.response?.data?.message}`) })
        } else {
            alert("Missing Mandatory Field...")
        }
    }

    useEffect(() => {
        axios.get('/api/prompt/fetch')
            .then((resp) => { setEditData(resp.data?.message); setIsTested(false) })
            .catch((err) => console.error(err))
    }, [])

    return (
        <>
            <Header />
            <Tabs />
            <Box mt={2} mx={5}>
                <Box display='flex'>
                    <Box width='60vw'>
                        <Box display='flex' flexDirection='column' justifyContent="center" mb={2} >
                            <Typography variant='h6' color={'orangered'}>Edit ChatBot Prompt</Typography>
                            <TextField
                                multiline
                                minRows={5}
                                rows={15}
                                fullWidth
                                value={editdata?.prompt_text}
                                onChange={(e) => setEditData(prev => ({ ...prev, prompt_text: e.target.value }))}
                            />
                        </Box>
                        <Box display='flex' flexDirection='column' justifyContent="center" mb={2} position='relative' >
                            <Typography variant='h6' color={'orangered'}>Paste Financial Statement's Content Below to Test Prompt (in Tabular Format)</Typography>
                            <TextField
                                multiline
                                minRows={4}
                                rows={4}
                                fullWidth
                                onChange={(e) => setTestData(e.target.value)}
                            />
                            <Box position='absolute' bottom={10} right={10}>
                                <Button variant='outlined' size='small' color='info' onClick={handleTest}>Click here to test</Button>
                            </Box>
                        </Box>
                        <Box display='flex' justifyContent='flex-end' mb={2}>
                            <Button variant='contained' color='success' onClick={() => { !isTested ? setShowDialog(true) : handleSavePrompt() }}>Save Prompt</Button>
                        </Box>
                    </Box>

                    <Box width='40vw' mx={2}>
                        <Typography variant='h6' color={'orangered'}>Prompt Response:</Typography>
                        {promptResponseLoading ? <Box textAlign='center'><CircularProgress /></Box> :
                            <>
                                {promptResponse.length > 0 &&
                                    <Box border={1} p={2} borderRadius={1} borderColor={colors.grey[400]} overflow={'scroll'} maxHeight='70vh' >
                                        <div style={{ fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: promptResponse }}></div>
                                    </Box>
                                }
                            </>
                        }
                    </Box>
                </Box>

                <Dialog
                    open={showDialog}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" textAlign={'center'}>
                        <WarningAmberIcon color='warning' fontSize='large' style={{ margin: "-15px 0" }} />
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" textAlign='center'>
                            <Typography variant='subtitle2'>You are saving the prompt without testing it. <br />This might lead to errors in article generation!</Typography>
                            <Typography variant='subtitle1'>Do you still want to proceed?</Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ width: "300px", height: "5vh", textAlign:'center' }}>
                        <Button size='small' variant='outlined' color='info' onClick={handleClose} autoFocus>Cancel</Button>
                        <Button size='small' variant='outlined' color='warning' onClick={handleSavePrompt}>Save Anyways</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    )
}

export default ChatBotPromptEditor