import React, { useState, useEffect } from 'react'
import { Box, Button, CircularProgress, colors, IconButton, Typography } from '@mui/material'
import { RemoveRedEyeSharp, EditSharp, StarSharp, StarOutlineSharp } from '@mui/icons-material'
import Header from '../components/Header'
// import Tabs from '../components/Tabs'
import axios from 'axios'
import { format } from 'date-fns'
import { useStore } from '../store/StoredData'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SortIcon from '@mui/icons-material/Sort';

const CategoryArticleTable = () => {
    const { catid } = useParams()
    console.log("Cat id", catid)
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const [categoryArticleList, setCategoryArticleList] = useState([])

    const [articleIndex, setArticleIndex] = useState(0)
    const [articleSize, setArticlesSize] = useState(0)
    const [hasMore, setHasMore] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(20)

    const [openHeadlineSort, setOpenHeadlineSort] = useState(false)
    const [openPublishedonSort, setOpenPublishedonSort] = useState(false)


    const [selectedHeadlineSort, setSelectedHeadlineSort] = useState(null)
    const [selectedPublishedSort, setSelectedPublishedSort] = useState("DESC")

    const [selectedSortColumn, setSelectedSortColumn] = useState('gmt_logtime')
    const [selectedSortValue, setSelectedSortValue] = useState("DESC")


    const [sortState, setSortState] = useState({
        sort_col: "GMT_LOGTIME",
        sort_val: "DESC"
    });

    const handleSortState = (sName, sVal) => {
        setSortState(prev => ({ ...prev, sort_col: sName, sort_val: sVal }))
    }

    const toggleFeaturedNews = (isFeatured, articleId) => {
        axios.post(`/api/articles/is-featured/${articleId}`, { is_featured: isFeatured })
            .then(resp => {
                setCategoryArticleList(prevList => prevList.map(art => art.id === resp.data.message.id ? ({ ...art, is_featured: resp.data.message.is_featured }) : art))
                alert(`Featured News Status Updated Successfully To - ${resp.data.message.is_featured}`);
            })
            .catch(error => alert(error.response.data))
    };

    useEffect(() => {
        fetchArticles()
    }, [])

    const fetchArticles = async () => {
        setCategoryArticleList([]);
        setLoading(true);
        setCurrentPage(0);
        await axios.get(`/api/article/category-dashboard/${catid}/${currentPage}`, {
            params: {
                page: 0,
            }
        })
            .then(async resp => {
                let { count, rows } = resp.data.message;
                console.log(resp.data.message);
                // setCategoryArticleList(resp.data.message)

                if (count) {
                    setCategoryArticleList(rows)
                    setArticleIndex(0);
                    setArticlesSize(count);

                    /* MANAGE NEXT PAGE CALL OR NOT */
                    if (count < pageSize) {
                        setHasMore(false);
                    } else {
                        setHasMore(true);
                        setCurrentPage(1);
                    }
                } else {
                    setCategoryArticleList([]);
                    setArticleIndex(0);
                    setArticlesSize(0);
                }
            }).catch(console.error)
            .finally(() => setLoading(false))
    };

    const fetchMoreArticle = async () => {
        const resp = await axios.get(`/api/article/category-dashboard/${catid}/${currentPage}`)
        console.log("fetchArticlesData :::", resp.data.message)
        const { count, rows } = resp.data.message;

        if (count < pageSize) {
            console.log('Looks Like No More Rows From Server...., Stopping On Page - ', currentPage)
            setHasMore(false);
        } else {
            setHasMore(true);
            console.log('*** Looks Like More Rows In Server...., Increasing the Page Count from = ', currentPage)
            setCurrentPage(currentPage => currentPage + 1);
        }
        return rows;
    };

    const fetchMoreData = async () => {
        console.log('Trying To Fetch More Data......... for page - ', currentPage)
        const moreRows = await fetchMoreArticle();
        console.log('fetchMoreArticles() LEN = ', moreRows.length, moreRows)
        setCategoryArticleList(prev => [...prev, ...moreRows]);
    }

    const fetchSortedData = (sortColumn, sortValue) => {
        console.log("Current Sort State :::", sortColumn, sortValue);
        setLoading(true)
        setCategoryArticleList([])
        axios.get(`/api/article/category-dashboard-sorted/${catid}/${0}`, {
            params: {
                sort_col: sortColumn,
                sort_val: sortValue
            }
        }).then(resp => {
            // if (resp.data.success)
                setCategoryArticleList(resp.data.message.rows)
        }).catch(err => console.log("Error ", err))
            .finally(() => setLoading(false))
    }

    return (
        <>
            <Header />
            {loading
                ? <Box display="flex" justifyContent="center" alignItems="center" margin="15%"><CircularProgress /></Box>
                : <>
                    {categoryArticleList.length > 0
                        ? <Box sx={{ listStyle: 'none', textAlign: 'center', border: '1px solid #CCC' }}>
                            {/* TABLE HEADER SECTION */}
                            <Box display='flex' justifyContent='flex-start' alignContent='center' alignItems='center' mx={1} px={1} py={0.5} my={0.5} fontSize={12} bgcolor={colors.blueGrey[100]}>
                                <Box width='13%' fontWeight={700} textAlign="center" display='flex' justifyContent='flex-start' alignItems='center'>
                                    <Button variant='outlined' color='secondary' size='small' onClick={() => history.push('/dashboard')}>Back</Button>
                                    <Box ml={4}>Action</Box>
                                </Box>
                                <Box width='40%' fontWeight={700} textAlign="center" display='flex' alignItems='center' position='relative'>
                                    <span title='sort' style={{ cursor: 'pointer' }}>
                                        <SortIcon fontSize='small' color='action' onClick={() => setOpenHeadlineSort(!openHeadlineSort)} />
                                    </span>
                                    <span>&nbsp;Article Headline</span>
                                    {openHeadlineSort &&
                                        <Box position='absolute' width='120px' height='max-content' bgcolor='#fff' border={1} borderRadius={1} borderColor="#e0e0e0" zIndex={9} top={20} left={0} textAlign='left'>
                                            <Button size='small' sx={{ textTransform: 'capitalize' }} p={0.5} fullWidth disabled={selectedHeadlineSort === "ASC"} onClick={(event) => {
                                                setSelectedHeadlineSort("ASC");
                                                fetchSortedData("title", "ASC");
                                                setSelectedPublishedSort(null);
                                                handleSortState('TITLE', 'ASC')
                                                setOpenHeadlineSort(false)
                                            }}>a-Z</Button>
                                            <Button size='small' sx={{ textTransform: 'capitalize' }} p={0.5} fullWidth disabled={selectedHeadlineSort === "DESC"} onClick={(event) => {
                                                setSelectedHeadlineSort("DESC");
                                                fetchSortedData("title", "DESC");
                                                setSelectedPublishedSort(null);
                                                handleSortState('TITLE', 'DESC')
                                                setOpenHeadlineSort(false)
                                            }}>Z-a</Button>
                                        </Box>
                                    }
                                </Box>
                                <Box width='10%' fontWeight={700} textAlign="center" display='flex' alignItems='center' justifyContent='center' >Featured<br />News</Box>
                                <Box width='10%' fontWeight={700} textAlign="center" display='flex' alignItems='center' >Article Category</Box>
                                <Box width='10%' fontWeight={700} textAlign="center" display='flex' alignItems='center'>Article ID</Box>
                                <Box width='10%' fontWeight={700} textAlign="center" display='flex' alignItems='center' position='relative'>
                                    <span title='sort' style={{ cursor: 'pointer' }}>
                                        <SortIcon fontSize='small' color='action' onClick={() => setOpenPublishedonSort(!openPublishedonSort)} />
                                    </span><span>&nbsp;Published On</span>
                                    {openPublishedonSort &&
                                        <Box position='absolute' width='120px' height='max-content' bgcolor='#fff' border={1} borderRadius={1} borderColor="#e0e0e0" zIndex={9} top={20} left={0} textAlign='left'>
                                            <Button size='small' sx={{ textTransform: 'capitalize' }} p={0.5} fullWidth disabled={selectedPublishedSort === "ASC"} onClick={() => {
                                                setSelectedPublishedSort("ASC");
                                                fetchSortedData("gmt_logtime", "ASC");
                                                setSelectedHeadlineSort(null);
                                                handleSortState("gmt_logtime", "ASC")
                                                setOpenPublishedonSort(false)
                                            }}>Ascending</Button>
                                            <Button size='small' sx={{ textTransform: 'capitalize' }} p={0.5} fullWidth disabled={selectedPublishedSort === "DESC"} onClick={() => {
                                                setSelectedPublishedSort("DESC");
                                                fetchSortedData("gmt_logtime", "DESC");
                                                setSelectedHeadlineSort(null);
                                                handleSortState("gmt_logtime", "DESC")
                                                setOpenPublishedonSort(false)
                                            }}>Descending</Button>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                            {/* TABLE DATA SECTION */}
                            <Box id="scrollableDiv" height={520} overflow="auto" px={1}>
                                <InfiniteScroll
                                    dataLength={categoryArticleList.length}
                                    next={fetchMoreData}
                                    hasMore={hasMore}
                                    loader={<Box display='flex' justifyContent='center' my={2}><CircularProgress /></Box>}
                                    scrollableTarget="scrollableDiv"
                                    endMessage={
                                        <p style={{ textAlign: 'center', fontSize: 16, fontWeight: 400, color: colors.blue[600] }}>
                                            Yay! You have seen it all
                                        </p>
                                    }
                                >
                                    {categoryArticleList?.map((article, idx) =>
                                        <Box key={idx} display="flex" justifyContent="flex-start" alignContent="center" alignItems="center"
                                            my={0.5} border={1} borderColor="#CCC" fontSize={12}>
                                            <Box width='13%' display="flex" justifyContent="center">
                                                <Box
                                                    onClick={() => { history.push(`/articles/view/${article.id}`); setArticleIndex(idx) }}
                                                    px={1}
                                                    style={{ cursor: 'pointer', textDecoration: 'none' }}
                                                    role="button"
                                                    title='View Article (Read Only)'>
                                                    <RemoveRedEyeSharp color='primary' fontSize="small" />
                                                </Box>
                                                <Box
                                                    onClick={() => { history.push(`/articles/edit/${article.id}`); setArticleIndex(idx) }}
                                                    px={1}
                                                    style={{ cursor: 'pointer', textDecoration: 'none' }}
                                                    role="button"
                                                    title='Edit Article'>
                                                    <EditSharp color='secondary' fontSize="small" />
                                                </Box>
                                                <Box
                                                    onClick={() => history.push(`/news-summary/edit/${article.item_id}`)}
                                                    px={1}
                                                    style={{ cursor: 'pointer', textDecoration: 'none' }}
                                                    role="button"
                                                    title='Article Summary'>
                                                    <BorderColorIcon color='error' fontSize='small' />
                                                </Box>
                                            </Box>
                                            <Box width='40%' textAlign="left" display='flex' alignItems='center'>
                                                <span style={{ margin: "5px", border: '1px solid #CCC', borderRadius: '5px' }}>
                                                    <img src={article.image_url} height='auto' width="50px" />
                                                </span>
                                                <span>{article.title}</span>
                                            </Box>
                                            <Box width='10%' textAlign="center">
                                                {article?.is_featured
                                                    ? <IconButton onClick={() => toggleFeaturedNews(!article?.is_featured, article?.id)} title="Click To Remove From Featured News"><StarSharp color="primary" /></IconButton>
                                                    : <IconButton onClick={() => toggleFeaturedNews(!article?.is_featured, article?.id)} title="Click To Mark As Featured News"><StarOutlineSharp color="action" /></IconButton>
                                                }
                                            </Box>
                                            <Box width='10%' textAlign="center" sx={{ color: 'blueviolet' }}>{article.category}</Box>
                                            <Box width='10%' textAlign="center">{article.id}</Box>
                                            <Box width='10%' textAlign="center">{format(new Date(article.gmt_logtime), "MMM dd, yy hh:mm:ss a")}</Box>
                                        </Box>
                                    )}
                                </InfiniteScroll>
                            </Box>
                        </Box>
                        : <Box width="100%" textAlign="center" my={5}>
                            <Typography variant='body1' color={colors.deepOrange[300]}>No Data To Display For Selected Settings</Typography>
                        </Box>
                    }
                </>}
        </>
    )
}

export default CategoryArticleTable

// api/article/category-dashboard/20210827130428