import { ClearSharp, EditSharp, RemoveRedEyeSharp, SearchSharp } from '@mui/icons-material'
import { Box, Button, CircularProgress, colors, TextField, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Header from '../components/Header'
import Tabs from '../components/Tabs'
import { useStore } from '../store/StoredData'
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'
import ReactDatePicker from 'react-datepicker'
import SortIcon from '@mui/icons-material/Sort';

const EarningTable = () => {

    const history = useHistory()

    const { earningFilingsList, setEarningFilingsList,
        earningFilingsIndex, setEarningFilingsIndex,
        earningFilingsSize, setEarningFilingsSize,
        hasMoreEarningsFilings, setHasMoreEarningsFilings,
        currentPageEarningsFilings, setCurrentPageEarningsFilings,
        pageSizeEarningsFilings, setPageSizeEarningsFilings } = useStore()


    const [loading, setLoading] = useState(false)
    const [dateFiledDateRange, setDateFiledUpdatedDateRange] = useState([new Date(), new Date()]);
    const [DateFiledUpdatedStartDate, DateFiledUpdatedEndDate] = dateFiledDateRange;
    const [companyName, setCompanyName] = useState('')
    const [openCompanySort, setOpenCompanySort] = useState(false)
    const [openPublishedonSort, setOpenPublishedonSort] = useState(false)

    const [selectedCompanySort, setSelectedCompanySort] = useState(null)
    const [selectedPublishedSort, setSelectedPublishedSort] = useState(null)

    const [sortState, setSortState] = useState({
        sort_col: "DATE_FILED",
        sort_val: "DESC"
    });

    const handleSortState = (sName, sVal) => {
        setSortState(prev => ({ ...prev, sort_col: sName, sort_val: sVal }))
    }


    const fetchSortedData = (sortColumn, sortValue) => {
        console.log("Current Sort State :::", sortColumn, sortValue);
        setLoading(true)
        setEarningFilingsList([])
        axios.get('api/filings/earnings-news', {
            params: {
                page: 0,
                sort_col: sortColumn,
                sort_val: sortValue
            }
        }).then(resp => {
            if (resp.data.success && resp.data.message.rows.length)
                setEarningFilingsList(resp.data.message.rows)
        }).catch(err => console.log("Error ", err))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchEarningList(companyName, DateFiledUpdatedStartDate, DateFiledUpdatedEndDate)
    }, [])

    const fetchEarningList = (company, startDate, endDate) => {
        setLoading(true)
        setEarningFilingsList([]);
        setCurrentPageEarningsFilings(0);
        axios.get(`/api/filings/earnings-news`, {
            params: {
                page: 0,
                company_name: company,
                startdate: startDate ? format(startDate, "yyyy-MM-dd") : null,
                enddate: endDate ? format(endDate, "yyyy-MM-dd") : null,
            }
        }).then((resp) => {
            let { count, rows } = resp.data.message;
            console.log("Earning Table ::", resp.data.message)
            if (count) {
                setEarningFilingsList(rows)
                setEarningFilingsIndex(0);
                setEarningFilingsSize(count);

                /* MANAGE NEXT PAGE CALL OR NOT */
                if (count < pageSizeEarningsFilings) {
                    // console.log('Looks Like No More Rows From Server...., Stopping On Page - ', currentPage)
                    setHasMoreEarningsFilings(false);
                } else {
                    setHasMoreEarningsFilings(true);
                    // console.log('*** Looks Like More Rows In Server...., Increasing the Page Count from = ', currentPage)
                    setCurrentPageEarningsFilings(1);
                }
            } else {
                setEarningFilingsList([]);
                setEarningFilingsIndex(0);
                setEarningFilingsSize(0);
            }

        }).catch(err => console.log("Error :::", err))
            .finally(() => setLoading(false))
    }

    const fetchMoreEarningFilings = async () => {
        const resp = await axios.get("/api/filings/earnings-news", {
            params: {
                page: currentPageEarningsFilings,
            }
        })
        console.log("fetchArticlesData :::", resp.data.message)
        const { count, rows } = resp.data.message;

        if (count < pageSizeEarningsFilings) {
            console.log('Looks Like No More Rows From Server...., Stopping On Page - ', currentPageEarningsFilings)
            setHasMoreEarningsFilings(false);
        } else {
            setHasMoreEarningsFilings(true);
            console.log('*** Looks Like More Rows In Server...., Increasing the Page Count from = ', currentPageEarningsFilings)
            setCurrentPageEarningsFilings(currentPage => currentPage + 1);
        }
        return rows;
    };

    const fetchMoreData = async () => {
        console.log('Trying To Fetch More Data......... for page - ', currentPageEarningsFilings)
        const moreRows = await fetchMoreEarningFilings();
        console.log('fetchMoreArticles() LEN = ', moreRows.length, moreRows)
        setEarningFilingsList(prev => [...prev, ...moreRows]);
    }

    const resetSearchBar = () => {
        setCurrentPageEarningsFilings(0)
        setCompanyName("")
        // setDateFiledUpdatedDateRange([null, null])
        fetchEarningList("", dateFiledDateRange[0], dateFiledDateRange[1])
    }

    return (
        <>
            <Header />
            <Tabs />
            <Box display='flex' justifyContent='flex-start' alignContent='center' alignItems='center' mx={1} px={1} py={0.5} my={0.5} fontSize={12} bgcolor={colors.blueGrey[100]}>
                <Box width='10%' fontWeight={700} textAlign="left">Action</Box>
                <Box width='20%' fontWeight={700} textAlign="left" display='flex' alignItems='center' position='relative'>
                    <span title='sort' style={{ cursor: 'pointer' }}>
                        <SortIcon fontSize='small' color='action' onClick={() => setOpenCompanySort(!openCompanySort)} />
                    </span>
                    <span>&nbsp;Company Name</span>
                    {openCompanySort &&
                        <Box position='absolute' width='120px' height='max-content' bgcolor='#fff' border={1} borderRadius={1} borderColor="#e0e0e0" zIndex={9} top={20} left={0} textAlign='left'>
                            <Button size='small' sx={{ textTransform: 'capitalize' }} p={0.5} fullWidth disabled={selectedCompanySort === "ASC"} onClick={(event) => {
                                setSelectedCompanySort("ASC");
                                fetchSortedData("title", "ASC");
                                handleSortState('TITLE', 'ASC')
                                setOpenCompanySort(false)
                            }}>a-Z</Button>
                            <Button size='small' sx={{ textTransform: 'capitalize' }} p={0.5} fullWidth disabled={selectedCompanySort === "DESC"} onClick={(event) => {
                                setSelectedCompanySort("DESC");
                                fetchSortedData("title", "DESC");
                                handleSortState('TITLE', 'DESC')
                                setOpenCompanySort(false)
                            }}>Z-a</Button>
                        </Box>
                    }
                </Box>
                <Box width='10%' fontWeight={700} textAlign="center" display='flex' alignItems='center' position='relative'>Form Type</Box>
                <Box width='10%' fontWeight={700} textAlign="center" display='flex' alignItems='center' position='relative'>Filing Exhibits</Box>
                <Box width='30%' fontWeight={700} textAlign="center" display='flex' alignItems='center' position='relative'>Filing Items</Box>
                <Box width='10%' fontWeight={700} textAlign="center" display='flex' alignItems='center' position='relative'>Company<br />Priority</Box>
                <Box width='10%' fontWeight={700} textAlign="center" display='flex' alignItems='center' position='relative'>Date Filed</Box>
                <Box width='10%' fontWeight={700} textAlign="center" display='flex' alignItems='center'>Filing ID</Box>
            </Box>

            {/* Search Bar  */}
            <Box display='flex' justifyContent='space-around' alignItems='center' textAlign='left' mx={0.5} bgcolor={colors.blueGrey[50]} fontSize={13} sx={{ borderBottomLeftRadius: 5, borderBottomRightRadius: 5 }}>
                <Box width='10%'></Box>
                <Box width='20%' mx={1}>
                    <TextField variant='outlined' sx={{ backgroundColor: '#FFF' }} size='small' name="insider_name" value={companyName} onChange={e => { setCompanyName(e.target.value) }} />
                </Box>
                <Box width='10%'></Box>
                <Box width='10%'></Box>
                <Box width='25%'></Box>
                <Box width='20%'>
                    <ReactDatePicker
                        // disabled
                        id="ArticleReactDatePicker"
                        className="ReactDatePicker"
                        selectsRange={true}
                        startDate={DateFiledUpdatedStartDate}
                        endDate={DateFiledUpdatedEndDate}
                        onChange={(update) => {
                            console.log('setArticleUpdatedDateRange = ', update);
                            setDateFiledUpdatedDateRange(update);
                        }}
                    />
                </Box>
                {/* <Box width='10%'></Box> */}
                <Box width='5%' display="flex" flexDirection="column">
                    <Button variant='outlined' color="secondary" sx={{ fontSize: 12, my: 0.25, p: 0 }} endIcon={<SearchSharp fontSize='small' color='secondary' />} title='Search For Resutls' onClick={() => fetchEarningList(companyName, DateFiledUpdatedStartDate, DateFiledUpdatedEndDate)} >search</Button>
                    <Button variant='outlined' color="info" sx={{ fontSize: 12, my: 0.25, p: 0 }} endIcon={<ClearSharp fontSize='small' color='info' />} title='Reset Search Bar' onClick={resetSearchBar} >clear</Button>
                </Box>
            </Box>

            {loading ? <Box display="flex" justifyContent="center" alignItems="center" margin="15%"><CircularProgress /></Box> :
                <>
                    {earningFilingsList.length > 0 ?
                        <>
                            {/* TABLE DATA SECTION */}
                            <Box id="scrollableDiv" height={520} overflow="auto" px={1}>
                                <InfiniteScroll
                                    dataLength={earningFilingsList.length}
                                    next={fetchMoreData}
                                    hasMore={hasMoreEarningsFilings}
                                    loader={<Box display='flex' justifyContent='center' my={2}><CircularProgress /></Box>}
                                    scrollableTarget="scrollableDiv"
                                    endMessage={
                                        <p style={{ textAlign: 'center', fontSize: 16, fontWeight: 400, color: colors.blue[600] }}>
                                            Yay! You have seen it all
                                        </p>
                                    }
                                >
                                    {earningFilingsList?.map((ear, idx) =>
                                        <Box key={idx} display="flex" justifyContent="flex-start" alignContent="center" alignItems="center"
                                            my={0.75} border={1} borderColor="#CCC" borderRadius={1} fontSize={12}>
                                            <Box width='10%' display="flex" justifyContent="flex-start">
                                                <Box
                                                    onClick={() => { history.push(`/earnings-news/${ear.sk_txn_fs_platter}`); setEarningFilingsIndex(idx) }}
                                                    px={1}
                                                    style={{ cursor: 'pointer', textDecoration: 'none' }}
                                                    role="button"
                                                    title='Edit Earnings News'>
                                                    <EditSharp color='secondary' fontSize="small" />
                                                </Box>
                                            </Box>
                                            <Box width='20%' textAlign="left" alignItems='center'>
                                                <Box>{ear.company_name} [{ear.ticker}]</Box>
                                                <Box fontSize={10} pt={0.5} color={colors.blueGrey[500]}>{ear.cik}&nbsp;&nbsp;|&nbsp;&nbsp;{ear.accession_number}</Box>
                                            </Box>
                                            <Box width='10%' textAlign="center">{ear.form_type}</Box>
                                            <Box width='10%' textAlign="left" pl={1}>{ear.exhibit_file_desc.map(exb => <Box m={0.5} color={colors.blueGrey[700]}>{exb}</Box>)}</Box>
                                            <Box width='30%' textAlign="left" pl={1}>{ear.filing_items.split(/\n/).map(item => <Box m={0.5} color={colors.blueGrey[700]}>{item}</Box>)}</Box>
                                            <Box width='10%' textAlign="center" pl={1}>{ear.ranking}</Box>
                                            <Box width='10%' textAlign="left" color={colors.blueGrey[700]} paddingLeft={5}>{format(new Date(ear.date_filed), "MMM dd, yy")}</Box>
                                            <Box width='10%' textAlign="left" color={colors.blueGrey[700]}>{ear.sk_txn_fs_platter}</Box>
                                        </Box>
                                    )}
                                </InfiniteScroll>
                            </Box>
                        </>
                        : <Box width="100%" textAlign="center" my={5}>
                            <Typography variant='body1' color={colors.deepOrange[300]}>No Data To Display For Selected Settings</Typography>
                        </Box>
                    }
                </>}
        </>
    )
}

export default EarningTable