import { backdropClasses, Box, Button, colors, IconButton, LinearProgress, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import AddBoxIcon from '@mui/icons-material/AddBox';

import Header from '../components/Header'
import Tabs from '../components/Tabs'
import { DeleteForeverSharp, EditSharp, SaveSharp } from '@mui/icons-material'
import { grey } from '@mui/material/colors'

import useQuery from '../components/useQuery'


const EarningsNews = () => {
    const history = useHistory();

    const [companyName, setCompanyName] = useState("")
    const [ticker, setTicker] = useState(null);
    const [earningQtr, setEarningQtr] = useState('Q1')
    const [profitLoss, setProfitLoss] = useState('profit')
    const [dateFiled, setDateFiled] = useState("")
    const [netIncome, setNetIncome] = useState("")
    const [profitLossUnits, setProfitLossUnits] = useState('')
    const [units, setUnits] = useState('')
    const [netIncUnits, setNetIncUnits] = useState('')
    const [revenueUnits, setRevenueUnits] = useState('')
    const [earningPerShare, setEarningPerShare] = useState('')
    const [industryName, setIndustryName] = useState('')

    const [stateOfIncorp, setStateOfIncorp] = useState('')

    const [profitLossAmount, setProfitLossAmount] = useState("")
    const [revenueAmount, setRevenueAmount] = useState("")

    const [references, setReferences] = useState([]);

    const [fillingId, setFillingID] = useState("")
    const [username, setUsername] = useState("")
    const [userId, setUserId] = useState("")

    const [filingList, setFilingList] = useState([])
    const [filingURL, setFilingURL] = useState()
    const [relatedNews, setRelatedNews] = useState([]);

    const [loading, setLoading] = useState(false);

    const params = useParams()
    console.log("params ::", params)

    let query = useQuery()
    console.log("Query Params :::", query);
    console.log("Username ::", query.get("u"));
    console.log("User ID ::", query.get("uid"));

    useEffect(() => {
        setFillingID(params.id)
        setUsername(query.get('u'))
        setUserId(query.get('uid'))

        const loadFilings = async () => {
            setLoading(true);
            axios.get(`/api/filings/by-id/${params.id}`)
                .then((resp) => {
                    console.log("Filings Resp :::", resp.data.message)
                    setFilingList(resp.data.message)
                    setFilingURL(resp.data.message[1].exhibit_file_path)
                    setCompanyName(resp.data.message[0].company_name)
                    setTicker(resp.data.message[0].ticker)
                    setStateOfIncorp(resp.data.message[0].state_name)
                    setIndustryName(resp.data.message[0].sic_desc);
                    setLoading(false);
                }).catch(err => console.log("Error :::", err))
        };

        if (params.id)
            loadFilings();

    }, [params.id])

    useEffect(() => {
        const getRelatedNews = async () => {
            axios.get(`/api/filings/related-news/${ticker.toUpperCase()}`)
                .then(resp => {
                    console.log('EARNINGS RELATED NEWS RESP === ', resp.data.message);
                    let items = resp.data.message.map(obj => ({ title: obj.title, url: `https://candorium.com/news/${obj.id}/${obj.title.replace(/\s/gmi, '-')}` }))
                    setRelatedNews(items);
                    setReferences(items)
                }).catch(error => {
                    console.error(`Error from related-news API - `, error);
                });
        }

        if (ticker)
            getRelatedNews();

    }, [ticker])

    console.log("Filling ID", fillingId);
    console.log("User", username || JSON.parse(localStorage.user).name);
    console.log("User ID", userId || JSON.parse(localStorage.user).id);

    const handleReferences = async (idx, event) => {
        console.log('handleReferences OBJ === ', idx, event.target.name, event.target.value);
        setReferences(prev => {
            if (prev.length) {
                const newState = prev.map((obj, i) => {
                    if (i === idx)
                        return { ...obj, [event.target.name]: event.target.value }
                    return obj;
                })
                return newState
            } else {
                console.log(idx, event.target.name, event.target.value)
            }
        });
    };

    const handleAddReference = () => {
        setRelatedNews(references.flatMap(x => x))
    }

    console.log('ADD REFERENCE ::: ', references);
    console.log('RELATED  NEWS ::: ', relatedNews)

    const handleDeleteReference = (idx) => {
        let nRef = references.filter((a, i) => i !== idx);
        console.log('AFTER DELETED REFERENCES === ', nRef);
        setReferences(nRef);
    }

    const handleSaveEarningsNews = () => {
        axios.post('/api/filings/earnings-news', {
            cik: filingList[0].cik,
            company_name: filingList[0].company_name,
            ticker: filingList[0].ticker,
            accession_number: filingList[0].accession_number,
            irk_txn_fs_platter_id: filingList[0].sk_txn_fs_platter,
            earnings_qtr: earningQtr,
            profit_or_loss: profitLoss,
            profit_or_loss_amount: netIncome,
            profit_or_loss_units: netIncUnits,
            net_income_amount: netIncome,
            net_income_units: netIncUnits,
            company_state_of_incorp: filingList[0].state_of_incorp,
            per_share_value: earningPerShare,
            company_sic_code: filingList[0].sic,
            company_sic_desc: industryName,
            revenue_amount: revenueAmount,
            revenue_units: revenueUnits,
            related_news: JSON.stringify(relatedNews),
            username: username || JSON.parse(localStorage.user).name,
            userid: userId || JSON.parse(localStorage.user).id
        }).then(resp => {
            if (resp.status === 201)
                alert('Earnings News Item Saved Successfully!');
        }).catch(resp => {
            console.log('SAVE ERROR FROM API - ', resp)
            alert(resp.response.data.message);
        })
    };

    // if (loading)
    //     return <Box mt={2}><LinearProgress color='secondary'></LinearProgress></Box>

    return (
        <>
            <Header />
            <Tabs />
            <hr />
            {loading ? <Box mt={2}><LinearProgress color='secondary'></LinearProgress></Box> : <>
                <Box mx={1} display="flex" justifyContent="flex-start" alignContent="center" alignItems="center" bgcolor={colors.blueGrey[50]} p={0.5}>
                    {filingList.length ? filingList.map((fl) => (
                        <Button onClick={() => setFilingURL(fl.exhibit_file_path)} variant='outlined' size='small' color='secondary' sx={{ mx: 2 }}>{fl.exhibit_file_desc}</Button>
                    )) : null}
                </Box>
                <Box width='100%' display='flex' justifyContent='space-between' mt={0.5}>
                    {/* ***** ***** ***** LEFT-SIDE FILINGS SECTION ***** ***** ***** */}
                    <Box width='49.5%' border={1} borderColor={colors.teal[600]}>
                        {filingURL
                            ? <iframe src={filingURL} height='100%' width='100%' style={{ border: 'none' }} />
                            : <Box textAlign="center" my={2}>Enter a valid 8-K Filling ID in the url....</Box>
                        }
                    </Box>

                    {/* ***** ***** ***** RIGHT-SIDE FORM SECTION ***** ***** ***** */}
                    <Box width='49.5%' display='flex' flexDirection='column'>
                        <Box display='flex' flexDirection='column' height={300} overflow='auto' p={1} mb={0.5} border={1} borderColor={colors.teal[600]} borderRadius={1} boxShadow={1}>
                            <h4 style={{ margin: 1, padding: 1, color: colors.blueGrey[800] }}>News Template:</h4>
                            {/* <Box display='flex' justifyContent='space-between' m={1}>
                        <Button variant='contained' color='inherit'>Cancel</Button>
                        <Button variant='contained' color='primary'>Save</Button>
                    </Box> */}

                            {/* ***** EMAIL SUBJECT SECTION ***** */}
                            <Typography sx={{ fontSize: 16, fontWeight: 600, color: 'blueviolet', mt: 1 }}>Email Subject Line:</Typography>
                            <Box display='flex' flexWrap='wrap' alignItems='start' border={1} borderColor={grey[400]} p={0.5}>
                                <Box m={0} p={0} fontSize={12} display='flex' alignItems='start'>
                                    <Typography sx={{ minWidth: 100, fontSize: 15, color: 'blue' }}>{companyName} {ticker ? '[' + ticker + ']' : null}&nbsp;</Typography>
                                    <EditSharp fontSize='10px' color='action' />
                                    <Typography sx={{ fontSize: 15 }} >&nbsp; announces &nbsp;</Typography>
                                </Box>
                                <Box m={0} p={0} display='flex' alignItems='start'>
                                    <Select variant='standard' size="small" defaultChecked={earningQtr} value={earningQtr} onChange={(e) => setEarningQtr(e.target.value)} sx={{ fontSize: 14, color: 'orangered' }}>
                                        <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='Q1'>Q1</MenuItem>
                                        <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='Q2'>Q2</MenuItem>
                                        <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='Q3'>Q3</MenuItem>
                                        <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='Q4'>Q4</MenuItem>
                                    </Select>
                                    <Typography sx={{ fontSize: 15 }}>&nbsp; earnings</Typography>
                                </Box>
                            </Box>

                            {/* ***** HEADLINES SECTION ***** */}
                            <Typography sx={{ fontSize: 16, fontWeight: 600, color: 'blueviolet', mt: 1 }}>Headline</Typography>
                            <Box display='flex' flexWrap='wrap' alignItems='start' border={1} borderColor={grey[400]} p={0.5}>
                                <Box m={0} p={0} display='flex' alignItems='start'>
                                    <Typography sx={{ minWidth: 100, fontSize: 15, color: 'blue' }}>{companyName} {ticker ? '[' + ticker + ']' : null}:&nbsp;</Typography>
                                    <EditSharp fontSize='10px' color='action' />&nbsp;
                                </Box>
                                <Box m={0} p={0} display='flex' alignItems='start'>
                                    <Select variant='standard' size="small" defaultChecked={earningQtr} value={earningQtr}
                                        onChange={(e) => setEarningQtr(e.target.value)} sx={{ fontSize: 14, color: 'orangered' }}>
                                        <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='Q1'>Q1</MenuItem>
                                        <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='Q2'>Q2</MenuItem>
                                        <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='Q3'>Q3</MenuItem>
                                        <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='Q4'>Q4</MenuItem>
                                    </Select>
                                    <Typography sx={{ fontSize: 15 }}>&nbsp;earnings report&nbsp;</Typography>
                                </Box>
                                <Box m={0} p={0} display='flex' alignItems='start'>
                                    <Select variant='standard' size="small" defaultChecked={profitLoss} value={profitLoss}
                                        onChange={(e) => setProfitLoss(e.target.value)} sx={{ fontSize: 14, color: 'orangered' }}>
                                        <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='profit'>profit</MenuItem>
                                        <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='loss'>loss</MenuItem>
                                    </Select>
                                    <Typography sx={{ fontSize: 15 }}>&nbsp;of&nbsp;$</Typography>
                                </Box>
                                <Box m={0} p={0} display='flex' alignItems='start' >
                                    <TextField
                                        sx={{ fontSize: 14, margin: 0, padding: 0 }}
                                        inputProps={{ style: { fontSize: 15, color: 'orangered' } }}
                                        size="small" variant='standard'
                                        placeholder='Net Income' value={netIncome} onChange={(event) => setNetIncome(event.target.value)}
                                    />&nbsp;
                                </Box>
                                <Box m={0} p={0} display='flex' alignItems='start'>
                                    <Select variant='standard' size="small" defaultChecked={netIncUnits} value={netIncUnits}
                                        onChange={(e) => setNetIncUnits(e.target.value)} sx={{ fontSize: 14, color: 'orangered' }}>
                                        {/* <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='thousands'>Thousands</MenuItem> */}
                                        <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='million'>Million</MenuItem>
                                        <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='billion'>Billion</MenuItem>
                                    </Select>
                                </Box>
                            </Box>

                            {/* ***** BODY SECTION ***** */}
                            <Typography sx={{ fontSize: 16, fontWeight: 600, color: 'blueviolet', mt: 1 }}>Body</Typography>
                            <Box border={1} borderColor={grey[400]} p={0.5}>
                                <Box display='flex' flexWrap='wrap' alignItems='start'>
                                    <Box m={0} p={0} fontSize={12} display='flex' alignItems='start'>
                                        <Typography sx={{ fontSize: 15, margin: 0, padding: 0, color: 'blue' }}>{companyName} {ticker ? '[' + ticker + ']' : null}&nbsp;</Typography>
                                        <Typography sx={{ fontSize: 15, margin: 0, padding: 0 }}>&nbsp;on&nbsp;{dateFiled}&nbsp;reported&nbsp;fiscal&nbsp;</Typography>
                                    </Box >
                                    <Box m={0} p={0} fontSize={12}>
                                        <Select variant='standard' size="small" defaultChecked={earningQtr} value={earningQtr}
                                            onChange={(e) => setEarningQtr(e.target.value)} sx={{ fontSize: 14, color: 'orangered' }}>
                                            <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='Q1'>Q1</MenuItem>
                                            <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='Q2'>Q2</MenuItem>
                                            <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='Q3'>Q3</MenuItem>
                                            <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='Q4'>Q4</MenuItem>
                                        </Select>&nbsp;
                                    </Box>
                                    <Box m={0} p={0} fontSize={12} display='flex' alignItems='start'>
                                        <Select variant='standard' size="small" defaultChecked={profitLoss} value={profitLoss}
                                            onChange={(e) => setProfitLoss(e.target.value)} sx={{ fontSize: 14, color: 'orangered' }}>
                                            <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='profit'>Profit</MenuItem>
                                            <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='loss'>Loss</MenuItem>
                                        </Select>
                                        <Typography sx={{ fontSize: 15, margin: 0, padding: 0 }}>&nbsp;of $</Typography>
                                    </Box>
                                    <Box display='flex' alignItems='start' m={0} p={0} fontSize={12}>
                                        <TextField
                                            sx={{ fontSize: 14, margin: 0, padding: 0 }}
                                            inputProps={{ style: { fontSize: 15, color: 'orangered' } }}
                                            size="small" variant='standard' placeholder='Net Income'
                                            value={netIncome} onChange={(event) => setNetIncome(event.target.value)}
                                        />
                                    </Box>
                                    <Box m={0} p={0} fontSize={12} display='flex' alignItems='start'>
                                        <Select size="small" variant='standard' defaultChecked={netIncUnits} value={netIncUnits}
                                            onChange={(e) => setUnits(e.target.value)} sx={{ fontSize: 14, color: 'orangered' }}>
                                            {/* <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='thousands'>Thousands</MenuItem> */}
                                            <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='million'>Million</MenuItem>
                                            <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='billion'>Billion</MenuItem>
                                        </Select>
                                    </Box>
                                </Box>

                                <Box display='flex' alignItems='start' m={0} mt={2} p={0} fontSize={12}>
                                    <Typography sx={{ fontSize: 15, margin: 0, padding: 0 }}>On a per-share basis, the {stateOfIncorp} based company said its diluted earnings are $</Typography>
                                </Box>
                                <Box display='flex' alignItems='start' m={0} p={0} fontSize={12} >
                                    <TextField
                                        sx={{ fontSize: 14, margin: 0, padding: 0 }}
                                        inputProps={{ style: { fontSize: 15, color: 'orangered' } }}
                                        size="small" variant='standard'
                                        placeholder='Earning/ Share' value={earningPerShare}
                                        onChange={(event) => setEarningPerShare(event.target.value)}
                                    />
                                    <Typography sx={{ fontSize: 15, margin: 0, padding: 0 }}>&nbsp;per share.</Typography>
                                </Box>
                                <Box mt={2} display='flex' alignItems='start' flexWrap='wrap'>
                                    <Typography sx={{ fontSize: 15, margin: 0, padding: 0 }}>The {industryName} firm posted a revenue of&nbsp;</Typography>
                                    <TextField
                                        sx={{ fontSize: 14, margin: 0, padding: 0 }}
                                        inputProps={{ style: { fontSize: 15, color: 'orangered' } }}
                                        size="small" variant='standard'
                                        title='Revenue Amount'
                                        placeholder='Enter revenue Amount'
                                        value={revenueAmount} onChange={(event) => setRevenueAmount(event.target.value)}
                                    />&nbsp;
                                    <Box m={0} p={0} display='flex' alignItems='start'>
                                        <Select size="small" variant='standard' defaultChecked={revenueUnits} value={revenueUnits}
                                            onChange={(e) => setRevenueUnits(e.target.value)} sx={{ fontSize: 14, color: 'orangered' }}>
                                            {/* <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='thousands'>Thousands</MenuItem> */}
                                            <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='million'>Million</MenuItem>
                                            <MenuItem sx={{ fontSize: 14, color: 'orangered' }} value='billion'>Billion</MenuItem>
                                        </Select>
                                        <Typography sx={{ fontSize: 15, margin: 0, padding: 0 }}>&nbsp;in this period.</Typography>
                                    </Box>
                                </Box>
                            </Box >

                            {/* ***** ***** ***** ALSO READ SECTION ***** ***** ***** */}
                            < Typography sx={{ fontSize: 16, fontWeight: 600, color: 'blueviolet', mt: 3 }}> Also Read:</Typography >
                            {
                                references.length > 0
                                    ? <Box display="flex" flexDirection="column" justifyContent="flex-start" p={1} marginTop={1} marginBottom={5}>
                                        {references.map((ref, idx) =>
                                            <Box key={idx} display="flex" justifyContent="flex-start" alignContent="center" alignItems="center">
                                                <Box width="5%" m={1}><Typography variantt="body1">{idx + 1}:</Typography></Box>
                                                <Box width="50%" m={1}><TextField id="title-1" variant="standard" name="title" label="Title" InputLabelProps={{ shrink: true }} fullWidth value={ref.title} onChange={e => handleReferences(idx, e)} /></Box>
                                                <Box width="40%" m={1}><TextField id="url-1" variant="standard" name="url" label="URL" InputLabelProps={{ shrink: true }} fullWidth value={ref.url} onChange={e => handleReferences(idx, e)} /></Box>
                                                <Box width="5%" m={1}><IconButton title='Delete Reference' onClick={() => handleAddReference()} size="medium">
                                                    <SaveSharp color="success" fontSize='medium' />
                                                </IconButton></Box>
                                                <Box width="5%" m={1}><IconButton title='Delete Reference' onClick={() => handleDeleteReference(idx)} size="medium">
                                                    <DeleteForeverSharp color="error" fontSize='medium' />
                                                </IconButton></Box>
                                            </Box>
                                        )}
                                    </Box>
                                    : null
                            }
                            < Box textAlign="center" >
                                <IconButton color='info' size='small' onClick={() => setReferences(prev => [...prev, { title: "", url: "" }])}>
                                    <AddBoxIcon color='info' fontSize='small' />Add References</IconButton>
                            </Box >
                            <Box display='flex' justifyContent='flex-end' m={1}>
                                <Button size='small' variant='contained' color='inherit' sx={{ mx: 2 }} onClick={() => history.push('/earnings-news')}>Cancel</Button>
                                <Button size='small' variant='contained' color='success' onClick={handleSaveEarningsNews}>Save</Button>
                            </Box>
                        </Box >
                        <Box overflow='auto' mt={0.5} p={1} border={1} borderColor={colors.teal[300]} borderRadius={1} boxShadow={2}>
                            <h5 style={{ margin: 1, padding: 1, color: colors.blue[800] }}>News Title (email Subject):</h5>
                            {/* TITLE SECTION */}
                            <Typography variant='h6' my={1} fontWeight='bold'>
                                <span style={{ color: colors.pink[800] }}>{companyName || "xxxxxxxxxx"}</span>{ticker ? <strong style={{ color: colors.pink[800] }}>&nbsp;[{ticker}]</strong> : null}
                                &nbsp;reports <span style={{ color: colors.pink[800] }}>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', prefix: '$' }).format(netIncome) || "xxxxxxxxxx"}{netIncUnits ? ` ${netIncUnits}` : null}&nbsp;
                                    <span style={{ color: colors.pink[800] }}>{earningQtr || "xxxxxxxxxx"}</span>&nbsp;
                                    <span style={{ color: colors.pink[800] }}>{profitLoss || "xxxxxxxxxx"}</span>
                                </span>
                            </Typography>
                            {/* SUMMARY SECTION */}
                            <h5 style={{ margin: 1, padding: 1, color: colors.blue[800] }}>News Summary:</h5>
                            <Typography my={1}>
                                The company generated&nbsp;<span style={{ color: colors.pink[800] }}>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', prefix: '$' }).format(netIncome) || "xxxxxxxxxx"}{netIncUnits ? ` ${netIncUnits}` : null}&nbsp;revenue this quarter</span>
                            </Typography>
                            {/* BODY SECTION */}
                            <h5 style={{ margin: 1, padding: 1, color: colors.blue[800] }}>News Description:</h5>
                            <Typography mb={2}>
                                <a href={'https://candorium.com/company/' + ticker} target='_blank' style={{ textDecoration: 'underline !important', color: 'dodgerblue', fontWeight: 600 }}>
                                    <span style={{ color: colors.pink[800] }}>{companyName || "xxxxxxxxxx"}</span>
                                    &nbsp;
                                    {ticker ? <strong style={{ color: colors.pink[800] }}>&nbsp;[{ticker}]</strong> : null}
                                </a>
                                &nbsp;has reported fiscal&nbsp;
                                <span style={{ color: colors.pink[800] }}>{earningQtr || "xxxxxxxxxx"} {profitLoss || "xxxxxxxxxx"}</span>
                                &nbsp;of&nbsp;
                                <span style={{ color: colors.pink[800] }}>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', prefix: '$' }).format(netIncome) || "xxxxxxxxxx"}{netIncUnits ? ` ${netIncUnits}` : null}.</span>
                            </Typography>
                            <Typography mb={2}>
                                On a per-share basis, the&nbsp;
                                <span style={{ color: colors.pink[800] }}>{stateOfIncorp || "xxxxxxxxxx"}</span>
                                -based company said its diluted earnings are&nbsp;
                                <span style={{ color: colors.pink[800] }}>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', prefix: '$', maximumFractionDigits: 2 }).format(earningPerShare) || "xxxxxxxxxx"}.</span>
                            </Typography>
                            <Typography mb={2}>
                                The&nbsp;
                                <span style={{ color: colors.pink[800] }}>{industryName || "xxxxxxxxxx"}</span>
                                &nbsp;firm posted revenue of&nbsp;
                                <span style={{ color: colors.pink[800] }}>${revenueAmount || "xxxxxxxxxx"}{revenueUnits ? ` ${revenueUnits}` : null}</span>
                                &nbsp;this period.
                            </Typography>

                            {/* Also Read Section */}
                            <h4 style={{ margin: 1, marginTop: 3, padding: 1, color: colors.pink[800] }}>Also Read:</h4>
                            {relatedNews.length > 0 && relatedNews.map(news =>
                                <ul><li><a href={news.url} target='_blank'>{news.title}</a></li></ul>
                            )}

                            {/* DISCLAIMER SECTION */}
                            <div style={{ width: '30%', borderBottom: '1px dashed #333' }}>&nbsp;</div>
                            <Typography variant='caption' MT={3} mb={2}>
                                <strong>NewsInMinutes</strong> is an offering of 10kInfo, Inc., bringing you news on the latest regulatory filings. You may <a href="/subscriptions">subscribe</a> to news alerts here.
                                <br />Although all care is taken to ensure the accuracy of the data, the article does not intend to replace user diligence while taking decisions of financial or legal implications.
                            </Typography>
                        </Box>
                    </Box >
                </Box >
            </>}
        </>
    )
};

export default EarningsNews;
