import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import { Redirect } from 'react-router';

import { DataProvider } from "./store/StoredData"

import Home from './views/Home';
import LoginComponent from "./components/login.component";
import ViewArticle from './views/ViewArticle';
import EditArticle from './views/EditArticle';
import AuthService from "./services/auth.service";
import ManageCategories from './views/ManageCategories';
import AddArticle from './views/AddArticle';
import RealtimePeople from './views/RealtimePeople';
import EditPerson from './views/EditPerson';
import ViewPerson from './views/ViewPerson';
import EditSummary from './views/EditSummary';
import NewsSummary from './views/NewsSummary';
import OwnershipNews from './views/OwnershipNews';
import EarningsNews from './views/EarningsNews';
import OwnerShipTable from './views/OwnerShipTable';
import EarningTable from './views/EarningTable';
import CategoryArticleTable from './views/CategoryArticleTable';
import Dashboard_NEW from './views/Dashboard_NEW';
import Comments from './views/Comment';
import CompanyVariance from './views/CompanyVariance'
import LayoffTable from './views/LayoffTable';
import LayoffNews from './views/LayoffNews';
import EditArticleMetaTags from './components/EditArticleMetaTags'

import './App.css';
import GuardrailsForStage from './views/GuardrailsForStage';
import ManageSTAGEdatasets from './views/ManageSTAGEdatasets';
import ChatBotPromptEditor from './views/ChatBotPromptEditor';

function App(props) {

  const [currentUser, setCurrentUser] = useState(localStorage.getItem('user'));
  const [session, setSession] = useState(true);

  useEffect(async () => {
    setCurrentUser(await AuthService.getCurrentUser());
  }, []);


  return (
    <div className="App">
      {/* {console.log('APP JS Current User - ', currentUser)} */}
      <DataProvider>
        <BrowserRouter>
          <Switch>
            <Route exact path={["", "/"]} render={() => (
              currentUser
                ? <Redirect {...props} session={session} setSession={setSession} currentUser={currentUser} to={"/home"} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/home"} render={() => (
              currentUser
                ? <Home currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/realtime-people"} render={() => (
              currentUser
                ? <RealtimePeople currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/news-summary"} render={() => (
              currentUser
                ? <NewsSummary currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/earnings-news"} render={() => (
              currentUser
                ? <EarningTable currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/earnings-news/:id?"} render={() => (
              currentUser
                ? <EarningsNews currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/ownership-news"} render={() => (
              currentUser
                ? <OwnerShipTable currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/dashboard"} render={() => (
              currentUser
                ? <Dashboard_NEW currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/comments"} render={() => (
              currentUser
                ? <Comments currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/card-variance-report"} render={() => (
              currentUser
                ? <CompanyVariance currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/company-variance"} render={() => (
              currentUser
                ? <CompanyVariance currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/dashboard/:catid/:page"} render={() => (
              currentUser
                ? <CategoryArticleTable currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/ownership-news/:id?"} render={() => (
              currentUser
                ? <OwnershipNews currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/earnings-news"} render={() => (
              currentUser
                ? <EarningTable currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/earnings-news/:id?"} render={() => (
              currentUser
                ? <EarningsNews currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/layoff"} render={() => (
              currentUser
                ? <LayoffTable currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/layoff/:id?"} render={() => (
              currentUser
                ? <LayoffNews currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/news-summary/edit/:id"} render={() => (
              currentUser
                ? <EditSummary currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/people/edit/:id"} render={() => (
              currentUser
                ? <EditPerson currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/people/view/:id"} render={() => (
              currentUser
                ? <ViewPerson currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/articles/view/:id"} render={() => (
              currentUser
                ? <ViewArticle currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/articles/add"} render={() => (
              currentUser
                ? <AddArticle currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/articles/edit/:id"} render={() => (
              currentUser
                ? <EditArticle currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/articles/edit/meta/:id"} render={() => (
              currentUser
                ? <EditArticleMetaTags currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/manageCategories"} render={() => (
              currentUser
                ? <ManageCategories currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/guardrails-for-stage"} render={() => (
              currentUser
                ? <GuardrailsForStage currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/manage-stage-datasets"} render={() => (
              currentUser
                ? <ManageSTAGEdatasets currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/chatbot-prompt"} render={() => (
              currentUser
                ? <ChatBotPromptEditor currentUser={currentUser} />
                : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/login"} render={() => (
              currentUser
                ? <Redirect to={"/"} />
                : <LoginComponent {...props} setCurrentUser={setCurrentUser} session={session} setSession={setSession} />
            )}
            />
          </Switch>
        </BrowserRouter>
      </DataProvider>
    </div>
  );
}

export default App;
