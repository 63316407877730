import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import axios from 'axios'
import { format } from 'date-fns'
import { Box, Button, CircularProgress, colors, IconButton, Typography } from '@mui/material'
import { RemoveRedEyeSharp, EditSharp, StarSharp, StarOutlineSharp } from '@mui/icons-material'

import InfiniteScroll from 'react-infinite-scroll-component';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructionsSharp';
import SortIcon from '@mui/icons-material/Sort';

import { useStore } from '../store/StoredData'

const Articles = () => {
    const history = useHistory();
    const { currentUser, setCurrentUser,
        initDateRange, headlineText, keywordText, companyTicker, macroIndicatorName, newsFeatured,
        hasMore, setHasMore,
        currentPage, setCurrentPage,
        pageSize, setPageSize,
        articlesList, setArticlesList,
        setArticleIndex, setArticlesSize,
        searchOn, setSearchOn,
        articleSourceName, setArticleSourceName
    } = useStore();

    const [loading, setLoading] = useState(false);

    const [openHeadlineSort, setOpenHeadlineSort] = useState(false)
    const [openVersionSort, setOpenVersionSort] = useState(false)
    const [openPublishedonSort, setOpenPublishedonSort] = useState(false)
    const [openSourceSort, setOpenSourceSort] = useState(false)

    const [selectedHeadlineSort, setSelectedHeadlineSort] = useState(null)
    const [selectedVersionSort, setSelectedVersionSort] = useState(null)
    const [selectedPublishedSort, setSelectedPublishedSort] = useState("DESC")
    const [selectedSourceSort, setSelectedSourceSort] = useState("DESC")

    const [selectedSortColumn, setSelectedSortColumn] = useState('gmt_logtime')
    const [selectedSortValue, setSelectedSortValue] = useState("DESC")

    const [sortState, setSortState] = useState({
        sort_col: "GMT_LOGTIME",
        sort_val: "DESC"
    });

    const handleSortState = (sName, sVal) => {
        setSortState(prev => ({ ...prev, sort_col: sName, sort_val: sVal }))
    }

    const fetchMoreArticles = async () => {
        const resp = await axios.get("/api/v3/articles", {
            params: {
                startdate: format(initDateRange[0], "yyyy-MM-dd"),
                enddate: format(initDateRange[1], "yyyy-MM-dd"),
                headlineText,
                keywordText,
                companyTicker,
                macroIndicatorName,
                articleSourceName,
                page: currentPage,
                sort_col: sortState.sort_col,
                sort_val: sortState.sort_val
            }
        })
        console.log("fetchArticlesData :::", resp.data.message)
        const { count, rows } = resp.data.message;

        if (count < pageSize) {
            console.log('Looks Like No More Rows From Server...., Stopping On Page - ', currentPage)
            setHasMore(false);
        } else {
            setHasMore(true);
            console.log('*** Looks Like More Rows In Server...., Increasing the Page Count from = ', currentPage)
            setCurrentPage(currentPage => currentPage + 1);
        }
        return rows;
    };

    const fetchMoreData = async () => {
        // setLoading(true);
        console.log('Trying To Fetch More Data......... for page - ', currentPage)
        const moreRows = await fetchMoreArticles();
        console.log('fetchMoreArticles() LEN = ', moreRows.length, moreRows)
        setArticlesList(prev => [...prev, ...moreRows]);
        // setLoading(false);
    }

    const toggleFeaturedNews = (isFeatured, articleId) => {
        axios.post(`/api/articles/is-featured/${articleId}`, { is_featured: isFeatured })
            .then(resp => {
                setArticlesList(prevList => prevList.map(art => art.id === resp.data.message.id ? ({ ...art, is_featured: resp.data.message.is_featured }) : art))
                alert(`Featured News Status Updated Successfully To - ${resp.data.message.is_featured}`);
            })
            .catch(error => alert(error.response.data))
    };

    useEffect(() => {
        const fetchArticles = async () => {
            setArticlesList([]);
            setLoading(true);
            setCurrentPage(0);
            console.log('Calling Articles API For PARAMS ::: ', currentPage, initDateRange, headlineText, keywordText, companyTicker, macroIndicatorName);

            await axios.get("/api/v3/articles", {
                params: {
                    startdate: format(initDateRange[0], "yyyy-MM-dd"),
                    enddate: format(initDateRange[1], "yyyy-MM-dd"),
                    headlineText,
                    keywordText,
                    companyTicker,
                    macroIndicatorName,
                    articleSourceName,
                    page: 0,
                    is_featured: newsFeatured,
                    sort_col: "gmt_logtime",
                    sort_val: "DESC"
                }
            }).then(async resp => {
                let { count, rows } = resp.data.message;
                console.log('RECVD FROM API Articles ::: ', count, rows);
                if (count) {
                    setArticlesList(rows);
                    setArticleIndex(0);
                    setArticlesSize(count);

                    /* MANAGE NEXT PAGE CALL OR NOT */
                    if (count < pageSize) {
                        console.log('Looks Like No More Rows From Server...., Stopping On Page - ', currentPage)
                        setHasMore(false);
                    } else {
                        setHasMore(true);
                        console.log('*** Looks Like More Rows In Server...., Increasing the Page Count from = ', currentPage)
                        setCurrentPage(1);
                    }
                } else {
                    setArticlesList([]);
                    setArticleIndex(0);
                    setArticlesSize(0);
                }
            }).catch(console.error);
        };

        if (initDateRange[1] >= initDateRange[0] && searchOn) {
            fetchArticles().then(() => setLoading(false), setSearchOn(false));
        }
    }, [initDateRange, searchOn])


    const fetchSortedData = (sortColumn, sortValue) => {
        console.log("Current Sort State :::", sortColumn, sortValue);
        setLoading(true);
        setArticlesList([])
        // title_sort, item_version_sort, gmt_logtime
        axios.get("/api/v3/articles", {
            params: {
                startdate: format(initDateRange[0], "yyyy-MM-dd"),
                enddate: format(initDateRange[1], "yyyy-MM-dd"),
                headlineText,
                keywordText,
                companyTicker,
                macroIndicatorName,
                articleSourceName,
                page: 0,
                is_featured: newsFeatured,
                sort_col: sortColumn,
                sort_val: sortValue
            }
        }).then(resp => {
            if (resp.data.sucess && resp.data.message.rows.length)
                setArticlesList(resp.data.message.rows)
        }).catch(error => {
            console.error('/api/v3/articles ERROR ', error);
        }).finally(() => setLoading(false));
    }

    return (
        <>
            {loading
                ? <Box display="flex" justifyContent="center" alignItems="center" margin="15%"><CircularProgress /></Box>
                : <>
                    {articlesList.length > 0
                        ? <Box sx={{ listStyle: 'none', textAlign: 'center', border: '1px solid #CCC' }}>
                            {/* TABLE HEADER SECTION */}
                            <Box display='flex' justifyContent='flex-start' alignContent='center' alignItems='center' mx={1} px={1} py={0.5} my={0.5} fontSize={12} bgcolor={colors.blueGrey[100]}>
                                <Box width='10%' fontWeight={700} textAlign="center">Action</Box>
                                <Box width='40%' fontWeight={700} textAlign="center" display='flex' alignItems='center' position='relative'>
                                    <span title='sort' style={{ cursor: 'pointer' }}>
                                        <SortIcon fontSize='small' color='action' onClick={() => setOpenHeadlineSort(!openHeadlineSort)} />
                                    </span>
                                    <span>&nbsp;Article Headline</span>
                                    {openHeadlineSort &&
                                        <Box position='absolute' width='120px' height='max-content' bgcolor='#fff' border={1} borderRadius={1} borderColor="#e0e0e0" zIndex={9} top={20} left={0} textAlign='left'>
                                            <Button size='small' sx={{ textTransform: 'capitalize' }} p={0.5} fullWidth disabled={selectedHeadlineSort === "ASC"} onClick={(event) => {
                                                setSelectedHeadlineSort("ASC");
                                                fetchSortedData("title", "ASC");
                                                setSelectedVersionSort(null);
                                                setSelectedPublishedSort(null);
                                                setSelectedSourceSort(null);
                                                handleSortState('TITLE', 'ASC')
                                                setOpenHeadlineSort(false)
                                            }}>a-Z</Button>
                                            <Button size='small' sx={{ textTransform: 'capitalize' }} p={0.5} fullWidth disabled={selectedHeadlineSort === "DESC"} onClick={(event) => {
                                                setSelectedHeadlineSort("DESC");
                                                fetchSortedData("title", "DESC");
                                                setSelectedVersionSort(null);
                                                setSelectedPublishedSort(null);
                                                setSelectedSourceSort(null);
                                                handleSortState('TITLE', 'DESC')
                                                setOpenHeadlineSort(false)
                                            }}>Z-a</Button>
                                        </Box>
                                    }
                                </Box>
                                <Box width='10%' fontWeight={700} textAlign="center" display='flex' alignItems='center' justifyContent='center' >Featured<br />News</Box>
                                <Box width='10%' fontWeight={700} textAlign="center" display='flex' alignItems='center' position='relative' >
                                    <span title='sort' style={{ cursor: 'pointer' }}>
                                        <SortIcon fontSize='small' color='action' onClick={() => setOpenSourceSort(!openHeadlineSort)} />
                                    </span>
                                    <span>&nbsp;Article Source</span>
                                    {openSourceSort &&
                                        <Box position='absolute' width='120px' height='max-content' bgcolor='#fff' border={1} borderRadius={1} borderColor="#e0e0e0" zIndex={9} top={20} left={0} textAlign='left'>
                                            <Button size='small' sx={{ textTransform: 'capitalize' }} p={0.5} fullWidth disabled={selectedSourceSort === "ASC"} onClick={(event) => {
                                                setSelectedSourceSort("ASC");
                                                fetchSortedData("news_type", "ASC");
                                                setSelectedVersionSort(null);
                                                setSelectedPublishedSort(null);
                                                setSelectedHeadlineSort(null);
                                                handleSortState('news_type', 'ASC')
                                                setOpenSourceSort(false)
                                            }}>a-Z</Button>
                                            <Button size='small' sx={{ textTransform: 'capitalize' }} p={0.5} fullWidth disabled={selectedSourceSort === "DESC"} onClick={(event) => {
                                                setSelectedSourceSort("DESC");
                                                fetchSortedData("news_type", "DESC");
                                                setSelectedVersionSort(null);
                                                setSelectedPublishedSort(null);
                                                setSelectedHeadlineSort(null);
                                                handleSortState('news_type', 'DESC')
                                                setOpenSourceSort(false)
                                            }}>Z-a</Button>
                                        </Box>
                                    }
                                </Box>
                                <Box width='10%' fontWeight={700} textAlign="center" display='flex' alignItems='center' justifyContent='center' position='relative'>
                                    <span title='sort' style={{ cursor: 'pointer' }}>
                                        <SortIcon fontSize='small' color='action' onClick={() => setOpenVersionSort(!openVersionSort)} />
                                    </span>
                                    <span>&nbsp;Article<br />&nbsp;Version</span>
                                    {openVersionSort &&
                                        <Box position='absolute' width='120px' height='max-content' bgcolor='#fff' border={1} borderRadius={1} borderColor="#e0e0e0" zIndex={9} top={20} left={0} textAlign='left'>
                                            <Button size='small' sx={{ textTransform: 'capitalize' }} p={0.5} fullWidth disabled={selectedVersionSort === "ASC"} onClick={() => {
                                                setSelectedVersionSort("ASC");
                                                fetchSortedData("ITEM_VERSION", "ASC");
                                                setSelectedHeadlineSort(null);
                                                setSelectedPublishedSort(null);
                                                setSelectedSourceSort(null);
                                                handleSortState("ITEM_VERSION", "ASC")
                                                setOpenVersionSort(false)
                                            }}>Ascending</Button>
                                            <Button size='small' sx={{ textTransform: 'capitalize' }} p={0.5} fullWidth disabled={selectedVersionSort === "DESC"} onClick={() => {
                                                setSelectedVersionSort("DESC");
                                                fetchSortedData("ITEM_VERSION", "DESC");
                                                setSelectedHeadlineSort(null);
                                                setSelectedPublishedSort(null);
                                                setSelectedSourceSort(null);
                                                handleSortState("ITEM_VERSION", "DESC")
                                                setOpenVersionSort(false)
                                            }}>Descending</Button>
                                        </Box>
                                    }
                                </Box>
                                <Box width='10%' fontWeight={700} textAlign="center" display='flex' alignItems='center'>Article ID</Box>
                                <Box width='10%' fontWeight={700} textAlign="center" display='flex' alignItems='center' position='relative'>
                                    <span title='sort' style={{ cursor: 'pointer' }}>
                                        <SortIcon fontSize='small' color='action' onClick={() => setOpenPublishedonSort(!openPublishedonSort)} />
                                    </span><span>&nbsp;Published On</span>
                                    {openPublishedonSort &&
                                        <Box position='absolute' width='120px' height='max-content' bgcolor='#fff' border={1} borderRadius={1} borderColor="#e0e0e0" zIndex={9} top={20} left={0} textAlign='left'>
                                            <Button size='small' sx={{ textTransform: 'capitalize' }} p={0.5} fullWidth disabled={selectedPublishedSort === "ASC"} onClick={() => {
                                                setSelectedPublishedSort("ASC");
                                                fetchSortedData("gmt_logtime", "ASC");
                                                setSelectedHeadlineSort(null);
                                                setSelectedVersionSort(null);
                                                setSelectedSourceSort(null);
                                                handleSortState("gmt_logtime", "ASC")
                                                setOpenPublishedonSort(false)
                                            }}>Ascending</Button>
                                            <Button size='small' sx={{ textTransform: 'capitalize' }} p={0.5} fullWidth disabled={selectedPublishedSort === "DESC"} onClick={() => {
                                                setSelectedPublishedSort("DESC");
                                                fetchSortedData("gmt_logtime", "DESC");
                                                setSelectedHeadlineSort(null);
                                                setSelectedVersionSort(null);
                                                setSelectedSourceSort(null);
                                                handleSortState("gmt_logtime", "DESC")
                                                setOpenPublishedonSort(false)
                                            }}>Descending</Button>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                            {/* TABLE DATA SECTION */}
                            <Box id="scrollableDiv" height={420} overflow="auto" px={1}>
                                <InfiniteScroll
                                    dataLength={articlesList.length}
                                    next={fetchMoreData}
                                    hasMore={hasMore}
                                    loader={<Box display='flex' justifyContent='center' my={2}><CircularProgress /></Box>}
                                    scrollableTarget="scrollableDiv"
                                    endMessage={
                                        <p style={{ textAlign: 'center', fontSize: 16, fontWeight: 400, color: colors.blue[600] }}>
                                            Yay! You have seen it all
                                        </p>
                                    }
                                >
                                    {articlesList?.map((article, idx) =>
                                        <Box key={idx} display="flex" justifyContent="flex-start" alignContent="center" alignItems="center"
                                            my={0.5} border={1} borderColor="#CCC" fontSize={12}>
                                            <Box width='10%' display="flex" justifyContent="flex-start">
                                                <Box
                                                    onClick={() => { history.push(`/articles/view/${article.id}`); setArticleIndex(idx) }}
                                                    px={1}
                                                    style={{ cursor: 'pointer', textDecoration: 'none' }}
                                                    role="button"
                                                    title='View Article (Read Only)'>
                                                    <RemoveRedEyeSharp color='primary' fontSize="small" />
                                                </Box>
                                                {(currentUser.id !== 2023032 && currentUser.id !== 2023033) &&
                                                    <><Box
                                                        onClick={() => { history.push(`/articles/edit/${article.id}`); setArticleIndex(idx) }}
                                                        px={1}
                                                        style={{ cursor: 'pointer', textDecoration: 'none' }}
                                                        role="button"
                                                        title='Edit Article'>
                                                        <EditSharp color='secondary' fontSize="small" />
                                                    </Box>
                                                        <Box
                                                            onClick={() => history.push(`/news-summary/edit/${article.item_id}`)}
                                                            px={1}
                                                            style={{ cursor: 'pointer', textDecoration: 'none' }}
                                                            role="button"
                                                            title='Article Summary'>
                                                            <BorderColorIcon color='error' fontSize='small' />
                                                        </Box>
                                                    </>}
                                                <Box
                                                    onClick={() => { history.push(`/articles/edit/meta/${article.id}`); setArticleIndex(idx) }}
                                                    px={1}
                                                    style={{ cursor: 'pointer', textDecoration: 'none' }}
                                                    role="button"
                                                    title='Edit Article Meta Tags'>
                                                    <IntegrationInstructionsIcon color='secondary' fontSize="small" />
                                                </Box>
                                            </Box>
                                            <Box width='40%' textAlign="left" display='flex' alignItems='center'>
                                                <span style={{ margin: "5px", border: '1px solid #CCC', borderRadius: '5px' }}>
                                                    <img src={article.image_url} height='auto' width="50px" />
                                                </span>
                                                <span>{article.title}</span>
                                            </Box>
                                            <Box width='10%' textAlign="center">
                                                {article?.is_featured
                                                    ? <IconButton onClick={() => toggleFeaturedNews(!article?.is_featured, article?.id)} title="Click To Remove From Featured News"><StarSharp color="primary" /></IconButton>
                                                    : <IconButton onClick={() => toggleFeaturedNews(!article?.is_featured, article?.id)} title="Click To Mark As Featured News"><StarOutlineSharp color="action" /></IconButton>
                                                }
                                            </Box>
                                            <Box width='10%' textAlign="center" sx={{ color: 'blueviolet' }}>{article.news_type}</Box>
                                            <Box width='10%' textAlign="center">{article?.item_version?.toString().padStart(2, '0') || null}</Box>
                                            <Box width='10%' textAlign="center">{article.id}</Box>
                                            <Box width='10%' textAlign="center">{format(new Date(article.gmt_logtime), "MMM dd, yy hh:mm:ss a")}</Box>
                                        </Box>
                                    )}
                                </InfiniteScroll>
                            </Box>
                        </Box>
                        : <Box width="100%" textAlign="center" my={5}>
                            <Typography variant='body1' color={colors.deepOrange[300]}>No Data To Display For Selected Settings</Typography>
                        </Box>
                    }
                </>}
        </>
    )
}

export default Articles