import { Box, Chip, colors, Typography } from '@mui/material'
import React from 'react'

const ArticleKeywords = (props) => {
    return (
        // backgroundColor: colors.blue[50], boxShadow: '1px 2px 2px #DDD'
        <><Box border={1} borderColor={colors.blue[200]} borderRadius={1} my={1} mx="auto" p={1}>
            <Typography variant='h6' color={colors.deepOrange[800]}>Article's Associated AP Keywords:</Typography>
            <Box display="flex" justifyContent="flex-start" flexWrap="wrap">
                {props.itemKeywords && props.itemKeywords.length
                    ? props.itemKeywords.map((keyword, idx) =>
                        <Chip id={idx} key={idx} label={keyword} sx={{ margin: 0.25, color: colors.deepPurple[500] }} size="small" variant='outlined' />
                    )
                    : <Box width="100%" textAlign="center" mx={3} color={colors.deepOrange[700]}>No Meta Keywords Associated!</Box>}
            </Box>
        </Box>
            <Box border={1} borderColor={colors.blue[200]} borderRadius={1} my={1} mx="auto" p={1}>
                <Typography variant='h6' color={colors.deepOrange[800]}>Article's Associated Mined Meta Keywords:</Typography>
                <Box display="flex" justifyContent="flex-start" flexWrap="wrap">
                    {props.itemMetaKeywords && props.itemMetaKeywords.length > 0
                        ? props.itemMetaKeywords.map((keyword, idx) =>
                            <Chip id={idx} key={idx} label={keyword} sx={{ margin: 0.25, color: colors.deepPurple[500] }} size="small" variant='outlined' />
                        )
                        : <Box width="100%" textAlign="center" mx={3} color={colors.deepOrange[700]}>No Mined Meta Keywords Associated!</Box>}
                </Box>
            </Box>
        </>
    )
}

export default ArticleKeywords