import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Tabs from '../components/Tabs'
import { Box, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, Select, Switch, TextField, Typography, colors } from '@mui/material'
import ReactDatePicker from 'react-datepicker'
import { format, startOfMonth, endOfMonth } from 'date-fns'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroll-component'
import Chart from "react-apexcharts";

const GuardrailsForStage = () => {

    const [perDiff, setPerDiff] = useState(100)
    const [lineTypeList, setLineTypeList] = useState(["TEXP", "SGA", "COGS", "TREV", "NETINC", "GM"])
    const [selectedLineType, setSelectedLineTYpe] = useState("TREV")
    const [initDateRange, setInitDateRange] = useState([startOfMonth(new Date()), endOfMonth(new Date())])
    const [top500Enabled, setTop500Enabled] = useState(false)
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(true)
    const [series, setSeries] = useState([])

    useEffect(() => {
        if (initDateRange[1] >= initDateRange[0])
            fetchTableData()
    }, [selectedLineType, top500Enabled, initDateRange[1]])


    const fetchTableData = async () => {
        setLoading(true)
        await axios.get(`/api/guard-rails/?startdate=${format(new Date(initDateRange[0]), "yyyy-MM-dd")}&enddate=${format(new Date(initDateRange[1]), "yyyy-MM-dd")}&linetype=${selectedLineType}&isTop500=${top500Enabled}`)
            .then((resp) => {
                console.log(resp.data)
                setTableData(resp.data.message)
                const greaterVal = resp.data.message.filter(item => parseFloat(item.percentage_diff) > parseFloat(perDiff))
                const lowerVal = resp.data.message.filter(item => parseFloat(item.percentage_diff) < -(parseFloat(perDiff)))
                const betweenVal = resp.data.message.filter(item => parseFloat(item.percentage_diff) >= -(parseFloat(perDiff)) && parseFloat(item.percentage_diff) <= parseFloat(perDiff))
                setSeries([greaterVal.length, lowerVal.length, betweenVal.length])
                setLoading(false)
            })
            .catch(err => console.error("Error While fetching Table Data"))
    }



    const handlePercentageDiff = (e) => {
        
        setPerDiff(e.target.value)
        const greaterVal = tableData.filter(item => parseFloat(item.percentage_diff) > parseFloat(e.target.value))
        const lowerVal = tableData.filter(item => parseFloat(item.percentage_diff) < -(parseFloat(e.target.value)))
        const betweenVal = tableData.filter(item => parseFloat(item.percentage_diff) >= -(parseFloat(e.target.value)) && parseFloat(item.percentage_diff) <= parseFloat(e.target.value))

        setSeries([greaterVal.length, lowerVal.length, betweenVal.length])
        console.log(greaterVal.length, lowerVal.length, betweenVal.length)
    }

    return (
        <div>
            <Header />
            <Tabs />
            <Box>
                {/* Header  */}
                <Box boxShadow={1} border={1} borderColor={colors.blueGrey[100]} display="flex" flexDirection="row" justifyContent="space-evenly" alignContent="center" alignItems="center" >
                    <h3>Guardrail Report</h3>

                    <FormControl>
                        <TextField type='number' variant='standard' value={perDiff} onChange={handlePercentageDiff} style={{ width: "100px", margin: "0 20px" }} />
                        <FormHelperText>% difference</FormHelperText>
                    </FormControl>

                    <FormControl>
                        <Select
                            native
                            variant='standard'
                            value={selectedLineType}
                            onChange={(e) => setSelectedLineTYpe(e.target.value)}
                        >
                            {lineTypeList.map((line) => <option value={line}>{line}</option>)}
                        </Select>
                        <FormHelperText>Selected Line Type</FormHelperText>
                    </FormControl>

                    <Box mx={2}>
                        <ReactDatePicker
                            id="ReactDatePicker"
                            className='ReactDatePicker'
                            dateFormat="MMM dd, yyyy"
                            selectsRange={true}
                            startDate={initDateRange[0]}
                            endDate={initDateRange[1]}
                            // maxDate={new Date()}
                            onChange={update => setInitDateRange(update)}
                        />
                    </Box>
                    <Box width="15%" >
                        <FormControlLabel
                            label={<Typography variant="body2" fontWeight={600} color={colors.deepOrange[800]}>&nbsp;Top 500</Typography>}
                            sx={{ margin: 'auto 0' }}
                            control={
                                <Checkbox
                                    checked={top500Enabled}
                                    sx={{ paddingRight: 0 }}
                                    onChange={() => setTop500Enabled(!top500Enabled)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />}
                        />
                    </Box>
                </Box>

                {loading ? <Box display="flex" justifyContent="center" alignItems="center" margin="15%"><CircularProgress /></Box> :
                    <>
                        {tableData.length > 0 ?
                            <Box display='flex' sx={{ listStyle: 'none', textAlign: 'center', border: '1px solid #CCC' }}>
                                <Box width='68%'>
                                    {/* TABLE HEADER  */}
                                    <Box display='flex' justifyContent='flex-start' alignContent='center' alignItems='center' mx={1} px={1} py={0.5} my={0.5} fontSize={12} bgcolor={colors.blueGrey[100]}>
                                        <Box width='10%' fontWeight={700} textAlign="center">OMP ID</Box>
                                        <Box width='10%' fontWeight={700} textAlign="center">CIK</Box>
                                        <Box width='20%' fontWeight={700} textAlign="left">Company Name</Box>
                                        <Box width='10%' fontWeight={700} textAlign="center">Date Filed</Box>
                                        <Box width='10%' fontWeight={700} textAlign="center">LineType</Box>
                                        <Box width='10%' fontWeight={700} textAlign="right">Current Year</Box>
                                        <Box width='10%' fontWeight={700} textAlign="right">Prev Year</Box>
                                        <Box width='10%' fontWeight={700} textAlign="center">Units</Box>
                                        <Box width='10%' fontWeight={700} textAlign="center">Percent Diff</Box>
                                    </Box>

                                    {/* TABLE ROW DATA  */}
                                    <Box id="scrollableDiv" height={500} overflow="auto" px={1}>
                                        <InfiniteScroll
                                            dataLength={tableData.length}
                                            // next={fetchMoreData}
                                            // hasMore={hasMore}
                                            loader={<Box display='flex' justifyContent='center' my={2}><CircularProgress /></Box>}
                                            scrollableTarget="scrollableDiv"
                                        >
                                            {tableData?.map((data, idx) =>
                                                <Box key={idx} display="flex" justifyContent="flex-start" alignContent="center" alignItems="center"
                                                    my={0.5} border={1} borderColor="#CCC" fontSize={12} py={1}>
                                                    <Box width='10%' textAlign="center" >{data?.omp || ''}</Box>
                                                    <Box width='10%' textAlign="center">{data?.cik || ''}</Box>
                                                    <Box width='20%' textAlign="left">{data.company_name || ''}</Box>
                                                    <Box width='10%' textAlign="center">{format(new Date(data.date_filed), "yyyy-MM-dd") || ''}</Box>
                                                    <Box width='10%' textAlign="center">{data?.linetype || ''}</Box>
                                                    <Box width='10%' textAlign="right">
                                                        {(parseFloat(data?.current_year_value).toLocaleString('en-IN', {
                                                            style: 'decimal',
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }) || '')}
                                                    </Box>
                                                    <Box width='10%' textAlign="right">
                                                        {(parseFloat(data?.previous_year_value).toLocaleString('en-IN', {
                                                            style: 'decimal',
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }) || '')}
                                                    </Box>
                                                    <Box width='10%' textAlign="center">{data?.uints || ''}</Box>
                                                    <Box width='10%' textAlign="center" sx={{ color: data?.percentage_diff < 1 ? "red" : data?.percentage_diff > 100 ? "green" : "black" }}>{data?.percentage_diff || ''}%</Box>
                                                </Box>
                                            )}
                                        </InfiniteScroll>
                                    </Box>
                                </Box>
                                {/* CHART AREA  */}
                                <Box width='30%' maxHeight='70vh' display='flex' justifyContent='center' alignItems='center'>
                                    <Chart
                                        type="pie"
                                        width={500}
                                        series={series}
                                        options={{
                                            labels: [`Greater than  ${perDiff}%`, `Less than  -${perDiff}%`, `Between -${perDiff}% and ${perDiff}%`],
                                            legend: { show: true, position: "bottom" },
                                            responsive: [
                                                {
                                                    breakpoint: 480,
                                                    options: {
                                                        chart: { width: 200 },
                                                        legend: { position: "bottom" },
                                                    },
                                                },
                                            ],
                                        }}
                                    />
                                </Box>
                            </Box>
                            : <Box width="100%" textAlign="center" my={5}>
                                <Typography variant='body1' color={colors.deepOrange[300]}>No Data To Display For Selected Settings</Typography>
                            </Box>}
                    </>}
            </Box>
        </div >
    )
}

export default GuardrailsForStage