import { LinearProgress, Box, Modal, TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, colors } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Header from '../components/Header'
import { DeleteOutline } from '@mui/icons-material'

const ManageCategories = () => {
    // const { getCategoryData } = useStore()
    const [categoryMasterData, setCategoryMasterData] = useState([])
    const [selectCategoryID, setSelectCategoryID] = useState()
    const [selectSubCategoryID, setSelectSubCategoryID] = useState()
    const [selectSubject, setSelectSubject] = useState()

    const [openAddCatModal, setOpenAddCatModal] = useState(false);
    const [openAddSubCatModal, setOpenAddSubCatModal] = useState(false);
    const [openAddSubjectModal, setOpenAddSubjectModal] = useState(false);
    const [openEditCatModal, setOpenEditCatModal] = useState(false);
    const [openEditSubCatModal, setOpenEditSubCatModal] = useState(false);
    const [openEditSubjectModal, setOpenEditSubjectModal] = useState(false);
    const [openDeleteCatDialog, setOpenDeleteCatDialog] = useState(false);
    const [openDeleteSubCatDialog, setOpenDeleteSubCatDialog] = useState(false);
    const [openDeleteSubjectDialog, setOpenDeleteSubjectDialog] = useState(false);
    // const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpenEditCatModal(false); setOpenEditSubCatModal(false); setOpenDeleteCatDialog(false); setOpenDeleteSubCatDialog(false); setOpenAddCatModal(false); setOpenAddSubCatModal(false); setOpenAddSubjectModal(false); setOpenEditSubjectModal(false); setOpenDeleteSubjectDialog(false) };

    const [addCategoryValue, setAddCategoryValue] = useState()
    const [addSubCategoryValue, setAddSubCategoryValue] = useState()
    const [addSubjectValue, setAddSubjectValue] = useState()

    const [editCategoryValue, setEditCategoryValue] = useState()
    const [editCategoryIndexValue, setEditCategoryIndexValue] = useState()
    const [editSubCategoryValue, setEditSubCategoryValue] = useState()
    const [editSubjectValue, setEditSubjectValue] = useState()


    useEffect(() => {
        const getCategoryData = async () => {
            const resp = await axios.get("/api/categories-subcategories")
            const data = await resp.data.message
            setCategoryMasterData(await data)
        }
        getCategoryData()
    }, [])

    const handleEditCategory = (e, categoryID, categoryName) => {
        e.stopPropagation();
        e.preventDefault();
        setOpenEditCatModal(true)
        setSelectCategoryID(categoryID)
        setEditCategoryValue(categoryName)
        console.log("EDIT CATEGORY ID", categoryID)
    }

    const handleEditSubCategory = (e, subCategoryID, subCategoryName) => {
        e.stopPropagation();
        e.preventDefault();
        setOpenEditSubCatModal(true)
        setSelectSubCategoryID(subCategoryID)
        setEditSubCategoryValue(subCategoryName)
        console.log("EDIT SUB-CATEGORY ID", subCategoryID)
    }

    const handleEditSubject = (e, subject) => {
        e.stopPropagation();
        e.preventDefault();
        setOpenEditSubjectModal(true)
        setSelectSubject(subject)
        setEditSubjectValue(subject)
    }

    // const handleDeleteCategory = (e, categoryID) => {
    //     e.stopPropagation();
    //     e.preventDefault();
    //     setOpenDeleteCatDialog(true)
    //     setSelectCategoryID(categoryID)
    //     console.log("EDIT CATEGORY ID", categoryID)
    // }

    const handleDeleteSubCategory = async (e, subCategoryID) => {
        e.stopPropagation();
        e.preventDefault();
        setOpenDeleteSubCatDialog(true)
        setSelectSubCategoryID(subCategoryID)
        console.log("EDIT SUB-CATEGORY ID", subCategoryID);
        if (openDeleteSubCatDialog)
            await deleteSubCategory();
    }

    const handleDeleteSubject = (e, subject) => {
        e.stopPropagation();
        e.preventDefault();
        setSelectSubject(subject)
        setOpenDeleteSubjectDialog(true)
        console.log("Selected Subject  :::", subject)
    }

    const saveAddCategory = () => {
        axios.post("/api/categories-subcategories/category", {
            cat_name: addCategoryValue
        }).then((resp) => {
            console.log(resp);
            window.location.reload()
        }).catch((err) => console.error(err))
    }

    const saveEditCategory = (id) => {
        console.log('EDIT CAT REQ ::: ', editCategoryValue)
        axios.put(`/api/categories-subcategories/category/${id}`, {
            cat_name: editCategoryValue,
            cat_index: editCategoryIndexValue
        }).then((resp) => {
            console.log('Edit CAT RESP ::: ', resp.data.message);
            window.location.reload()
        }).catch((err) => console.error(err))
    }

    const saveVisibilityCategory = (e, id) => {
        e.preventDefault()
        e.stopPropagation()
        axios.put(`/api/categories-subcategories/category-visibility/${id}`)
            .then((resp) => {
                console.log(resp);
                window.location.reload()
            }).catch((err) => console.error(err))
    }
    // const deleteCategory = () => {
    //     axios.delete(`api/categories-subcategories/category/${}`)
    //         .then((resp) => { console.log(resp);window.location.reload()})
    //         .catch((err) => console.error(err))
    // }

    const saveAddSubCategory = () => {
        axios.post("/api/categories-subcategories/subcategory", {
            cat_id: selectCategoryID,
            subcat_name: addSubCategoryValue
        }).then((resp) => { console.log(resp); window.location.reload() })
            .catch((err) => console.error(err))
    }

    const saveEditSubCategory = async (id) => {
        console.log('EDIT SUB-CAT REQ ::: ', editSubCategoryValue)
        await axios.put(`/api/categories-subcategories/subcategory/${id}`, {
            subcat_name: editSubCategoryValue,
            thumbnail: null
        }).then((resp) => {
            console.log('EDIT SUB-CAT RESP ::: ', resp);
            window.location.reload()
        })
            .catch((err) => console.error(err))
    }

    const deleteSubCategory = async () => {
        await axios.delete(`/api/categories-subcategories/subcategory/${selectSubCategoryID}`)
            .then(resp => { alert(`Sub Category successfully Deleted!`); handleClose(); window.location.reload() })
            .catch((err) => console.error(err))
    }

    const saveVisibilitySubCategory = (e, id) => {
        e.preventDefault()
        e.stopPropagation()
        axios.put(`/api/categories-subcategories/subcategory-visibility/${id}`)
            .then((resp) => {
                console.log(resp);
                window.location.reload()
            }).catch((err) => console.error(err))
    }

    const saveAddSubject = () => {
        axios.post(`/api/categories-subcategories/subcategory/subjects/${selectSubCategoryID}`, {
            subjects: addSubjectValue
        }).then((resp) => {
            console.log(resp);
            window.location.reload()
        }).catch((err) => console.error(err))
    }

    const saveEditSubject = (id) => {
        axios.put(`/api/categories-subcategories/subcategory/subjects/${id}`, {
            subject_prev: selectSubject,
            subject_new: editSubjectValue,
        }).then((resp) => {
            console.log(resp);
            window.location.reload()
        }).catch((err) => console.error(err))
    }

    const deleteSubject = () => {
        console.log('DELETE SUBJECT ENTRY REQ ::::', selectSubject)
        axios.delete(`/api/categories-subcategories/subcategory/subjects/${selectSubCategoryID}/${selectSubject}`)
            .then((resp) => {
                console.log('DELETE SUBJECT ENTRY RESP ::::', resp.data.message);
                window.location.reload()
            }).catch((err) => console.error(err))
        console.log("Selected Subject :::", selectSubject);
    }

    // console.log("categoryMasterData :::", categoryMasterData);
    return (
        <>
            <Header />
            {/* <Box >
                <Box>
                    {categoryMasterData.length > 0 && categoryMasterData.map((cat) =>
                        <Box>
                            {cat.cat_name} {cat.cat_index}
                        </Box>
                    )}
                </Box>
            </Box> */}
            <Box width="100%" display='flex' justifyContent='center' mx={2} position='sticky' top={0} bgcolor='#fff'>
                <Box width="30%">
                    {categoryMasterData.length > 0 ?
                        <Box border={1} borderColor={colors.grey[500]} borderRadius={1} color={colors.indigo[500]} fontWeight={500} p={.5} m={.5} style={{ cursor: 'pointer' }}
                            onClick={() => setOpenAddCatModal(true)}>+ Add Category</Box> : null}
                </Box>
                <Box width="60%" >
                    <Box width="100%" display='flex'>
                        {selectCategoryID ?
                            <Box width="50%" border={1} borderColor={colors.grey[500]} borderRadius={1} color={colors.indigo[500]} fontWeight={500} p={.5} m={.5} style={{ cursor: 'pointer' }}
                                onClick={() => setOpenAddSubCatModal(true)}>+ Add Sub Category</Box> : null}
                        {selectSubCategoryID ?
                            <Box width="50%" border={1} borderColor={colors.grey[500]} borderRadius={1} color={colors.indigo[500]} fontWeight={500} p={.5} m={.5} style={{ cursor: 'pointer' }}
                                onClick={() => setOpenAddSubjectModal(true)}>+ Add Subject</Box> : null}
                    </Box>
                </Box>
            </Box>
            {categoryMasterData.length <= 0 ? <LinearProgress /> :
                categoryMasterData.map((cat) => (
                    <>
                        <Box width="100%" display='flex' justifyContent='center' mx={2}>
                            <Box width="30%">
                                <Box
                                    id="category"
                                    bgcolor={selectCategoryID === cat.cat_id ? colors.indigo[700] : "none"}
                                    color={selectCategoryID === cat.cat_id ? "#fff" : colors.indigo[700]}
                                    border={1}
                                    borderColor={colors.grey[400]}
                                    borderRadius={0.5}
                                    boxShadow={1}
                                    p={.5}
                                    m={.5}
                                    display='flex'
                                    justifyContent='space-between'
                                    alignContent={'center'}
                                    alignItems={'center'}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => { selectCategoryID === cat.cat_id ? setSelectCategoryID(null) : setSelectCategoryID(cat.cat_id) }}>
                                    <Box width="50%">{cat.cat_name}</Box>
                                    <Box width="30%" title="Tab Index">
                                        <span style={{ color: colors.orange[500], marginRight: 20 }}><span style={{ color: colors.grey[500] }}>Tab Index:&nbsp;</span>{cat.cat_index}</span>
                                    </Box>
                                    <Box width="10%" title="Edit Category" onClick={(e) => handleEditCategory(e, cat.cat_id, cat.cat_name)}>
                                        <EditIcon sx={{ color: selectCategoryID === cat.cat_id ? colors.grey[300] : colors.indigo[300] }} />&nbsp;
                                    </Box>
                                    {cat.cat_hide
                                        ? <Box width="10%" title="Click To Unhide" onClick={(e) => saveVisibilityCategory(e, cat.cat_id)}>
                                            <VisibilityOffIcon sx={{ color: selectCategoryID === cat.cat_id ? colors.grey[300] : colors.indigo[300] }} />&nbsp;
                                        </Box>
                                        : <Box width="10%" title="Click To Hide" onClick={(e) => saveVisibilityCategory(e, cat.cat_id)}>&nbsp;
                                            <VisibilityIcon sx={{ color: selectCategoryID === cat.cat_id ? colors.grey[300] : colors.indigo[300] }} />
                                        </Box>
                                    }
                                </Box>
                            </Box>
                            <Box width="60%">
                                {selectCategoryID && cat.cat_id === selectCategoryID ?
                                    cat.subcats === null ? <Box ml={5} mt={1.2} >No SubCategory Found</Box> :
                                        cat.subcats?.map((subcat) => (
                                            <>
                                                <Box width="100%" display='flex'>
                                                    <Box width='50%' maxHeight={300} style={{ overflowY: 'auto' }}>
                                                        {subcat.sub_cat_id ?
                                                            <>
                                                                <Box
                                                                    id={'subcategory'}
                                                                    bgcolor={selectSubCategoryID === subcat.sub_cat_id ? colors.indigo[700] : "none"}
                                                                    color={selectSubCategoryID === subcat.sub_cat_id ? "#fff" : colors.indigo[700]}
                                                                    border={1}
                                                                    borderColor={colors.grey[400]}
                                                                    borderRadius={0.5}
                                                                    boxShadow={1}
                                                                    p={.5}
                                                                    m={.5}
                                                                    display='flex'
                                                                    justifyContent='space-between'
                                                                    alignContent={'center'}
                                                                    alignItems={'center'}
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => { selectSubCategoryID === subcat.sub_cat_id ? setSelectSubCategoryID(null) : setSelectSubCategoryID(subcat.sub_cat_id) }}>
                                                                    <Box>{subcat.sub_cat_name}</Box>
                                                                    <Box display='flex'>
                                                                        <Box title="Edit Sub-Category" onClick={(e) => handleEditSubCategory(e, subcat.sub_cat_id, subcat.sub_cat_name)}>
                                                                            <EditIcon sx={{ color: selectCategoryID === cat.hide ? colors.grey[400] : colors.deepOrange[400] }} />&nbsp;
                                                                        </Box>
                                                                        {subcat.sub_cat_hide
                                                                            ? <Box title="Click To Unhide" onClick={(e) => saveVisibilitySubCategory(e, subcat.sub_cat_id)}>
                                                                                <VisibilityOffIcon sx={{ color: selectCategoryID === cat.hide ? colors.indigo[300] : colors.grey[400] }} />&nbsp;</Box>
                                                                            : <Box title="Click To Hide" onClick={(e) => saveVisibilitySubCategory(e, subcat.sub_cat_id)}>
                                                                                <VisibilityIcon sx={{ color: selectCategoryID === cat.hide ? colors.grey[400] : colors.indigo[300] }} />&nbsp;</Box>
                                                                        }
                                                                        <Box title="Delete Sub-Category" onClick={(e) => handleDeleteSubCategory(e, subcat.sub_cat_id)}>
                                                                            <DeleteOutline sx={{ color: colors.red[400] }} />
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </> : <Box ml={5} mt={1.2} >No SubCategory Found</Box>}
                                                    </Box>
                                                    <Box width="50%" maxHeight={300} style={{ overflowY: 'auto' }}>
                                                        {selectSubCategoryID && subcat.sub_cat_id === selectSubCategoryID ?
                                                            subcat.subject.length ? subcat.subject.map((sub, idx) => (
                                                                <>
                                                                    <Box >
                                                                        <Box p={.5} m={.5} display='flex' justifyContent='space-between' style={{ cursor: 'pointer', border: '1px solid darkblue' }}>
                                                                            <Box>{sub}</Box>
                                                                            <Box display='flex'>
                                                                                <Box title="Edit Subject" onClick={(e) => handleEditSubject(e, sub)}>
                                                                                    <EditIcon sx={{ color: colors.indigo[500] }} />
                                                                                </Box>
                                                                                <Box title="Delete Subject" onClick={(e) => handleDeleteSubject(e, sub)}>
                                                                                    <DeleteOutline sx={{ color: colors.red[500] }} />
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </>
                                                            )) : <Box ml={5} mt={1.2} > No Subject Found</Box>
                                                            : null}
                                                    </Box>
                                                </Box>
                                            </>
                                        ))
                                    : null}
                            </Box>
                        </Box>
                    </>
                )
                )}

            {/* Add Category Modal  */}
            <Modal
                open={openAddCatModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 450,
                    height: "150px",
                    bgcolor: 'background.paper',
                    // border: '2px solid #000',
                    boxShadow: 24,
                    p: 1,
                    borderRadius: 3
                }}>
                    <Box display='flex' justifyContent='center' alignItems="center" height="100%">
                        <>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '40ch', },
                                }}
                                autoComplete="off"
                            >
                                {/* <Typography variant='body1'>Edit Category</Typography> */}
                                <TextField
                                    required
                                    id="standard-required"
                                    label="Add Category"
                                    variant="standard"
                                    type="text"
                                    value={addCategoryValue}
                                    onChange={(e) => setAddCategoryValue(e.target.value)}

                                />
                                <Box display='flex' justifyContent='center'>
                                    <Button variant="outlined" color='primary' size='small' style={{ margin: "0 5px" }} onClick={saveAddCategory}>Save</Button>
                                    <Button variant="outlined" color="error" size='small' onClick={handleClose}>Cancel</Button>
                                </Box>
                            </Box>
                        </>
                    </Box>
                </Box>
            </Modal>

            {/* Add Sub-Category Modal  */}
            <Modal
                open={openAddSubCatModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    height: "200px",
                    bgcolor: 'background.paper',
                    // border: '2px solid #000',
                    boxShadow: 24,
                    p: 1,
                    borderRadius: 3
                }}>
                    <Box display='flex' justifyContent='center' alignItems="center" height="100%">
                        <>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '40ch', },
                                }}
                                autoComplete="off"
                            >
                                {/* <Typography variant='body1'>Edit Category</Typography> */}
                                <Box>

                                    <TextField
                                        // required
                                        disabled
                                        id="standard-required"
                                        label="Selected Category"
                                        variant="filled"
                                        defaultValue={categoryMasterData.filter((c) => c.cat_id === selectCategoryID).map((cat) => cat.cat_name)}
                                        type="text"
                                    />
                                </Box>
                                <TextField
                                    required
                                    id="standard-required"
                                    label="Add Sub-Category"
                                    variant="standard"
                                    // defaultValue={sub.sub_cat_name}
                                    type="text"
                                    value={addSubCategoryValue}
                                    onChange={(e) => setAddSubCategoryValue(e.target.value)}

                                />
                                <Box display='flex' justifyContent='center'>
                                    <Button variant="outlined" color='primary' size='small' style={{ margin: "0 5px" }} onClick={saveAddSubCategory}>Save</Button>
                                    <Button variant="outlined" color="error" size='small' onClick={handleClose}>Cancel</Button>
                                </Box>
                            </Box>
                        </>
                    </Box>
                </Box>
            </Modal>

            {/* Add Subject Modal  */}
            <Modal
                open={openAddSubjectModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    height: "250px",
                    bgcolor: 'background.paper',
                    // border: '2px solid #000',
                    boxShadow: 24,
                    p: 1,
                    borderRadius: 3
                }}>
                    <Box display='flex' justifyContent='center' alignItems="center" height="100%">
                        {selectCategoryID && categoryMasterData.length ? categoryMasterData.filter((c) => c.cat_id === selectCategoryID)[0]['subcats']?.filter((s) => s.sub_cat_id === selectSubCategoryID).map((sub) => (
                            <>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '40ch', },
                                    }}
                                    autoComplete="off"
                                >
                                    {/* <Typography variant='body1'>Edit Category</Typography> */}
                                    <Box>

                                        <TextField
                                            // required
                                            disabled
                                            id="standard-required"
                                            label="Selected Category"
                                            variant="filled"
                                            defaultValue={categoryMasterData.filter((c) => c.cat_id === selectCategoryID).map((cat) => cat.cat_name)}
                                            type="text"

                                        />
                                    </Box>
                                    <Box>
                                        <TextField
                                            // required
                                            disabled
                                            id="standard-required"
                                            label="Selected Sub Category"
                                            variant="filled"
                                            defaultValue={sub.sub_cat_name}
                                            type="text"

                                        />
                                    </Box>
                                    <TextField
                                        required
                                        id="standard-required"
                                        label="Add Subject"
                                        variant="standard"
                                        // defaultValue={sub.sub_cat_name}
                                        type="text"
                                        value={addSubjectValue}
                                        onChange={(e) => setAddSubjectValue(e.target.value)}

                                    />
                                    <Box display='flex' justifyContent='center'>
                                        <Button variant="outlined" color='primary' size='small' style={{ margin: "0 5px" }} onClick={saveAddSubject}>Save</Button>
                                        <Button variant="outlined" color="error" size='small' onClick={handleClose}>Cancel</Button>
                                    </Box>
                                </Box>
                            </>)) : null}
                    </Box>
                </Box>
            </Modal>

            {/* Edit Category Modal  */}
            <Modal
                open={openEditCatModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 450,
                    height: "200px",
                    bgcolor: 'background.paper',
                    // border: '2px solid #000',
                    boxShadow: 24,
                    p: 1,
                    borderRadius: 3
                }}>
                    <Box display='flex' justifyContent='center' alignItems="center" height="100%">
                        {categoryMasterData.length ? categoryMasterData.filter((c) => c.cat_id === selectCategoryID).map((cat) => (
                            <>
                                <Box
                                    component="form"
                                    sx={{ '& .MuiTextField-root': { m: 1, width: '40ch' } }}
                                    autoComplete="off"
                                >
                                    {/* <Typography variant='body1'>Edit Category</Typography> */}
                                    <TextField
                                        required
                                        id="standard-required"
                                        label="Edit Category"
                                        variant="filled"
                                        defaultValue={cat.cat_name}
                                        // value={cat.cat_name}
                                        value={editCategoryValue}
                                        onChange={(e) => setEditCategoryValue(e.target.value)}
                                        type="text"

                                    />
                                    <Box>
                                        <TextField
                                            required
                                            id="standard-required"
                                            label="Edit Index"
                                            variant="filled"
                                            defaultValue={cat.cat_index}
                                            value={editCategoryIndexValue}
                                            onChange={(e) => setEditCategoryIndexValue(e.target.value)}
                                            type="number"
                                        />
                                    </Box>
                                    <Box display='flex' justifyContent='center'>
                                        <Button variant="outlined" color='primary' size='small' style={{ margin: "0 5px" }} onClick={() => saveEditCategory(cat.cat_id)}>Save</Button>
                                        <Button variant="outlined" color="error" size='small' onClick={handleClose}>Cancel</Button>
                                    </Box>
                                </Box>
                            </>
                        )) : null
                        }
                    </Box>
                </Box>
            </Modal>

            {/* Edit Sub-Category Modal  */}
            <Modal
                open={openEditSubCatModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    height: "200px",
                    bgcolor: 'background.paper',
                    // border: '2px solid #000',
                    boxShadow: 24,
                    p: 1,
                    borderRadius: 3
                }}>
                    <Box display='flex' justifyContent='center' alignItems="center" height="100%">
                        {selectCategoryID && categoryMasterData.length ? categoryMasterData.filter((c) => c.cat_id === selectCategoryID)[0]['subcats']?.filter((s) => s.sub_cat_id === selectSubCategoryID).map((sub) => (
                            <>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '40ch', },
                                    }}
                                    autoComplete="off"
                                >
                                    {/* <Typography variant='body1'>Edit Category</Typography> */}
                                    <Box>

                                        <TextField
                                            // required
                                            disabled
                                            id="standard-required"
                                            label="Selected Category"
                                            variant="filled"
                                            defaultValue={categoryMasterData.filter((c) => c.cat_id === selectCategoryID).map((cat) => cat.cat_name)}
                                            type="text"

                                        />
                                    </Box>
                                    <TextField
                                        required
                                        id="standard-required"
                                        label="Edit Sub-Category"
                                        variant="filled"
                                        defaultValue={sub.sub_cat_name}
                                        value={editSubCategoryValue}
                                        onChange={(e) => setEditSubCategoryValue(e.target.value)}
                                        type="text"

                                    />
                                    <Box display='flex' justifyContent='center'>
                                        <Button variant="outlined" color='primary' size='small' style={{ margin: "0 5px" }} onClick={() => { saveEditSubCategory(sub.sub_cat_id) }}>Save</Button>
                                        <Button variant="outlined" color="error" size='small' onClick={handleClose}>Cancel</Button>
                                    </Box>
                                </Box>
                            </>
                        )) : null
                        }
                    </Box>
                </Box>
            </Modal>

            {/* Edit Subject Modal  */}
            <Modal
                open={openEditSubjectModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    height: "250px",
                    bgcolor: 'background.paper',
                    // border: '2px solid #000',
                    boxShadow: 24,
                    p: 1,
                    borderRadius: 3
                }}>
                    <Box display='flex' justifyContent='center' alignItems="center" height="100%">
                        {selectCategoryID && categoryMasterData.length ? categoryMasterData.filter((c) => c.cat_id === selectCategoryID)[0]['subcats']?.filter((s) => s.sub_cat_id === selectSubCategoryID).map((sub) => (
                            <>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '40ch', },
                                    }}
                                    autoComplete="off"
                                >
                                    {/* <Typography variant='body1'>Edit Category</Typography> */}
                                    <Box>
                                        <TextField
                                            // required
                                            disabled
                                            id="standard-required"
                                            label="Selected Category"
                                            variant="filled"
                                            defaultValue={categoryMasterData.filter((c) => c.cat_id === selectCategoryID).map((cat) => cat.cat_name)}
                                            type="text"

                                        />
                                    </Box>
                                    <Box>
                                        <TextField
                                            // required
                                            disabled
                                            id="standard-required"
                                            label="Selected Sub-Category"
                                            variant="filled"
                                            defaultValue={sub.sub_cat_name}
                                            type="text"
                                        />
                                    </Box>
                                    <TextField
                                        required
                                        id="standard-required"
                                        label="Edit Subject"
                                        variant="filled"
                                        defaultValue={sub.subject.filter((subject) => subject === selectSubject)}
                                        value={editSubjectValue}
                                        onChange={(e) => setEditSubjectValue(e.target.value)}
                                        type="text"

                                    />
                                    <Box display='flex' justifyContent='center'>
                                        <Button variant="outlined" color='primary' size='small' style={{ margin: "0 5px" }} onClick={() => saveEditSubject(sub.sub_cat_id)}>Save</Button>
                                        <Button variant="outlined" color="error" size='small' onClick={handleClose}>Cancel</Button>
                                    </Box>
                                </Box>
                            </>
                        )) : null
                        }
                    </Box>
                </Box>
            </Modal>

            {/* Delete category Dialog  */}
            <Dialog
                open={openDeleteCatDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirm Delete ?
                </DialogTitle>
                {/* <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                    </DialogContentText>
                </DialogContent> */}
                <DialogActions>
                    {/* <Button onClick={deleteCategory} variant='outlined' color='primary'>Confirm</Button> */}
                    <Button onClick={handleClose} variant='outlined' color='error'>Close</Button>
                </DialogActions>
            </Dialog>

            {/* Delete Sub-Category Dialog  */}
            <Dialog
                open={openDeleteSubCatDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirm Delete ?
                </DialogTitle>
                {/* <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                    </DialogContentText>
                </DialogContent> */}
                <DialogActions>
                    <Button onClick={deleteSubCategory} variant='outlined' color='primary'>Confirm</Button>
                    <Button onClick={handleClose} variant='outlined' color='error'>Close</Button>
                </DialogActions>
            </Dialog>

            {/* Delete Subject Dialog  */}
            <Dialog
                open={openDeleteSubjectDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirm Delete ?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Subject :  {selectSubject}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={deleteSubject} variant='outlined' color='primary'>Confirm</Button>
                    <Button onClick={handleClose} variant='outlined' color='error'>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ManageCategories