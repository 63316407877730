import React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { colors, Typography } from '@mui/material';


const ManageCategoriesComp = ({
    category,
    assignedMasterCategoriesSubCategoriesTree,
    setAssignedMasterCategoriesSubCategoriesTree }) => {

    const [checked, setChecked] = React.useState([true, false]); // [0]=parent; [1]=child;

    const handleParentChange = (event) => {
        console.log('MUI Checkbox CAT EVENT = ', event.target.value, event.target.checked)
        setChecked([event.target.checked, event.target.checked]);
        let nVal = Array.from(assignedMasterCategoriesSubCategoriesTree).map(x => x.cat_id == event.target.value ? { ...x, isChecked: event.target.checked } : x)
        console.log('Final Assinged Cats Map = ', nVal)
        setAssignedMasterCategoriesSubCategoriesTree(nVal)
    };

    const handleChildChange = (event, catid) => {
        console.log('MUI Checkbox SUB-CAT EVENT = ', event.target.value, event.target.checked)
        setChecked([event.target.checked, checked[1]]);
        let nVal = Array.from(assignedMasterCategoriesSubCategoriesTree).map(x => {
            if (x.cat_id == catid) {
                let sc = x.subcats.map(y => y.sub_cat_id == event.target.value ? { ...y, isChecked: event.target.checked } : y)
                x.isChecked = sc.some(ch => ch.isChecked) || false
                return { ...x, subcats: sc }
            } else {
                return x
            }
        })
        console.log('Final Assinged Sub Cats Map = ', nVal)
        setAssignedMasterCategoriesSubCategoriesTree(nVal)
    };

    return (
        <>
            {category
                ? <Box>
                    <Box display='flex' justifyContent='space-between' alignContent='center' alignItems='center' bgcolor={colors.blueGrey[100]}>
                    <FormControlLabel
                        label={category.cat_name}
                        control={
                            <Checkbox
                                checked={category.isChecked}
                                indeterminate={Array.from(category.subcats).some(sc => sc.isChecked)}
                                value={category.cat_id}
                                disabled={category.cat_hide}
                                onChange={handleParentChange}
                                sx={{ color: colors.deepOrange[500] }}
                                size='medium'
                            />
                        }
                        sx={{ color: colors.deepOrange[500], background: colors.blueGrey[100], width: '100%', ml: 0, borderRadius: 1.5 }}
                    />
                    <Typography variant='caption' px={1}>Index:&nbsp;{category.cat_index || '-'}</Typography>
                    </Box>
                    {category.subcats && category.subcats.length > 0
                        ? category.subcats.map(subcat =>
                            subcat && subcat.sub_cat_name && subcat.sub_cat_id && <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                                <FormControlLabel
                                    label={subcat.sub_cat_name}
                                    control={
                                        <Checkbox
                                            checked={subcat.isChecked}
                                            value={subcat.sub_cat_id}
                                            onChange={(e) => handleChildChange(e, category.cat_id)}
                                            disabled={category.cat_hide && subcat.sub_cat_hide}
                                            size='small'
                                        />
                                    }
                                />
                            </Box>
                        )
                        : null}
                </Box>
                : null}
        </>

    );
}

export default ManageCategoriesComp