import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios';

import Article from '../components/Article';
import Header from '../components/Header';
import ArticleKeywords from '../components/ArticleKeywords';
import MasterCategories from '../components/MasterCategories';
import AddChangeDeleteCategories from '../components/AddchangeDeleteCategories';
import { Box, Button, CircularProgress, colors, LinearProgress } from '@mui/material';

import { useStore } from '../store/StoredData';
import MetaTitle from '../components/MetaTitle';
import NewsPriority from '../components/NewsPriority';
import NewsFeatured from '../components/NewsFeatured';

const EditArticle = () => {
    const { initDateRange,
        masterCategories, setMasterCategories,
        masterSubCategories, setMasterSubCategories,
        masterCategoriesSubCategoriesTree, setMasterCategoriesSubCategoriesTree,
        assignedCategoriesAndSubCategories, setAssignedCategoriesAndSubCategories,
        searchOn, setSearchOn,
        articlesList, setArticlesList,
        articleContent, setArticleContent,
        articleIndex, setArticleIndex,
        articlesSize, setArticlesSize
    } = useStore();

    // console.log('EDIT ARTICLE VIEW DATERANGE ======== ', initDateRange);
    const params = useParams();
    const history = useHistory();

    // const [article, setArticle] = useState(null);
    // const [categories, setCategories] = useState();
    // const [subCategories, setSubCategories] = useState();
    // const [mappedCategoriesAndSubCategories, setMappedCategoriesAndSubCategories] = useState();
    // const [assignedCategoriesAndSubCategories, setAssignedCategoriesAndSubCategories] = useState();
    const [articleAPKeywords, setArticleAPKeywords] = useState();
    const [articleMetaKeywords, setArticleMetaKeywords] = useState();
    const [articleSubjects, setArticleSubjects] = useState([]);
    const [newsPriority, setNewsPriority] = useState("");

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const fetchCategories = async () => {
            // console.log('Calling Categories API...');
            await axios.get("/api/categories").then(async resp => {
                // console.log('RECVD FROM API Master Categories ::: ', resp.data);
                if (resp.data.message.length)
                    setMasterCategories(resp.data.message)
            }).catch(console.error)
        };
        const fetchSubCategories = async () => {
            // console.log('Calling Categories API...');
            await axios.get("/api/sub-categories").then(async resp => {
                // console.log('RECVD FROM API Master Sub Categories ::: ', resp.data.message);
                if (resp.data.message.length)
                    setMasterSubCategories(resp.data.message)
            }).catch(console.error)
        };
        const fetchCategoriesSubCategoriesMapping = async () => {
            // console.log('Calling Categories API...');
            await axios.get("/api/categories-subcategories").then(async resp => {
                // console.log('RECVD FROM API Categories & Sub Categories Mapping ::: ', resp.data.message);
                if (resp.data.message.length)
                    setMasterCategoriesSubCategoriesTree(resp.data.message)
            }).catch(console.error)
        };
        const fetchCategoriesSubCategoriesByArticleId = async () => {
            // console.log('Calling Categories API...');
            await axios.get(`/api/v3/assigned-categories-by-article/${params.id}`).then(async resp => {
                // console.log('RECVD FROM API assigned-categories-by-article ::: ', resp.data.message);
                if (resp.data.message.length)
                    setAssignedCategoriesAndSubCategories(resp.data.message)
            }).catch(console.error)
        };
        const fetchArticle = async () => {
            await axios.get(`/api/articles/${params.id}`).then(async resp => {
                // console.log('RECVD ARTICLE DETAILS ::: ', resp.data.message[0]);
                setArticleContent(resp.data.message[0]);
                setArticleMetaKeywords(resp.data.message[0].meta_keywords.split(','))
                // setNewsPriority()
            }).catch(error => console.error('Error While Fetching Article Details -- ', error))
        };
        // const fetchArticleKeywords = async () => {
        //     console.log(`Calling  /api/articles/keywords/${articleId}`)
        //     await axios.get(`/api/articles/keywords/${articleId}`).then(async resp => {
        //         console.log('RECVD ARTICLE KEYWORDS ::: ', resp.data.message[0].item_keywords);
        //         setArticlekeywords(resp.data.message[0].item_keywords)
        //     }).catch(error => console.error('Error While Fetching Article Details -- ', error))
        // };
        const fetchArticleKeywordsAndSubjects = async () => {
            // console.log(`Calling  /api/articles/keywords-subjects/${params.id}`)
            await axios.get(`/api/articles/keywords-subjects/${params.id}`).then(async resp => {
                // console.log('RECVD ARTICLE KEYWORDS & SUBJECTS ::: ', resp.data.message[0]?.item_keywords || null, resp.data.message[0]?.item_subject || null);
                setArticleAPKeywords(resp.data.message[0]?.item_keywords || null)
                setArticleSubjects(resp.data.message[0]?.item_subject.map((sub, idx) => ({ id: idx, name: sub.name })) || [])
            }).catch(error => console.error('Error While Fetching Article Details -- ', error))
        };
        fetchCategories();
        fetchSubCategories();
        fetchCategoriesSubCategoriesMapping();
        fetchCategoriesSubCategoriesByArticleId();
        fetchArticle();
        fetchArticleKeywordsAndSubjects().then(() => setLoading(false));

        return () => clearImmediate();

    }, [articleIndex, params.id]);

    return (
        <>
            <Header />
            {articleContent && <MetaTitle title={articleContent.title} desc={articleContent.summary} keywords={articleAPKeywords}></MetaTitle>}
            {loading
                ? <Box display="flex" justifyContent="center" alignItems="center" alignContent="center" height="80vh"><CircularProgress /></Box>
                : <>
                    <Box display={"flex"} width={"100%"}>
                        <Box width={"19%"} display="flex" justifyContent={"space-evenly"} alignContent={"center"} alignItems={"center"}>
                            <Button variant='outlined' color='secondary' size='small' onClick={() => history.push("/home")}>back</Button>
                            <Box>
                                {articleIndex > 0 ? <Button variant='contained' color='info' size='small' onClick={() => {
                                    setArticleIndex(articleIndex => articleIndex - 1);
                                    history.push(`/articles/edit/${articlesList[articleIndex].id}`);
                                    // setSearchOn(true)
                                }}>prev article</Button> : null}
                            </Box>
                        </Box>
                        <Box width={"62%"} mx={3}>
                            <ArticleKeywords itemKeywords={articleAPKeywords} itemMetaKeywords={articleMetaKeywords} />
                        </Box>
                        <Box width={"19%"} display="flex" justifyContent={"space-evenly"} alignContent={"center"} alignItems={"center"}>
                            {articleIndex < articlesSize - 1 ? <Button variant='contained' color='info' size='small' onClick={() => {
                                setArticleIndex(articleIndex => articleIndex + 1);
                                history.push(`/articles/edit/${articlesList[articleIndex].id}`);
                                // setSearchOn(true)
                            }}>next article</Button> : null}
                        </Box>
                    </Box>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', alignContent: 'center', alignItems: 'start' }}>
                        {articleContent ?
                            <>
                                <div style={{ width: '19%', marginTop: 5, padding: 5 }}>
                                    {/* <MasterCategories
                                    isEditable={true}
                                    title="From Database"
                                    masterCategories={masterCategories}
                                    masterSubCategories={masterSubCategories}
                                    masterCategoriesSubCategoriesTree={masterCategoriesSubCategoriesTree}
                                /> */}
                                </div>
                                <div style={{ width: '62%', margin: 1 }}>
                                    <Article
                                        isEditable={true}
                                        article={articleContent} />
                                </div>
                                <div style={{ width: '19%', marginTop: 5, padding: 5, display: "flex", flexDirection: 'column' }}>
                                    <Box mb={2}>
                                        <NewsFeatured
                                            isEditable={true}
                                            title="News Featured"
                                        />
                                    </Box>
                                    {/* <Box mb={2}>
                                    <NewsPriority
                                        isEditable={true}
                                        title="News Priority"
                                    />
                                </Box>
                                <Box>
                                    <AddChangeDeleteCategories
                                        isEditable={true}
                                        title="From Article"
                                        masterCategories={masterCategories}
                                        masterSubCategories={masterSubCategories}
                                        assignedCategoriesAndSubCategories={assignedCategoriesAndSubCategories}
                                        masterCategoriesSubCategoriesTree={masterCategoriesSubCategoriesTree}
                                    />
                                </Box> */}
                                </div></> : <Box width="100%"> <LinearProgress /></Box>}
                    </div>
                </>}
        </>
    )
}

export default EditArticle