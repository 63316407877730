import React, { useEffect, useState, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { Box, colors, CircularProgress, TextField, Button, IconButton, makeStyles } from '@mui/material';
import Tabs from '../components/Tabs';

import axios from 'axios'
import { ClearSharp, EditSharp, RemoveRedEyeSharp, SearchSharp } from '@mui/icons-material'

import { useStore } from '../store/StoredData'
import InfiniteScroll from 'react-infinite-scroll-component';
import DatePicker from "react-datepicker";
import { addDays, format, startOfDay } from 'date-fns';

import "react-datepicker/dist/react-datepicker.css";
import "../App.css";
import Header from '../components/Header';

const RealtimePeople = () => {

    const history = useHistory()
    const location = useLocation()

    const [loading, setLoading] = useState(false);
    const { searchOn, setSearchOn } = useStore();

    const [livefeedCurrentPage, setLivefeedCurrentPage] = useState(0)
    const [livefeedPageSize, setLivefeedPageSize] = useState(20)
    const [personList, setPersonList] = useState([])
    const [personName, setPersonName] = useState("");
    const [headline, setHeadline] = useState("");
    const [personUpdatedDateRange, setPersonUpdatedDateRange] = useState([null, null]);
    const [PersonUpdatedStartDate, PersonUpdatedEndDate] = personUpdatedDateRange;
    const [articleUpdatedDateRange, setArticleUpdatedDateRange] = useState([null, null]);
    const [ArticleUpdatedStartDate, ArticleUpdatedEndDate] = articleUpdatedDateRange;
    const [personIndex, setPersonIndex] = useState()
    const [personListSize, setPersonListSize] = useState()
    const [livefeedHasMore, setLivefeedHasMore] = useState(false)
    const [companySuggestion, setCompanySuggestions] = useState([]);
    const [suggDropDownState, SetSuggDropDownState] = useState(false);
    const [suggLoading, setSuggLoading] = useState(false);


    const resetSearchBar = async () => {
        setPersonName("");
        setHeadline("");
        setPersonUpdatedDateRange([null, null]);
        setArticleUpdatedDateRange([null, null]);
        setLoading(true);
        axios.get("/api/articles/livefeed", {
            params: {
                page: 0,
                person_name: "",
                headline: ""
            }
        }).then(async resp => {
            let { count, rows } = resp.data.message;
            console.log('RECVD Persons FROM API ::: ', count, rows);
            if (count) {
                setPersonList(rows);
                setPersonIndex(0);
                setPersonListSize(count);

                /* MANAGE NEXT PAGE CALL OR NOT */
                if (count < livefeedPageSize) {
                    console.log('Looks Like No More Rows From Server...., Stopping On Page - ', livefeedCurrentPage)
                    setLivefeedHasMore(false);
                } else {
                    setLivefeedHasMore(true);
                    console.log('*** Looks Like More Rows In Server...., Increasing the Page Count from = ', livefeedCurrentPage)
                    setLivefeedCurrentPage(1);
                }
            } else {
                setPersonList([]);
                setPersonIndex(0);
                setPersonListSize(0);
            }
            setLoading(false);
        }).catch(console.error);
    }

    const getPersonData = async () => {
        setLoading(true);
        setPersonList([]);
        console.log('Calling Articles API For PARAMS ::: ',);

        axios.get("/api/articles/livefeed", {
            params: {
                page: 0,
                person_name: personName,
                headline,
                PersonUpdatedStartDate: PersonUpdatedStartDate ? format(new Date(PersonUpdatedStartDate), "yyyy-MM-dd") : null,
                PersonUpdatedEndDate: PersonUpdatedEndDate ? format(new Date(PersonUpdatedEndDate), "yyyy-MM-dd") : null,
                ArticleUpdatedStartDate: ArticleUpdatedStartDate ? format(new Date(ArticleUpdatedStartDate), "yyyy-MM-dd") : null,
                ArticleUpdatedEndDate: ArticleUpdatedEndDate ? format(new Date(ArticleUpdatedEndDate), "yyyy-MM-dd") : null,
            }
        }).then(async resp => {
            let { count, rows } = resp.data.message;
            console.log('RECVD Persons FROM API ::: ', count, rows);
            if (count) {
                setPersonList(rows);
                setPersonIndex(0);
                setPersonListSize(count);

                /* MANAGE NEXT PAGE CALL OR NOT */
                if (count < livefeedPageSize) {
                    console.log('Looks Like No More Rows From Server...., Stopping On Page - ', livefeedCurrentPage)
                    setLivefeedHasMore(false);
                } else {
                    setLivefeedHasMore(true);
                    console.log('*** Looks Like More Rows In Server...., Increasing the Page Count from = ', livefeedCurrentPage)
                    setLivefeedCurrentPage(1);
                }
            } else {
                // setPersonList([]);
                setPersonIndex(0);
                setPersonListSize(0);
            }
            setLoading(false);
        }).catch(console.error);
    }

    const fetchMorePerson = async () => {
        let resp = await axios.get("/api/articles/livefeed", {
            params: {
                page: livefeedCurrentPage,
                person_name: personName,
                headline: headline
            }
        })
        console.log("fetchArticlesData :::", resp.data.message)
        const { count, rows } = resp.data.message;

        if (count < livefeedPageSize) {
            console.log('Looks Like No More Rows From Server...., Stopping On Page - ', livefeedCurrentPage)
            setLivefeedHasMore(false);
        } else {
            setLivefeedHasMore(true);
            console.log('*** Looks Like More Rows In Server...., Increasing the Page Count from = ', livefeedCurrentPage)
            setLivefeedCurrentPage(livefeedCurrentPage => livefeedCurrentPage + 1);
        }
        return rows;
    };

    const fetchMoreData = async () => {
        // setLoading(true);
        console.log('Trying To Fetch More Data......... for page - ', livefeedCurrentPage)
        const moreRows = await fetchMorePerson();
        console.log('fetchMoreArticles() LEN = ', moreRows.length, moreRows)
        setPersonList(prev => [...prev, ...moreRows]);
        // setLoading(false);
    }

    useEffect(() => {
        getPersonData();
        const myInterval = setInterval(async () => {
            await getPersonData()
        }, 120000)
        return () => clearInterval(myInterval)
    }, [])


    return (
        <>
            <Header />
            <Tabs />
            <div style={{ display: 'flex', width: '100%' }}>
                {loading
                    ? <Box display="flex" width="100%" justifyContent={"center"} alignItems={"center"} margin={"15%"}><CircularProgress /></Box>
                    : <Box width='95%' border={1} borderColor="#CCC" textAlign='left' mx='auto' position='relative' display='flex' flexDirection='column'>
                        <Box display='flex' justifyContent='space-between' alignItems='center' textAlign='left' p={1} mt={0.5} mx={0.5} bgcolor={colors.blueGrey[100]} fontSize={13} sx={{ borderTopLeftRadius: 5, borderTopRightRadius: 5, borderColor: "#CCC" }}>
                            <Box width='25%' mx={1} fontWeight={700}>Person Name</Box>
                            <Box width='20%' mx={1} fontWeight={700}>Last Updated</Box>
                            <Box width='45%' mx={1} fontWeight={700}>Article Headline</Box>
                            <Box width='20%' mx={1} fontWeight={700}>Date of Article</Box>
                            <Box width='10%' display="flex" flexDirection="column" m={0}>&nbsp;</Box>
                        </Box>

                        {/* Search Bar */}
                        <Box display='flex' justifyContent='space-around' alignItems='center' textAlign='left' mx={0.5} bgcolor={colors.blueGrey[50]} fontSize={13} sx={{ borderBottomLeftRadius: 5, borderBottomRightRadius: 5 }}>
                            <Box width='25%' mx={1}>
                                <TextField variant='standard' size='small' name="insider_name" value={personName} onChange={e => { setPersonName(e.target.value) }} />
                            </Box>
                            <Box width='20%' mx={1}>
                                {/* <span><strong>Last Updated</strong></span> */}
                                <DatePicker
                                    // disabled
                                    id="PersonReactDatePicker"
                                    className="ReactDatePicker"
                                    selectsRange={true}
                                    startDate={PersonUpdatedStartDate}
                                    endDate={PersonUpdatedEndDate}
                                    onChange={(update) => {
                                        console.log('setArticleUpdatedDateRange = ', update);
                                        setPersonUpdatedDateRange(update);
                                    }}
                                />
                            </Box>
                            <Box width='45%' mx={1}>
                                <TextField variant='standard' size='small' name="title" value={headline} onChange={e => { setHeadline(e.target.value) }} />
                            </Box>
                            <Box width='20%' mx={1}>
                                <DatePicker
                                    // disabled
                                    id="ArticleReactDatePicker"
                                    className="ReactDatePicker"
                                    selectsRange={true}
                                    startDate={ArticleUpdatedStartDate}
                                    endDate={ArticleUpdatedEndDate}
                                    onChange={(update) => {
                                        console.log('setArticleUpdatedDateRange = ', update);
                                        setArticleUpdatedDateRange(update);
                                    }}
                                />
                            </Box>
                            <Box width='10%' display="flex" flexDirection="column">
                                <Button variant='outlined' color="secondary" sx={{ fontSize: 12, my: 0.25, p: 0 }} endIcon={<SearchSharp fontSize='small' color='secondary' />} title='Search For Resutls' onClick={getPersonData} >search</Button>
                                <Button variant='outlined' color="info" sx={{ fontSize: 12, my: 0.25, p: 0 }} endIcon={<ClearSharp fontSize='small' color='info' />} title='Reset Search Bar' onClick={resetSearchBar} >clear</Button>
                            </Box>
                        </Box>
                        {/* PEOPLE DATA TABLE SECTION */}
                        <Box id="scrollableDiv" height={450} overflow="auto">
                            <InfiniteScroll
                                dataLength={personList.length}
                                next={fetchMoreData}
                                hasMore={livefeedHasMore}
                                loader={<Box display='flex' justifyContent='center' my={2}><CircularProgress /></Box>}
                                scrollableTarget="scrollableDiv"
                                endMessage={
                                    personList.length > 0 ? <p style={{ textAlign: 'center', fontSize: 16, fontWeight: 400, color: colors.blue[600] }}>
                                        Yay! You have seen it all
                                    </p> : null
                                }
                            >
                                {loading && !personList.length && <Box display='flex' justifyContent='center' my={2}><CircularProgress /></Box>}
                                {personList.length
                                    ? personList.map((person, idx) =>
                                        <Box key={idx}>
                                            <Box display='flex' justifyContent='space-between' alignItems='center' textAlign='left' p={1} m={.5} border={1} borderColor="#CCC" borderRadius={1} fontSize={13}>
                                                <Box width='25%' mx={1} display='flex' justifyContent='flex-start' alignItems='center'>
                                                    <Box mr={1}>
                                                        <a
                                                            onClick={() => history.push(`/people/view/${person.sk_txn_sec_people_profile}`)}
                                                            style={{ cursor: 'pointer', textDecoration: 'none' }}
                                                            role="button"
                                                            title='View Person'>
                                                            <RemoveRedEyeSharp color='info' fontSize="small" />
                                                        </a>
                                                    </Box>
                                                    <Box mr={1}>
                                                        <a
                                                            onClick={() => history.push(`/people/edit/${person.sk_txn_sec_people_profile}`)}
                                                            style={{ cursor: 'pointer', textDecoration: 'none' }}
                                                            role="button"
                                                            title='Edit Person'>
                                                            <EditSharp color='secondary' fontSize="small" />
                                                        </a>
                                                    </Box>
                                                    <Box color={person.is_curated ? colors.indigo[400] : colors.deepOrange[500]} fontSize={14} fontWeight={500}>{person.person_name}</Box>
                                                </Box>
                                                <Box width='20%' mx={1}>
                                                    <span>{person.last_updated_at ? format(new Date(person.last_updated_at), "MMM dd, yy hh:mm:ss a") : null}</span>
                                                </Box>
                                                <Box width='45%' mx={1} >
                                                    <Box onClick={() => history.push(`/articles/view/${person.id}`)} style={{ cursor: 'pointer', textDecoration: "underline" }} color="blue">{person.title}</Box>
                                                </Box>
                                                <Box width='20%' mx={1}>
                                                    <span>{person.gmt_logtime ? format(new Date(person.gmt_logtime), "MMM dd, yy hh:mm:ss a") : null}</span>
                                                </Box>
                                                <Box width='5%' mx={1}>&nbsp;</Box>
                                            </Box>
                                        </Box>
                                    )
                                    : <Box width="95%" textAlign="center" color="orangered" my={2}>No Articles To Display</Box>}
                            </InfiniteScroll>
                        </Box>
                    </Box>}
            </div>
        </>
    )
}

export default RealtimePeople