import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, colors, LinearProgress, TextField, Typography } from '@mui/material';
import axios from 'axios';
// import { FastForwardSharp } from '@mui/icons-material'
import Header from './Header';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const EditArticleMetaTags = () => {

    const history = useHistory();
    const params = useParams();

    const [articleMeta, setArticleMeta] = useState();
    const [articleContent, setArticleContent] = useState();
    const [loading, setLoading] = useState(false);
    const [isNew, setisNew] = useState();

    const currentUser = JSON.parse(localStorage.getItem('user'));

    console.log('current Params ID === ', params.id)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await axios.get(`/api/articles/${params.id}`)
                .then(async resp => {
                    setArticleContent({ ...resp.data.message[0] });
                }).catch(error => console.error('Error While Fetching Article Details -- ', error))

            await axios.get(`/api/articles/meta/${params.id}`).then(resp => {
                console.log('GET Article Meta Tags API RESP === ', { ...resp.data.message });
                setArticleMeta(resp.data.message);
                if (resp.data.sucess === true && resp.data.message !== null) {
                    console.log('Old Meta')
                    setisNew(false);
                } else {
                    console.log('New Meta')
                    setisNew(true);
                }
                setLoading(false);
            }).catch();
        };

        fetchData();
        // fetchMeta();

    }, [params.id]);

    const handleSave = async () => {
        if (!articleMeta.meta_title_slug) {
            alert('meta title slug is a required field!');
            return false;
        }
        if (!articleMeta.meta_title) {
            alert('meta title is a required field!');
            return false;
        }
        if (!articleMeta.meta_description) {
            alert('meta description is a required field!');
            return false;
        }
        // if (!articleMeta.meta_canonical) {
        //     alert('meta canonical is a required field!');
        //     return false;
        // }
        if (!articleMeta.meta_keywords) {
            alert('meta keywords is a required field!');
            return false;
        }
        if (!articleMeta.image_alt_text) {
            alert('image alt text is a required field!');
            return false;
        }
        if (!articleMeta.image_caption_text) {
            alert('image caption text is a required field!');
            return false;
        }
        if (!currentUser.name) {
            alert('user is a required field!');
            return false;
        }

        console.log('Prepared Save data = ', {
            meta_title_slug: articleMeta.meta_title_slug,
            meta_title: articleMeta.meta_title,
            meta_description: articleMeta.meta_description,
            meta_keywords: articleMeta.meta_keywords,
            image_alt_text: articleMeta.image_alt_text,
            image_caption_text: articleMeta.image_caption_text,
            user: JSON.parse(localStorage.getItem('user'))?.['name']
        });

        // if (isNew) {
        //     console.log('Making POST Request')
        //     await axios({
        //         method: 'POST',
        //         url: `/api/articles/meta/${params.id}`,
        //         headers: { 'Content-Type': 'application/json' },
        //         data: {
        //             meta_title_slug: articleMeta.meta_title_slug || null,
        //             meta_title: articleMeta.meta_title || null,
        //             meta_description: articleMeta.meta_description || null,
        //             meta_keywords: articleMeta.meta_keywords || null,
        //             meta_canonical: articleMeta.meta_canonical || articleMeta?.meta_canonical || `https://candorium.com/news/${params.id}/${articleContent?.title_slug}`,
        //             image_alt_text: articleMeta.image_alt_text || null,
        //             image_caption_text: articleMeta.image_caption_text || null,
        //             user: JSON.parse(localStorage.getItem('user'))?.['name']
        //         }
        //     }).then(resp => {
        //         console.log('Saved Article Meta Tags Successfully... ', resp.data.message);
        //         alert('Article Updated Successfully!');
        //         window.location.reload()
        //     }).catch(error => console.error(`Error While Saving Article, ${error}`));
        // } else {
        //     console.log('Making PUT Request')
        //     await axios({
        //         method: 'PUT',
        //         url: `/api/articles/meta/${params.id}`,
        //         headers: { 'Content-Type': 'application/json' },
        //         data: {
        //             meta_title_slug: articleMeta.meta_title_slug || null,
        //             meta_title: articleMeta.meta_title || null,
        //             meta_description: articleMeta.meta_description || null,
        //             meta_keywords: articleMeta.meta_keywords || null,
        //             meta_canonical: articleMeta.meta_canonical || articleMeta?.meta_canonical || `https://candorium.com/news/${params.id}/${articleContent?.title_slug}`,
        //             image_alt_text: articleMeta.image_alt_text || null,
        //             image_caption_text: articleMeta.image_caption_text || null,
        //             user: JSON.parse(localStorage.getItem('user'))?.['name']
        //         }
        //     }).then(resp => {
        //         console.log('Saved Article Meta Tags Successfully... ', resp.data.message);
        //         alert('Article Updated Successfully!');
        //         window.location.reload()
        //     }).catch(error => console.error(`Error While Saving Article, ${error}`));
        // }

        await axios({
            method: 'POST',
            url: `/api/articles/meta/upsert/${params.id}`,
            data: {
                meta_title: articleMeta.meta_title || null,
                meta_description: articleMeta.meta_description || null,
                meta_keywords: articleMeta.meta_keywords || null,
                meta_title_slug: articleMeta.meta_title_slug || null,
                meta_canonical: articleMeta.meta_canonical || articleMeta?.meta_canonical || `https://candorium.com/news/${params.id}/${articleContent?.title_slug}`,
                image_alt_text: articleMeta.image_alt_text || null,
                image_caption_text: articleMeta.image_caption_text || null,
                user: JSON.parse(localStorage.getItem('user'))?.['name']
            }
        }).then(resp => {
            console.log('Saved Article Meta Tags Successfully... ', resp.data.message);
            alert('Article Updated Successfully!');
            window.location.reload()
        }).catch(error => console.error(`Error While Saving Article, ${error}`));

    }

    const handleMetaChange = async (e) => {
        console.log('handleMetaChange ::: ', e.target.name, e.target.value)
        setArticleMeta(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }

    console.log('articleMeta ===== ', articleMeta)
    return (
        <>
            <Header />
            {loading
                ? <LinearProgress />
                : <Box display={'flex'} width={'100%'} justifyContent={'space-evenly'} mt={1}>
                    <Box width="50%" mx={0.5} border={1} borderRadius={1} borderColor={colors.grey[400]} p={1}>
                        <Typography variant='h5' color={'orangered'} textAlign={'center'} mx={1} mt={2} mb={3}>Original Article Reference</Typography>
                        {articleContent &&
                            <>
                                <Typography variant='body1' fontWeight={600} color={'orangered'}>Title Slug:</Typography>
                                <Box display={'flex'} justifyContent={'space-between'}>
                                    <Typography variant='body1'>{articleContent?.title_slug}</Typography>
                                    {/* <FastForwardSharp color='action' /> */}
                                </Box>
                                <hr />
                                <Typography variant='body1' fontWeight={600} color={'orangered'}>Headline:</Typography><Typography variant='body1'>{articleContent?.title}</Typography>
                                <hr />
                                <Typography variant='body1' fontWeight={600} color={'orangered'}>Summary:</Typography><Typography variant='body1'>{articleContent?.summary}</Typography>
                                <hr />
                                <Typography variant='body1' fontWeight={600} color={'orangered'}>Content:</Typography>
                                <Box display={'flex'} justifyContent={'space-between'}>
                                    <Typography variant='body1'><span dangerouslySetInnerHTML={{ __html: articleContent.description }} style={{ overflow: 'scroll', height: '400px' }}></span></Typography>
                                    {/* <FastForwardSharp color='action' /> */}
                                </Box>
                                <hr />
                                <Typography variant='body1' fontWeight={600} color={'orangered'}>Keywords:</Typography><Typography variant='body1'><img src={articleContent?.article_keywords_extracted} width={'50%'} height={'auto'} alt="" /></Typography>
                                <hr />
                                <Typography variant='body1' fontWeight={600} color={'orangered'}>Image:</Typography><Typography variant='body1'><img src={articleContent?.image_url} width={'50%'} height={'auto'} alt="" /></Typography>
                                <hr />
                                <Typography variant='body1' fontWeight={600} color={'orangered'}>Image ALT Tag:</Typography>
                                <Box display={'flex'} justifyContent={'space-between'}>
                                    <Typography variant='body1'>{articleContent?.image_alt_text}</Typography>
                                    {/* <FastForwardSharp color='action' /> */}
                                </Box>
                                <hr />
                                <Typography variant='body1' fontWeight={600} color={'orangered'}>Image Caption Text:</Typography>
                                <Box display={'flex'} justifyContent={'space-between'}>
                                    <Typography variant='body1'>{articleContent?.image_caption_text}</Typography>
                                    {/* <FastForwardSharp color='action' /> */}
                                </Box>
                                <hr />
                                <Typography variant='body1' fontWeight={600} color={'orangered'}>Source: {articleContent.source && <a rel="noreferrer" href={articleContent.source} target='_blank'>{articleContent.title}</a>}</Typography>
                                <hr />
                            </>
                        }
                    </Box>
                    <Box width="50%" mx={0.5} border={1} borderRadius={1} borderColor={colors.grey[400]}>
                        <Box>
                            <Box display='flex' justifyContent='space-between' mx={1} mt={2} mb={3}>
                                <Button variant='contained' color='inherit' size='small' onClick={() => history.goBack()}>back</Button>
                                <Typography variant='h5' color={'orangered'}>Manage Article Meta Tags</Typography>
                                {isNew === true
                                    ? <Button variant='contained' color='primary' size='small' onClick={handleSave}>Create</Button>
                                    : <Button variant='contained' color='primary' size='small' onClick={handleSave}>Save</Button>
                                }
                            </Box>
                            <Box display={'flex'} flexDirection={'column'} p={1}>
                                <TextField multiline rows={2} sx={{ my: 1 }} label='URL Slug:' name='meta_title_slug' value={articleMeta?.meta_title_slug || ''} onChange={(e) => handleMetaChange(e)} />
                                <TextField multiline rows={2} sx={{ my: 1 }} label='Meta Title Tag:' name='meta_title' value={articleMeta?.meta_title || ''} onChange={(e) => handleMetaChange(e)} />
                                <TextField multiline rows={5} sx={{ my: 1 }} label='Meta Description:' name='meta_description' value={articleMeta?.meta_description || ''} onChange={(e) => handleMetaChange(e)} />
                                {articleMeta?.meta_description &&
                                    <small style={{ color: (articleMeta?.meta_description.length > 320 || articleMeta?.meta_description.length < 150) ? "orangered" : "blueviolet", fontWeight: 500 }}>
                                        {Intl.NumberFormat('en-US', { compactDisplay: 'short' }).format(articleMeta?.meta_description.length) + ' chars'}
                                        <small style={{ color: 'GrayText' }}>(150-320 ideal length)</small>
                                    </small>}
                                <TextField multiline rows={2} sx={{ my: 1 }} label='Meta keywords:' name='meta_keywords' value={articleMeta?.meta_keywords || ''} onChange={(e) => handleMetaChange(e)} />
                                <TextField multiline rows={2} sx={{ my: 1 }} label='Image Alt Tag:' name='image_alt_text' value={articleMeta?.image_alt_text || ''} onChange={(e) => handleMetaChange(e)} />
                                <TextField multiline rows={2} sx={{ my: 1 }} label='Image Caption Text:' name='image_caption_text' value={articleMeta?.image_caption_text || ''} onChange={(e) => handleMetaChange(e)} />
                                <TextField
                                    disabled
                                    multiline rows={2}
                                    sx={{ my: 1 }}
                                    label='Meta Canonical Link:'
                                    name='meta_canonical'
                                    defaultValue={articleMeta?.meta_canonical || `https://candorium.com/news/${params.id}/${articleContent?.title_slug}`}
                                    onChange={(e) => handleMetaChange(e)} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }
        </>
    )
}

export default EditArticleMetaTags
