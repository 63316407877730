import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios';

import Article from '../components/Article';
import Header from '../components/Header';
import ArticleKeywords from '../components/ArticleKeywords';
import MasterCategories from '../components/MasterCategories';
import AddChangeDeleteCategories from '../components/AddchangeDeleteCategories';
import { Box, Button } from '@mui/material';

import { useStore } from '../store/StoredData';
import MetaTitle from '../components/MetaTitle';
import { ReportOutlined, ReportProblem, ReportProblemOutlined, ReportSharp } from '@mui/icons-material';

const ViewArticle = () => {
  const { initDateRange,
    masterCategories, setMasterCategories,
    masterSubCategories, setMasterSubCategories,
    masterCategoriesSubCategoriesTree, setMasterCategoriesSubCategoriesTree,
    assignedCategoriesAndSubCategories, setAssignedCategoriesAndSubCategories,
    searchOn, setSearchOn,
    articlesList, setArticlesList,
    articleContent, setArticleContent,
    articleIndex, setArticleIndex,
    articlesSize, setArticlesSize
  } = useStore();

  console.log('VIEW ARTICLE VIEW DATERANGE ======== ', initDateRange);
  const params = useParams();
  const history = useHistory();

  // const [article, setArticle] = useState(null);
  // const [categories, setCategories] = useState();
  // const [subCategories, setSubCategories] = useState();
  // const [mappedCategoriesAndSubCategories, setMappedCategoriesAndSubCategories] = useState();
  // const [assignedCategoriesAndSubCategories, setAssignedCategoriesAndSubCategories] = useState();
  const [articleKeywords, setArticlekeywords] = useState();
  const [articleSubjects, setArticleSubjects] = useState([]);

  useEffect(async () => {
    const fetchCategories = async () => {
      console.log('Calling Categories API...');
      await axios.get("/api/categories").then(async resp => {
        console.log('RECVD FROM API Master Categories ::: ', resp.data);
        if (resp.data.message.length)
          setMasterCategories(resp.data.message)
        // setCategories(resp.data.message)
      }).catch(console.error)
    };
    const fetchSubCategories = async () => {
      console.log('Calling Categories API...');
      await axios.get("/api/sub-categories").then(async resp => {
        console.log('RECVD FROM API Master Sub Categories ::: ', resp.data.message);
        if (resp.data.message.length)
          setMasterSubCategories(resp.data.message)
        // setSubCategories(resp.data.message)
      }).catch(console.error)
    };
    const fetchCategoriesSubCategoriesMapping = async () => {
      console.log('Calling Categories API...');
      await axios.get("/api/categories-subcategories").then(async resp => {
        console.log('RECVD FROM API Categories & Sub Categories Mapping ::: ', resp.data.message);
        if (resp.data.message.length)
          setMasterCategoriesSubCategoriesTree(resp.data.message)
        // setMappedCategoriesAndSubCategories(resp.data.message)
      }).catch(console.error)
    };
    const fetchCategoriesSubCategoriesByArticleId = async () => {
      console.log('Calling Categories API...');
      await axios.get(`/api/v2/assigned-categories-by-article/${params.id}`).then(async resp => {
        console.log('RECVD FROM API assigned-categories-by-article ::: ', resp.data.message);
        if (resp.data.message.length)
          setAssignedCategoriesAndSubCategories(resp.data.message)
      }).catch(console.error)
    };
    const fetchArticle = async () => {
      setArticleContent(null);
      await axios.get(`/api/articles/${params.id}`).then(async resp => {
        console.log('RECVD ARTICLE DETAILS ::: ', resp.data.message[0]);
        setArticleContent(resp.data.message[0]);
      }).catch(error => console.error('Error While Fetching Article Details -- ', error))
    };
    // const fetchArticleKeywords = async () => {
    //     console.log(`Calling  /api/articles/keywords/${articleId}`)
    //     await axios.get(`/api/articles/keywords/${articleId}`).then(async resp => {
    //         console.log('RECVD ARTICLE KEYWORDS ::: ', resp.data.message[0].item_keywords);
    //         setArticlekeywords(resp.data.message[0].item_keywords)
    //     }).catch(error => console.error('Error While Fetching Article Details -- ', error))
    // };
    const fetchArticleKeywordsAndSubjects = async () => {
      console.log(`Calling  /api/articles/keywords-subjects/${params.id}`);
      await axios.get(`/api/articles/keywords-subjects/${params.id}`).then(async resp => {
        console.log('RECVD ARTICLE KEYWORDS & SUBJECTS ::: ', resp.data.message[0].item_keywords, resp.data.message[0].item_subject);
        setArticlekeywords(resp.data.message[0].item_keywords)
        setArticleSubjects(resp.data.message[0].item_subject.map((sub, idx) => ({ id: idx, name: sub.name })))
      }).catch(error => console.error('Error While Fetching Article Details -- ', error))
    };
    fetchCategories();
    fetchSubCategories();
    fetchCategoriesSubCategoriesMapping();
    fetchCategoriesSubCategoriesByArticleId();
    fetchArticle();
    fetchArticleKeywordsAndSubjects();

  }, [articleIndex, params.id]);

  console.log('ARTICLE SIZE == ', articlesSize, '  INDEX === ', articleIndex);
  return (
    <>
      <Header />
      {articleContent && <MetaTitle title={articleContent.title}></MetaTitle>}
      <Box display={"flex"} width={"100%"}>
        <Box width={"19%"} display="flex" justifyContent={"space-evenly"} alignContent={"center"} alignItems={"center"}>
          <Button variant='outlined' color='secondary' size='small' onClick={() => history.push("/home")}>back</Button>
          {articleIndex > 0 ? <Button id="prev" variant='contained' color='info' size='small' onClick={() => {
            setArticleIndex(articleIndex => articleIndex - 1);
            history.push(`/articles/view/${articlesList[articleIndex].id}`);
          }}>prev article</Button> : null}
        </Box>
        <Box width={"62%"} p={0.5}>
          <ArticleKeywords itemKeywords={articleKeywords} />
        </Box>
        <Box width={"19%"} display="flex" justifyContent={"space-evenly"} alignContent={"center"} alignItems={"center"}>
          {articleIndex < articlesSize - 1
            ? <Button
              id="next"
              variant='contained'
              color='info'
              size='small'
              onClick={() => {
                setArticleIndex(articleIndex => articleIndex + 1);
                history.push(`/articles/view/${articlesList[articleIndex].id}`);
              }}>next article</Button>
            : null}
          <Button
            variant='outlined'
            size='small'
            sx={{ my: 0.5, fontWeight: 500 }}
            color="error"
            startIcon={<ReportProblemOutlined color='warning' />}
            onClick={() => history.push(`/articles/edit/${params.id}`)}>Edit Article</Button>
        </Box>
      </Box>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', alignContent: 'center', alignItems: 'start' }}>
        <div style={{ width: '30%', marginTop: 5, padding: 5 }}>
          <MasterCategories
            isEditable={false}
            title="From Database"
            masterCategories={masterCategories}
            masterSubCategories={masterSubCategories}
            masterCategoriesSubCategoriesTree={masterCategoriesSubCategoriesTree}
          />
        </div>
        <div style={{ width: '100%', margin: 1 }}>
          {articleContent
            ? <Article
              isEditable={false}
              article={articleContent} />
            : <Box width="100%" textAlign="center" mt={3}>Fetching Article Content...</Box>}
        </div>
        <div style={{ width: '30%', marginTop: 5, padding: 5 }}>
          <AddChangeDeleteCategories
            isEditable={false}
            title="From Article"
            masterCategories={masterCategories}
            masterSubCategories={masterSubCategories}
            assignedCategoriesAndSubCategories={assignedCategoriesAndSubCategories}
            masterCategoriesSubCategoriesTree={masterCategoriesSubCategoriesTree}
          />
        </div>
      </div>
    </>
  )
}

export default ViewArticle