import { useState, createContext, useContext, useEffect } from "react";
import axios from "axios";

const StoreContext = createContext(null)

export const DataProvider = ({ children }) => {

    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [initDateRange, setInitDateRange] = useState([new Date(), new Date()]);
    const [masterCategories, setMasterCategories] = useState([]);
    const [masterSubCategories, setMasterSubCategories] = useState([]);
    const [masterCategoriesSubCategoriesTree, setMasterCategoriesSubCategoriesTree] = useState([]);
    const [masterIndicatorSubTree, setMasterIndicatorSubTree] = useState([]);
    const [assignedCategoriesAndSubCategories, setAssignedCategoriesAndSubCategories] = useState();
    const [headlineText, setHeadlineText] = useState("");
    const [keywordText, setKeywordText] = useState("");
    const [companyTicker, setCompanyTicker] = useState("");
    const [macroIndicatorName, setMacroIndicatorName] = useState("");
    const [articleSourceName, setArticleSourceName] = useState(null)
    const [searchOn, setSearchOn] = useState(true);
    const [articlesList, setArticlesList] = useState([]);
    const [articleContent, setArticleContent] = useState();
    const [articleIndex, setArticleIndex] = useState(0);
    const [articlesSize, setArticlesSize] = useState(0);
    const [newsFeatured, setNewsFeatured] = useState(false)


    const [hasMore, setHasMore] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);

    const [ownershipFilingsList, setOwnershipFilingsList] = useState([])
    const [ownershipFilingsIndex, setOwnershipFilingsIndex] = useState(0)
    const [ownershipFilingsSize, setOwnershipFilingsSize] = useState(0)
    const [hasMoreOwnershipFilings, setHasMoreOwnershipFilings] = useState(true);
    const [currentPageOwnershipFilings, setCurrentPageOwnershipFilings] = useState(0);
    const [pageSizeOwnershipFilings, setPageSizeOwnershipFilings] = useState(20);

    const [earningFilingsList, setEarningFilingsList] = useState([])
    const [earningFilingsIndex, setEarningFilingsIndex] = useState(0)
    const [earningFilingsSize, setEarningFilingsSize] = useState(0)
    const [hasMoreEarningsFilings, setHasMoreEarningsFilings] = useState(true);
    const [currentPageEarningsFilings, setCurrentPageEarningsFilings] = useState(0);
    const [pageSizeEarningsFilings, setPageSizeEarningsFilings] = useState(20);

    const [layoffFilingsList, setLayoffFilingsList] = useState([])
    const [layoffFilingsIndex, setLayoffFilingsIndex] = useState(0)
    const [layoffFilingsSize, setLayoffFilingsSize] = useState(0)
    const [hasMoreLayoffsFilings, setHasMoreLayoffsFilings] = useState(true);
    const [currentPageLayoffsFilings, setCurrentPageLayoffsFilings] = useState(0);
    const [pageSizeLayoffsFilings, setPageSizeLayoffsFilings] = useState(20);

    const saveArticlesList = (articlesList) => setArticlesList(articlesList);

    const selectDateRange = (daterange) => {
        // console.log('STORE RECEIVED DATE-RANGE ==================', daterange)
        setInitDateRange(daterange)
    }

    const resetSearchBar = () => {
        setInitDateRange([new Date(), new Date()]);
        setHeadlineText("");
        setKeywordText("");
        setCompanyTicker("");
        setMacroIndicatorName("");
        setSearchOn(true);
        setHasMore(false);
        setCurrentPage(0);
        setNewsFeatured(false);
        setArticleSourceName(null)
    };

    // useEffect(async () => {
    //     setCurrentUser(await AuthService.getCurrentUser())
    // }, []);

    useEffect(() => {
        const fetchCategories = async () => {
            // console.log('Calling Categories API...');
            await axios.get("/api/categories").then(async resp => {
                console.log('/api/categories RECVD FROM API Master Categories ::: ', resp.data);
                if (resp.data.message.length)
                    setMasterCategories(resp.data.message)
            }).catch(console.error)
        };
        const fetchSubCategories = async () => {
            // console.log('Calling Categories API...');
            await axios.get("/api/sub-categories").then(async resp => {
                console.log('/api/sub-categories RECVD FROM API Master Sub Categories ::: ', resp.data.message);
                if (resp.data.message.length)
                    setMasterSubCategories(resp.data.message)
            }).catch(console.error)
        };
        const fetchCategoriesSubCategoriesMapping = async () => {
            // console.log('Calling Categories API...');
            await axios.get("/api/categories-subcategories").then(async resp => {
                // console.log('RECVD FROM API Categories & Sub Categories Mapping ::: ', resp.data.message);
                if (resp.data.message.length) {
                    setMasterCategoriesSubCategoriesTree(resp.data.message);
                    let data = resp.data.message.map(cats => {
                        let mapped = cats.subcats.map(obj => {
                            return { ...obj, isChecked: false };
                        });
                        return { ...cats, isChecked: false, subcats: mapped };
                    })
                }
            }).catch(console.error)
        };


        if (currentUser) {
            fetchCategories();
            fetchSubCategories();
            fetchCategoriesSubCategoriesMapping();
        }

        return () => clearImmediate();;

    }, [])
    // console.log('USE-STORE DATERANGE =============== ', initDateRange);
    // console.log('USE-STORE ARTICLE-INDEX =============== ', articleIndex);


    return (
        <StoreContext.Provider value={{
            currentUser, setCurrentUser,
            initDateRange, selectDateRange,
            masterCategories, setMasterCategories,
            masterSubCategories, setMasterSubCategories,
            masterCategoriesSubCategoriesTree, setMasterCategoriesSubCategoriesTree,
            assignedCategoriesAndSubCategories, setAssignedCategoriesAndSubCategories,
            headlineText, setHeadlineText,
            keywordText, setKeywordText,
            companyTicker, setCompanyTicker,
            macroIndicatorName, setMacroIndicatorName,
            searchOn, setSearchOn,
            articlesList, setArticlesList,
            articleContent, setArticleContent,
            articleIndex, setArticleIndex,
            articlesSize, setArticlesSize,
            saveArticlesList,
            resetSearchBar,
            newsFeatured, setNewsFeatured,
            hasMore, setHasMore,
            currentPage, setCurrentPage,
            pageSize, setPageSize,
            masterIndicatorSubTree, setMasterIndicatorSubTree,
            hasMoreOwnershipFilings, setHasMoreOwnershipFilings,
            currentPageOwnershipFilings, setCurrentPageOwnershipFilings,
            pageSizeOwnershipFilings, setPageSizeOwnershipFilings,
            ownershipFilingsList, setOwnershipFilingsList,
            ownershipFilingsIndex, setOwnershipFilingsIndex,
            ownershipFilingsSize, setOwnershipFilingsSize,
            earningFilingsList, setEarningFilingsList,
            earningFilingsIndex, setEarningFilingsIndex,
            earningFilingsSize, setEarningFilingsSize,
            hasMoreEarningsFilings, setHasMoreEarningsFilings,
            currentPageEarningsFilings, setCurrentPageEarningsFilings,
            pageSizeEarningsFilings, setPageSizeEarningsFilings,
            articleSourceName, setArticleSourceName,
            layoffFilingsList, setLayoffFilingsList,
            layoffFilingsIndex, setLayoffFilingsIndex,
            layoffFilingsSize, setLayoffFilingsSize,
            hasMoreLayoffsFilings, setHasMoreLayoffsFilings,
            currentPageLayoffsFilings, setCurrentPageLayoffsFilings,
            pageSizeLayoffsFilings, setPageSizeLayoffsFilings
        }}>
            {children}
        </StoreContext.Provider>
    )
}

export const useStore = () => {
    return useContext(StoreContext)
}
