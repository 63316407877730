import React, { useMemo, useState } from 'react'
import Header from '../components/Header'
import Tabs from '../components/Tabs'
import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, FormLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography, colors } from '@mui/material'
import { useEffect } from 'react'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useCallback } from 'react'

const ManageSTAGEdatasets = () => {

    const availableDatasetList = [
        // { id: '1a', label: 'Companies that always balance using ARM + Top 500 companies by revenue' },
        // { id: '1b', label: 'Companies that did not balanced from Dataset 1a should be moved to Dataset 1b' },
        { id: '1', label: 'Companies that always balance using ARM + Top 500 companies by revenue' },
        { id: '2', label: 'Companies that fall under mutual funds and ETFs ( excluding companies that fall under dataset 1a/b)' },
        { id: '3', label: 'Companies that fall under Finance, Insurance & Real estate ( excluding companies that fall under dataset 1a/b)' },
        { id: '4', label: `Companies that do not balance even after MRM- will be moved by Adhya's team ( Companies that were already pushed to Dataset 4 should be untouched, excluding companies that fall under dataset 1a/b)` },
        { id: '5', label: `Companies that do not balance by ARM and need manual intervention - will be moved by Adhya's team ( Companies that were already pushed to Dataset 5 should be untouched, excluding companies that fall under dataset  1a/b)` },
        { id: '6', label: 'Companies that do not have a CIK and SIC' },
        { id: '7', label: 'Unknown' }
    ]
    const [datasetid, setDatasetid] = useState(availableDatasetList[6].id)
    const [selectedDataset, setSelectedDataset] = useState(datasetid)
    const [companyList, setCompanyList] = useState([])
    const [companyListCopy, setCompanyListCopy] = useState([])
    const [checkedCompanyList, setCheckedCompanyList] = useState([])
    const [comment, setComment] = useState('')
    const [loading, setLoading] = useState(true)
    const [filterValue, setFilterValue] = useState('')

    const fetchCompanyList = async () => {
        setCompanyListCopy([])
        setCompanyList([])
        setLoading(true)
        await axios.get(`/api/stage-dataset/${datasetid}`)
            .then((resp) => {
                console.log('API RESP for Dataset ID - ', datasetid, resp.data.message);
                setCompanyListCopy(resp.data.message);
                setCompanyList(resp.data.message);
                setCheckedCompanyList([])
                setLoading(false)
            }).catch(err => console.error("Error while fetching Stage Data :::", err))
    }

    const changeDataset = (e) => {
        setDatasetid(e.target.value)
        setSelectedDataset(e.target.value)
    }

    const handleCheck = (e, c) => {
        if (e.target.checked) {
            console.log("Checked company", c)
            setCheckedCompanyList(prev => [...prev, c])
        } else {
            setCheckedCompanyList(prev => prev.filter(i => i.cik != c.cik))
        }
    }

    // console.log("Checked Company List :::", checkedCompanyList)

    const updateStageDataset = () => {
        axios.put('/api/stage-dataset', {
            companies: checkedCompanyList,
            dataset: selectedDataset,
            comments: comment
        }).then((resp) => {
            console.log('POST SAVE API RESP ::: ', resp.data.message)
            fetchCompanyList()
            setComment('')
            setCheckedCompanyList()
        }).catch(err => console.error("error while updating dataset :::", err))
    }

    const filteredCompanyList = useMemo(() => {
        return companyList.filter((comp) => {
            return comp.company_name.toLowerCase().includes(filterValue.toLowerCase())
        })
    }, [companyList, filterValue])

    // console.log('companyList == ', companyList)
    // console.log('companyListCopy == ', companyListCopy)

    useEffect(() => {
        fetchCompanyList()
    }, [datasetid])

    return (
        <div>
            <Header />
            <Tabs />
            <Box display={'flex'} mt={3} mx={1}>
                {/* Table Section  */}
                <Box width='58%'>
                    <Box display='flex' justifyContent='flex-start' alignContent='center' alignItems='center' mx={0} px={4} py={2} fontSize={12} bgcolor={colors.blueGrey[100]}>
                        <Box display='flex' flexDirection='column' width='70%' fontWeight={700} textAlign="left">
                            <div>Company Name</div>
                            <TextField
                                size='small'
                                variant='standard'
                                placeholder='Filter Dataset'
                                value={filterValue}
                                // helperText={`Search by Company Name`}
                                style={{ margin: "0 20px 0 0", width: '50%' }}
                                // onChange={optimizedFilter}
                                onChange={(e) => setFilterValue(e.target.value)}
                            />
                        </Box>
                        <Box width='10%' fontWeight={700} textAlign="left">CIK</Box>
                        <Box width='10%' fontWeight={700} textAlign="left">Ticker</Box>
                        <Box width='10%' fontWeight={700} textAlign="left">Dataset</Box>
                    </Box>
                    {loading
                        ? <Box display="flex" justifyContent="center" alignItems="center" margin="15%"><CircularProgress /></Box>
                        : <Box id="scrollableDiv" height={'80vh'} overflow="auto">
                            <InfiniteScroll
                                dataLength={filteredCompanyList.length}
                                loader={<Box display='flex' justifyContent='center' my={2}><CircularProgress /></Box>}
                                scrollableTarget="scrollableDiv"
                            >
                                {filteredCompanyList.map((c) => (
                                    <Box key={c.cik} display="flex" justifyContent="flex-start" alignContent="center" alignItems="center" px={2} fontSize={14} border={1} borderColor='#E5E5E5' borderRadius={1}>
                                        <Box width='70%' textAlign="left">
                                            <FormControlLabel
                                                control={<Checkbox name={c.company_name} checked={checkedCompanyList?.filter(comp => comp.cik == c.cik).length ? true : false} onChange={(e) => handleCheck(e, c)} />}
                                                label={c.company_name}
                                            />
                                        </Box>
                                        <Box width='10%' textAlign="left">{c.cik}</Box>
                                        <Box width='10%' textAlign="left">{c.ticker}</Box>
                                        <Box width='10%' textAlign="left">{c.dataset}</Box>
                                    </Box>
                                ))}
                            </InfiniteScroll>
                        </Box>}
                </Box>
                <Box width='40%'>
                    <Box display="flex" flexDirection='column' justifyContent='flex-start' alignContent='center' alignItems='center' border={1} borderColor={colors.blueGrey[100]} px={2}>
                        <Box display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center" my={3}>
                            <FormControl>
                                <Select
                                    variant='outlined'
                                    size='small'
                                    value={datasetid}
                                    style={{ width: 150 }}
                                    onChange={changeDataset}
                                >
                                    {availableDatasetList.map((item) => <MenuItem key={item.id} value={item.id}>Dataset - {item.id}</MenuItem>)}
                                </Select>
                                <FormHelperText style={{ padding: 0, margin: 0 }}>Select a dataset ID</FormHelperText>
                            </FormControl>
                            {/* <Box fontSize={16} mx={1} color={colors.blueGrey[600]}>{'narrow down >'}</Box>
                            <TextField
                                variant='standard'
                                placeholder='Search Company'
                                helperText={`Search by Company Name`}
                                style={{ margin: "0 20px" }}
                                inputRef={filterRef}
                                onChange={optimizedFilter}
                            /> */}
                        </Box>
                        <hr style={{ padding: 0, margin: 0, backgroundColor: 'rebeccapurple', height: 4, color: 'red', width: '100%' }} />
                        <Box display='flex' width='100%' justifyContent='space-evenly' alignContent='center' alignItems='center' my={3}>
                            <FormControl component="fieldset" style={{ textAlign: 'center' }}>
                                <FormLabel component="legend">Move to Dataset</FormLabel>
                                <RadioGroup aria-label="dataset" name="dataset" style={{ display: 'flex', flexDirection: 'row' }} value={selectedDataset} onChange={(e) => setSelectedDataset(e.target.value)}>
                                    {availableDatasetList.map(item => (
                                        <FormControlLabel key={item.id} value={item.id} disabled={item.id == datasetid} control={<Radio size='small' />} label={item.id} />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Typography height={60} textAlign='center' variant='caption' width='100%'>{availableDatasetList.filter(item => item.id === selectedDataset)[0]?.label}</Typography>
                        <Box width={'100%'} mb={2}>
                            <TextField
                                fullWidth
                                multiline
                                label='Comments'
                                maxRows={2}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            />
                        </Box>
                        <Box display='flex' width='100%' justifyContent='flex-end' mb={2}>
                            <Button disabled={datasetid == selectedDataset || comment.trim().length == 0 || checkedCompanyList?.length == 0} variant='contained' color='primary' onClick={updateStageDataset}>Save</Button>
                        </Box>
                    </Box>
                </Box>
            </Box >
        </div >
    )
}

export default ManageSTAGEdatasets 