import React from 'react'
import { Box, colors, IconButton, LinearProgress } from '@mui/material'
import { AddBoxRounded } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { useStore } from '../store/StoredData';

const MasterCategories = (props) => {
    let params = useParams();

    const {
        initDateRange, selectDateRange,
        masterCategories, setMasterCategories,
        masterSubCategories, setMasterSubCategories,
        masterCategoriesSubCategoriesTree, setMasterCategoriesSubCategoriesTree,
        assignedCategoriesAndSubCategories, setAssignedCategoriesAndSubCategories, articleContent
    } = useStore();

    const bodyScrollHeight = document.body.scrollHeight;
    const bodyOffsetHeight = document.body.offsetHeight;
    const htmlScrollHeight = document.documentElement.scrollHeight;
    const htmlClientHeight = document.documentElement.clientHeight;
    const htmlOffsetHeight = document.documentElement.offsetHeight;
    const pageHeight = Math.max(bodyScrollHeight, bodyOffsetHeight, htmlScrollHeight, htmlClientHeight, htmlOffsetHeight) / 1.1;

    const assignSubCategory = async (sub_cat_id, cat_id) => {
        await axios.put(`/api/save-article-subcat/${params.id}`,
            { master_category_id: cat_id, sub_category_id: sub_cat_id })
            .then(resp => {
                console.log('RESP From updateArticleCatsSubCats API === ', resp.data.message);
                window.location.reload();
            })
            .catch(error => console.error('ERROR From updateArticleCatsSubCats API === ', error))
    };

    console.log('masterCategoriesSubCategoriesTree :::: ', masterCategoriesSubCategoriesTree)
    return (
        <>
            <div style={{ border: '1px solid #CCC', boxShadow: '1px 2px 2px #CCC', height: pageHeight, overflowY: 'auto' }}>
                <h4 style={{ textAlign: 'left', margin: 0, padding: 5, color: colors.deepPurple[800] }}>{props.title}</h4>
                {masterCategoriesSubCategoriesTree && masterCategoriesSubCategoriesTree.length
                    ? <ul style={{ listStyle: 'none', textAlign: 'left', margin: 0, padding: 0 }}>
                        {masterCategoriesSubCategoriesTree.map((category, idx) =>
                            <li key={idx} id={category.id} style={{ fontSize: 15, fontWeight: 600, border: '1px solid #555', borderRadius: 3, margin: 15, padding: '5px 10px', backgroundColor: colors.deepPurple[50] }}>
                                <span style={{ lineHeight: 2, color: colors.deepPurple[700] }}>{category.cat_name}</span>
                                {category.subcats && category.subcats.length
                                    ? <ul style={{ listStyle: 'none', textAlign: 'left', margin: 0, padding: 0 }}>
                                        {category.subcats.map((subcat, idx) => {
                                            if (subcat.sub_cat_id) return <li key={idx} id={subcat.cat_id} style={{ fontSize: 13, color: colors.deepPurple[500], border: '1px solid #999', borderRadius: 3, margin: 3, padding: '0 5px', backgroundColor: colors.common.white }}>
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    {subcat.sub_cat_name}
                                                    {props.isEditable &&
                                                        <IconButton size='small' onClick={() => {
                                                            alert(`Saving Sub Category "${subcat.sub_cat_name}", With ID - ${subcat.sub_cat_id}
                                                        \nUnder Category - "${category.cat_name}" ID - ${category.cat_id}
                                                        \nFor Article ID - ${params.id}`);
                                                            assignSubCategory(subcat.sub_cat_id, category.cat_id);
                                                        }}>
                                                            <AddBoxRounded fontSize='10px' style={{ color: colors.deepPurple[500] }} />
                                                        </IconButton>}
                                                </Box>
                                            </li>
                                        }
                                        )}
                                    </ul> : null}
                            </li>
                        )}
                    </ul>
                    : <span>No Data</span>
                }
            </div >
        </>
    )
}

export default MasterCategories