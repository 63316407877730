import { ClearSharp, EditSharp, RemoveRedEyeSharp, SearchSharp } from '@mui/icons-material'
import { Box, Button, CircularProgress, colors, TextField, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Header from '../components/Header'
import Tabs from '../components/Tabs'
import { useStore } from '../store/StoredData'
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'
import ReactDatePicker from 'react-datepicker'
import SortIcon from '@mui/icons-material/Sort';


const OwnerShipTable = () => {

    const history = useHistory()

    const { hasMoreOwnershipFilings, setHasMoreOwnershipFilings,
        currentPageOwnershipFilings, setCurrentPageOwnershipFilings,
        pageSizeOwnershipFilings, setPageSizeOwnershipFilings, ownershipFilingsList, setOwnershipFilingsList,
        ownershipFilingsIndex, setOwnershipFilingsIndex,
        ownershipFilingsSize, setOwnershipFilingsSize, } = useStore()


    const [loading, setLoading] = useState(false)
    const [dateFiledDateRange, setDateFiledUpdatedDateRange] = useState([new Date(), new Date()]);
    const [DateFiledUpdatedStartDate, DateFiledUpdatedEndDate] = dateFiledDateRange;
    const [companyName, setCompanyName] = useState('')
    const [openCompanySort, setOpenCompanySort] = useState(false)
    const [openPublishedonSort, setOpenPublishedonSort] = useState(false)

    const [selectedCompanySort, setSelectedCompanySort] = useState(null)
    const [selectedPublishedSort, setSelectedPublishedSort] = useState(null)

    const [sortState, setSortState] = useState({
        sort_col: "DATE_FILED",
        sort_val: "DESC"
    });

    const handleSortState = (sName, sVal) => {
        setSortState(prev => ({ ...prev, sort_col: sName, sort_val: sVal }))
    }

    const fetchSortedData = (sortColumn, sortValue) => {
        console.log("Current Sort State :::", sortColumn, sortValue);
        setLoading(true)
        setOwnershipFilingsList([])
        axios.get('/api/ownership-news', {
            params: {
                page: 0,
                sort_col: sortColumn,
                sort_val: sortValue
            }
        }).then(resp => {
            if (resp.data.success && resp.data.message.rows.length)
                setOwnershipFilingsList(resp.data.message.rows)
        }).catch(err => console.log("Error ", err))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchOwnershipList(companyName, DateFiledUpdatedStartDate, DateFiledUpdatedEndDate)
    }, [])

    const fetchOwnershipList = (company, startDate, endDate) => {
        setLoading(true)
        setOwnershipFilingsList([]);
        setCurrentPageOwnershipFilings(0);
        axios.get(`/api/filings/ownership-news`, {
            params: {
                page: 0,
                company_name: company,
                startdate: startDate ? format(startDate, "yyyy-MM-dd") : null,
                enddate: endDate ? format(endDate, "yyyy-MM-dd") : null,
            }
        }).then((resp) => {
            let { count, rows } = resp.data.message;
            console.log("Ownership Table ::", rows)
            if (count) {
                setOwnershipFilingsList(rows)
                setOwnershipFilingsIndex(0);
                setOwnershipFilingsSize(count);

                /* MANAGE NEXT PAGE CALL OR NOT */
                if (count < pageSizeOwnershipFilings) {
                    // console.log('Looks Like No More Rows From Server...., Stopping On Page - ', currentPage)
                    setHasMoreOwnershipFilings(false);
                } else {
                    setHasMoreOwnershipFilings(true);
                    // console.log('*** Looks Like More Rows In Server...., Increasing the Page Count from = ', currentPage)
                    setCurrentPageOwnershipFilings(1);
                }
            } else {
                setOwnershipFilingsList([]);
                setOwnershipFilingsIndex(0);
                setOwnershipFilingsSize(0);
            }
        }).catch(err => console.log("Error :::", err))
            .finally(() => setLoading(false))
    }



    const fetchMoreOwnershipFilings = async () => {
        const resp = await axios.get("/api/filings/ownership-news", {
            params: {
                page: currentPageOwnershipFilings,
            }
        })
        console.log("fetchArticlesData :::", resp.data.message)
        const { count, rows } = resp.data.message;

        if (count < pageSizeOwnershipFilings) {
            console.log('Looks Like No More Rows From Server...., Stopping On Page - ', currentPageOwnershipFilings)
            setHasMoreOwnershipFilings(false);
        } else {
            setHasMoreOwnershipFilings(true);
            console.log('*** Looks Like More Rows In Server...., Increasing the Page Count from = ', currentPageOwnershipFilings)
            setCurrentPageOwnershipFilings(currentPage => currentPage + 1);
        }
        return rows;
    };

    const fetchMoreData = async () => {
        console.log('Trying To Fetch More Data......... for page - ', currentPageOwnershipFilings)
        const moreRows = await fetchMoreOwnershipFilings();
        console.log('fetchMoreArticles() LEN = ', moreRows.length, moreRows)
        setOwnershipFilingsList(prev => [...prev, ...moreRows]);
    }

    const resetSearchBar = () => {
        setCurrentPageOwnershipFilings(0)
        setCompanyName("")
        // setDateFiledUpdatedDateRange([null, null])
        fetchOwnershipList("", dateFiledDateRange[0], dateFiledDateRange[1])
    }

    return (
        <>
            <Header />
            <Tabs />
            <Box display='flex' justifyContent='flex-start' alignContent='center' alignItems='center' mx={1} px={1} py={0.5} my={0.5} fontSize={12} bgcolor={colors.blueGrey[100]}>
                <Box width='10%' fontWeight={700} textAlign="left">Action</Box>
                <Box width='20%' fontWeight={700} textAlign="left" display='flex' alignItems='center' position='relative'>
                    <span title='sort' style={{ cursor: 'pointer' }}>
                        <SortIcon fontSize='small' color='action' onClick={() => setOpenCompanySort(!openCompanySort)} />
                    </span>
                    <span>&nbsp;Company Name</span>
                    {openCompanySort &&
                        <Box position='absolute' width='120px' height='max-content' bgcolor='#fff' border={1} borderRadius={1} borderColor="#e0e0e0" zIndex={9} top={20} left={0} textAlign='left'>
                            <Button size='small' sx={{ textTransform: 'capitalize' }} p={0.5} fullWidth disabled={selectedCompanySort === "ASC"} onClick={(event) => {
                                setSelectedCompanySort("ASC");
                                fetchSortedData("title", "ASC");
                                handleSortState('TITLE', 'ASC')
                                setOpenCompanySort(false)
                            }}>a-Z</Button>
                            <Button size='small' sx={{ textTransform: 'capitalize' }} p={0.5} fullWidth disabled={selectedCompanySort === "DESC"} onClick={(event) => {
                                setSelectedCompanySort("DESC");
                                fetchSortedData("title", "DESC");
                                handleSortState('TITLE', 'DESC')
                                setOpenCompanySort(false)
                            }}>Z-a</Button>
                        </Box>
                    }
                </Box>
                <Box width='10%' fontWeight={700} textAlign="center" display='flex' alignItems='center' position='relative'>Form Type</Box>
                <Box width='30%' fontWeight={700} textAlign="center" display='flex' alignItems='center' position='relative'>Filing Exhibits</Box>
                <Box width='10%' fontWeight={700} textAlign="center" display='flex' alignItems='center' position='relative'>Company<br />Priority</Box>
                <Box width='20%' fontWeight={700} textAlign="center" display='flex' alignItems='left' position='relative'>Date Filed</Box>
                <Box width='10%' fontWeight={700} textAlign="center" display='flex' alignItems='center'>Filing ID</Box>
            </Box>

            {/* Search Bar */}
            <Box display='flex' justifyContent='space-around' alignItems='center' textAlign='left' mx={0.5} bgcolor={colors.blueGrey[50]} fontSize={13} sx={{ borderBottomLeftRadius: 5, borderBottomRightRadius: 5 }}>
                <Box width='10%'></Box>
                <Box width='20%' mx={1}>
                    <TextField variant='standard' size='small' name="insider_name" value={companyName} onChange={e => { setCompanyName(e.target.value) }} />
                </Box>
                <Box width='10%'></Box>
                <Box width='30%'></Box>
                <Box width='20%'>
                    <ReactDatePicker
                        // disabled
                        id="ArticleReactDatePicker"
                        className="ReactDatePicker"
                        selectsRange={true}
                        startDate={DateFiledUpdatedStartDate}
                        endDate={DateFiledUpdatedEndDate}
                        onChange={(update) => {
                            console.log('setArticleUpdatedDateRange = ', update);
                            setDateFiledUpdatedDateRange(update);
                        }}
                    />
                </Box>
                {/* <Box width='10%'></Box> */}
                <Box width='10%' display="flex" flexDirection="column">
                    <Button variant='outlined' color="secondary" sx={{ fontSize: 12, my: 0.25, p: 0 }} endIcon={<SearchSharp fontSize='small' color='secondary' />} title='Search For Resutls' onClick={() => fetchOwnershipList(companyName, DateFiledUpdatedStartDate, DateFiledUpdatedEndDate)} >search</Button>
                    <Button variant='outlined' color="info" sx={{ fontSize: 12, my: 0.25, p: 0 }} endIcon={<ClearSharp fontSize='small' color='info' />} title='Reset Search Bar' onClick={resetSearchBar} >clear</Button>
                </Box>
            </Box>

            {loading
                ? <Box display="flex" justifyContent="center" alignItems="center" margin="15%"><CircularProgress /></Box>
                : <> {ownershipFilingsList.length > 0 
                    ? <>
                        {/* TABLE DATA SECTION */}
                        <Box id="scrollableDiv" height={520} overflow="auto" px={1}>
                            <InfiniteScroll
                                dataLength={ownershipFilingsList.length}
                                next={fetchMoreData}
                                hasMore={hasMoreOwnershipFilings}
                                loader={<Box display='flex' justifyContent='center' my={2}><CircularProgress /></Box>}
                                scrollableTarget="scrollableDiv"
                                endMessage={
                                    <p style={{ textAlign: 'center', fontSize: 16, fontWeight: 400, color: colors.blue[600] }}>
                                        Yay! You have seen it all
                                    </p>
                                }
                                key={1}
                            >
                                {ownershipFilingsList.map((own, idx) =>
                                    <Box key={idx} display="flex" justifyContent="flex-start" alignContent="center" alignItems="center"
                                        my={0.5} border={1} borderColor="#CCC" fontSize={12}>
                                        <Box width='10%' display="flex" justifyContent="flex-start">
                                            <Box
                                                onClick={() => { history.push(`/ownership-news/${own.sk_txn_fs_platter}`); setOwnershipFilingsIndex(idx) }}
                                                px={1}
                                                style={{ cursor: 'pointer', textDecoration: 'none' }}
                                                role="button"
                                                title='Edit Ownership Form'>
                                                <EditSharp color='secondary' fontSize="small" />
                                            </Box>
                                        </Box>
                                        <Box width='20%' textAlign="left" display='flex' alignItems='center'>{own.company_name}</Box>
                                        <Box width='10%' textAlign="center">{own.form_type}</Box>
                                        <Box width='30%' textAlign="left" pl={1}>{own.exhibit_file_desc.map(exb => <p>{exb}</p>)}</Box>
                                        <Box width='10%' textAlign="center" pl={1}>{own.ranking}</Box>
                                        <Box width='20%' textAlign="left" paddingLeft={5}>{format(new Date(own.date_filed), "MMM dd, yy")}</Box>
                                        <Box width='10%' textAlign="left">{own.sk_txn_fs_platter}</Box>
                                    </Box>
                                )}
                            </InfiniteScroll>
                        </Box>
                    </>
                    : <Box width="100%" textAlign="center" my={5}>
                        <Typography variant='body1' color={colors.deepOrange[300]}>No Data To Display For Selected Settings</Typography>
                    </Box>
                }
                </>}
        </>
    )
}

export default OwnerShipTable