import { useState } from 'react';
import Switch from '@mui/material/Switch';

import Header from '../components/Header'
import Tabs from '../components/Tabs'


const Comments = (props) => {

    // if (loading)
    //     return <LinearProgress color='secondary'></LinearProgress>
    const [articleList, setArticleList] = useState([
        {
            id: 123,
            Title: "Werewolf Therapeutics, Inc. reports $11.9 million Q1 loss",
            Description: "Werewolf Therapeutics, Inc. [HOWL] has reported fiscal Q1 loss of $11.90 million. On a per-share basis, the DE based company said its diluted earnings are $0.34.The Pharmaceutical Preparations firm posted revenue of $4.40 million in this period.",
            comments: [
                {
                    user: "12344",
                    comment: "�If you live with passion you will always be happy. If you live with passion you will always be happy. If you live with passion you will always be happy. If you live with passion you will always be happy. If you live with passion you will always be happy. If you live with passion you will always be happy.�",
                    isHidden: true
                },
                {
                    user: "12345",
                    comment: "�If you not live with passion you will always be un-happy.�",
                    isHidden: false
                },
                {
                    user: "12346",
                    comment: "�If you live with passion you will always be happy.�",
                    isHidden: true
                },
                {
                    user: "12347",
                    comment: "�If you not live with passion you will always be un-happy.�",
                    isHidden: false
                }
            ]
        },
        {
            id: 123,
            Title: "Werewolf Therapeutics, Inc. reports $11.9 million Q1 loss",
            Description: "Werewolf Therapeutics, Inc. [HOWL] has reported fiscal Q1 loss of $11.90 million. On a per-share basis, the DE based company said its diluted earnings are $0.34.The Pharmaceutical Preparations firm posted revenue of $4.40 million in this period.",
            comments: [
                {
                    user: "12344",
                    comment: "�If you live with passion you will always be happy. If you live with passion you will always be happy. If you live with passion you will always be happy. If you live with passion you will always be happy. If you live with passion you will always be happy. If you live with passion you will always be happy.�",
                    isHidden: true
                },
                {
                    user: "12345",
                    comment: "�If you not live with passion you will always be un-happy.�",
                    isHidden: false
                },
                {
                    user: "12346",
                    comment: "�If you live with passion you will always be happy.�",
                    isHidden: true
                },
                {
                    user: "12347",
                    comment: "�If you not live with passion you will always be un-happy.�",
                    isHidden: false
                }
            ]
        },
        // {
        //   id: 123,
        //   Title: "Articletitle",
        //   Description: "this is Article Description",
        //   comments: [
        //     {
        //       user: "2312424",
        //       comment: "iduidj wdiqwu qwjdwqd w",
        //       isHidden: true
        //     },
        //     {
        //       user: "2312421",
        //       comment: "iduidj wdiqwu qwjdwqd w",
        //       isHidden: false
        //     }
        //   ]
        // }
    ])
    console.log("This is article list", articleList)


    const handleChange = (articleId, record) => {

        let articles = articleList;
        const currentIndex = articles.find(r => r.id == articleId).comments.findIndex(r => r.user == record.user)
        console.log("entered...")
        let mutatedRecord = { ...record, isHidden: !record.isHidden }
        articles.find(r => r.id == articleId).comments[currentIndex] = mutatedRecord;
        console.log("entered...")
        console.log(articles)
        setArticleList((prev) => [...articles])
        // console.log(articles)
        // console.log(mutatedRecord)

        // setArticleList(event.target.value);
    };

    return (
        <>
            <Header />
            <Tabs />
            <hr />
            {/* <h1>Moderate Comment Section</h1> */}
            {/* <div style={{ margin: '12px 20px' }}>
                {articleList.map(article => (
                    <div key={article.id}>
                        <h3 style={{"color": "#131313","fontSize": "20px"}}>{article.Title}</h3>
                        <p style={{width:"80%",alignItems:"center","color": '#131313', lineHeight: "1.4",fontWeight: '400'}}>{article.Description}</p>
                        {article.comments.map(comment => (
                            <div style={{ width: '80%', display: 'flex', justifyContent: 'space-between', alignItems: 'start', border: '1px solid gray', marginBottom: "5px" }}>
                                <p style={{ color: comment.isHidden ? "gray" : "black", width: "60%" }}>{comment.comment}</p>
                                <Switch key={comment.user} checked={comment.isHidden} onChange={(evt) => handleChange(article.id, comment)} />
                            </div>
                            // </div>
                        ))}
                    </div>
                ))}
            </div> */}

            <div style={{ margin: '12px 20px' }}>
                <ul style={{ listStyle: 'none' }}>
                    {articleList.map(article => (
                        <li key={article.id}>
                            <h4 style={{ "color": "#131313", "fontSize": "20px" }}>{article.Title}</h4>
                            <p style={{ width: "80%", alignItems: "center", "color": '#131313', lineHeight: "1.4", fontWeight: '400' }}>{article.Description}</p>
                            {article.comments.map(comment => (
                                <li style={{ width: '80%', display: 'flex', justifyContent: 'space-between', alignItems: 'start', border: '1px solid gray', marginBottom: "5px" }}>
                                    <p style={{ color: comment.isHidden ? "gray" : "black", width: "60%" }}>{comment.comment}</p>
                                    <Switch key={comment.user} checked={comment.isHidden} onChange={(evt) => handleChange(article.id, comment)} />
                                </li>
                                // </div>
                            ))}
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
}

export default Comments;