import React, { Component } from "react";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import { Button, Box, CircularProgress, Paper, TextField } from "@mui/material";

import AuthService from "../services/auth.service";
// import {getCurrentUser, login} from "../services/auth.service";

const required = (value) => {
	if (!value) {
		return (
			<Box className="alert alert-danger" role="alert">
				This field is required!
			</Box>
		);
	}
};

export default class Login extends Component {
	constructor(props) {
		super(props);
		this.handleLogin = this.handleLogin.bind(this);
		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onChangePassword = this.onChangePassword.bind(this);
		this.setCurrentUser = this.props.setCurrentUser.bind(this);

		this.state = {
			email: "",
			password: "",
			loading: false,
			message: "",
			currentUser: this.props.currentUser,
		};
	}

	onChangeEmail(e) {
		this.setState({ email: e.target.value });
	}

	onChangePassword(e) {
		this.setState({ password: e.target.value });
	}

	async handleLogin(e) {
		e.preventDefault();

		this.setState({
			message: "",
			loading: true,
			currentUser: await AuthService.getCurrentUser(),
		});

		this.form.validateAll();

		if (this.checkBtn.context._errors.length === 0) {
			this.setState({ loading: true });
			AuthService.login(this.state.email, this.state.password).then(
				(response) => {
					if (response.data.success === true) {
						localStorage.setItem('user', JSON.stringify(response.data.message))
						this.props.setCurrentUser(response.data.message);
						this.props.setSession(true);
						this.setState({ currentUser: response.data.message });
						window.location.replace('/')
						// window.history.back()
					}

					if (response.data.success === false) {
						this.setState({
							loading: false,
							message: response.data.message,
						});
					}
				},
				(error) => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					this.setState({
						loading: false,
						message: resMessage,
					});
				}
			);
		} else {
			this.setState({
				loading: false,
			});
		}
	}

	render() {
		return (
			<Box
				width="100%"
				height="100vh"
				display="flex"
				justifyContent="center"
				alignItems="center"
			>
				<Paper
					variant="elevation"
					elevation={2}
					minHeight="30vh"
					padding="50px"
				>
					<Box
						width="100%"
						display="flex"
						justifyContent="center"
						marginTop={5}
					>
						<img
							src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
							alt="profile-img"
							width="95px"
							height="96px"
							style={{ borderRadius: "50%" }}
						/>
					</Box>
					<Box
						maxWidth="350px"
						padding="40px"
						display="flex"
						flexDirection="column"
					>
						<Form
							onSubmit={this.handleLogin}
							ref={(c) => {
								this.form = c;
							}}
						>
							<Box my={3}>
								{/* <label htmlFor="email" >email</label> */}
								<TextField
									size="small"
									variant="standard"
									placeholder="Email"
									required
									fullWidth
									type="text"
									name="email"
									value={this.state.email}
									onChange={this.onChangeEmail}
									validations={[required]}
								/>
							</Box>

							<Box my={3}>
								{/* <label htmlFor="password">Password</label> */}
								<TextField
									size="small"
									variant="standard"
									placeholder="Password"
									required
									fullWidth
									type="password"
									name="password"
									value={this.state.password}
									onChange={this.onChangePassword}
									validations={[required]}
								/>
							</Box>

							<Box my={3} width="100%" display="flex" justifyContent="center">
								<Button
									variant="contained"
									color="primary"
									// className="btn btn-primary btn-block"
									disabled={
										!this.state.email ||
										!this.state.password ||
										this.state.loading
									}
									onClick={(e) => this.handleLogin(e)}
								>
									{this.state.loading && (
										// <span className="spinner-border spinner-border-sm"></span>
										<CircularProgress style={{ padding: '10px' }} />
									)}
									<span>Login</span>
								</Button>
							</Box>

							{this.state.message && (
								<Box>
									<Box className="alert alert-danger" role="alert">
										{this.state.message}
									</Box>
								</Box>
							)}
							<CheckButton
								style={{ display: "none" }}
								ref={(c) => {
									this.checkBtn = c;
								}}
							/>
						</Form>
					</Box>
				</Paper>
			</Box>
		);
	}
}
